<mat-toolbar
  class="main-mat-toolbar"
  color="primary"
  [ngStyle]="getMatToolbarStyles()"
>
  <div class="nav-menu-in-header">
    <ul class="links-in-header">
      <li (click)="delayedRouting('home')">
        <span style="font-size: 30px"
          >CATCH<span style="font-size: 10px">V3</span></span
        >
      </li>
      <li (click)="delayedRouting('about')">About</li>
      <li (click)="delayedRouting('apis')">APIs</li>

      <li (click)="delayedRouting('contact')">Contact</li>
    </ul>

    <div class="spacer">
      This div will use up in-between space and this text won't be seen
    </div>

    <button mat-icon-button (click)="delayedRouting('settings')">
      <fa-icon icon="cog" [fixedWidth]="true"></fa-icon>
    </button>
  </div>

  <div class="nav-menu-in-side-nav">
    <button matRipple mat-icon-button (click)="_openSidenav()">
      <fa-icon icon="bars" [fixedWidth]="true"></fa-icon>
    </button>

    <div class="spacer">
      This div will use up in-between space and this text won't be seen
    </div>

    <div class="title" (click)="delayedRouting('home')">
      <span style="font-size: 30px">CATCH</span>
    </div>

    <div class="spacer">
      This div will use up in-between space and this text won't be seen
    </div>

    <button mat-icon-button (click)="delayedRouting('settings')">
      <fa-icon icon="cog" [fixedWidth]="true"></fa-icon>
    </button>
  </div>
</mat-toolbar>

<div class="home-page-container">
  <div class="long-title" [ngStyle]="{ height: titleHeightPx + 'px' }">
    <div>
      <!--  -->
      <span>C</span>OMET{{ " " }}
      <!--  -->
      <span>A</span>STEROID
      <!--  -->
      <span>T</span>ELESCOPIC
      <!--  -->
      <span>C</span>ATALOG
      <!--  -->
      <span>H</span>UNTER
    </div>
  </div>

  <div class="form-wrapper" [ngStyle]="{ height: searchFieldHeightPx }">
    <app-search-field></app-search-field>
  </div>
</div>

<div class="contact-page-wrapper">
  <h1 class="main-heading">CONTACT</h1>
  <form [formGroup]="form" (ngSubmit)="submit()">
    <mat-card color="primary" class="mat-card-theme-override">
      <div id="name-email-wrapper">
        <mat-form-field id="fullname">
          <input matInput placeholder="Name" formControlName="username" />
          <mat-error *ngIf="form.get('username')?.invalid">
            Name is required
          </mat-error>
        </mat-form-field>
        <mat-form-field id="email">
          <input
            matInput
            placeholder="Email"
            formControlName="email"
            type="email"
          />
          <mat-error *ngIf="form.get('email')?.hasError('required')">
            Email is required
          </mat-error>
          <mat-error *ngIf="form.get('email')?.hasError('email')">
            Email should be valid
          </mat-error>
        </mat-form-field>
      </div>

      <mat-form-field id="message">
        <textarea
          matInput
          placeholder="Message"
          formControlName="message"
          minlength="10"
          maxlength="1000"
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="10"
          cdkAutosizeMaxRows="10"
        ></textarea>
        <mat-hint align="end"
          >{{ form.get("message")?.value?.length }} / 1000</mat-hint
        >
        <mat-error *ngIf="form.get('message')?.hasError('required')">
          Message is required
        </mat-error>
        <mat-error *ngIf="form.get('message')?.hasError('minlength')">
          Message must be longer than 10 characters
        </mat-error>
        <mat-error *ngIf="form.get('message')?.hasError('maxlength')">
          Message must be less than 1000 characters
        </mat-error>
      </mat-form-field>

      <div id="buttons">
        <div id="recaptcha-wrapper">
          <div id="recaptcha-id"></div>
        </div>
        <div id="submit-button-wrapper">
          <button
            mat-raised-button
            color="accent"
            [disabled]="!isFormSubmittable()"
          >
            Send
          </button>
        </div>
      </div>
    </mat-card>
  </form>
</div>

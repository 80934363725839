import { IApiDataCaughtResult } from '../models/IApiDataCaughtResult';

/**
 *
 */
export const apiDataMockResult: IApiDataCaughtResult = {
  count: 778,
  data: [
    {
      airmass: 1.055449,
      archive_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_tricam%3Ap20020121_obsdata_20020121132624c',
      cutout_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_tricam%3Ap20020121_obsdata_20020121132624c?format=fits&size=5.00arcmin&ra=177.51011&dec=15.25013',
      date: '2002-01-21 13:26:54.000',
      ddec: 9.813682,
      dec: 15.25013,
      delta: 2.83330835056674,
      dra: -2.64437,
      drh: -5.0789549,
      elong: 128.5424,
      exposure: 60,
      filter: 'NONE',
      maglimit: null,
      mjd_start: 52295.56,
      mjd_stop: 52295.560694444444,
      phase: 12.5942,
      preview_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_tricam%3Ap20020121_obsdata_20020121132624c?format=jpeg&size=5.00arcmin&ra=177.51011&dec=15.25013',
      product_id: 'P20020121_OBSDATA_20020121132624C',
      ra: 177.51011,
      rh: 3.531535016403,
      sangle: 103.483,
      seeing: null,
      source: 'neat_palomar_tricam',
      source_name: 'NEAT Palomar Tricam',
      true_anomaly: 254.1847,
      unc_a: 4.967,
      unc_b: 0.359,
      unc_theta: -25.651,
      vangle: 116.10500000000002,
      vmag: 17.33,
    },
    {
      airmass: 1.055584,
      archive_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_tricam%3Ap20020121_obsdata_20020121134124c',
      cutout_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_tricam%3Ap20020121_obsdata_20020121134124c?format=fits&size=5.00arcmin&ra=177.50992&dec=15.25081',
      date: '2002-01-21 13:41:54.000',
      ddec: 9.806215,
      dec: 15.25081,
      delta: 2.83316207193636,
      dra: -2.62949,
      drh: -5.0789922,
      elong: 128.5529,
      exposure: 60,
      filter: 'NONE',
      maglimit: null,
      mjd_start: 52295.57041666667,
      mjd_stop: 52295.57111111111,
      phase: 12.5924,
      preview_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_tricam%3Ap20020121_obsdata_20020121134124c?format=jpeg&size=5.00arcmin&ra=177.50992&dec=15.25081',
      product_id: 'P20020121_OBSDATA_20020121134124C',
      ra: 177.50992,
      rh: 3.531504458169,
      sangle: 103.47899999999998,
      seeing: null,
      source: 'neat_palomar_tricam',
      source_name: 'NEAT Palomar Tricam',
      true_anomaly: 254.1862,
      unc_a: 4.967,
      unc_b: 0.359,
      unc_theta: -25.65,
      vangle: 116.10500000000002,
      vmag: 17.33,
    },
    {
      airmass: 1.038482,
      archive_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_tricam%3Ap20020222_obsdata_20020222120052c',
      cutout_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_tricam%3Ap20020222_obsdata_20020222120052c?format=fits&size=5.00arcmin&ra=174.62244&dec=17.97594',
      date: '2002-02-22 12:01:22.000',
      ddec: 13.76413,
      dec: 17.97594,
      delta: 2.49069892940669,
      dra: -23.1946,
      drh: -5.1794841,
      elong: 159.9563,
      exposure: 60,
      filter: 'NONE',
      maglimit: null,
      mjd_start: 52327.500601851854,
      mjd_stop: 52327.501296296294,
      phase: 5.6655,
      preview_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_tricam%3Ap20020222_obsdata_20020222120052c?format=jpeg&size=5.00arcmin&ra=174.62244&dec=17.97594',
      product_id: 'P20020222_OBSDATA_20020222120052C',
      ra: 174.62244,
      rh: 3.436865439347,
      sangle: 69.62299999999999,
      seeing: null,
      source: 'neat_palomar_tricam',
      source_name: 'NEAT Palomar Tricam',
      true_anomaly: 258.8381,
      unc_a: 5.65,
      unc_b: 0.393,
      unc_theta: -24.108,
      vangle: 114.76100000000002,
      vmag: 16.941,
    },
    {
      airmass: 1.038667,
      archive_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_tricam%3Ap20020222_obsdata_20020222121552c',
      cutout_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_tricam%3Ap20020222_obsdata_20020222121552c?format=fits&size=5.00arcmin&ra=174.62074&dec=17.9769',
      date: '2002-02-22 12:16:22.000',
      ddec: 13.75189,
      dec: 17.9769,
      delta: 2.49063084002561,
      dra: -23.1647,
      drh: -5.179512,
      elong: 159.9648,
      exposure: 60,
      filter: 'NONE',
      maglimit: null,
      mjd_start: 52327.51101851852,
      mjd_stop: 52327.511712962965,
      phase: 5.6632,
      preview_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_tricam%3Ap20020222_obsdata_20020222121552c?format=jpeg&size=5.00arcmin&ra=174.62074&dec=17.9769',
      product_id: 'P20020222_OBSDATA_20020222121552C',
      ra: 174.62074,
      rh: 3.436834277647,
      sangle: 69.596,
      seeing: null,
      source: 'neat_palomar_tricam',
      source_name: 'NEAT Palomar Tricam',
      true_anomaly: 258.8396,
      unc_a: 5.65,
      unc_b: 0.393,
      unc_theta: -24.107,
      vangle: 114.75999999999999,
      vmag: 16.941,
    },
    {
      airmass: 1.038863,
      archive_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_tricam%3Ap20020222_obsdata_20020222123100c',
      cutout_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_tricam%3Ap20020222_obsdata_20020222123100c?format=fits&size=5.00arcmin&ra=174.61904&dec=17.97786',
      date: '2002-02-22 12:31:30.000',
      ddec: 13.74028,
      dec: 17.97786,
      delta: 2.49056228151862,
      dra: -23.1321,
      drh: -5.1795403,
      elong: 159.9733,
      exposure: 60,
      filter: 'NONE',
      maglimit: null,
      mjd_start: 52327.521527777775,
      mjd_stop: 52327.52222222222,
      phase: 5.6609,
      preview_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_tricam%3Ap20020222_obsdata_20020222123100c?format=jpeg&size=5.00arcmin&ra=174.61904&dec=17.97786',
      product_id: 'P20020222_OBSDATA_20020222123100C',
      ra: 174.61904,
      rh: 3.436802838785,
      sangle: 69.56899999999999,
      seeing: null,
      source: 'neat_palomar_tricam',
      source_name: 'NEAT Palomar Tricam',
      true_anomaly: 258.8412,
      unc_a: 5.65,
      unc_b: 0.393,
      unc_theta: -24.107,
      vangle: 114.75999999999999,
      vmag: 16.941,
    },
    {
      airmass: 1.170283,
      archive_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_geodss%3Ag19971002_obsdata_971002110736a',
      cutout_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_geodss%3Ag19971002_obsdata_971002110736a?format=fits&size=5.00arcmin&ra=8.14763&dec=-10.75019',
      date: '1997-10-02 11:07:46.000',
      ddec: -6.94458,
      dec: -10.75019,
      delta: 2.43843648775416,
      dra: -28.3707,
      drh: 5.1629701,
      elong: 165.55,
      exposure: 20,
      filter: 'NONE',
      maglimit: null,
      mjd_start: 50723.46361111111,
      mjd_stop: 50723.463842592595,
      phase: 4.1893,
      preview_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_geodss%3Ag19971002_obsdata_971002110736a?format=jpeg&size=5.00arcmin&ra=8.14763&dec=-10.75019',
      product_id: 'G19971002_OBSDATA_971002110736A',
      ra: 8.14763,
      rh: 3.416742577319,
      sangle: 181.7,
      seeing: null,
      source: 'neat_maui_geodss',
      source_name: 'NEAT Maui GEODSS',
      true_anomaly: 99.5381,
      unc_a: 0.457,
      unc_b: 0.358,
      unc_theta: 28.325,
      vangle: 60.77699999999999,
      vmag: 16.871,
    },
    {
      airmass: 1.170389,
      archive_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_geodss%3Ag19971002_obsdata_971002112309a',
      cutout_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_geodss%3Ag19971002_obsdata_971002112309a?format=fits&size=5.00arcmin&ra=8.14556&dec=-10.75069',
      date: '1997-10-02 11:23:19.000',
      ddec: -6.9305,
      dec: -10.75069,
      delta: 2.43848825602172,
      dra: -28.3531,
      drh: 5.1629451,
      elong: 165.545,
      exposure: 20,
      filter: 'NONE',
      maglimit: null,
      mjd_start: 50723.47440972222,
      mjd_stop: 50723.474641203706,
      phase: 4.1907,
      preview_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_geodss%3Ag19971002_obsdata_971002112309a?format=jpeg&size=5.00arcmin&ra=8.14556&dec=-10.75069',
      product_id: 'G19971002_OBSDATA_971002112309A',
      ra: 8.14556,
      rh: 3.416774776346,
      sangle: 181.747,
      seeing: null,
      source: 'neat_maui_geodss',
      source_name: 'NEAT Maui GEODSS',
      true_anomaly: 99.5397,
      unc_a: 0.457,
      unc_b: 0.358,
      unc_theta: 28.324,
      vangle: 60.77799999999999,
      vmag: 16.872,
    },
    {
      airmass: 1.170443,
      archive_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_geodss%3Ag19971002_obsdata_971002113659a',
      cutout_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_geodss%3Ag19971002_obsdata_971002113659a?format=fits&size=5.00arcmin&ra=8.14371&dec=-10.75114',
      date: '1997-10-02 11:37:09.000',
      ddec: -6.91818,
      dec: -10.75114,
      delta: 2.43853448313155,
      dra: -28.3341,
      drh: 5.1629229,
      elong: 165.5405,
      exposure: 20,
      filter: 'NONE',
      maglimit: null,
      mjd_start: 50723.48401620371,
      mjd_stop: 50723.484247685185,
      phase: 4.1919,
      preview_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_geodss%3Ag19971002_obsdata_971002113659a?format=jpeg&size=5.00arcmin&ra=8.14371&dec=-10.75114',
      product_id: 'G19971002_OBSDATA_971002113659A',
      ra: 8.14371,
      rh: 3.416803420577,
      sangle: 181.789,
      seeing: null,
      source: 'neat_maui_geodss',
      source_name: 'NEAT Maui GEODSS',
      true_anomaly: 99.5412,
      unc_a: 0.457,
      unc_b: 0.358,
      unc_theta: 28.323,
      vangle: 60.778999999999996,
      vmag: 16.872,
    },
    {
      airmass: 1.302671,
      archive_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_geodss%3Ag19971029_obsdata_971029072300a',
      cutout_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_geodss%3Ag19971029_obsdata_971029072300a?format=fits&size=5.00arcmin&ra=3.89604&dec=-11.18307',
      date: '1997-10-29 07:23:10.000',
      ddec: 2.36028,
      dec: -11.18307,
      delta: 2.66347304565651,
      dra: -17.718,
      drh: 5.0898035,
      elong: 141.5449,
      exposure: 20,
      filter: 'NONE',
      maglimit: null,
      mjd_start: 50750.30763888889,
      mjd_stop: 50750.30787037037,
      phase: 10.1718,
      preview_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_geodss%3Ag19971029_obsdata_971029072300a?format=jpeg&size=5.00arcmin&ra=3.89604&dec=-11.18307',
      product_id: 'G19971029_OBSDATA_971029072300A',
      ra: 3.89604,
      rh: 3.496244736403,
      sangle: 230.838,
      seeing: null,
      source: 'neat_maui_geodss',
      source_name: 'NEAT Maui GEODSS',
      true_anomaly: 103.5372,
      unc_a: 0.424,
      unc_b: 0.327,
      unc_theta: 26.573,
      vangle: 62.138000000000005,
      vmag: 17.156,
    },
    {
      airmass: 1.281664,
      archive_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_geodss%3Ag19971029_obsdata_971029072537a',
      cutout_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_geodss%3Ag19971029_obsdata_971029072537a?format=fits&size=5.00arcmin&ra=3.89582&dec=-11.18304',
      date: '1997-10-29 07:25:47.000',
      ddec: 2.362701,
      dec: -11.18304,
      delta: 2.66349429819121,
      dra: -17.7187,
      drh: 5.0897978,
      elong: 141.543,
      exposure: 20,
      filter: 'NONE',
      maglimit: null,
      mjd_start: 50750.30945601852,
      mjd_stop: 50750.3096875,
      phase: 10.1722,
      preview_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_geodss%3Ag19971029_obsdata_971029072537a?format=jpeg&size=5.00arcmin&ra=3.89582&dec=-11.18304',
      product_id: 'G19971029_OBSDATA_971029072537A',
      ra: 3.89582,
      rh: 3.496250077687,
      sangle: 230.839,
      seeing: null,
      source: 'neat_maui_geodss',
      source_name: 'NEAT Maui GEODSS',
      true_anomaly: 103.5374,
      unc_a: 0.424,
      unc_b: 0.327,
      unc_theta: 26.573,
      vangle: 62.138000000000005,
      vmag: 17.156,
    },
    {
      airmass: 1.305257,
      archive_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_geodss%3Ag19971029_obsdata_971029073726a',
      cutout_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_geodss%3Ag19971029_obsdata_971029073726a?format=fits&size=5.00arcmin&ra=3.89483&dec=-11.18291',
      date: '1997-10-29 07:37:36.000',
      ddec: 2.37368,
      dec: -11.18291,
      delta: 2.66359034512195,
      dra: -17.7206,
      drh: 5.0897723,
      elong: 141.5345,
      exposure: 20,
      filter: 'NONE',
      maglimit: null,
      mjd_start: 50750.317662037036,
      mjd_stop: 50750.31789351852,
      phase: 10.1741,
      preview_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_geodss%3Ag19971029_obsdata_971029073726a?format=jpeg&size=5.00arcmin&ra=3.89483&dec=-11.18291',
      product_id: 'G19971029_OBSDATA_971029073726A',
      ra: 3.89483,
      rh: 3.496274198441,
      sangle: 230.844,
      seeing: null,
      source: 'neat_maui_geodss',
      source_name: 'NEAT Maui GEODSS',
      true_anomaly: 103.5386,
      unc_a: 0.424,
      unc_b: 0.327,
      unc_theta: 26.573,
      vangle: 62.138000000000005,
      vmag: 17.156,
    },
    {
      airmass: 1.28431,
      archive_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_geodss%3Ag19971029_obsdata_971029074044a',
      cutout_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_geodss%3Ag19971029_obsdata_971029074044a?format=fits&size=5.00arcmin&ra=3.89455&dec=-11.18287',
      date: '1997-10-29 07:40:54.000',
      ddec: 2.376757,
      dec: -11.18287,
      delta: 2.66361718899184,
      dra: -17.7208,
      drh: 5.0897651,
      elong: 141.5322,
      exposure: 20,
      filter: 'NONE',
      maglimit: null,
      mjd_start: 50750.31995370371,
      mjd_stop: 50750.320185185185,
      phase: 10.1746,
      preview_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_geodss%3Ag19971029_obsdata_971029074044a?format=jpeg&size=5.00arcmin&ra=3.89455&dec=-11.18287',
      product_id: 'G19971029_OBSDATA_971029074044A',
      ra: 3.89455,
      rh: 3.49628093454,
      sangle: 230.846,
      seeing: null,
      source: 'neat_maui_geodss',
      source_name: 'NEAT Maui GEODSS',
      true_anomaly: 103.5389,
      unc_a: 0.424,
      unc_b: 0.327,
      unc_theta: 26.573,
      vangle: 62.138000000000005,
      vmag: 17.156,
    },
    {
      airmass: 1.307821,
      archive_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_geodss%3Ag19971029_obsdata_971029075156a',
      cutout_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_geodss%3Ag19971029_obsdata_971029075156a?format=fits&size=5.00arcmin&ra=3.89362&dec=-11.18275',
      date: '1997-10-29 07:52:06.000',
      ddec: 2.387228,
      dec: -11.18275,
      delta: 2.66370836468632,
      dra: -17.72,
      drh: 5.0897409,
      elong: 141.5241,
      exposure: 20,
      filter: 'NONE',
      maglimit: null,
      mjd_start: 50750.327731481484,
      mjd_stop: 50750.32796296296,
      phase: 10.1763,
      preview_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_geodss%3Ag19971029_obsdata_971029075156a?format=jpeg&size=5.00arcmin&ra=3.89362&dec=-11.18275',
      product_id: 'G19971029_OBSDATA_971029075156A',
      ra: 3.89362,
      rh: 3.496303796379,
      sangle: 230.851,
      seeing: null,
      source: 'neat_maui_geodss',
      source_name: 'NEAT Maui GEODSS',
      true_anomaly: 103.5401,
      unc_a: 0.424,
      unc_b: 0.327,
      unc_theta: 26.572,
      vangle: 62.13900000000001,
      vmag: 17.156,
    },
    {
      airmass: 1.286419,
      archive_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_geodss%3Ag19971029_obsdata_971029075300a',
      cutout_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_geodss%3Ag19971029_obsdata_971029075300a?format=fits&size=5.00arcmin&ra=3.89353&dec=-11.18274',
      date: '1997-10-29 07:53:10.000',
      ddec: 2.388227,
      dec: -11.18274,
      delta: 2.66371705367152,
      dra: -17.7199,
      drh: 5.0897386,
      elong: 141.5234,
      exposure: 20,
      filter: 'NONE',
      maglimit: null,
      mjd_start: 50750.32847222222,
      mjd_stop: 50750.3287037037,
      phase: 10.1765,
      preview_url:
        'https://sbnsurveys.astro.umd.edu/api/images/urn%3Anasa%3Apds%3Agbo.ast.neat.survey%3Adata_geodss%3Ag19971029_obsdata_971029075300a?format=jpeg&size=5.00arcmin&ra=3.89353&dec=-11.18274',
      product_id: 'G19971029_OBSDATA_971029075300A',
      ra: 3.89353,
      rh: 3.49630597369,
      sangle: 230.852,
      seeing: null,
      source: 'neat_maui_geodss',
      source_name: 'NEAT Maui GEODSS',
      true_anomaly: 103.5402,
      unc_a: 0.424,
      unc_b: 0.327,
      unc_theta: 26.572,
      vangle: 62.13900000000001,
      vmag: 17.156,
    },
    {
      airmass: 1.04074,
      archive_url: null,
      cutout_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20170806095706-22&SIZE=0.0833&POS=237.22374,-23.40706&FORMAT=fits',
      date: '2017-08-06 09:57:57.000',
      ddec: -7.37085,
      dec: -23.40706,
      delta: 2.48214098975971,
      dra: 17.43667,
      drh: -1.060786,
      elong: 106.2417,
      exposure: 100,
      filter: 'i',
      maglimit: null,
      mjd_start: 57971.41466435,
      mjd_stop: 57971.41582175741,
      phase: 19.3917,
      preview_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20170806095706-22&SIZE=0.0833&POS=237.22374,-23.40706&FORMAT=png',
      product_id: '20170806095706-22',
      ra: 237.22374,
      rh: 2.932113526514,
      sangle: 281.493,
      seeing: 2.13968,
      'skymapper:field_id': 1611,
      'skymapper:image_type': 'Main Survey',
      'skymapper:sb_mag': 19.132,
      'skymapper:zpapprox': 27.925419,
      source: 'skymapper_dr4',
      source_name: 'SkyMapperDR4',
      true_anomaly: 344.2389,
      unc_a: 1.197,
      unc_b: 0.11,
      unc_theta: -24.571,
      vangle: 113.10500000000002,
      vmag: 16.296,
    },
    {
      airmass: 1.70029,
      archive_url: null,
      cutout_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20180514174056-08&SIZE=0.0833&POS=343.79196,-16.32033&FORMAT=fits',
      date: '2018-05-14 17:41:47.000',
      ddec: 8.896217,
      dec: -16.32033,
      delta: 3.1918765824629,
      dra: 37.45092,
      drh: 2.7462728,
      elong: 75.0087,
      exposure: 100,
      filter: 'r',
      maglimit: null,
      mjd_start: 58252.73677083,
      mjd_stop: 58252.73792823741,
      phase: 18.4292,
      preview_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20180514174056-08&SIZE=0.0833&POS=343.79196,-16.32033&FORMAT=png',
      product_id: '20180514174056-08',
      ra: 343.79196,
      rh: 3.088866469012,
      sangle: 64.923,
      seeing: 4.11018,
      'skymapper:field_id': null,
      'skymapper:image_type': null,
      'skymapper:sb_mag': 20.2342,
      'skymapper:zpapprox': 28.76372,
      source: 'skymapper_dr4',
      source_name: 'SkyMapperDR4',
      true_anomaly: 44.7109,
      unc_a: 1.065,
      unc_b: 0.066,
      unc_theta: 20.898,
      vangle: 66.40299999999999,
      vmag: 17.051,
    },
    {
      airmass: 1.84049,
      archive_url: null,
      cutout_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20180516171828-12&SIZE=0.0833&POS=344.30721,-16.20355&FORMAT=fits',
      date: '2018-05-16 17:19:20.000',
      ddec: 8.491242,
      dec: -16.20355,
      delta: 3.16933023185389,
      dra: 36.67122,
      drh: 2.765112,
      elong: 76.4014,
      exposure: 100,
      filter: 'r',
      maglimit: null,
      mjd_start: 58254.72118056,
      mjd_stop: 58254.72233796741,
      phase: 18.5364,
      preview_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20180516171828-12&SIZE=0.0833&POS=344.30721,-16.20355&FORMAT=png',
      product_id: '20180516171828-12',
      ra: 344.30721,
      rh: 3.092024970479,
      sangle: 65.06200000000001,
      seeing: 3.99075,
      'skymapper:field_id': null,
      'skymapper:image_type': null,
      'skymapper:sb_mag': 20.3553,
      'skymapper:zpapprox': 28.67796,
      source: 'skymapper_dr4',
      source_name: 'SkyMapperDR4',
      true_anomaly: 45.1011,
      unc_a: 1.071,
      unc_b: 0.067,
      unc_theta: 21.076,
      vangle: 66.21000000000001,
      vmag: 17.04,
    },
    {
      airmass: 1.60708,
      archive_url: null,
      cutout_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20180516174436-12&SIZE=0.0833&POS=344.31182,-16.20253&FORMAT=fits',
      date: '2018-05-16 17:45:27.000',
      ddec: 8.497987,
      dec: -16.20253,
      delta: 3.16912008613416,
      dra: 36.60334,
      drh: 2.7652835,
      elong: 76.4144,
      exposure: 100,
      filter: 'g',
      maglimit: null,
      mjd_start: 58254.73931713,
      mjd_stop: 58254.74047453741,
      phase: 18.5373,
      preview_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20180516174436-12&SIZE=0.0833&POS=344.31182,-16.20253&FORMAT=png',
      product_id: '20180516174436-12',
      ra: 344.31182,
      rh: 3.092053937159,
      sangle: 65.06299999999999,
      seeing: 4.04051,
      'skymapper:field_id': null,
      'skymapper:image_type': null,
      'skymapper:sb_mag': 21.2049,
      'skymapper:zpapprox': 28.979359,
      source: 'skymapper_dr4',
      source_name: 'SkyMapperDR4',
      true_anomaly: 45.1047,
      unc_a: 1.071,
      unc_b: 0.067,
      unc_theta: 21.077,
      vangle: 66.208,
      vmag: 17.039,
    },
    {
      airmass: 1.21525,
      archive_url: null,
      cutout_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20180918104950-03&SIZE=0.0833&POS=345.3618,-20.02717&FORMAT=fits',
      date: '2018-09-18 10:50:42.000',
      ddec: -4.5989,
      dec: -20.02717,
      delta: 2.36686507416083,
      dra: -23.7612,
      drh: 3.6184583,
      elong: 159.2939,
      exposure: 100,
      filter: 'u',
      maglimit: null,
      mjd_start: 58379.4512963,
      mjd_stop: 58379.45245370741,
      phase: 6.1282,
      preview_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20180918104950-03&SIZE=0.0833&POS=345.3618,-20.02717&FORMAT=png',
      product_id: '20180918104950-03',
      ra: 345.3618,
      rh: 3.325811127971,
      sangle: 210.20499999999998,
      seeing: 3.12493,
      'skymapper:field_id': 1366,
      'skymapper:image_type': 'Main Survey',
      'skymapper:sb_mag': null,
      'skymapper:zpapprox': 26.250927,
      source: 'skymapper_dr4',
      source_name: 'SkyMapperDR4',
      true_anomaly: 67.9438,
      unc_a: 1.503,
      unc_b: 0.106,
      unc_theta: 22.002,
      vangle: 65.25899999999999,
      vmag: 16.698,
    },
    {
      airmass: 1.20914,
      archive_url: null,
      cutout_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20180918105150-03&SIZE=0.0833&POS=345.36156,-20.02721&FORMAT=fits',
      date: '2018-09-18 10:52:42.000',
      ddec: -4.59639,
      dec: -20.02721,
      delta: 2.36687354532797,
      dra: -23.7651,
      drh: 3.6184643,
      elong: 159.2927,
      exposure: 100,
      filter: 'v',
      maglimit: null,
      mjd_start: 58379.45268519,
      mjd_stop: 58379.45384259741,
      phase: 6.1285,
      preview_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20180918105150-03&SIZE=0.0833&POS=345.36156,-20.02721&FORMAT=png',
      product_id: '20180918105150-03',
      ra: 345.36156,
      rh: 3.325814030422,
      sangle: 210.208,
      seeing: 3.17966,
      'skymapper:field_id': 1366,
      'skymapper:image_type': 'Main Survey',
      'skymapper:sb_mag': 20.0879,
      'skymapper:zpapprox': 26.473932,
      source: 'skymapper_dr4',
      source_name: 'SkyMapperDR4',
      true_anomaly: 67.944,
      unc_a: 1.503,
      unc_b: 0.106,
      unc_theta: 22.001,
      vangle: 65.25899999999999,
      vmag: 16.698,
    },
    {
      airmass: 1.20316,
      archive_url: null,
      cutout_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20180918105351-03&SIZE=0.0833&POS=345.36133,-20.02726&FORMAT=fits',
      date: '2018-09-18 10:54:43.000',
      ddec: -4.59384,
      dec: -20.02726,
      delta: 2.36688208973036,
      dra: -23.769,
      drh: 3.6184704,
      elong: 159.2915,
      exposure: 100,
      filter: 'g',
      maglimit: null,
      mjd_start: 58379.45408565,
      mjd_stop: 58379.45524305741,
      phase: 6.1289,
      preview_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20180918105351-03&SIZE=0.0833&POS=345.36133,-20.02726&FORMAT=png',
      product_id: '20180918105351-03',
      ra: 345.36133,
      rh: 3.325816957056,
      sangle: 210.211,
      seeing: 2.71938,
      'skymapper:field_id': 1366,
      'skymapper:image_type': 'Main Survey',
      'skymapper:sb_mag': 19.6204,
      'skymapper:zpapprox': 28.870823,
      source: 'skymapper_dr4',
      source_name: 'SkyMapperDR4',
      true_anomaly: 67.9443,
      unc_a: 1.503,
      unc_b: 0.106,
      unc_theta: 22.001,
      vangle: 65.25899999999999,
      vmag: 16.698,
    },
    {
      airmass: 1.19625,
      archive_url: null,
      cutout_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20180918105613-03&SIZE=0.0833&POS=345.36105,-20.02731&FORMAT=fits',
      date: '2018-09-18 10:57:05.000',
      ddec: -4.59084,
      dec: -20.02731,
      delta: 2.36689212051039,
      dra: -23.7734,
      drh: 3.6184775,
      elong: 159.29,
      exposure: 100,
      filter: 'r',
      maglimit: null,
      mjd_start: 58379.45572917,
      mjd_stop: 58379.456886577405,
      phase: 6.1293,
      preview_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20180918105613-03&SIZE=0.0833&POS=345.36105,-20.02731&FORMAT=png',
      product_id: '20180918105613-03',
      ra: 345.36105,
      rh: 3.325820391634,
      sangle: 210.214,
      seeing: 2.42829,
      'skymapper:field_id': 1366,
      'skymapper:image_type': 'Main Survey',
      'skymapper:sb_mag': 19.5704,
      'skymapper:zpapprox': 28.652283,
      source: 'skymapper_dr4',
      source_name: 'SkyMapperDR4',
      true_anomaly: 67.9446,
      unc_a: 1.503,
      unc_b: 0.106,
      unc_theta: 22.001,
      vangle: 65.25899999999999,
      vmag: 16.698,
    },
    {
      airmass: 1.19366,
      archive_url: null,
      cutout_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20180918105814-03&SIZE=0.0833&POS=345.36081,-20.02735&FORMAT=fits',
      date: '2018-09-18 10:59:05.000',
      ddec: -4.58829,
      dec: -20.02735,
      delta: 2.36690060013484,
      dra: -23.7772,
      drh: 3.6184836,
      elong: 159.2888,
      exposure: 100,
      filter: 'u',
      maglimit: null,
      mjd_start: 58379.45711806,
      mjd_stop: 58379.45827546741,
      phase: 6.1296,
      preview_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20180918105814-03&SIZE=0.0833&POS=345.36081,-20.02735&FORMAT=png',
      product_id: '20180918105814-03',
      ra: 345.36081,
      rh: 3.3258232941,
      sangle: 210.21699999999998,
      seeing: 3.12742,
      'skymapper:field_id': 1366,
      'skymapper:image_type': 'Main Survey',
      'skymapper:sb_mag': 20.0877,
      'skymapper:zpapprox': 26.278786,
      source: 'skymapper_dr4',
      source_name: 'SkyMapperDR4',
      true_anomaly: 67.9448,
      unc_a: 1.503,
      unc_b: 0.106,
      unc_theta: 22.001,
      vangle: 65.25899999999999,
      vmag: 16.698,
    },
    {
      airmass: 1.18803,
      archive_url: null,
      cutout_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20180918110014-03&SIZE=0.0833&POS=345.36058,-20.02739&FORMAT=fits',
      date: '2018-09-18 11:01:05.000',
      ddec: -4.58572,
      dec: -20.02739,
      delta: 2.36690908237424,
      dra: -23.7809,
      drh: 3.6184896,
      elong: 159.2876,
      exposure: 100,
      filter: 'v',
      maglimit: null,
      mjd_start: 58379.45850694,
      mjd_stop: 58379.45966434741,
      phase: 6.1299,
      preview_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20180918110014-03&SIZE=0.0833&POS=345.36058,-20.02739&FORMAT=png',
      product_id: '20180918110014-03',
      ra: 345.36058,
      rh: 3.32582619655,
      sangle: 210.22,
      seeing: 3.12493,
      'skymapper:field_id': 1366,
      'skymapper:image_type': 'Main Survey',
      'skymapper:sb_mag': 20.1497,
      'skymapper:zpapprox': 26.485016,
      source: 'skymapper_dr4',
      source_name: 'SkyMapperDR4',
      true_anomaly: 67.945,
      unc_a: 1.503,
      unc_b: 0.106,
      unc_theta: 22.001,
      vangle: 65.25999999999999,
      vmag: 16.698,
    },
    {
      airmass: 1.17955,
      archive_url: null,
      cutout_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20180918110214-03&SIZE=0.0833&POS=345.36034,-20.02743&FORMAT=fits',
      date: '2018-09-18 11:03:06.000',
      ddec: -4.58312,
      dec: -20.02743,
      delta: 2.36691763811991,
      dra: -23.7845,
      drh: 3.6184957,
      elong: 159.2864,
      exposure: 100,
      filter: 'i',
      maglimit: null,
      mjd_start: 58379.45990741,
      mjd_stop: 58379.461064817406,
      phase: 6.1303,
      preview_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20180918110214-03&SIZE=0.0833&POS=345.36034,-20.02743&FORMAT=png',
      product_id: '20180918110214-03',
      ra: 345.36034,
      rh: 3.325829123224,
      sangle: 210.223,
      seeing: 2.19939,
      'skymapper:field_id': 1366,
      'skymapper:image_type': 'Main Survey',
      'skymapper:sb_mag': 18.6075,
      'skymapper:zpapprox': 27.892141,
      source: 'skymapper_dr4',
      source_name: 'SkyMapperDR4',
      true_anomaly: 67.9453,
      unc_a: 1.503,
      unc_b: 0.106,
      unc_theta: 22.001,
      vangle: 65.25999999999999,
      vmag: 16.698,
    },
    {
      airmass: 1.1742,
      archive_url: null,
      cutout_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20180918110415-03&SIZE=0.0833&POS=345.36011,-20.02748&FORMAT=fits',
      date: '2018-09-18 11:05:06.000',
      ddec: -4.58053,
      dec: -20.02748,
      delta: 2.36692612583808,
      dra: -23.7881,
      drh: 3.6185017,
      elong: 159.2852,
      exposure: 100,
      filter: 'z',
      maglimit: null,
      mjd_start: 58379.4612963,
      mjd_stop: 58379.46245370741,
      phase: 6.1306,
      preview_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20180918110415-03&SIZE=0.0833&POS=345.36011,-20.02748&FORMAT=png',
      product_id: '20180918110415-03',
      ra: 345.36011,
      rh: 3.325832025705,
      sangle: 210.226,
      seeing: 2.2591,
      'skymapper:field_id': 1366,
      'skymapper:image_type': 'Main Survey',
      'skymapper:sb_mag': 18.5596,
      'skymapper:zpapprox': 27.150965,
      source: 'skymapper_dr4',
      source_name: 'SkyMapperDR4',
      true_anomaly: 67.9455,
      unc_a: 1.503,
      unc_b: 0.106,
      unc_theta: 22.001,
      vangle: 65.25999999999999,
      vmag: 16.699,
    },
    {
      airmass: 1.17308,
      archive_url: null,
      cutout_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20180918110615-13&SIZE=0.0833&POS=345.35988,-20.02752&FORMAT=fits',
      date: '2018-09-18 11:07:06.000',
      ddec: -4.57793,
      dec: -20.02752,
      delta: 2.36693461626928,
      dra: -23.7916,
      drh: 3.6185077,
      elong: 159.2839,
      exposure: 100,
      filter: 'u',
      maglimit: null,
      mjd_start: 58379.46268519,
      mjd_stop: 58379.46384259741,
      phase: 6.131,
      preview_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20180918110615-13&SIZE=0.0833&POS=345.35988,-20.02752&FORMAT=png',
      product_id: '20180918110615-13',
      ra: 345.35988,
      rh: 3.325834928189,
      sangle: 210.22899999999998,
      seeing: 3.27918,
      'skymapper:field_id': 1366,
      'skymapper:image_type': 'Main Survey',
      'skymapper:sb_mag': null,
      'skymapper:zpapprox': 26.300655,
      source: 'skymapper_dr4',
      source_name: 'SkyMapperDR4',
      true_anomaly: 67.9457,
      unc_a: 1.503,
      unc_b: 0.106,
      unc_theta: 22.001,
      vangle: 65.25999999999999,
      vmag: 16.699,
    },
    {
      airmass: 1.16791,
      archive_url: null,
      cutout_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20180918110814-13&SIZE=0.0833&POS=345.35964,-20.02756&FORMAT=fits',
      date: '2018-09-18 11:09:06.000',
      ddec: -4.57532,
      dec: -20.02756,
      delta: 2.36694310937184,
      dra: -23.7951,
      drh: 3.6185138,
      elong: 159.2827,
      exposure: 100,
      filter: 'v',
      maglimit: null,
      mjd_start: 58379.46407407,
      mjd_stop: 58379.46523147741,
      phase: 6.1313,
      preview_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20180918110814-13&SIZE=0.0833&POS=345.35964,-20.02756&FORMAT=png',
      product_id: '20180918110814-13',
      ra: 345.35964,
      rh: 3.325837830659,
      sangle: 210.232,
      seeing: 3.07019,
      'skymapper:field_id': 1366,
      'skymapper:image_type': 'Main Survey',
      'skymapper:sb_mag': 20.2102,
      'skymapper:zpapprox': 26.48715,
      source: 'skymapper_dr4',
      source_name: 'SkyMapperDR4',
      true_anomaly: 67.946,
      unc_a: 1.503,
      unc_b: 0.106,
      unc_theta: 22.001,
      vangle: 65.25999999999999,
      vmag: 16.699,
    },
    {
      airmass: 1.1866,
      archive_url: null,
      cutout_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20180927101745-11&SIZE=0.0833&POS=343.9945,-20.19206&FORMAT=fits',
      date: '2018-09-27 10:18:36.000',
      ddec: -1.2392,
      dec: -20.19206,
      delta: 2.43314924595306,
      dra: -20.2291,
      drh: 3.6559513,
      elong: 150.9099,
      exposure: 100,
      filter: 'i',
      maglimit: null,
      mjd_start: 58388.42900463,
      mjd_stop: 58388.43016203741,
      phase: 8.3729,
      preview_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20180927101745-11&SIZE=0.0833&POS=343.9945,-20.19206&FORMAT=png',
      product_id: '20180927101745-11',
      ra: 343.9945,
      rh: 3.344670733401,
      sangle: 223.811,
      seeing: 3.4832,
      'skymapper:field_id': 1365,
      'skymapper:image_type': 'Main Survey',
      'skymapper:sb_mag': 19.113,
      'skymapper:zpapprox': 27.982437,
      source: 'skymapper_dr4',
      source_name: 'SkyMapperDR4',
      true_anomaly: 69.4586,
      unc_a: 1.46,
      unc_b: 0.105,
      unc_theta: 21.451,
      vangle: 65.846,
      vmag: 16.781,
    },
    {
      airmass: 1.18114,
      archive_url: null,
      cutout_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20180927101945-11&SIZE=0.0833&POS=343.9943,-20.19207&FORMAT=fits',
      date: '2018-09-27 10:20:36.000',
      ddec: -1.23665,
      dec: -20.19207,
      delta: 2.43316089336134,
      dra: -20.2325,
      drh: 3.6559569,
      elong: 150.9085,
      exposure: 100,
      filter: 'z',
      maglimit: null,
      mjd_start: 58388.43039352,
      mjd_stop: 58388.43155092741,
      phase: 8.3732,
      preview_url:
        'https://api.skymapper.nci.org.au/public/siap/dr4/get_image?IMAGE=20180927101945-11&SIZE=0.0833&POS=343.9943,-20.19207&FORMAT=png',
      product_id: '20180927101945-11',
      ra: 343.9943,
      rh: 3.344673665886,
      sangle: 223.813,
      seeing: 3.4633,
      'skymapper:field_id': 1365,
      'skymapper:image_type': 'Main Survey',
      'skymapper:sb_mag': 18.376,
      'skymapper:zpapprox': 27.253454,
      source: 'skymapper_dr4',
      source_name: 'SkyMapperDR4',
      true_anomaly: 69.4589,
      unc_a: 1.46,
      unc_b: 0.105,
      unc_theta: 21.451,
      vangle: 65.846,
      vmag: 16.781,
    },
    {
      airmass: 1.63100004196167,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/0703/024/rings.v3.skycell.0703.024.wrp.z.55476_20903.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F0703%2F024%2Frings.v3.skycell.0703.024.wrp.z.55476_20903.fits&ra=309.97934&dec=-31.1821&size=720&format=fits',
      date: '2010-10-07 05:01:49.035',
      ddec: 13.60851,
      dec: -31.1821,
      delta: 2.26409931305836,
      dra: 17.1234,
      drh: 4.5697648,
      elong: 110.0748,
      exposure: 30,
      filter: 'zp1',
      maglimit: null,
      mjd_start: 55476.2094217,
      mjd_stop: 55476.20976892222,
      phase: 19.7999,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F0703%2F024%2Frings.v3.skycell.0703.024.wrp.z.55476_20903.fits&ra=309.97934&dec=-31.1821&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.0703.024.wrp.z.55476_20903.fits',
      'ps1:frame_id': 235736,
      'ps1:projection_id': 703,
      'ps1:skycell_id': 24,
      ra: 309.97934,
      rh: 2.770981423822,
      sangle: 250.23399999999998,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 59.4739,
      unc_a: 0.121,
      unc_b: 0.093,
      unc_theta: -6.82,
      vangle: 81.04500000000002,
      vmag: 15.869,
    },
    {
      airmass: 1.5829999446868896,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/0703/024/rings.v3.skycell.0703.024.wrp.z.55477_22394.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F0703%2F024%2Frings.v3.skycell.0703.024.wrp.z.55477_22394.fits&ra=310.12476&dec=-31.08864&size=720&format=fits',
      date: '2010-10-08 05:23:17.553',
      ddec: 13.85213,
      dec: -31.08864,
      delta: 2.27897864462346,
      dra: 17.8163,
      drh: 4.5807113,
      elong: 109.2477,
      exposure: 30,
      filter: 'zp1',
      maglimit: null,
      mjd_start: 55477.2243351,
      mjd_stop: 55477.22468232222,
      phase: 19.8806,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F0703%2F024%2Frings.v3.skycell.0703.024.wrp.z.55477_22394.fits&ra=310.12476&dec=-31.08864&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.0703.024.wrp.z.55477_22394.fits',
      'ps1:frame_id': 236289,
      'ps1:projection_id': 703,
      'ps1:skycell_id': 24,
      ra: 310.12476,
      rh: 2.773663030108,
      sangle: 250.417,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 59.7069,
      unc_a: 0.12,
      unc_b: 0.093,
      unc_theta: -6.74,
      vangle: 80.97399999999999,
      vmag: 15.887,
    },
    {
      airmass: 1.5299999713897705,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/0704/058/rings.v3.skycell.0704.058.wrp.y.55492_20378.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F0704%2F058%2Frings.v3.skycell.0704.058.wrp.y.55492_20378.fits&ra=312.85316&dec=-29.57461&size=720&format=fits',
      date: '2010-10-23 04:54:16.990',
      ddec: 16.29685,
      dec: -29.57461,
      delta: 2.50640016250035,
      dra: 27.15597,
      drh: 4.7311927,
      elong: 97.4518,
      exposure: 30,
      filter: 'yp1',
      maglimit: null,
      mjd_start: 55492.2041897,
      mjd_stop: 55492.204536922225,
      phase: 20.5202,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F0704%2F058%2Frings.v3.skycell.0704.058.wrp.y.55492_20378.fits&ra=312.85316&dec=-29.57461&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.0704.058.wrp.y.55492_20378.fits',
      'ps1:frame_id': 243930,
      'ps1:projection_id': 704,
      'ps1:skycell_id': 58,
      ra: 312.85316,
      rh: 2.813955729179,
      sangle: 252.451,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 63.0936,
      unc_a: 0.108,
      unc_b: 0.084,
      unc_theta: -4.939,
      vangle: 79.62799999999999,
      vmag: 16.151,
    },
    {
      airmass: 1.5260000228881836,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/0704/058/rings.v3.skycell.0704.058.wrp.y.55492_21495.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F0704%2F058%2Frings.v3.skycell.0704.058.wrp.y.55492_21495.fits&ra=312.85548&dec=-29.5734&size=720&format=fits',
      date: '2010-10-23 05:10:21.491',
      ddec: 16.32826,
      dec: -29.5734,
      delta: 2.50657381411598,
      dra: 27.15985,
      drh: 4.7312972,
      elong: 97.4432,
      exposure: 30,
      filter: 'yp1',
      maglimit: null,
      mjd_start: 55492.2153529,
      mjd_stop: 55492.21570012222,
      phase: 20.5204,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F0704%2F058%2Frings.v3.skycell.0704.058.wrp.y.55492_21495.fits&ra=312.85548&dec=-29.5734&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.0704.058.wrp.y.55492_21495.fits',
      'ps1:frame_id': 243952,
      'ps1:projection_id': 704,
      'ps1:skycell_id': 58,
      ra: 312.85548,
      rh: 2.813986230134,
      sangle: 252.453,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 63.0961,
      unc_a: 0.108,
      unc_b: 0.084,
      unc_theta: -4.937,
      vangle: 79.62700000000001,
      vmag: 16.152,
    },
    {
      airmass: 1.5299999713897705,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/0704/068/rings.v3.skycell.0704.068.wrp.y.55492_20378.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F0704%2F068%2Frings.v3.skycell.0704.068.wrp.y.55492_20378.fits&ra=312.85316&dec=-29.57461&size=720&format=fits',
      date: '2010-10-23 04:54:16.990',
      ddec: 16.29685,
      dec: -29.57461,
      delta: 2.50640016250035,
      dra: 27.15597,
      drh: 4.7311927,
      elong: 97.4518,
      exposure: 30,
      filter: 'yp1',
      maglimit: null,
      mjd_start: 55492.2041897,
      mjd_stop: 55492.204536922225,
      phase: 20.5202,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F0704%2F068%2Frings.v3.skycell.0704.068.wrp.y.55492_20378.fits&ra=312.85316&dec=-29.57461&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.0704.068.wrp.y.55492_20378.fits',
      'ps1:frame_id': 243930,
      'ps1:projection_id': 704,
      'ps1:skycell_id': 68,
      ra: 312.85316,
      rh: 2.813955729179,
      sangle: 252.451,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 63.0936,
      unc_a: 0.108,
      unc_b: 0.084,
      unc_theta: -4.939,
      vangle: 79.62799999999999,
      vmag: 16.151,
    },
    {
      airmass: 1.5260000228881836,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/0704/068/rings.v3.skycell.0704.068.wrp.y.55492_21495.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F0704%2F068%2Frings.v3.skycell.0704.068.wrp.y.55492_21495.fits&ra=312.85548&dec=-29.5734&size=720&format=fits',
      date: '2010-10-23 05:10:21.491',
      ddec: 16.32826,
      dec: -29.5734,
      delta: 2.50657381411598,
      dra: 27.15985,
      drh: 4.7312972,
      elong: 97.4432,
      exposure: 30,
      filter: 'yp1',
      maglimit: null,
      mjd_start: 55492.2153529,
      mjd_stop: 55492.21570012222,
      phase: 20.5204,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F0704%2F068%2Frings.v3.skycell.0704.068.wrp.y.55492_21495.fits&ra=312.85548&dec=-29.5734&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.0704.068.wrp.y.55492_21495.fits',
      'ps1:frame_id': 243952,
      'ps1:projection_id': 704,
      'ps1:skycell_id': 68,
      ra: 312.85548,
      rh: 2.813986230134,
      sangle: 252.453,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 63.0961,
      unc_a: 0.108,
      unc_b: 0.084,
      unc_theta: -4.937,
      vangle: 79.62700000000001,
      vmag: 16.152,
    },
    {
      airmass: 1.5360000133514404,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/0704/084/rings.v3.skycell.0704.084.wrp.z.55500_19558.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F0704%2F084%2Frings.v3.skycell.0704.084.wrp.z.55500_19558.fits&ra=314.68914&dec=-28.67709&size=720&format=fits',
      date: '2010-10-31 04:42:27.542',
      ddec: 17.39988,
      dec: -28.67709,
      delta: 2.63185794199177,
      dra: 31.26013,
      drh: 4.8031892,
      elong: 91.4332,
      exposure: 30,
      filter: 'zp1',
      maglimit: null,
      mjd_start: 55500.1959785,
      mjd_stop: 55500.19632572222,
      phase: 20.4832,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F0704%2F084%2Frings.v3.skycell.0704.084.wrp.z.55500_19558.fits&ra=314.68914&dec=-28.67709&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.0704.084.wrp.z.55500_19558.fits',
      'ps1:frame_id': 247946,
      'ps1:projection_id': 704,
      'ps1:skycell_id': 84,
      ra: 314.68914,
      rh: 2.83595956354,
      sangle: 253.15800000000002,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 64.8602,
      unc_a: 0.103,
      unc_b: 0.079,
      unc_theta: -3.567,
      vangle: 78.72899999999998,
      vmag: 16.289,
    },
    {
      airmass: 1.5360000133514404,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/0704/084/rings.v3.skycell.0704.084.wrp.z.55500_20697.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F0704%2F084%2Frings.v3.skycell.0704.084.wrp.z.55500_20697.fits&ra=314.69185&dec=-28.67577&size=720&format=fits',
      date: '2010-10-31 04:58:51.906',
      ddec: 17.42957,
      dec: -28.67577,
      delta: 2.63203811293887,
      dra: 31.26666,
      drh: 4.8032879,
      elong: 91.4247,
      exposure: 30,
      filter: 'zp1',
      maglimit: null,
      mjd_start: 55500.2073716,
      mjd_stop: 55500.207718822225,
      phase: 20.483,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F0704%2F084%2Frings.v3.skycell.0704.084.wrp.z.55500_20697.fits&ra=314.69185&dec=-28.67577&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.0704.084.wrp.z.55500_20697.fits',
      'ps1:frame_id': 247967,
      'ps1:projection_id': 704,
      'ps1:skycell_id': 84,
      ra: 314.69185,
      rh: 2.83599116628,
      sangle: 253.159,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 64.8627,
      unc_a: 0.103,
      unc_b: 0.079,
      unc_theta: -3.564,
      vangle: 78.72800000000001,
      vmag: 16.289,
    },
    {
      airmass: 1.805999994277954,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/0786/043/rings.v3.skycell.0786.043.wrp.y.55343_54723.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F0786%2F043%2Frings.v3.skycell.0786.043.wrp.y.55343_54723.fits&ra=316.86826&dec=-25.99598&size=720&format=fits',
      date: '2010-05-27 13:08:50.310',
      ddec: -9.27337,
      dec: -25.99598,
      delta: 1.90751486543903,
      dra: 21.18607,
      drh: 2.2610192,
      elong: 114.0471,
      exposure: 30,
      filter: 'yp1',
      maglimit: null,
      mjd_start: 55343.5476309,
      mjd_stop: 55343.547978122224,
      phase: 21.7443,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F0786%2F043%2Frings.v3.skycell.0786.043.wrp.y.55343_54723.fits&ra=316.86826&dec=-25.99598&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.0786.043.wrp.y.55343_54723.fits',
      'ps1:frame_id': 173741,
      'ps1:projection_id': 786,
      'ps1:skycell_id': 43,
      ra: 316.86826,
      rh: 2.498168519398,
      sangle: 76.99599999999998,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 25.2424,
      unc_a: 0.137,
      unc_b: 0.121,
      unc_theta: -2.457,
      vangle: 77.673,
      vmag: 15.08,
    },
    {
      airmass: 1.7059999704360962,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/0786/043/rings.v3.skycell.0786.043.wrp.y.55343_56007.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F0786%2F043%2Frings.v3.skycell.0786.043.wrp.y.55343_56007.fits&ra=316.87028&dec=-25.99678&size=720&format=fits',
      date: '2010-05-27 13:27:19.383',
      ddec: -9.24229,
      dec: -25.99678,
      delta: 1.90739098418752,
      dra: 21.13111,
      drh: 2.2613236,
      elong: 114.0578,
      exposure: 30,
      filter: 'yp1',
      maglimit: null,
      mjd_start: 55343.5604674,
      mjd_stop: 55343.56081462222,
      phase: 21.7423,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F0786%2F043%2Frings.v3.skycell.0786.043.wrp.y.55343_56007.fits&ra=316.87028&dec=-25.99678&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.0786.043.wrp.y.55343_56007.fits',
      'ps1:frame_id': 173763,
      'ps1:projection_id': 786,
      'ps1:skycell_id': 43,
      ra: 316.87028,
      rh: 2.498185283973,
      sangle: 76.99799999999999,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 25.2461,
      unc_a: 0.137,
      unc_b: 0.121,
      unc_theta: -2.455,
      vangle: 77.67099999999999,
      vmag: 15.08,
    },
    {
      airmass: 1.805999994277954,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/0786/053/rings.v3.skycell.0786.053.wrp.y.55343_54723.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F0786%2F053%2Frings.v3.skycell.0786.053.wrp.y.55343_54723.fits&ra=316.86826&dec=-25.99598&size=720&format=fits',
      date: '2010-05-27 13:08:50.310',
      ddec: -9.27337,
      dec: -25.99598,
      delta: 1.90751486543903,
      dra: 21.18607,
      drh: 2.2610192,
      elong: 114.0471,
      exposure: 30,
      filter: 'yp1',
      maglimit: null,
      mjd_start: 55343.5476309,
      mjd_stop: 55343.547978122224,
      phase: 21.7443,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F0786%2F053%2Frings.v3.skycell.0786.053.wrp.y.55343_54723.fits&ra=316.86826&dec=-25.99598&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.0786.053.wrp.y.55343_54723.fits',
      'ps1:frame_id': 173741,
      'ps1:projection_id': 786,
      'ps1:skycell_id': 53,
      ra: 316.86826,
      rh: 2.498168519398,
      sangle: 76.99599999999998,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 25.2424,
      unc_a: 0.137,
      unc_b: 0.121,
      unc_theta: -2.457,
      vangle: 77.673,
      vmag: 15.08,
    },
    {
      airmass: 1.7059999704360962,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/0786/053/rings.v3.skycell.0786.053.wrp.y.55343_56007.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F0786%2F053%2Frings.v3.skycell.0786.053.wrp.y.55343_56007.fits&ra=316.87028&dec=-25.99678&size=720&format=fits',
      date: '2010-05-27 13:27:19.383',
      ddec: -9.24229,
      dec: -25.99678,
      delta: 1.90739098418752,
      dra: 21.13111,
      drh: 2.2613236,
      elong: 114.0578,
      exposure: 30,
      filter: 'yp1',
      maglimit: null,
      mjd_start: 55343.5604674,
      mjd_stop: 55343.56081462222,
      phase: 21.7423,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F0786%2F053%2Frings.v3.skycell.0786.053.wrp.y.55343_56007.fits&ra=316.87028&dec=-25.99678&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.0786.053.wrp.y.55343_56007.fits',
      'ps1:frame_id': 173763,
      'ps1:projection_id': 786,
      'ps1:skycell_id': 53,
      ra: 316.87028,
      rh: 2.498185283973,
      sangle: 76.99799999999999,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 25.2461,
      unc_a: 0.137,
      unc_b: 0.121,
      unc_theta: -2.455,
      vangle: 77.67099999999999,
      vmag: 15.08,
    },
    {
      airmass: 1.8240000009536743,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/0873/070/rings.v3.skycell.0873.070.wrp.y.55557_20514.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F0873%2F070%2Frings.v3.skycell.0873.070.wrp.y.55557_20514.fits&ra=332.16467&dec=-20.92255&size=720&format=fits',
      date: '2010-12-27 04:56:13.526',
      ddec: 22.97,
      dec: -20.92255,
      delta: 3.50282793128533,
      dra: 47.55832,
      drh: 5.1650447,
      elong: 52.1,
      exposure: 30,
      filter: 'yp1',
      maglimit: null,
      mjd_start: 55557.2055385,
      mjd_stop: 55557.20588572222,
      phase: 14.9875,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F0873%2F070%2Frings.v3.skycell.0873.070.wrp.y.55557_20514.fits&ra=332.16467&dec=-20.92255&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.0873.070.wrp.y.55557_20514.fits',
      'ps1:frame_id': 268261,
      'ps1:projection_id': 873,
      'ps1:skycell_id': 70,
      ra: 332.16467,
      rh: 3.000745659511,
      sangle: 256.122,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 76.6777,
      unc_a: 0.081,
      unc_b: 0.059,
      unc_theta: 10.133,
      vangle: 71.25299999999999,
      vmag: 17.136,
    },
    {
      airmass: 1.8240000009536743,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/0874/079/rings.v3.skycell.0874.079.wrp.y.55557_20514.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F0874%2F079%2Frings.v3.skycell.0874.079.wrp.y.55557_20514.fits&ra=332.16467&dec=-20.92255&size=720&format=fits',
      date: '2010-12-27 04:56:13.526',
      ddec: 22.97,
      dec: -20.92255,
      delta: 3.50282793128533,
      dra: 47.55832,
      drh: 5.1650447,
      elong: 52.1,
      exposure: 30,
      filter: 'yp1',
      maglimit: null,
      mjd_start: 55557.2055385,
      mjd_stop: 55557.20588572222,
      phase: 14.9875,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F0874%2F079%2Frings.v3.skycell.0874.079.wrp.y.55557_20514.fits&ra=332.16467&dec=-20.92255&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.0874.079.wrp.y.55557_20514.fits',
      'ps1:frame_id': 268261,
      'ps1:projection_id': 874,
      'ps1:skycell_id': 79,
      ra: 332.16467,
      rh: 3.000745659511,
      sangle: 256.122,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 76.6777,
      unc_a: 0.081,
      unc_b: 0.059,
      unc_theta: 10.133,
      vangle: 71.25299999999999,
      vmag: 17.136,
    },
    {
      airmass: 1.9950000047683716,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/0874/096/rings.v3.skycell.0874.096.wrp.y.55561_20707.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F0874%2F096%2Frings.v3.skycell.0874.096.wrp.y.55561_20707.fits&ra=333.54101&dec=-20.31104&size=720&format=fits',
      date: '2010-12-31 04:59:00.754',
      ddec: 23.21107,
      dec: -20.31104,
      delta: 3.5571364975649,
      dra: 48.08697,
      drh: 5.1813781,
      elong: 49.4985,
      exposure: 30,
      filter: 'yp1',
      maglimit: null,
      mjd_start: 55561.207474,
      mjd_stop: 55561.207821222226,
      phase: 14.3707,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F0874%2F096%2Frings.v3.skycell.0874.096.wrp.y.55561_20707.fits&ra=333.54101&dec=-20.31104&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.0874.096.wrp.y.55561_20707.fits',
      'ps1:frame_id': 270121,
      'ps1:projection_id': 874,
      'ps1:skycell_id': 96,
      ra: 333.54101,
      rh: 3.012701832812,
      sangle: 256.443,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 77.4573,
      unc_a: 0.08,
      unc_b: 0.058,
      unc_theta: 11.134,
      vangle: 70.756,
      vmag: 17.186,
    },
    {
      airmass: 1.1019999980926514,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1327/050/rings.v3.skycell.1327.050.wrp.y.55917_19669.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1327%2F050%2Frings.v3.skycell.1327.050.wrp.y.55917_19669.fits&ra=22.00645&dec=2.14023&size=720&format=fits',
      date: '2011-12-22 04:44:03.809',
      ddec: 7.860617,
      dec: 2.14023,
      delta: 3.57209664298417,
      dra: 0.572191,
      drh: 4.201815,
      elong: 111.2096,
      exposure: 30,
      filter: 'yp1',
      maglimit: null,
      mjd_start: 55917.1970927,
      mjd_stop: 55917.19743992222,
      phase: 13.1386,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1327%2F050%2Frings.v3.skycell.1327.050.wrp.y.55917_19669.fits&ra=22.00645&dec=2.14023&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1327.050.wrp.y.55917_19669.fits',
      'ps1:frame_id': 431947,
      'ps1:projection_id': 1327,
      'ps1:skycell_id': 50,
      ra: 22.00645,
      rh: 4.033537678486,
      sangle: 245.642,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 127.8212,
      unc_a: 0.115,
      unc_b: 0.079,
      unc_theta: 34.307,
      vangle: 61.17500000000001,
      vmag: 18.367,
    },
    {
      airmass: 1.090000033378601,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1327/050/rings.v3.skycell.1327.050.wrp.y.55917_20311.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1327%2F050%2Frings.v3.skycell.1327.050.wrp.y.55917_20311.fits&ra=22.00647&dec=2.14056&size=720&format=fits',
      date: '2011-12-22 04:53:18.186',
      ddec: 7.861037,
      dec: 2.14056,
      delta: 3.5722041853765,
      dra: 0.569444,
      drh: 4.2017772,
      elong: 111.2033,
      exposure: 30,
      filter: 'yp1',
      maglimit: null,
      mjd_start: 55917.2035091,
      mjd_stop: 55917.20385632222,
      phase: 13.1391,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1327%2F050%2Frings.v3.skycell.1327.050.wrp.y.55917_20311.fits&ra=22.00647&dec=2.14056&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1327.050.wrp.y.55917_20311.fits',
      'ps1:frame_id': 431960,
      'ps1:projection_id': 1327,
      'ps1:skycell_id': 50,
      ra: 22.00647,
      rh: 4.033553247917,
      sangle: 245.643,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 127.8218,
      unc_a: 0.115,
      unc_b: 0.079,
      unc_theta: 34.307,
      vangle: 61.17500000000001,
      vmag: 18.367,
    },
    {
      airmass: 1.1150000095367432,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1327/050/rings.v3.skycell.1327.050.wrp.y.55918_19013.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1327%2F050%2Frings.v3.skycell.1327.050.wrp.y.55918_19013.fits&ra=22.01606&dec=2.19292&size=720&format=fits',
      date: '2011-12-23 04:34:36.507',
      ddec: 8.064738,
      dec: 2.19292,
      delta: 3.58886263243211,
      dra: 1.181429,
      drh: 4.1959535,
      elong: 110.2355,
      exposure: 30,
      filter: 'yp1',
      maglimit: null,
      mjd_start: 55918.1905267,
      mjd_stop: 55918.19087392222,
      phase: 13.2161,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1327%2F050%2Frings.v3.skycell.1327.050.wrp.y.55918_19013.fits&ra=22.01606&dec=2.19292&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1327.050.wrp.y.55918_19013.fits',
      'ps1:frame_id': 432048,
      'ps1:projection_id': 1327,
      'ps1:skycell_id': 50,
      ra: 22.01606,
      rh: 4.035946579546,
      sangle: 245.791,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 127.9236,
      unc_a: 0.115,
      unc_b: 0.079,
      unc_theta: 34.349,
      vangle: 61.17599999999999,
      vmag: 18.38,
    },
    {
      airmass: 1.1009999513626099,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1327/050/rings.v3.skycell.1327.050.wrp.y.55918_19600.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1327%2F050%2Frings.v3.skycell.1327.050.wrp.y.55918_19600.fits&ra=22.01611&dec=2.19323&size=720&format=fits',
      date: '2011-12-23 04:43:03.329',
      ddec: 8.065095,
      dec: 2.19323,
      delta: 3.58896146711766,
      dra: 1.178372,
      drh: 4.1959188,
      elong: 110.2297,
      exposure: 30,
      filter: 'yp1',
      maglimit: null,
      mjd_start: 55918.1963927,
      mjd_stop: 55918.19673992223,
      phase: 13.2166,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1327%2F050%2Frings.v3.skycell.1327.050.wrp.y.55918_19600.fits&ra=22.01611&dec=2.19323&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1327.050.wrp.y.55918_19600.fits',
      'ps1:frame_id': 432060,
      'ps1:projection_id': 1327,
      'ps1:skycell_id': 50,
      ra: 22.01611,
      rh: 4.035960793569,
      sangle: 245.792,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 127.9242,
      unc_a: 0.115,
      unc_b: 0.079,
      unc_theta: 34.349,
      vangle: 61.17599999999999,
      vmag: 18.38,
    },
    {
      airmass: 1.0720000267028809,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1327/050/rings.v3.skycell.1327.050.wrp.z.55918_21428.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1327%2F050%2Frings.v3.skycell.1327.050.wrp.z.55918_21428.fits&ra=22.01625&dec=2.19422&size=720&format=fits',
      date: '2011-12-23 05:09:23.153',
      ddec: 8.066154,
      dec: 2.19422,
      delta: 3.58926990452135,
      dra: 1.173948,
      drh: 4.1958109,
      elong: 110.2118,
      exposure: 30,
      filter: 'zp1',
      maglimit: null,
      mjd_start: 55918.2146777,
      mjd_stop: 55918.215024922225,
      phase: 13.2181,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1327%2F050%2Frings.v3.skycell.1327.050.wrp.z.55918_21428.fits&ra=22.01625&dec=2.19422&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1327.050.wrp.z.55918_21428.fits',
      'ps1:frame_id': 432095,
      'ps1:projection_id': 1327,
      'ps1:skycell_id': 50,
      ra: 22.01625,
      rh: 4.036005099561,
      sangle: 245.79500000000002,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 127.9261,
      unc_a: 0.115,
      unc_b: 0.079,
      unc_theta: 34.35,
      vangle: 61.17599999999999,
      vmag: 18.38,
    },
    {
      airmass: 1.062999963760376,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1327/050/rings.v3.skycell.1327.050.wrp.z.55918_22637.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1327%2F050%2Frings.v3.skycell.1327.050.wrp.z.55918_22637.fits&ra=22.01634&dec=2.19487&size=720&format=fits',
      date: '2011-12-23 05:26:47.643',
      ddec: 8.066821,
      dec: 2.19487,
      delta: 3.5894741284016,
      dra: 1.175337,
      drh: 4.1957395,
      elong: 110.1999,
      exposure: 30,
      filter: 'zp1',
      maglimit: null,
      mjd_start: 55918.2267667,
      mjd_stop: 55918.22711392222,
      phase: 13.219,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1327%2F050%2Frings.v3.skycell.1327.050.wrp.z.55918_22637.fits&ra=22.01634&dec=2.19487&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1327.050.wrp.z.55918_22637.fits',
      'ps1:frame_id': 432119,
      'ps1:projection_id': 1327,
      'ps1:skycell_id': 50,
      ra: 22.01634,
      rh: 4.036034391529,
      sangle: 245.797,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 127.9273,
      unc_a: 0.115,
      unc_b: 0.079,
      unc_theta: 34.35,
      vangle: 61.17599999999999,
      vmag: 18.38,
    },
    {
      airmass: 1.1319999694824219,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1328/049/rings.v3.skycell.1328.049.wrp.z.55912_19986.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1328%2F049%2Frings.v3.skycell.1328.049.wrp.z.55912_19986.fits&ra=22.01916&dec=1.89618&size=720&format=fits',
      date: '2011-12-17 04:48:37.188',
      ddec: 6.796768,
      dec: 1.89618,
      delta: 3.48932303399487,
      dra: -2.49225,
      drh: 4.2312183,
      elong: 116.1652,
      exposure: 30,
      filter: 'zp1',
      maglimit: null,
      mjd_start: 55912.2002568,
      mjd_stop: 55912.200604022226,
      phase: 12.684,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1328%2F049%2Frings.v3.skycell.1328.049.wrp.z.55912_19986.fits&ra=22.01916&dec=1.89618&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1328.049.wrp.z.55912_19986.fits',
      'ps1:frame_id': 431486,
      'ps1:projection_id': 1328,
      'ps1:skycell_id': 49,
      ra: 22.01916,
      rh: 4.02137030502,
      sangle: 244.834,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 127.3036,
      unc_a: 0.118,
      unc_b: 0.081,
      unc_theta: 34.089,
      vangle: 61.16999999999999,
      vmag: 18.304,
    },
    {
      airmass: 1.1030000448226929,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1328/049/rings.v3.skycell.1328.049.wrp.z.55912_21155.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1328%2F049%2Frings.v3.skycell.1328.049.wrp.z.55912_21155.fits&ra=22.01896&dec=1.89671&size=720&format=fits',
      date: '2011-12-17 05:05:27.852',
      ddec: 6.79786,
      dec: 1.89671,
      delta: 3.48951256667421,
      dra: -2.49959,
      drh: 4.2311496,
      elong: 116.1534,
      exposure: 30,
      filter: 'zp1',
      maglimit: null,
      mjd_start: 55912.2119543,
      mjd_stop: 55912.212301522224,
      phase: 12.6852,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1328%2F049%2Frings.v3.skycell.1328.049.wrp.z.55912_21155.fits&ra=22.01896&dec=1.89671&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1328.049.wrp.z.55912_21155.fits',
      'ps1:frame_id': 431511,
      'ps1:projection_id': 1328,
      'ps1:skycell_id': 49,
      ra: 22.01896,
      rh: 4.021398887681,
      sangle: 244.836,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 127.3048,
      unc_a: 0.118,
      unc_b: 0.081,
      unc_theta: 34.09,
      vangle: 61.16999999999999,
      vmag: 18.304,
    },
    {
      airmass: 1.1150000095367432,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1328/059/rings.v3.skycell.1328.059.wrp.y.55918_19013.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1328%2F059%2Frings.v3.skycell.1328.059.wrp.y.55918_19013.fits&ra=22.01606&dec=2.19292&size=720&format=fits',
      date: '2011-12-23 04:34:36.507',
      ddec: 8.064738,
      dec: 2.19292,
      delta: 3.58886263243211,
      dra: 1.181429,
      drh: 4.1959535,
      elong: 110.2355,
      exposure: 30,
      filter: 'yp1',
      maglimit: null,
      mjd_start: 55918.1905267,
      mjd_stop: 55918.19087392222,
      phase: 13.2161,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1328%2F059%2Frings.v3.skycell.1328.059.wrp.y.55918_19013.fits&ra=22.01606&dec=2.19292&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1328.059.wrp.y.55918_19013.fits',
      'ps1:frame_id': 432048,
      'ps1:projection_id': 1328,
      'ps1:skycell_id': 59,
      ra: 22.01606,
      rh: 4.035946579546,
      sangle: 245.791,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 127.9236,
      unc_a: 0.115,
      unc_b: 0.079,
      unc_theta: 34.349,
      vangle: 61.17599999999999,
      vmag: 18.38,
    },
    {
      airmass: 1.1009999513626099,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1328/059/rings.v3.skycell.1328.059.wrp.y.55918_19600.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1328%2F059%2Frings.v3.skycell.1328.059.wrp.y.55918_19600.fits&ra=22.01611&dec=2.19323&size=720&format=fits',
      date: '2011-12-23 04:43:03.329',
      ddec: 8.065095,
      dec: 2.19323,
      delta: 3.58896146711766,
      dra: 1.178372,
      drh: 4.1959188,
      elong: 110.2297,
      exposure: 30,
      filter: 'yp1',
      maglimit: null,
      mjd_start: 55918.1963927,
      mjd_stop: 55918.19673992223,
      phase: 13.2166,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1328%2F059%2Frings.v3.skycell.1328.059.wrp.y.55918_19600.fits&ra=22.01611&dec=2.19323&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1328.059.wrp.y.55918_19600.fits',
      'ps1:frame_id': 432060,
      'ps1:projection_id': 1328,
      'ps1:skycell_id': 59,
      ra: 22.01611,
      rh: 4.035960793569,
      sangle: 245.792,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 127.9242,
      unc_a: 0.115,
      unc_b: 0.079,
      unc_theta: 34.349,
      vangle: 61.17599999999999,
      vmag: 18.38,
    },
    {
      airmass: 1.0729999542236328,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1329/046/rings.v3.skycell.1329.046.wrp.g.55863_38176.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1329%2F046%2Frings.v3.skycell.1329.046.wrp.g.55863_38176.fits&ra=27.22674&dec=1.63463&size=720&format=fits',
      date: '2011-10-29 09:10:40.383',
      ddec: -5.01846,
      dec: 1.63463,
      delta: 2.9239412573252,
      dra: -27.3479,
      drh: 4.5098983,
      elong: 166.9291,
      exposure: 43,
      filter: 'gp1',
      maglimit: null,
      mjd_start: 55863.382163,
      mjd_stop: 55863.38266068519,
      phase: 3.3,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1329%2F046%2Frings.v3.skycell.1329.046.wrp.g.55863_38176.fits&ra=27.22674&dec=1.63463&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1329.046.wrp.g.55863_38176.fits',
      'ps1:frame_id': 415495,
      'ps1:projection_id': 1329,
      'ps1:skycell_id': 46,
      ra: 27.22674,
      rh: 3.898111052415,
      sangle: 206.28300000000002,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 122.0314,
      unc_a: 0.139,
      unc_b: 0.09,
      unc_theta: 32.382,
      vangle: 60.79499999999999,
      vmag: 17.795,
    },
    {
      airmass: 1.062999963760376,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1329/046/rings.v3.skycell.1329.046.wrp.g.55863_39378.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1329%2F046%2Frings.v3.skycell.1329.046.wrp.g.55863_39378.fits&ra=27.22462&dec=1.63424&size=720&format=fits',
      date: '2011-10-29 09:27:24.429',
      ddec: -5.0178,
      dec: 1.63424,
      delta: 2.92400291017086,
      dra: -27.3518,
      drh: 4.5098342,
      elong: 166.9193,
      exposure: 43,
      filter: 'gp1',
      maglimit: null,
      mjd_start: 55863.3937839,
      mjd_stop: 55863.394281585184,
      phase: 3.3024,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1329%2F046%2Frings.v3.skycell.1329.046.wrp.g.55863_39378.fits&ra=27.22462&dec=1.63424&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1329.046.wrp.g.55863_39378.fits',
      'ps1:frame_id': 415514,
      'ps1:projection_id': 1329,
      'ps1:skycell_id': 46,
      ra: 27.22462,
      rh: 3.898141320048,
      sangle: 206.327,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 122.0327,
      unc_a: 0.139,
      unc_b: 0.09,
      unc_theta: 32.382,
      vangle: 60.79499999999999,
      vmag: 17.795,
    },
    {
      airmass: 1.1119999885559082,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1329/046/rings.v3.skycell.1329.046.wrp.r.55863_35796.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1329%2F046%2Frings.v3.skycell.1329.046.wrp.r.55863_35796.fits&ra=27.23108&dec=1.63543&size=720&format=fits',
      date: '2011-10-29 08:36:22.736',
      ddec: -5.01987,
      dec: 1.63543,
      delta: 2.92381569450816,
      dra: -27.3276,
      drh: 4.5100297,
      elong: 166.9491,
      exposure: 40,
      filter: 'rp1',
      maglimit: null,
      mjd_start: 55863.358365,
      mjd_stop: 55863.35882796296,
      phase: 3.2951,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1329%2F046%2Frings.v3.skycell.1329.046.wrp.r.55863_35796.fits&ra=27.23108&dec=1.63543&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1329.046.wrp.r.55863_35796.fits',
      'ps1:frame_id': 415458,
      'ps1:projection_id': 1329,
      'ps1:skycell_id': 46,
      ra: 27.23108,
      rh: 3.898049021904,
      sangle: 206.195,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 122.0288,
      unc_a: 0.139,
      unc_b: 0.09,
      unc_theta: 32.382,
      vangle: 60.79400000000001,
      vmag: 17.795,
    },
    {
      airmass: 1.090999960899353,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1329/046/rings.v3.skycell.1329.046.wrp.r.55863_36898.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1329%2F046%2Frings.v3.skycell.1329.046.wrp.r.55863_36898.fits&ra=27.22907&dec=1.63506&size=720&format=fits',
      date: '2011-10-29 08:52:14.009',
      ddec: -5.01921,
      dec: 1.63506,
      delta: 2.92387361336358,
      dra: -27.339,
      drh: 4.5099689,
      elong: 166.9398,
      exposure: 40,
      filter: 'rp1',
      maglimit: null,
      mjd_start: 55863.3693751,
      mjd_stop: 55863.369838062965,
      phase: 3.2974,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1329%2F046%2Frings.v3.skycell.1329.046.wrp.r.55863_36898.fits&ra=27.22907&dec=1.63506&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1329.046.wrp.r.55863_36898.fits',
      'ps1:frame_id': 415476,
      'ps1:projection_id': 1329,
      'ps1:skycell_id': 46,
      ra: 27.22907,
      rh: 3.898077699509,
      sangle: 206.236,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 122.03,
      unc_a: 0.139,
      unc_b: 0.09,
      unc_theta: 32.382,
      vangle: 60.79400000000001,
      vmag: 17.795,
    },
    {
      airmass: 1.1169999837875366,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1329/051/rings.v3.skycell.1329.051.wrp.i.55852_38902.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1329%2F051%2Frings.v3.skycell.1329.051.wrp.i.55852_38902.fits&ra=29.21996&dec=2.07229&size=720&format=fits',
      date: '2011-10-18 09:21:08.362',
      ddec: -6.741,
      dec: 2.07229,
      delta: 2.88381578091172,
      dra: -28.0005,
      drh: 4.5699761,
      elong: 170.1309,
      exposure: 45,
      filter: 'ip1',
      maglimit: null,
      mjd_start: 55852.3894197,
      mjd_stop: 55852.38994053333,
      phase: 2.5314,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1329%2F051%2Frings.v3.skycell.1329.051.wrp.i.55852_38902.fits&ra=29.21996&dec=2.07229&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1329.051.wrp.i.55852_38902.fits',
      'ps1:frame_id': 408824,
      'ps1:projection_id': 1329,
      'ps1:skycell_id': 51,
      ra: 29.21996,
      rh: 3.869287743255,
      sangle: 139.01,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 120.7878,
      unc_a: 0.141,
      unc_b: 0.089,
      unc_theta: 32.273,
      vangle: 60.622000000000014,
      vmag: 17.735,
    },
    {
      airmass: 1.0889999866485596,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1329/051/rings.v3.skycell.1329.051.wrp.i.55852_40185.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1329%2F051%2Frings.v3.skycell.1329.051.wrp.i.55852_40185.fits&ra=29.21756&dec=2.07172&size=720&format=fits',
      date: '2011-10-18 09:39:37.021',
      ddec: -6.74166,
      dec: 2.07172,
      delta: 2.88384013551867,
      dra: -28.0186,
      drh: 4.5699066,
      elong: 170.1366,
      exposure: 45,
      filter: 'ip1',
      maglimit: null,
      mjd_start: 55852.4022514,
      mjd_stop: 55852.402772233334,
      phase: 2.5299,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1329%2F051%2Frings.v3.skycell.1329.051.wrp.i.55852_40185.fits&ra=29.21756&dec=2.07172&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1329.051.wrp.i.55852_40185.fits',
      'ps1:frame_id': 408842,
      'ps1:projection_id': 1329,
      'ps1:skycell_id': 51,
      ra: 29.21756,
      rh: 3.869321610385,
      sangle: 139.094,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 120.7892,
      unc_a: 0.141,
      unc_b: 0.089,
      unc_theta: 32.273,
      vangle: 60.622000000000014,
      vmag: 17.735,
    },
    {
      airmass: 1.187000036239624,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1418/030/rings.v3.skycell.1418.030.wrp.y.55962_21242.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1418%2F030%2Frings.v3.skycell.1418.030.wrp.y.55962_21242.fits&ra=25.93408&dec=5.56588&size=720&format=fits',
      date: '2012-02-05 05:06:42.164',
      ddec: 13.91196,
      dec: 5.56588,
      delta: 4.36228927713819,
      dra: 22.55219,
      drh: 3.9319279,
      elong: 70.527,
      exposure: 30,
      filter: 'yp1',
      maglimit: null,
      mjd_start: 55962.2128144,
      mjd_stop: 55962.213161622225,
      phase: 12.9751,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1418%2F030%2Frings.v3.skycell.1418.030.wrp.y.55962_21242.fits&ra=25.93408&dec=5.56588&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1418.030.wrp.y.55962_21242.fits',
      'ps1:frame_id': 450558,
      'ps1:projection_id': 1418,
      'ps1:skycell_id': 30,
      ra: 25.93408,
      rh: 4.139277682731,
      sangle: 250.674,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 132.3077,
      unc_a: 0.098,
      unc_b: 0.069,
      unc_theta: 35.335,
      vangle: 61.34299999999999,
      vmag: 18.905,
    },
    {
      airmass: 1.0470000505447388,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1421/007/rings.v3.skycell.1421.007.wrp.z.55800_62579.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1421%2F007%2Frings.v3.skycell.1421.007.wrp.z.55800_62579.fits&ra=35.27737&dec=4.3403&size=720&format=fits',
      date: '2011-08-27 15:01:57.859',
      ddec: -3.64316,
      dec: 4.3403,
      delta: 3.13274661001259,
      dra: -3.04564,
      drh: 4.8346794,
      elong: 118.9736,
      exposure: 30,
      filter: 'zp1',
      maglimit: null,
      mjd_start: 55800.6261905,
      mjd_stop: 55800.62653772222,
      phase: 13.7185,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1421%2F007%2Frings.v3.skycell.1421.007.wrp.z.55800_62579.fits&ra=35.27737&dec=4.3403&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1421.007.wrp.z.55800_62579.fits',
      'ps1:frame_id': 382935,
      'ps1:projection_id': 1421,
      'ps1:skycell_id': 7,
      ra: 35.27737,
      rh: 3.728621626234,
      sangle: 75.935,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 114.6255,
      unc_a: 0.123,
      unc_b: 0.076,
      unc_theta: 32.5,
      vangle: 60.071,
      vmag: 17.766,
    },
    {
      airmass: 1.062999963760376,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1421/007/rings.v3.skycell.1421.007.wrp.z.55800_62627.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1421%2F007%2Frings.v3.skycell.1421.007.wrp.z.55800_62627.fits&ra=35.27736&dec=4.34029&size=720&format=fits',
      date: '2011-08-27 15:02:39.953',
      ddec: -3.64341,
      dec: 4.34029,
      delta: 3.13274179616465,
      dra: -3.04563,
      drh: 4.8346771,
      elong: 118.9741,
      exposure: 30,
      filter: 'zp1',
      maglimit: null,
      mjd_start: 55800.6266777,
      mjd_stop: 55800.62702492222,
      phase: 13.7184,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1421%2F007%2Frings.v3.skycell.1421.007.wrp.z.55800_62627.fits&ra=35.27736&dec=4.34029&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1421.007.wrp.z.55800_62627.fits',
      'ps1:frame_id': 382936,
      'ps1:projection_id': 1421,
      'ps1:skycell_id': 7,
      ra: 35.27736,
      rh: 3.728622986701,
      sangle: 75.935,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 114.6256,
      unc_a: 0.123,
      unc_b: 0.076,
      unc_theta: 32.5,
      vangle: 60.071,
      vmag: 17.766,
    },
    {
      airmass: 1.0520000457763672,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1421/007/rings.v3.skycell.1421.007.wrp.z.55800_62985.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1421%2F007%2Frings.v3.skycell.1421.007.wrp.z.55800_62985.fits&ra=35.27729&dec=4.3402&size=720&format=fits',
      date: '2011-08-27 15:07:48.997',
      ddec: -3.64526,
      dec: 4.3402,
      delta: 3.13270646629099,
      dra: -3.04535,
      drh: 4.83466,
      elong: 118.9776,
      exposure: 30,
      filter: 'zp1',
      maglimit: null,
      mjd_start: 55800.6302546,
      mjd_stop: 55800.630601822224,
      phase: 13.7179,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1421%2F007%2Frings.v3.skycell.1421.007.wrp.z.55800_62985.fits&ra=35.27729&dec=4.3402&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1421.007.wrp.z.55800_62985.fits',
      'ps1:frame_id': 382945,
      'ps1:projection_id': 1421,
      'ps1:skycell_id': 7,
      ra: 35.27729,
      rh: 3.728632974888,
      sangle: 75.936,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 114.626,
      unc_a: 0.123,
      unc_b: 0.076,
      unc_theta: 32.5,
      vangle: 60.071,
      vmag: 17.766,
    },
    {
      airmass: 1.1759999990463257,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1421/018/rings.v3.skycell.1421.018.wrp.y.55788_55400.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1421%2F018%2Frings.v3.skycell.1421.018.wrp.y.55788_55400.fits&ra=35.14165&dec=4.53686&size=720&format=fits',
      date: '2011-08-15 13:18:35.730',
      ddec: -1.19134,
      dec: 4.53686,
      delta: 3.25993753392493,
      dra: 5.06586,
      drh: 4.8911662,
      elong: 107.6029,
      exposure: 30,
      filter: 'yp1',
      maglimit: null,
      mjd_start: 55788.5544066,
      mjd_stop: 55788.55475382222,
      phase: 15.1509,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1421%2F018%2Frings.v3.skycell.1421.018.wrp.y.55788_55400.fits&ra=35.14165&dec=4.53686&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1421.018.wrp.y.55788_55400.fits',
      'ps1:frame_id': 376598,
      'ps1:projection_id': 1421,
      'ps1:skycell_id': 18,
      ra: 35.14165,
      rh: 3.694713746338,
      sangle: 73.643,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 113.1115,
      unc_a: 0.116,
      unc_b: 0.072,
      unc_theta: 32.525,
      vangle: 60.09100000000001,
      vmag: 17.816,
    },
    {
      airmass: 1.1390000581741333,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1421/018/rings.v3.skycell.1421.018.wrp.y.55788_56462.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1421%2F018%2Frings.v3.skycell.1421.018.wrp.y.55788_56462.fits&ra=35.142&dec=4.53677&size=720&format=fits',
      date: '2011-08-15 13:33:52.832',
      ddec: -1.19672,
      dec: 4.53677,
      delta: 3.25981915185857,
      dra: 5.039328,
      drh: 4.8911176,
      elong: 107.6127,
      exposure: 30,
      filter: 'yp1',
      maglimit: null,
      mjd_start: 55788.5650212,
      mjd_stop: 55788.56536842222,
      phase: 15.1499,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1421%2F018%2Frings.v3.skycell.1421.018.wrp.y.55788_56462.fits&ra=35.142&dec=4.53677&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1421.018.wrp.y.55788_56462.fits',
      'ps1:frame_id': 376619,
      'ps1:projection_id': 1421,
      'ps1:skycell_id': 18,
      ra: 35.142,
      rh: 3.694743733143,
      sangle: 73.64500000000001,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 113.1128,
      unc_a: 0.116,
      unc_b: 0.072,
      unc_theta: 32.525,
      vangle: 60.09100000000001,
      vmag: 17.816,
    },
    {
      airmass: 1.0440000295639038,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1777/010/rings.v3.skycell.1777.010.wrp.r.56309_31843.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1777%2F010%2Frings.v3.skycell.1777.010.wrp.r.56309_31843.fits&ra=57.52299&dec=20.56891&size=720&format=fits',
      date: '2013-01-17 07:39:27.396',
      ddec: 0.943793,
      dec: 20.56891,
      delta: 4.11429599237573,
      dra: -6.72132,
      drh: 1.8803594,
      elong: 122.6468,
      exposure: 40,
      filter: 'rp1',
      maglimit: null,
      mjd_start: 56309.3188356,
      mjd_stop: 56309.319298562965,
      phase: 10.1079,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1777%2F010%2Frings.v3.skycell.1777.010.wrp.r.56309_31843.fits&ra=57.52299&dec=20.56891&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1777.010.wrp.r.56309_31843.fits',
      'ps1:frame_id': 567978,
      'ps1:projection_id': 1777,
      'ps1:skycell_id': 10,
      ra: 57.52299,
      rh: 4.718222423078,
      sangle: 257.968,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 157.7718,
      unc_a: 0.124,
      unc_b: 0.097,
      unc_theta: 27.682,
      vangle: 67.303,
      vmag: 19.304,
    },
    {
      airmass: 1.069000005722046,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1777/010/rings.v3.skycell.1777.010.wrp.r.56309_32994.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1777%2F010%2Frings.v3.skycell.1777.010.wrp.r.56309_32994.fits&ra=57.52244&dec=20.56898&size=720&format=fits',
      date: '2013-01-17 07:56:02.525',
      ddec: 0.932353,
      dec: 20.56898,
      delta: 4.11446510696389,
      dra: -6.70177,
      drh: 1.8802941,
      elong: 122.6345,
      exposure: 40,
      filter: 'rp1',
      maglimit: null,
      mjd_start: 56309.3303533,
      mjd_stop: 56309.33081626296,
      phase: 10.1093,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1777%2F010%2Frings.v3.skycell.1777.010.wrp.r.56309_32994.fits&ra=57.52244&dec=20.56898&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1777.010.wrp.r.56309_32994.fits',
      'ps1:frame_id': 567997,
      'ps1:projection_id': 1777,
      'ps1:skycell_id': 10,
      ra: 57.52244,
      rh: 4.718234930005,
      sangle: 257.968,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 157.7725,
      unc_a: 0.124,
      unc_b: 0.097,
      unc_theta: 27.682,
      vangle: 67.303,
      vmag: 19.304,
    },
    {
      airmass: 1.1449999809265137,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1780/011/rings.v3.skycell.1780.011.wrp.z.56173_58557.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1780%2F011%2Frings.v3.skycell.1780.011.wrp.z.56173_58557.fits&ra=70.29644&dec=20.48173&size=720&format=fits',
      date: '2012-09-03 14:04:03.681',
      ddec: 3.170237,
      dec: 20.48173,
      delta: 4.43220785444437,
      dra: 11.09657,
      drh: 2.6539718,
      elong: 89.7095,
      exposure: 30,
      filter: 'zp1',
      maglimit: null,
      mjd_start: 56173.5859801,
      mjd_stop: 56173.58632732222,
      phase: 12.8357,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1780%2F011%2Frings.v3.skycell.1780.011.wrp.z.56173_58557.fits&ra=70.29644&dec=20.48173&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1780.011.wrp.z.56173_58557.fits',
      'ps1:frame_id': 518740,
      'ps1:projection_id': 1780,
      'ps1:skycell_id': 11,
      ra: 70.29644,
      rh: 4.54062095919,
      sangle: 82.281,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 149.3968,
      unc_a: 0.112,
      unc_b: 0.084,
      unc_theta: 24.153,
      vangle: 70.60499999999999,
      vmag: 19.311,
    },
    {
      airmass: 1.093000054359436,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1780/022/rings.v3.skycell.1780.022.wrp.i.56224_60639.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1780%2F022%2Frings.v3.skycell.1780.022.wrp.i.56224_60639.fits&ra=69.49664&dec=21.03553&size=720&format=fits',
      date: '2012-10-24 14:34:10.478',
      ddec: 0.081208,
      dec: 21.03553,
      delta: 3.80367478263636,
      dra: -16.7076,
      drh: 2.3604657,
      elong: 140.4796,
      exposure: 45,
      filter: 'ip1',
      maglimit: null,
      mjd_start: 56224.6068053,
      mjd_stop: 56224.60732613333,
      phase: 7.8873,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1780%2F022%2Frings.v3.skycell.1780.022.wrp.i.56224_60639.fits&ra=69.49664&dec=21.03553&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1780.022.wrp.i.56224_60639.fits',
      'ps1:frame_id': 537426,
      'ps1:projection_id': 1780,
      'ps1:skycell_id': 22,
      ra: 69.49664,
      rh: 4.614491630182,
      sangle: 83.26799999999997,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 152.6603,
      unc_a: 0.134,
      unc_b: 0.102,
      unc_theta: 23.469,
      vangle: 70.881,
      vmag: 19.044,
    },
    {
      airmass: 1.0460000038146973,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1780/025/rings.v3.skycell.1780.025.wrp.i.56234_55825.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1780%2F025%2Frings.v3.skycell.1780.025.wrp.i.56234_55825.fits&ra=68.18254&dec=21.02547&size=720&format=fits',
      date: '2012-11-03 13:24:50.871',
      ddec: -0.49413,
      dec: 21.02547,
      delta: 3.73102087110586,
      dra: -21.1563,
      drh: 2.3038281,
      elong: 151.6335,
      exposure: 45,
      filter: 'ip1',
      maglimit: null,
      mjd_start: 56234.5586617,
      mjd_stop: 56234.55918253333,
      phase: 5.8495,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1780%2F025%2Frings.v3.skycell.1780.025.wrp.i.56234_55825.fits&ra=68.18254&dec=21.02547&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1780.025.wrp.i.56234_55825.fits',
      'ps1:frame_id': 543487,
      'ps1:projection_id': 1780,
      'ps1:skycell_id': 25,
      ra: 68.18254,
      rh: 4.627896555987,
      sangle: 83.14299999999997,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 153.2725,
      unc_a: 0.137,
      unc_b: 0.104,
      unc_theta: 23.744,
      vangle: 70.57499999999999,
      vmag: 19.014,
    },
    {
      airmass: 1.1410000324249268,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1780/026/rings.v3.skycell.1780.026.wrp.i.56237_58072.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1780%2F026%2Frings.v3.skycell.1780.026.wrp.i.56237_58072.fits&ra=67.72527&dec=21.01458&size=720&format=fits',
      date: '2012-11-06 13:56:37.140',
      ddec: -0.70717,
      dec: 21.01458,
      delta: 3.71410854864339,
      dra: -22.2289,
      drh: 2.2866559,
      elong: 155.0863,
      exposure: 45,
      filter: 'ip1',
      maglimit: null,
      mjd_start: 56237.580725,
      mjd_stop: 56237.58124583333,
      phase: 5.1768,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1780%2F026%2Frings.v3.skycell.1780.026.wrp.i.56237_58072.fits&ra=67.72527&dec=21.01458&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1780.026.wrp.i.56237_58072.fits',
      'ps1:frame_id': 545581,
      'ps1:projection_id': 1780,
      'ps1:skycell_id': 26,
      ra: 67.72527,
      rh: 4.631902770836,
      sangle: 83.12599999999998,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 153.4573,
      unc_a: 0.138,
      unc_b: 0.105,
      unc_theta: 23.859,
      vangle: 70.46000000000001,
      vmag: 19.008,
    },
    {
      airmass: 1.1410000324249268,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1780/027/rings.v3.skycell.1780.027.wrp.i.56237_58072.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1780%2F027%2Frings.v3.skycell.1780.027.wrp.i.56237_58072.fits&ra=67.72527&dec=21.01458&size=720&format=fits',
      date: '2012-11-06 13:56:37.140',
      ddec: -0.70717,
      dec: 21.01458,
      delta: 3.71410854864339,
      dra: -22.2289,
      drh: 2.2866559,
      elong: 155.0863,
      exposure: 45,
      filter: 'ip1',
      maglimit: null,
      mjd_start: 56237.580725,
      mjd_stop: 56237.58124583333,
      phase: 5.1768,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1780%2F027%2Frings.v3.skycell.1780.027.wrp.i.56237_58072.fits&ra=67.72527&dec=21.01458&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1780.027.wrp.i.56237_58072.fits',
      'ps1:frame_id': 545581,
      'ps1:projection_id': 1780,
      'ps1:skycell_id': 27,
      ra: 67.72527,
      rh: 4.631902770836,
      sangle: 83.12599999999998,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 153.4573,
      unc_a: 0.138,
      unc_b: 0.105,
      unc_theta: 23.859,
      vangle: 70.46000000000001,
      vmag: 19.008,
    },
    {
      airmass: 1.125,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1780/027/rings.v3.skycell.1780.027.wrp.r.56238_57552.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1780%2F027%2Frings.v3.skycell.1780.027.wrp.r.56238_57552.fits&ra=67.56961&dec=21.01023&size=720&format=fits',
      date: '2012-11-07 13:49:40.412',
      ddec: -0.76201,
      dec: 21.01023,
      delta: 3.70909890591562,
      dra: -22.5747,
      drh: 2.2810034,
      elong: 156.2291,
      exposure: 40,
      filter: 'rp1',
      maglimit: null,
      mjd_start: 56238.5759307,
      mjd_stop: 56238.57639366296,
      phase: 4.9506,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1780%2F027%2Frings.v3.skycell.1780.027.wrp.r.56238_57552.fits&ra=67.56961&dec=21.01023&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1780.027.wrp.r.56238_57552.fits',
      'ps1:frame_id': 546226,
      'ps1:projection_id': 1780,
      'ps1:skycell_id': 27,
      ra: 67.56961,
      rh: 4.633215469012,
      sangle: 83.12599999999998,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 153.518,
      unc_a: 0.138,
      unc_b: 0.105,
      unc_theta: 23.899,
      vangle: 70.42099999999999,
      vmag: 19.006,
    },
    {
      airmass: 1.1690000295639038,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1780/027/rings.v3.skycell.1780.027.wrp.r.56238_58718.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1780%2F027%2Frings.v3.skycell.1780.027.wrp.r.56238_58718.fits&ra=67.56773&dec=21.01017&size=720&format=fits',
      date: '2012-11-07 14:06:27.482',
      ddec: -0.77562,
      dec: 21.01017,
      delta: 3.70904338631077,
      dra: -22.5555,
      drh: 2.2809373,
      elong: 156.2427,
      exposure: 40,
      filter: 'rp1',
      maglimit: null,
      mjd_start: 56238.5875866,
      mjd_stop: 56238.58804956296,
      phase: 4.9479,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1780%2F027%2Frings.v3.skycell.1780.027.wrp.r.56238_58718.fits&ra=67.56773&dec=21.01017&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1780.027.wrp.r.56238_58718.fits',
      'ps1:frame_id': 546248,
      'ps1:projection_id': 1780,
      'ps1:skycell_id': 27,
      ra: 67.56773,
      rh: 4.633230824574,
      sangle: 83.12599999999998,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 153.5187,
      unc_a: 0.138,
      unc_b: 0.105,
      unc_theta: 23.9,
      vangle: 70.41999999999999,
      vmag: 19.006,
    },
    {
      airmass: 1.156000018119812,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1781/028/rings.v3.skycell.1781.028.wrp.y.56199_51839.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1781%2F028%2Frings.v3.skycell.1781.028.wrp.y.56199_51839.fits&ra=71.18294&dec=20.88637&size=720&format=fits',
      date: '2012-09-29 12:27:19.026',
      ddec: 1.714132,
      dec: 20.88637,
      delta: 4.0776838628257,
      dra: -2.75519,
      drh: 2.5040294,
      elong: 114.1188,
      exposure: 30,
      filter: 'yp1',
      maglimit: null,
      mjd_start: 56199.5187966,
      mjd_stop: 56199.519143822225,
      phase: 11.5191,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1781%2F028%2Frings.v3.skycell.1781.028.wrp.y.56199_51839.fits&ra=71.18294&dec=20.88637&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1781.028.wrp.y.56199_51839.fits',
      'ps1:frame_id': 523254,
      'ps1:projection_id': 1781,
      'ps1:skycell_id': 28,
      ra: 71.18294,
      rh: 4.579248665538,
      sangle: 83.25999999999999,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 151.0854,
      unc_a: 0.123,
      unc_b: 0.093,
      unc_theta: 23.438,
      vangle: 71.11000000000001,
      vmag: 19.164,
    },
    {
      airmass: 1.1180000305175781,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1781/028/rings.v3.skycell.1781.028.wrp.y.56199_52962.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1781%2F028%2Frings.v3.skycell.1781.028.wrp.y.56199_52962.fits&ra=71.18273&dec=20.88649&size=720&format=fits',
      date: '2012-09-29 12:42:54.436',
      ddec: 1.702392,
      dec: 20.88649,
      delta: 4.07754413576021,
      dra: -2.77955,
      drh: 2.5039672,
      elong: 114.1298,
      exposure: 30,
      filter: 'yp1',
      maglimit: null,
      mjd_start: 56199.5296231,
      mjd_stop: 56199.529970322226,
      phase: 11.518,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1781%2F028%2Frings.v3.skycell.1781.028.wrp.y.56199_52962.fits&ra=71.18273&dec=20.88649&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1781.028.wrp.y.56199_52962.fits',
      'ps1:frame_id': 523276,
      'ps1:projection_id': 1781,
      'ps1:skycell_id': 28,
      ra: 71.18273,
      rh: 4.579264323774,
      sangle: 83.25999999999999,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 151.0861,
      unc_a: 0.123,
      unc_b: 0.093,
      unc_theta: 23.437,
      vangle: 71.11000000000001,
      vmag: 19.164,
    },
    {
      airmass: 1.0880000591278076,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1781/028/rings.v3.skycell.1781.028.wrp.z.56201_53368.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1781%2F028%2Frings.v3.skycell.1781.028.wrp.z.56201_53368.fits&ra=71.14142&dec=20.90727&size=720&format=fits',
      date: '2012-10-01 12:49:20.160',
      ddec: 1.581953,
      dec: 20.90727,
      delta: 4.05213330262621,
      dra: -3.93957,
      drh: 2.4924482,
      elong: 116.1343,
      exposure: 30,
      filter: 'zp1',
      maglimit: null,
      mjd_start: 56201.5340875,
      mjd_stop: 56201.53443472222,
      phase: 11.3143,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1781%2F028%2Frings.v3.skycell.1781.028.wrp.z.56201_53368.fits&ra=71.14142&dec=20.90727&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1781.028.wrp.z.56201_53368.fits',
      'ps1:frame_id': 524782,
      'ps1:projection_id': 1781,
      'ps1:skycell_id': 28,
      ra: 71.14142,
      rh: 4.582156647363,
      sangle: 83.29199999999997,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 151.2138,
      unc_a: 0.124,
      unc_b: 0.094,
      unc_theta: 23.409,
      vangle: 71.12,
      vmag: 19.153,
    },
    {
      airmass: 1.0880000591278076,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1781/029/rings.v3.skycell.1781.029.wrp.z.56201_53368.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1781%2F029%2Frings.v3.skycell.1781.029.wrp.z.56201_53368.fits&ra=71.14142&dec=20.90727&size=720&format=fits',
      date: '2012-10-01 12:49:20.160',
      ddec: 1.581953,
      dec: 20.90727,
      delta: 4.05213330262621,
      dra: -3.93957,
      drh: 2.4924482,
      elong: 116.1343,
      exposure: 30,
      filter: 'zp1',
      maglimit: null,
      mjd_start: 56201.5340875,
      mjd_stop: 56201.53443472222,
      phase: 11.3143,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1781%2F029%2Frings.v3.skycell.1781.029.wrp.z.56201_53368.fits&ra=71.14142&dec=20.90727&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1781.029.wrp.z.56201_53368.fits',
      'ps1:frame_id': 524782,
      'ps1:projection_id': 1781,
      'ps1:skycell_id': 29,
      ra: 71.14142,
      rh: 4.582156647363,
      sangle: 83.29199999999997,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 151.2138,
      unc_a: 0.124,
      unc_b: 0.094,
      unc_theta: 23.409,
      vangle: 71.12,
      vmag: 19.153,
    },
    {
      airmass: 1.1150000095367432,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1871/062/rings.v3.skycell.1871.062.wrp.y.56558_62815.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1871%2F062%2Frings.v3.skycell.1871.062.wrp.y.56558_62815.fits&ra=102.30376&dec=26.67395&size=720&format=fits',
      date: '2013-09-23 15:05:47.549',
      ddec: 2.24705,
      dec: 26.67395,
      delta: 4.96646378708606,
      dra: 15.75773,
      drh: 0.4411535,
      elong: 79.5787,
      exposure: 80,
      filter: 'yp1',
      maglimit: null,
      mjd_start: 56558.6285596,
      mjd_stop: 56558.629485525926,
      phase: 11.6534,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1871%2F062%2Frings.v3.skycell.1871.062.wrp.y.56558_62815.fits&ra=102.30376&dec=26.67395&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1871.062.wrp.y.56558_62815.fits',
      'ps1:frame_id': 658857,
      'ps1:projection_id': 1871,
      'ps1:skycell_id': 62,
      ra: 102.30376,
      rh: 4.885750574561,
      sangle: 95.55399999999997,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 174.3416,
      unc_a: 0.11,
      unc_b: 0.081,
      unc_theta: -4.228,
      vangle: 86.344,
      vmag: 19.853,
    },
    {
      airmass: 1.2070000171661377,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1871/062/rings.v3.skycell.1871.062.wrp.z.56558_60397.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1871%2F062%2Frings.v3.skycell.1871.062.wrp.z.56558_60397.fits&ra=102.3009&dec=26.67358&size=720&format=fits',
      date: '2013-09-23 14:30:48.804',
      ddec: 2.270611,
      dec: 26.67358,
      delta: 4.96683853698146,
      dra: 15.80451,
      drh: 0.4412942,
      elong: 79.5574,
      exposure: 60,
      filter: 'zp1',
      maglimit: null,
      mjd_start: 56558.6043843,
      mjd_stop: 56558.60507874445,
      phase: 11.6528,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1871%2F062%2Frings.v3.skycell.1871.062.wrp.z.56558_60397.fits&ra=102.3009&dec=26.67358&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1871.062.wrp.z.56558_60397.fits',
      'ps1:frame_id': 658834,
      'ps1:projection_id': 1871,
      'ps1:skycell_id': 62,
      ra: 102.3009,
      rh: 4.885744383969,
      sangle: 95.555,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 174.3399,
      unc_a: 0.11,
      unc_b: 0.081,
      unc_theta: -4.226,
      vangle: 86.34199999999998,
      vmag: 19.853,
    },
    {
      airmass: 1.0240000486373901,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1872/087/rings.v3.skycell.1872.087.wrp.y.56586_60469.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1872%2F087%2Frings.v3.skycell.1872.087.wrp.y.56586_60469.fits&ra=104.39786&dec=27.22627&size=720&format=fits',
      date: '2013-10-21 14:32:00.545',
      ddec: 3.887854,
      dec: 27.22627,
      delta: 4.53362395915387,
      dra: 2.864374,
      drh: 0.2792936,
      elong: 105.3025,
      exposure: 80,
      filter: 'yp1',
      maglimit: null,
      mjd_start: 56586.6050989,
      mjd_stop: 56586.606024825924,
      phase: 11.3223,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1872%2F087%2Frings.v3.skycell.1872.087.wrp.y.56586_60469.fits&ra=104.39786&dec=27.22627&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1872.087.wrp.y.56586_60469.fits',
      'ps1:frame_id': 667656,
      'ps1:projection_id': 1872,
      'ps1:skycell_id': 87,
      ra: 104.39786,
      rh: 4.891570944958,
      sangle: 94.47800000000001,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 176.3883,
      unc_a: 0.122,
      unc_b: 0.088,
      unc_theta: -6.386,
      vangle: 87.55200000000002,
      vmag: 19.66,
    },
    {
      airmass: 1.0290000438690186,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1872/087/rings.v3.skycell.1872.087.wrp.y.56586_60906.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1872%2F087%2Frings.v3.skycell.1872.087.wrp.y.56586_60906.fits&ra=104.39795&dec=27.22639&size=720&format=fits',
      date: '2013-10-21 14:38:18.623',
      ddec: 3.882272,
      dec: 27.22639,
      delta: 4.53355772720402,
      dra: 2.859405,
      drh: 0.2792683,
      elong: 105.3068,
      exposure: 80,
      filter: 'yp1',
      maglimit: null,
      mjd_start: 56586.6094748,
      mjd_stop: 56586.61040072593,
      phase: 11.3221,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1872%2F087%2Frings.v3.skycell.1872.087.wrp.y.56586_60906.fits&ra=104.39795&dec=27.22639&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1872.087.wrp.y.56586_60906.fits',
      'ps1:frame_id': 667660,
      'ps1:projection_id': 1872,
      'ps1:skycell_id': 87,
      ra: 104.39795,
      rh: 4.891571650845,
      sangle: 94.47800000000001,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 176.3887,
      unc_a: 0.122,
      unc_b: 0.088,
      unc_theta: -6.386,
      vangle: 87.55200000000002,
      vmag: 19.66,
    },
    {
      airmass: 1.1130000352859497,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1872/087/rings.v3.skycell.1872.087.wrp.z.56586_55959.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1872%2F087%2Frings.v3.skycell.1872.087.wrp.z.56586_55959.fits&ra=104.39688&dec=27.22509&size=720&format=fits',
      date: '2013-10-21 13:26:54.829',
      ddec: 3.942575,
      dec: 27.22509,
      delta: 4.53430974244398,
      dra: 2.935856,
      drh: 0.2795548,
      elong: 105.2581,
      exposure: 60,
      filter: 'zp1',
      maglimit: null,
      mjd_start: 56586.5600096,
      mjd_stop: 56586.560704044445,
      phase: 11.325,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1872%2F087%2Frings.v3.skycell.1872.087.wrp.z.56586_55959.fits&ra=104.39688&dec=27.22509&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1872.087.wrp.z.56586_55959.fits',
      'ps1:frame_id': 667606,
      'ps1:projection_id': 1872,
      'ps1:skycell_id': 87,
      ra: 104.39688,
      rh: 4.891563649086,
      sangle: 94.48200000000003,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 176.385,
      unc_a: 0.122,
      unc_b: 0.088,
      unc_theta: -6.383,
      vangle: 87.55099999999999,
      vmag: 19.66,
    },
    {
      airmass: 1.128000020980835,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1872/087/rings.v3.skycell.1872.087.wrp.z.56586_56295.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1872%2F087%2Frings.v3.skycell.1872.087.wrp.z.56586_56295.fits&ra=104.39695&dec=27.22518&size=720&format=fits',
      date: '2013-10-21 13:31:44.503',
      ddec: 3.938755,
      dec: 27.22518,
      delta: 4.5342587848651,
      dra: 2.929338,
      drh: 0.2795354,
      elong: 105.2614,
      exposure: 60,
      filter: 'zp1',
      maglimit: null,
      mjd_start: 56586.5633623,
      mjd_stop: 56586.56405674445,
      phase: 11.3248,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1872%2F087%2Frings.v3.skycell.1872.087.wrp.z.56586_56295.fits&ra=104.39695&dec=27.22518&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1872.087.wrp.z.56586_56295.fits',
      'ps1:frame_id': 667609,
      'ps1:projection_id': 1872,
      'ps1:skycell_id': 87,
      ra: 104.39695,
      rh: 4.89156419043,
      sangle: 94.48200000000003,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 176.3853,
      unc_a: 0.122,
      unc_b: 0.088,
      unc_theta: -6.384,
      vangle: 87.55099999999999,
      vmag: 19.66,
    },
    {
      airmass: 1.1100000143051147,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1880/036/rings.v3.skycell.1880.036.wrp.z.57008_65688.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1880%2F036%2Frings.v3.skycell.1880.036.wrp.z.57008_65688.fits&ra=140.00149&dec=25.57397&size=720&format=fits',
      date: '2014-12-17 15:46:44.484',
      ddec: 8.12017,
      dec: 25.57397,
      delta: 3.97369339458117,
      dra: -7.85428,
      drh: -2.0484021,
      elong: 130.2448,
      exposure: 30,
      filter: 'zp1',
      maglimit: null,
      mjd_start: 57008.6572857,
      mjd_stop: 57008.65763292222,
      phase: 9.26,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1880%2F036%2Frings.v3.skycell.1880.036.wrp.z.57008_65688.fits&ra=140.00149&dec=25.57397&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1880.036.wrp.z.57008_65688.fits',
      'ps1:frame_id': 839614,
      'ps1:projection_id': 1880,
      'ps1:skycell_id': 36,
      ra: 140.00149,
      rh: 4.67038279666,
      sangle: 99.83100000000002,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 210.2377,
      unc_a: 0.182,
      unc_b: 0.092,
      unc_theta: -17.053,
      vangle: 104.90800000000002,
      vmag: 19.187,
    },
    {
      airmass: 1.093000054359436,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1880/046/rings.v3.skycell.1880.046.wrp.z.57009_64810.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1880%2F046%2Frings.v3.skycell.1880.046.wrp.z.57009_64810.fits&ra=139.94548&dec=25.62872&size=720&format=fits',
      date: '2014-12-18 15:34:06.152',
      ddec: 8.261624,
      dec: 25.62872,
      delta: 3.9608840121425,
      dra: -8.40564,
      drh: -2.05347,
      elong: 131.2882,
      exposure: 30,
      filter: 'zp1',
      maglimit: null,
      mjd_start: 57009.6485087,
      mjd_stop: 57009.64885592222,
      phase: 9.1162,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1880%2F046%2Frings.v3.skycell.1880.046.wrp.z.57009_64810.fits&ra=139.94548&dec=25.62872&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1880.046.wrp.z.57009_64810.fits',
      'ps1:frame_id': 840394,
      'ps1:projection_id': 1880,
      'ps1:skycell_id': 46,
      ra: 139.94548,
      rh: 4.669208590688,
      sangle: 99.476,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 210.3246,
      unc_a: 0.183,
      unc_b: 0.092,
      unc_theta: -17.012,
      vangle: 104.88299999999998,
      vmag: 19.179,
    },
    {
      airmass: 1.0809999704360962,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1880/046/rings.v3.skycell.1880.046.wrp.z.57009_64859.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1880%2F046%2Frings.v3.skycell.1880.046.wrp.z.57009_64859.fits&ra=139.94545&dec=25.62874&size=720&format=fits',
      date: '2014-12-18 15:34:48.177',
      ddec: 8.261031,
      dec: 25.62874,
      delta: 3.96087781902553,
      dra: -8.40521,
      drh: -2.0534725,
      elong: 131.2887,
      exposure: 30,
      filter: 'zp1',
      maglimit: null,
      mjd_start: 57009.6489951,
      mjd_stop: 57009.64934232222,
      phase: 9.1162,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1880%2F046%2Frings.v3.skycell.1880.046.wrp.z.57009_64859.fits&ra=139.94545&dec=25.62874&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1880.046.wrp.z.57009_64859.fits',
      'ps1:frame_id': 840397,
      'ps1:projection_id': 1880,
      'ps1:skycell_id': 46,
      ra: 139.94545,
      rh: 4.669208013786,
      sangle: 99.476,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 210.3247,
      unc_a: 0.183,
      unc_b: 0.092,
      unc_theta: -17.012,
      vangle: 104.88299999999998,
      vmag: 19.179,
    },
    {
      airmass: 1.2120000123977661,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1880/058/rings.v3.skycell.1880.058.wrp.i.57021_44588.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1880%2F058%2Frings.v3.skycell.1880.058.wrp.i.57021_44588.fits&ra=138.9801&dec=26.33733&size=720&format=fits',
      date: '2014-12-30 10:43:01.602',
      ddec: 9.69499,
      dec: 26.33733,
      delta: 3.82452321739062,
      dra: -14.4746,
      drh: -2.1135737,
      elong: 143.8559,
      exposure: 45,
      filter: 'ip1',
      maglimit: null,
      mjd_start: 57021.4462859,
      mjd_stop: 57021.44680673333,
      phase: 7.1629,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1880%2F058%2Frings.v3.skycell.1880.058.wrp.i.57021_44588.fits&ra=138.9801&dec=26.33733&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1880.058.wrp.i.57021_44588.fits',
      'ps1:frame_id': 846057,
      'ps1:projection_id': 1880,
      'ps1:skycell_id': 58,
      ra: 138.9801,
      rh: 4.655010638694,
      sangle: 93.81099999999998,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 211.3624,
      unc_a: 0.189,
      unc_b: 0.096,
      unc_theta: -16.474,
      vangle: 104.435,
      vmag: 19.091,
    },
    {
      airmass: 1.1610000133514404,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1880/058/rings.v3.skycell.1880.058.wrp.i.57021_45774.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1880%2F058%2Frings.v3.skycell.1880.058.wrp.i.57021_45774.fits&ra=138.97882&dec=26.3381&size=720&format=fits',
      date: '2014-12-30 11:00:07.308',
      ddec: 9.680221,
      dec: 26.3381,
      delta: 3.82440083801696,
      dra: -14.5038,
      drh: -2.113634,
      elong: 143.8688,
      exposure: 45,
      filter: 'ip1',
      maglimit: null,
      mjd_start: 57021.4581575,
      mjd_stop: 57021.45867833333,
      phase: 7.1606,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1880%2F058%2Frings.v3.skycell.1880.058.wrp.i.57021_45774.fits&ra=138.97882&dec=26.3381&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1880.058.wrp.i.57021_45774.fits',
      'ps1:frame_id': 846074,
      'ps1:projection_id': 1880,
      'ps1:skycell_id': 58,
      ra: 138.97882,
      rh: 4.654996146069,
      sangle: 93.803,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 211.3635,
      unc_a: 0.189,
      unc_b: 0.096,
      unc_theta: -16.473,
      vangle: 104.43400000000003,
      vmag: 19.091,
    },
    {
      airmass: 1.11899995803833,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1880/058/rings.v3.skycell.1880.058.wrp.i.57021_46961.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1880%2F058%2Frings.v3.skycell.1880.058.wrp.i.57021_46961.fits&ra=138.97754&dec=26.33886&size=720&format=fits',
      date: '2014-12-30 11:17:12.427',
      ddec: 9.664724,
      dec: 26.33886,
      delta: 3.82427873422809,
      dra: -14.5303,
      drh: -2.1136943,
      elong: 143.8817,
      exposure: 45,
      filter: 'ip1',
      maglimit: null,
      mjd_start: 57021.4700223,
      mjd_stop: 57021.470543133335,
      phase: 7.1584,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1880%2F058%2Frings.v3.skycell.1880.058.wrp.i.57021_46961.fits&ra=138.97754&dec=26.33886&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1880.058.wrp.i.57021_46961.fits',
      'ps1:frame_id': 846091,
      'ps1:projection_id': 1880,
      'ps1:skycell_id': 58,
      ra: 138.97754,
      rh: 4.654981661335,
      sangle: 93.79599999999999,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 211.3645,
      unc_a: 0.189,
      unc_b: 0.096,
      unc_theta: -16.473,
      vangle: 104.43400000000003,
      vmag: 19.091,
    },
    {
      airmass: 1.0839999914169312,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1880/058/rings.v3.skycell.1880.058.wrp.i.57021_48150.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1880%2F058%2Frings.v3.skycell.1880.058.wrp.i.57021_48150.fits&ra=138.97625&dec=26.33962&size=720&format=fits',
      date: '2014-12-30 11:34:19.671',
      ddec: 9.648546,
      dec: 26.33962,
      delta: 3.82415659073042,
      dra: -14.5541,
      drh: -2.1137546,
      elong: 143.8947,
      exposure: 45,
      filter: 'ip1',
      maglimit: null,
      mjd_start: 57021.4819117,
      mjd_stop: 57021.48243253333,
      phase: 7.1562,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1880%2F058%2Frings.v3.skycell.1880.058.wrp.i.57021_48150.fits&ra=138.97625&dec=26.33962&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1880.058.wrp.i.57021_48150.fits',
      'ps1:frame_id': 846110,
      'ps1:projection_id': 1880,
      'ps1:skycell_id': 58,
      ra: 138.97625,
      rh: 4.654967146155,
      sangle: 93.78800000000001,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 211.3656,
      unc_a: 0.189,
      unc_b: 0.096,
      unc_theta: -16.472,
      vangle: 104.43299999999999,
      vmag: 19.091,
    },
    {
      airmass: 1.159000039100647,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1880/068/rings.v3.skycell.1880.068.wrp.i.57023_45243.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1880%2F068%2Frings.v3.skycell.1880.068.wrp.i.57023_45243.fits&ra=138.76345&dec=26.46569&size=720&format=fits',
      date: '2015-01-01 10:52:28.066',
      ddec: 9.797398,
      dec: 26.46569,
      delta: 3.80459160348739,
      dra: -15.4479,
      drh: -2.123756,
      elong: 146.0055,
      exposure: 45,
      filter: 'ip1',
      maglimit: null,
      mjd_start: 57023.4528422,
      mjd_stop: 57023.45336303333,
      phase: 6.7917,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1880%2F068%2Frings.v3.skycell.1880.068.wrp.i.57023_45243.fits&ra=138.76345&dec=26.46569&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1880.068.wrp.i.57023_45243.fits',
      'ps1:frame_id': 846877,
      'ps1:projection_id': 1880,
      'ps1:skycell_id': 68,
      ra: 138.76345,
      rh: 4.6525552115,
      sangle: 92.47000000000003,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 211.5396,
      unc_a: 0.19,
      unc_b: 0.097,
      unc_theta: -16.373,
      vangle: 104.33100000000002,
      vmag: 19.078,
    },
    {
      airmass: 1.121000051498413,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1880/068/rings.v3.skycell.1880.068.wrp.i.57023_46344.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1880%2F068%2Frings.v3.skycell.1880.068.wrp.i.57023_46344.fits&ra=138.76218&dec=26.4664&size=720&format=fits',
      date: '2015-01-01 11:08:18.587',
      ddec: 9.78272,
      dec: 26.4664,
      delta: 3.80448393507737,
      dra: -15.4723,
      drh: -2.1238118,
      elong: 146.0175,
      exposure: 45,
      filter: 'ip1',
      maglimit: null,
      mjd_start: 57023.4638436,
      mjd_stop: 57023.464364433334,
      phase: 6.7896,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1880%2F068%2Frings.v3.skycell.1880.068.wrp.i.57023_46344.fits&ra=138.76218&dec=26.4664&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1880.068.wrp.i.57023_46344.fits',
      'ps1:frame_id': 846893,
      'ps1:projection_id': 1880,
      'ps1:skycell_id': 68,
      ra: 138.76218,
      rh: 4.652541716554,
      sangle: 92.46199999999999,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 211.5406,
      unc_a: 0.19,
      unc_b: 0.097,
      unc_theta: -16.372,
      vangle: 104.33100000000002,
      vmag: 19.078,
    },
    {
      airmass: 1.090000033378601,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1880/068/rings.v3.skycell.1880.068.wrp.i.57023_47442.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1880%2F068%2Frings.v3.skycell.1880.068.wrp.i.57023_47442.fits&ra=138.76091&dec=26.46712&size=720&format=fits',
      date: '2015-01-01 11:24:08.330',
      ddec: 9.767493,
      dec: 26.46712,
      delta: 3.80437653813405,
      dra: -15.4943,
      drh: -2.1238675,
      elong: 146.0295,
      exposure: 45,
      filter: 'ip1',
      maglimit: null,
      mjd_start: 57023.474836,
      mjd_stop: 57023.47535683333,
      phase: 6.7875,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1880%2F068%2Frings.v3.skycell.1880.068.wrp.i.57023_47442.fits&ra=138.76091&dec=26.46712&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1880.068.wrp.i.57023_47442.fits',
      'ps1:frame_id': 846911,
      'ps1:projection_id': 1880,
      'ps1:skycell_id': 68,
      ra: 138.76091,
      rh: 4.652528232295,
      sangle: 92.45400000000001,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 211.5416,
      unc_a: 0.19,
      unc_b: 0.097,
      unc_theta: -16.371,
      vangle: 104.32999999999998,
      vmag: 19.078,
    },
    {
      airmass: 1.065999984741211,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1950/036/rings.v3.skycell.1950.036.wrp.g.56715_31022.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1950%2F036%2Frings.v3.skycell.1950.036.wrp.g.56715_31022.fits&ra=90.56994&dec=29.55184&size=720&format=fits',
      date: '2014-02-27 07:27:40.329',
      ddec: -1.40139,
      dec: 29.55184,
      delta: 4.42758949517482,
      dra: -1.13261,
      drh: -0.4574638,
      elong: 111.9514,
      exposure: 43,
      filter: 'gp1',
      maglimit: null,
      mjd_start: 56715.3106346,
      mjd_stop: 56715.311132285184,
      phase: 10.8353,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1950%2F036%2Frings.v3.skycell.1950.036.wrp.g.56715_31022.fits&ra=90.56994&dec=29.55184&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1950.036.wrp.g.56715_31022.fits',
      'ps1:frame_id': 710247,
      'ps1:projection_id': 1950,
      'ps1:skycell_id': 36,
      ra: 90.56994,
      rh: 4.884852443844,
      sangle: 272.702,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 186.1336,
      unc_a: 0.127,
      unc_b: 0.086,
      unc_theta: -6.307,
      vangle: 81.108,
      vmag: 19.603,
    },
    {
      airmass: 1.0959999561309814,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1950/036/rings.v3.skycell.1950.036.wrp.g.56715_32337.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1950%2F036%2Frings.v3.skycell.1950.036.wrp.g.56715_32337.fits&ra=90.56982&dec=29.55172&size=720&format=fits',
      date: '2014-02-27 07:46:36.498',
      ddec: -1.41981,
      dec: 29.55172,
      delta: 4.42778049090475,
      dra: -1.10876,
      drh: -0.4575381,
      elong: 111.938,
      exposure: 43,
      filter: 'gp1',
      maglimit: null,
      mjd_start: 56715.3237847,
      mjd_stop: 56715.32428238518,
      phase: 10.8364,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1950%2F036%2Frings.v3.skycell.1950.036.wrp.g.56715_32337.fits&ra=90.56982&dec=29.55172&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1950.036.wrp.g.56715_32337.fits',
      'ps1:frame_id': 710266,
      'ps1:projection_id': 1950,
      'ps1:skycell_id': 36,
      ra: 90.56982,
      rh: 4.884848969499,
      sangle: 272.7,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 186.1346,
      unc_a: 0.127,
      unc_b: 0.086,
      unc_theta: -6.307,
      vangle: 81.108,
      vmag: 19.603,
    },
    {
      airmass: 1.1380000114440918,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1952/038/rings.v3.skycell.1952.038.wrp.i.56648_53846.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1952%2F038%2Frings.v3.skycell.1952.038.wrp.i.56648_53846.fits&ra=98.69676&dec=29.22405&size=720&format=fits',
      date: '2013-12-22 12:56:20.669',
      ddec: 3.869052,
      dec: 29.22405,
      delta: 3.92161280828185,
      dra: -23.96,
      drh: -0.077193,
      elong: 170.7877,
      exposure: 45,
      filter: 'ip1',
      maglimit: null,
      mjd_start: 56648.5388677,
      mjd_stop: 56648.53938853333,
      phase: 1.8481,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1952%2F038%2Frings.v3.skycell.1952.038.wrp.i.56648_53846.fits&ra=98.69676&dec=29.22405&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1952.038.wrp.i.56648_53846.fits',
      'ps1:frame_id': 689602,
      'ps1:projection_id': 1952,
      'ps1:skycell_id': 38,
      ra: 98.69676,
      rh: 4.895176811853,
      sangle: 52.891999999999996,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 181.0159,
      unc_a: 0.144,
      unc_b: 0.1,
      unc_theta: -6.504,
      vangle: 85.28899999999999,
      vmag: 19.348,
    },
    {
      airmass: 1.190000057220459,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1952/038/rings.v3.skycell.1952.038.wrp.i.56648_55245.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1952%2F038%2Frings.v3.skycell.1952.038.wrp.i.56648_55245.fits&ra=98.6942&dec=29.22441&size=720&format=fits',
      date: '2013-12-22 13:16:30.001',
      ddec: 3.847727,
      dec: 29.22441,
      delta: 3.92158759382782,
      dra: -23.9353,
      drh: -0.0772732,
      elong: 170.8002,
      exposure: 45,
      filter: 'ip1',
      maglimit: null,
      mjd_start: 56648.5528646,
      mjd_stop: 56648.55338543333,
      phase: 1.8456,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1952%2F038%2Frings.v3.skycell.1952.038.wrp.i.56648_55245.fits&ra=98.6942&dec=29.22441&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1952.038.wrp.i.56648_55245.fits',
      'ps1:frame_id': 689623,
      'ps1:projection_id': 1952,
      'ps1:skycell_id': 38,
      ra: 98.6942,
      rh: 4.895176187503,
      sangle: 52.822,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 181.017,
      unc_a: 0.144,
      unc_b: 0.1,
      unc_theta: -6.503,
      vangle: 85.28800000000001,
      vmag: 19.348,
    },
    {
      airmass: 1.1089999675750732,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1953/009/rings.v3.skycell.1953.009.wrp.i.56620_61433.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1953%2F009%2Frings.v3.skycell.1953.009.wrp.i.56620_61433.fits&ra=102.92522&dec=28.31199&size=720&format=fits',
      date: '2013-11-24 14:45:35.535',
      ddec: 5.274594,
      dec: 28.31199,
      delta: 4.09221017611417,
      dra: -15.0471,
      drh: 0.0831597,
      elong: 140.5655,
      exposure: 45,
      filter: 'ip1',
      maglimit: null,
      mjd_start: 56620.6147342,
      mjd_stop: 56620.61525503333,
      phase: 7.3652,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1953%2F009%2Frings.v3.skycell.1953.009.wrp.i.56620_61433.fits&ra=102.92522&dec=28.31199&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1953.009.wrp.i.56620_61433.fits',
      'ps1:frame_id': 677631,
      'ps1:projection_id': 1953,
      'ps1:skycell_id': 9,
      ra: 102.92522,
      rh: 4.895129603942,
      sangle: 88.543,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 178.9141,
      unc_a: 0.137,
      unc_b: 0.096,
      unc_theta: -7.168,
      vangle: 87.19799999999998,
      vmag: 19.44,
    },
    {
      airmass: 1.0959999561309814,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1953/009/rings.v3.skycell.1953.009.wrp.i.56620_61726.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1953%2F009%2Frings.v3.skycell.1953.009.wrp.i.56620_61726.fits&ra=102.92488&dec=28.31209&size=720&format=fits',
      date: '2013-11-24 14:49:49.482',
      ddec: 5.270442,
      dec: 28.31209,
      delta: 4.09218134197732,
      dra: -15.0442,
      drh: 0.0831428,
      elong: 140.5688,
      exposure: 45,
      filter: 'ip1',
      maglimit: null,
      mjd_start: 56620.6176734,
      mjd_stop: 56620.61819423333,
      phase: 7.3646,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1953%2F009%2Frings.v3.skycell.1953.009.wrp.i.56620_61726.fits&ra=102.92488&dec=28.31209&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1953.009.wrp.i.56620_61726.fits',
      'ps1:frame_id': 677636,
      'ps1:projection_id': 1953,
      'ps1:skycell_id': 9,
      ra: 102.92488,
      rh: 4.895129745101,
      sangle: 88.54199999999997,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 178.9143,
      unc_a: 0.137,
      unc_b: 0.096,
      unc_theta: -7.168,
      vangle: 87.19799999999998,
      vmag: 19.44,
    },
    {
      airmass: 1.1549999713897705,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1953/009/rings.v3.skycell.1953.009.wrp.i.56620_62869.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1953%2F009%2Frings.v3.skycell.1953.009.wrp.i.56620_62869.fits&ra=102.92363&dec=28.31247&size=720&format=fits',
      date: '2013-11-24 15:05:41.437',
      ddec: 5.255229,
      dec: 28.31247,
      delta: 4.09207336622199,
      dra: -15.0319,
      drh: 0.0830794,
      elong: 140.581,
      exposure: 45,
      filter: 'ip1',
      maglimit: null,
      mjd_start: 56620.6286914,
      mjd_stop: 56620.62921223333,
      phase: 7.3627,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1953%2F009%2Frings.v3.skycell.1953.009.wrp.i.56620_62869.fits&ra=102.92363&dec=28.31247&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1953.009.wrp.i.56620_62869.fits',
      'ps1:frame_id': 677651,
      'ps1:projection_id': 1953,
      'ps1:skycell_id': 9,
      ra: 102.92363,
      rh: 4.895130274003,
      sangle: 88.53899999999999,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 178.9152,
      unc_a: 0.137,
      unc_b: 0.096,
      unc_theta: -7.168,
      vangle: 87.197,
      vmag: 19.44,
    },
    {
      airmass: 1.1369999647140503,
      archive_url:
        'https://ps1images.stsci.edu/rings.v3.skycell/1953/009/rings.v3.skycell.1953.009.wrp.i.56620_63125.fits',
      cutout_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1953%2F009%2Frings.v3.skycell.1953.009.wrp.i.56620_63125.fits&ra=102.92329&dec=28.31258&size=720&format=fits',
      date: '2013-11-24 15:09:58.045',
      ddec: 5.251231,
      dec: 28.31258,
      delta: 4.09204429043401,
      dra: -15.0282,
      drh: 0.0830623,
      elong: 140.5843,
      exposure: 45,
      filter: 'ip1',
      maglimit: null,
      mjd_start: 56620.6316614,
      mjd_stop: 56620.632182233334,
      phase: 7.3622,
      preview_url:
        'https://ps1images.stsci.edu/cgi-bin/fitscut.cgi?red=%2Frings.v3.skycell%2F1953%2F009%2Frings.v3.skycell.1953.009.wrp.i.56620_63125.fits&ra=102.92329&dec=28.31258&size=1199&format=jpeg',
      product_id: 'rings.v3.skycell.1953.009.wrp.i.56620_63125.fits',
      'ps1:frame_id': 677656,
      'ps1:projection_id': 1953,
      'ps1:skycell_id': 9,
      ra: 102.92329,
      rh: 4.895130416504,
      sangle: 88.53800000000001,
      seeing: null,
      source: 'ps1dr2',
      source_name: 'PanSTARRS 1 DR2',
      true_anomaly: 178.9154,
      unc_a: 0.137,
      unc_b: 0.096,
      unc_theta: -7.168,
      vangle: 87.197,
      vmag: 19.44,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Jan22/703_20220122_2B_N32022_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220122_2b_n32022_01_0003.arch?ra=107.10802&dec=30.84928&size=5.00arcmin&format=fits',
      date: '2022-01-22 05:15:52.762',
      ddec: 2.972751,
      dec: 30.84928,
      delta: 3.8743968181837,
      dra: -22.4752,
      drh: -0.854472,
      elong: 161.0992,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59601.21918706018,
      mjd_stop: 59601.219534282405,
      phase: 3.7907,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220122_2b_n32022_01_0003.arch?ra=107.10802&dec=30.84928&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220122_2b_n32022_01_0003.arch',
      ra: 107.10802,
      rh: 4.816024156881,
      sangle: 301.948,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 192.7072,
      unc_a: 0.222,
      unc_b: 0.11,
      unc_theta: 0.154,
      vangle: 89.805,
      vmag: 19.256,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Jan22/703_20220122_2B_N32022_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220122_2b_n32022_01_0004.arch?ra=107.10708&dec=30.84938&size=5.00arcmin&format=fits',
      date: '2022-01-22 05:23:37.738',
      ddec: 2.96353,
      dec: 30.84938,
      delta: 3.87441861161882,
      dra: -22.4789,
      drh: -0.8545009,
      elong: 161.0935,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59601.22456872685,
      mjd_stop: 59601.224915949075,
      phase: 3.7918,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220122_2b_n32022_01_0004.arch?ra=107.10708&dec=30.84938&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220122_2b_n32022_01_0004.arch',
      ra: 107.10708,
      rh: 4.816021501052,
      sangle: 301.93899999999996,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 192.7077,
      unc_a: 0.222,
      unc_b: 0.11,
      unc_theta: 0.155,
      vangle: 89.80399999999997,
      vmag: 19.256,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Jan22/703_20220122_2B_N32022_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220122_2b_n32022_01_0001.arch?ra=107.1099&dec=30.84907&size=5.00arcmin&format=fits',
      date: '2022-01-22 05:00:24.317',
      ddec: 2.990885,
      dec: 30.84907,
      delta: 3.87435342921078,
      dra: -22.4661,
      drh: -0.8544144,
      elong: 161.1106,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59601.20844116898,
      mjd_stop: 59601.2087883912,
      phase: 3.7884,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220122_2b_n32022_01_0001.arch?ra=107.1099&dec=30.84907&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220122_2b_n32022_01_0001.arch',
      ra: 107.1099,
      rh: 4.816029459664,
      sangle: 301.966,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 192.7064,
      unc_a: 0.222,
      unc_b: 0.11,
      unc_theta: 0.153,
      vangle: 89.80599999999998,
      vmag: 19.256,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Jan22/703_20220122_2B_N32022_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220122_2b_n32022_01_0002.arch?ra=107.10896&dec=30.84917&size=5.00arcmin&format=fits',
      date: '2022-01-22 05:08:07.932',
      ddec: 2.98188,
      dec: 30.84917,
      delta: 3.8743750742348,
      dra: -22.4709,
      drh: -0.8544431,
      elong: 161.1049,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59601.21380708333,
      mjd_stop: 59601.214154305555,
      phase: 3.7895,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220122_2b_n32022_01_0002.arch?ra=107.10896&dec=30.84917&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220122_2b_n32022_01_0002.arch',
      ra: 107.10896,
      rh: 4.816026811787,
      sangle: 301.957,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 192.7068,
      unc_a: 0.222,
      unc_b: 0.11,
      unc_theta: 0.154,
      vangle: 89.805,
      vmag: 19.256,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Feb08/703_20220208_2B_N32021_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220208_2b_n32021_01_0003.arch?ra=104.55666&dec=31.02545&size=5.00arcmin&format=fits',
      date: '2022-02-08 03:28:52.512',
      ddec: 0.490657,
      dec: 31.02545,
      delta: 3.98486426366623,
      dra: -16.571,
      drh: -0.9451538,
      elong: 142.7559,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59618.144878611114,
      mjd_stop: 59618.14522583333,
      phase: 7.1286,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220208_2b_n32021_01_0003.arch?ra=104.55666&dec=31.02545&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220208_2b_n32021_01_0003.arch',
      ra: 104.55666,
      rh: 4.807228271643,
      sangle: 286.748,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 194.0838,
      unc_a: 0.215,
      unc_b: 0.106,
      unc_theta: 1.555,
      vangle: 88.38999999999999,
      vmag: 19.31,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Feb08/703_20220208_2B_N32021_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220208_2b_n32021_01_0001.arch?ra=104.55821&dec=31.02541&size=5.00arcmin&format=fits',
      date: '2022-02-08 03:11:31.376',
      ddec: 0.509292,
      dec: 31.02541,
      delta: 3.98475955111544,
      dra: -16.5594,
      drh: -0.9450894,
      elong: 142.7691,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59618.13282842593,
      mjd_stop: 59618.133175648145,
      phase: 7.1264,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220208_2b_n32021_01_0001.arch?ra=104.55821&dec=31.02541&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220208_2b_n32021_01_0001.arch',
      ra: 104.55821,
      rh: 4.807234848947,
      sangle: 286.754,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 194.0828,
      unc_a: 0.215,
      unc_b: 0.106,
      unc_theta: 1.554,
      vangle: 88.39100000000002,
      vmag: 19.31,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Feb08/703_20220208_2B_N32021_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220208_2b_n32021_01_0004.arch?ra=104.55588&dec=31.02547&size=5.00arcmin&format=fits',
      date: '2022-02-08 03:37:35.337',
      ddec: 0.48106,
      dec: 31.02547,
      delta: 3.9849169206635,
      dra: -16.5759,
      drh: -0.9451862,
      elong: 142.7493,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59618.15092982639,
      mjd_stop: 59618.15127704861,
      phase: 7.1297,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220208_2b_n32021_01_0004.arch?ra=104.55588&dec=31.02547&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220208_2b_n32021_01_0004.arch',
      ra: 104.55588,
      rh: 4.807224968563,
      sangle: 286.745,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 194.0842,
      unc_a: 0.215,
      unc_b: 0.106,
      unc_theta: 1.555,
      vangle: 88.38999999999999,
      vmag: 19.31,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Feb08/703_20220208_2B_N32021_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220208_2b_n32021_01_0002.arch?ra=104.55744&dec=31.02543&size=5.00arcmin&format=fits',
      date: '2022-02-08 03:20:11.013',
      ddec: 0.500075,
      dec: 31.02543,
      delta: 3.98481178957144,
      dra: -16.5655,
      drh: -0.9451215,
      elong: 142.7625,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59618.13884274306,
      mjd_stop: 59618.13918996528,
      phase: 7.1275,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220208_2b_n32021_01_0002.arch?ra=104.55744&dec=31.02543&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220208_2b_n32021_01_0002.arch',
      ra: 104.55744,
      rh: 4.807231566233,
      sangle: 286.751,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 194.0833,
      unc_a: 0.215,
      unc_b: 0.106,
      unc_theta: 1.555,
      vangle: 88.39100000000002,
      vmag: 19.31,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Feb01/703_20220201_2B_N32021_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220201_2b_n32021_01_0001.arch?ra=105.49551&dec=30.98804&size=5.00arcmin&format=fits',
      date: '2022-02-01 06:46:07.658',
      ddec: 1.282556,
      dec: 30.98804,
      delta: 3.93055822068084,
      dra: -19.4493,
      drh: -0.9084115,
      elong: 150.2479,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59611.28185946759,
      mjd_stop: 59611.282206689815,
      phase: 5.8287,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220201_2b_n32021_01_0001.arch?ra=105.49551&dec=30.98804&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220201_2b_n32021_01_0001.arch',
      ra: 105.49551,
      rh: 4.810901627907,
      sangle: 290.884,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 193.525,
      unc_a: 0.219,
      unc_b: 0.108,
      unc_theta: 1.034,
      vangle: 88.91300000000001,
      vmag: 19.283,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Feb01/703_20220201_2B_N32021_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220201_2b_n32021_01_0004.arch?ra=105.4929&dec=30.98818&size=5.00arcmin&format=fits',
      date: '2022-02-01 07:10:59.117',
      ddec: 1.253817,
      dec: 30.98818,
      delta: 3.93068039253271,
      dra: -19.4247,
      drh: -0.908504,
      elong: 150.2288,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59611.299121724536,
      mjd_stop: 59611.29946894676,
      phase: 5.8322,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220201_2b_n32021_01_0004.arch?ra=105.4929&dec=30.98818&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220201_2b_n32021_01_0004.arch',
      ra: 105.4929,
      rh: 4.810892571147,
      sangle: 290.871,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 193.5265,
      unc_a: 0.219,
      unc_b: 0.108,
      unc_theta: 1.036,
      vangle: 88.91199999999998,
      vmag: 19.283,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Feb01/703_20220201_2B_N32021_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220201_2b_n32021_01_0002.arch?ra=105.49464&dec=30.98809&size=5.00arcmin&format=fits',
      date: '2022-02-01 06:54:25.008',
      ddec: 1.272862,
      dec: 30.98809,
      delta: 3.93059891334477,
      dra: -19.4417,
      drh: -0.9084423,
      elong: 150.2415,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59611.28761583333,
      mjd_stop: 59611.287963055554,
      phase: 5.8299,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220201_2b_n32021_01_0002.arch?ra=105.49464&dec=30.98809&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220201_2b_n32021_01_0002.arch',
      ra: 105.49464,
      rh: 4.810898607893,
      sangle: 290.88,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 193.5255,
      unc_a: 0.219,
      unc_b: 0.108,
      unc_theta: 1.035,
      vangle: 88.91300000000001,
      vmag: 19.283,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Feb01/703_20220201_2B_N32021_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220201_2b_n32021_01_0003.arch?ra=105.49377&dec=30.98813&size=5.00arcmin&format=fits',
      date: '2022-02-01 07:02:41.981',
      ddec: 1.263282,
      dec: 30.98813,
      delta: 3.93063962272636,
      dra: -19.4335,
      drh: -0.9084731,
      elong: 150.2352,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59611.29336783565,
      mjd_stop: 59611.29371505787,
      phase: 5.831,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220201_2b_n32021_01_0003.arch?ra=105.49377&dec=30.98813&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220201_2b_n32021_01_0003.arch',
      ra: 105.49377,
      rh: 4.810895590066,
      sangle: 290.876,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 193.526,
      unc_a: 0.219,
      unc_b: 0.108,
      unc_theta: 1.035,
      vangle: 88.91199999999998,
      vmag: 19.283,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Jan08/703_20220108_2B_N28023_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220108_2b_n28023_01_0003.arch?ra=109.59603&dec=30.48757&size=5.00arcmin&format=fits',
      date: '2022-01-08 06:49:11.791',
      ddec: 5.002619,
      dec: 30.48757,
      delta: 3.84725569961141,
      dra: -24.0018,
      drh: -0.7796603,
      elong: 171.775,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59587.28399063658,
      mjd_stop: 59587.28433785879,
      phase: 1.6715,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220108_2b_n28023_01_0003.arch?ra=109.59603&dec=30.48757&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220108_2b_n28023_01_0003.arch',
      ra: 109.59603,
      rh: 4.822600110663,
      sangle: 2.990000000000009,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 191.5773,
      unc_a: 0.223,
      unc_b: 0.111,
      unc_theta: -1.161,
      vangle: 91.156,
      vmag: 19.246,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Jan08/703_20220108_2B_N28023_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220108_2b_n28023_01_0001.arch?ra=109.59811&dec=30.4872&size=5.00arcmin&format=fits',
      date: '2022-01-08 06:33:02.741',
      ddec: 5.021864,
      dec: 30.4872,
      delta: 3.84725905780127,
      dra: -23.9938,
      drh: -0.7796001,
      elong: 171.7762,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59587.27277478009,
      mjd_stop: 59587.27312200231,
      phase: 1.6713,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220108_2b_n28023_01_0001.arch?ra=109.59811&dec=30.4872&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220108_2b_n28023_01_0001.arch',
      ra: 109.59811,
      rh: 4.822605160882,
      sangle: 3.084000000000003,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 191.5763,
      unc_a: 0.223,
      unc_b: 0.111,
      unc_theta: -1.163,
      vangle: 91.15699999999998,
      vmag: 19.246,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Jan08/703_20220108_2B_N28023_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220108_2b_n28023_01_0004.arch?ra=109.59498&dec=30.48775&size=5.00arcmin&format=fits',
      date: '2022-01-08 06:57:16.285',
      ddec: 4.992903,
      dec: 30.48775,
      delta: 3.84725409285346,
      dra: -24.0048,
      drh: -0.7796905,
      elong: 171.7744,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59587.28959820602,
      mjd_stop: 59587.28994542824,
      phase: 1.6716,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220108_2b_n28023_01_0004.arch?ra=109.59498&dec=30.48775&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220108_2b_n28023_01_0004.arch',
      ra: 109.59498,
      rh: 4.822597585569,
      sangle: 2.943000000000012,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 191.5777,
      unc_a: 0.223,
      unc_b: 0.111,
      unc_theta: -1.161,
      vangle: 91.15499999999997,
      vmag: 19.246,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Jan08/703_20220108_2B_N28023_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220108_2b_n28023_01_0002.arch?ra=109.59707&dec=30.48738&size=5.00arcmin&format=fits',
      date: '2022-01-08 06:41:07.083',
      ddec: 5.012281,
      dec: 30.48738,
      delta: 3.84725735535362,
      dra: -23.9981,
      drh: -0.7796302,
      elong: 171.7756,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59587.27838059028,
      mjd_stop: 59587.2787278125,
      phase: 1.6714,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220108_2b_n28023_01_0002.arch?ra=109.59707&dec=30.48738&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220108_2b_n28023_01_0002.arch',
      ra: 109.59707,
      rh: 4.822602636775,
      sangle: 3.037000000000006,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 191.5768,
      unc_a: 0.223,
      unc_b: 0.111,
      unc_theta: -1.162,
      vangle: 91.156,
      vmag: 19.246,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Jan04/703_20220104_2B_N28023_02_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220104_2b_n28023_02_0001.arch?ra=110.29682&dec=30.35465&size=5.00arcmin&format=fits',
      date: '2022-01-04 09:58:03.292',
      ddec: 5.315059,
      dec: 30.35465,
      delta: 3.85064939032179,
      dra: -23.7445,
      drh: -0.7588713,
      elong: 170.9726,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59583.41514226852,
      mjd_stop: 59583.41548949074,
      phase: 1.8355,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220104_2b_n28023_02_0001.arch?ra=110.29682&dec=30.35465&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220104_2b_n28023_02_0001.arch',
      ra: 110.29682,
      rh: 4.824319004345,
      sangle: 33.616000000000014,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 191.2641,
      unc_a: 0.223,
      unc_b: 0.111,
      unc_theta: -1.521,
      vangle: 91.529,
      vmag: 19.249,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Jan04/703_20220104_2B_N28023_02_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220104_2b_n28023_02_0003.arch?ra=110.29471&dec=30.35505&size=5.00arcmin&format=fits',
      date: '2022-01-04 10:14:37.941',
      ddec: 5.2983,
      dec: 30.35505,
      delta: 3.85063344009275,
      dra: -23.7248,
      drh: -0.7589332,
      elong: 170.9784,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59583.42665440972,
      mjd_stop: 59583.42700163194,
      phase: 1.8343,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220104_2b_n28023_02_0003.arch?ra=110.29471&dec=30.35505&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220104_2b_n28023_02_0003.arch',
      ra: 110.29471,
      rh: 4.824313958502,
      sangle: 33.536,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 191.265,
      unc_a: 0.223,
      unc_b: 0.111,
      unc_theta: -1.52,
      vangle: 91.52800000000002,
      vmag: 19.249,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Jan04/703_20220104_2B_N28023_02_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220104_2b_n28023_02_0002.arch?ra=110.29577&dec=30.35485&size=5.00arcmin&format=fits',
      date: '2022-01-04 10:06:17.427',
      ddec: 5.306639,
      dec: 30.35485,
      delta: 3.85064144410026,
      dra: -23.735,
      drh: -0.758902,
      elong: 170.9755,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59583.42086142361,
      mjd_stop: 59583.421208645836,
      phase: 1.8349,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220104_2b_n28023_02_0002.arch?ra=110.29577&dec=30.35485&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220104_2b_n28023_02_0002.arch',
      ra: 110.29577,
      rh: 4.824316497655,
      sangle: 33.577,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 191.2646,
      unc_a: 0.223,
      unc_b: 0.111,
      unc_theta: -1.521,
      vangle: 91.52800000000002,
      vmag: 19.249,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Jan04/703_20220104_2B_N28023_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220104_2b_n28023_01_0003.arch?ra=110.29892&dec=30.35425&size=5.00arcmin&format=fits',
      date: '2022-01-04 09:41:32.207',
      ddec: 5.33247,
      dec: 30.35425,
      delta: 3.85066546365705,
      dra: -23.7619,
      drh: -0.7588097,
      elong: 170.9668,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59583.40367137732,
      mjd_stop: 59583.40401859954,
      phase: 1.8367,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220104_2b_n28023_01_0003.arch?ra=110.29892&dec=30.35425&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220104_2b_n28023_01_0003.arch',
      ra: 110.29892,
      rh: 4.824324031699,
      sangle: 33.696,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 191.2632,
      unc_a: 0.223,
      unc_b: 0.111,
      unc_theta: -1.522,
      vangle: 91.52999999999997,
      vmag: 19.249,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Jan04/703_20220104_2B_N28023_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220104_2b_n28023_01_0004.arch?ra=110.29788&dec=30.35445&size=5.00arcmin&format=fits',
      date: '2022-01-04 09:49:43.220',
      ddec: 5.32376,
      dec: 30.35445,
      delta: 3.85065747757119,
      dra: -23.7536,
      drh: -0.7588402,
      elong: 170.9697,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59583.40935439815,
      mjd_stop: 59583.40970162037,
      phase: 1.8361,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220104_2b_n28023_01_0004.arch?ra=110.29788&dec=30.35445&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220104_2b_n28023_01_0004.arch',
      ra: 110.29788,
      rh: 4.824321541049,
      sangle: 33.65700000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 191.2636,
      unc_a: 0.223,
      unc_b: 0.111,
      unc_theta: -1.522,
      vangle: 91.529,
      vmag: 19.249,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Jan04/703_20220104_2B_N28023_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220104_2b_n28023_01_0002.arch?ra=110.29997&dec=30.35405&size=5.00arcmin&format=fits',
      date: '2022-01-04 09:33:19.851',
      ddec: 5.341357,
      dec: 30.35405,
      delta: 3.85067351739871,
      dra: -23.7696,
      drh: -0.758779,
      elong: 170.9639,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59583.3979728125,
      mjd_stop: 59583.39832003472,
      phase: 1.8373,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220104_2b_n28023_01_0002.arch?ra=110.29997&dec=30.35405&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220104_2b_n28023_01_0002.arch',
      ra: 110.29997,
      rh: 4.82432652906,
      sangle: 33.73599999999999,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 191.2627,
      unc_a: 0.223,
      unc_b: 0.111,
      unc_theta: -1.523,
      vangle: 91.52999999999997,
      vmag: 19.249,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Jan04/703_20220104_2B_N28023_02_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220104_2b_n28023_02_0004.arch?ra=110.29362&dec=30.35526&size=5.00arcmin&format=fits',
      date: '2022-01-04 10:23:11.309',
      ddec: 5.289956,
      dec: 30.35526,
      delta: 3.85062527645101,
      dra: -23.7138,
      drh: -0.7589651,
      elong: 170.9814,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59583.43259616898,
      mjd_stop: 59583.432943391206,
      phase: 1.8337,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220104_2b_n28023_02_0004.arch?ra=110.29362&dec=30.35526&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220104_2b_n28023_02_0004.arch',
      ra: 110.29362,
      rh: 4.824311354031,
      sangle: 33.495000000000005,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 191.2655,
      unc_a: 0.223,
      unc_b: 0.111,
      unc_theta: -1.52,
      vangle: 91.52699999999999,
      vmag: 19.249,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Jan04/703_20220104_2B_N28023_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220104_2b_n28023_01_0001.arch?ra=110.30099&dec=30.35385&size=5.00arcmin&format=fits',
      date: '2022-01-04 09:25:20.735',
      ddec: 5.350144,
      dec: 30.35385,
      delta: 3.8506813992287,
      dra: -23.7766,
      drh: -0.7587492,
      elong: 170.9611,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59583.39242748843,
      mjd_stop: 59583.39277471065,
      phase: 1.8378,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220104_2b_n28023_01_0001.arch?ra=110.30099&dec=30.35385&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220104_2b_n28023_01_0001.arch',
      ra: 110.30099,
      rh: 4.824328959169,
      sangle: 33.775000000000006,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 191.2623,
      unc_a: 0.223,
      unc_b: 0.111,
      unc_theta: -1.523,
      vangle: 91.531,
      vmag: 19.249,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Feb20/703_20220220_2B_N32021_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220220_2b_n32021_01_0003.arch?ra=103.30857&dec=30.99279&size=5.00arcmin&format=fits',
      date: '2022-02-20 06:49:47.368',
      ddec: -1.21328,
      dec: 30.99279,
      delta: 4.10795626280376,
      dra: -10.5178,
      drh: -1.0100491,
      elong: 129.7263,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59630.28440240741,
      mjd_stop: 59630.28474962963,
      phase: 9.11,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220220_2b_n32021_01_0003.arch?ra=103.30857&dec=30.99279&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220220_2b_n32021_01_0003.arch',
      ra: 103.30857,
      rh: 4.800374478904,
      sangle: 282.08299999999997,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 195.074,
      unc_a: 0.208,
      unc_b: 0.102,
      unc_theta: 2.259,
      vangle: 87.69400000000002,
      vmag: 19.37,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Feb20/703_20220220_2B_N32021_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220220_2b_n32021_01_0001.arch?ra=103.30947&dec=30.99288&size=5.00arcmin&format=fits',
      date: '2022-02-20 06:33:54.882',
      ddec: -1.19845,
      dec: 30.99288,
      delta: 4.10782949844524,
      dra: -10.5432,
      drh: -1.0099902,
      elong: 129.7381,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59630.27337826389,
      mjd_stop: 59630.27372548611,
      phase: 9.1084,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220220_2b_n32021_01_0001.arch?ra=103.30947&dec=30.99288&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220220_2b_n32021_01_0001.arch',
      ra: 103.30947,
      rh: 4.800380909247,
      sangle: 282.086,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 195.0731,
      unc_a: 0.208,
      unc_b: 0.102,
      unc_theta: 2.258,
      vangle: 87.695,
      vmag: 19.37,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Feb20/703_20220220_2B_N32021_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220220_2b_n32021_01_0002.arch?ra=103.30902&dec=30.99283&size=5.00arcmin&format=fits',
      date: '2022-02-20 06:41:51.167',
      ddec: -1.20595,
      dec: 30.99283,
      delta: 4.1078928683457,
      dra: -10.5307,
      drh: -1.0100197,
      elong: 129.7322,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59630.27889082176,
      mjd_stop: 59630.27923804398,
      phase: 9.1092,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220220_2b_n32021_01_0002.arch?ra=103.30902&dec=30.99283&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220220_2b_n32021_01_0002.arch',
      ra: 103.30902,
      rh: 4.800377693839,
      sangle: 282.084,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 195.0736,
      unc_a: 0.208,
      unc_b: 0.102,
      unc_theta: 2.258,
      vangle: 87.695,
      vmag: 19.37,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Feb20/703_20220220_2B_N32021_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220220_2b_n32021_01_0004.arch?ra=103.30812&dec=30.99274&size=5.00arcmin&format=fits',
      date: '2022-02-20 06:57:43.740',
      ddec: -1.22042,
      dec: 30.99274,
      delta: 4.1080197149963,
      dra: -10.5045,
      drh: -1.0100786,
      elong: 129.7204,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59630.28991597222,
      mjd_stop: 59630.290263194445,
      phase: 9.1108,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220220_2b_n32021_01_0004.arch?ra=103.30812&dec=30.99274&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220220_2b_n32021_01_0004.arch',
      ra: 103.30812,
      rh: 4.800371262721,
      sangle: 282.081,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 195.0745,
      unc_a: 0.208,
      unc_b: 0.102,
      unc_theta: 2.259,
      vangle: 87.69400000000002,
      vmag: 19.37,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Feb27/703_20220227_2B_N32021_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220227_2b_n32021_01_0003.arch?ra=102.86284&dec=30.92737&size=5.00arcmin&format=fits',
      date: '2022-02-27 04:48:36.803',
      ddec: -1.78024,
      dec: 30.92737,
      delta: 4.19089434009162,
      dra: -6.87312,
      drh: -1.0469626,
      elong: 122.5086,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59637.20025234954,
      mjd_stop: 59637.20059957176,
      phase: 10.0232,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220227_2b_n32021_01_0003.arch?ra=102.86284&dec=30.92737&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220227_2b_n32021_01_0003.arch',
      ra: 102.86284,
      rh: 4.796266652765,
      sangle: 280.256,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 195.6394,
      unc_a: 0.203,
      unc_b: 0.1,
      unc_theta: 2.516,
      vangle: 87.44799999999998,
      vmag: 19.41,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Feb27/703_20220227_2B_N32021_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220227_2b_n32021_01_0002.arch?ra=102.86314&dec=30.92744&size=5.00arcmin&format=fits',
      date: '2022-02-27 04:40:31.520',
      ddec: -1.77155,
      dec: 30.92744,
      delta: 4.19082350549659,
      dra: -6.88026,
      drh: -1.0469326,
      elong: 122.5144,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59637.19463564815,
      mjd_stop: 59637.19498287037,
      phase: 10.0226,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220227_2b_n32021_01_0002.arch?ra=102.86314&dec=30.92744&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220227_2b_n32021_01_0002.arch',
      ra: 102.86314,
      rh: 4.796270048728,
      sangle: 280.258,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 195.6389,
      unc_a: 0.203,
      unc_b: 0.1,
      unc_theta: 2.516,
      vangle: 87.44799999999998,
      vmag: 19.41,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Feb27/703_20220227_2B_N32021_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220227_2b_n32021_01_0001.arch?ra=102.86344&dec=30.9275&size=5.00arcmin&format=fits',
      date: '2022-02-27 04:32:25.933',
      ddec: -1.76279,
      dec: 30.9275,
      delta: 4.1907526691254,
      dra: -6.88684,
      drh: -1.0469026,
      elong: 122.5203,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59637.18901542824,
      mjd_stop: 59637.18936265046,
      phase: 10.0219,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220227_2b_n32021_01_0001.arch?ra=102.86344&dec=30.9275&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220227_2b_n32021_01_0001.arch',
      ra: 102.86344,
      rh: 4.796273446721,
      sangle: 280.259,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 195.6385,
      unc_a: 0.203,
      unc_b: 0.1,
      unc_theta: 2.515,
      vangle: 87.44799999999998,
      vmag: 19.41,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Feb27/703_20220227_2B_N32021_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220227_2b_n32021_01_0004.arch?ra=102.86254&dec=30.9273&size=5.00arcmin&format=fits',
      date: '2022-02-27 04:56:42.457',
      ddec: -1.78886,
      dec: 30.9273,
      delta: 4.19096527111888,
      dra: -6.86542,
      drh: -1.0469926,
      elong: 122.5027,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59637.20587334491,
      mjd_stop: 59637.20622056713,
      phase: 10.0239,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220227_2b_n32021_01_0004.arch?ra=102.86254&dec=30.9273&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220227_2b_n32021_01_0004.arch',
      ra: 102.86254,
      rh: 4.796263254108,
      sangle: 280.255,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 195.6398,
      unc_a: 0.203,
      unc_b: 0.1,
      unc_theta: 2.516,
      vangle: 87.44799999999998,
      vmag: 19.41,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Mar03/703_20220303_2B_N32021_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220303_2b_n32021_01_0003.arch?ra=102.69664&dec=30.87641&size=5.00arcmin&format=fits',
      date: '2022-03-03 05:26:31.204',
      ddec: -2.15909,
      dec: 30.87641,
      delta: 4.24267342662391,
      dra: -4.60281,
      drh: -1.0684332,
      elong: 118.3848,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59641.22657643518,
      mjd_stop: 59641.22692365741,
      phase: 10.4778,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220303_2b_n32021_01_0003.arch?ra=102.69664&dec=30.87641&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220303_2b_n32021_01_0003.arch',
      ra: 102.69664,
      rh: 4.793807265485,
      sangle: 279.373,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 195.969,
      unc_a: 0.201,
      unc_b: 0.099,
      unc_theta: 2.614,
      vangle: 87.35700000000003,
      vmag: 19.434,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Mar03/703_20220303_2B_N32021_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220303_2b_n32021_01_0004.arch?ra=102.69648&dec=30.87635&size=5.00arcmin&format=fits',
      date: '2022-03-03 05:32:53.695',
      ddec: -2.16515,
      dec: 30.87635,
      delta: 4.2427320271804,
      dra: -4.594,
      drh: -1.0684568,
      elong: 118.3803,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59641.23100341435,
      mjd_stop: 59641.231350636575,
      phase: 10.4782,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220303_2b_n32021_01_0004.arch?ra=102.69648&dec=30.87635&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220303_2b_n32021_01_0004.arch',
      ra: 102.69648,
      rh: 4.7938045339,
      sangle: 279.372,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 195.9693,
      unc_a: 0.201,
      unc_b: 0.099,
      unc_theta: 2.614,
      vangle: 87.35700000000003,
      vmag: 19.435,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Mar03/703_20220303_2B_N32021_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220303_2b_n32021_01_0001.arch?ra=102.69695&dec=30.87654&size=5.00arcmin&format=fits',
      date: '2022-03-03 05:13:45.936',
      ddec: -2.14671,
      dec: 30.87654,
      delta: 4.24255625310294,
      dra: -4.61949,
      drh: -1.068386,
      elong: 118.3939,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59641.21771916666,
      mjd_stop: 59641.21806638889,
      phase: 10.4768,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220303_2b_n32021_01_0001.arch?ra=102.69695&dec=30.87654&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220303_2b_n32021_01_0001.arch',
      ra: 102.69695,
      rh: 4.793812730517,
      sangle: 279.375,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 195.9682,
      unc_a: 0.201,
      unc_b: 0.099,
      unc_theta: 2.614,
      vangle: 87.358,
      vmag: 19.434,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Mar03/703_20220303_2B_N32021_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220303_2b_n32021_01_0002.arch?ra=102.6968&dec=30.87648&size=5.00arcmin&format=fits',
      date: '2022-03-03 05:20:08.384',
      ddec: -2.15294,
      dec: 30.87648,
      delta: 4.24261479937453,
      dra: -4.61131,
      drh: -1.0684095,
      elong: 118.3894,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59641.22214564815,
      mjd_stop: 59641.22249287037,
      phase: 10.4773,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220303_2b_n32021_01_0002.arch?ra=102.6968&dec=30.87648&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220303_2b_n32021_01_0002.arch',
      ra: 102.6968,
      rh: 4.793809999359,
      sangle: 279.374,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 195.9686,
      unc_a: 0.201,
      unc_b: 0.099,
      unc_theta: 2.614,
      vangle: 87.358,
      vmag: 19.434,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Mar09/703_20220309_2B_N32021_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220309_2b_n32021_01_0001.arch?ra=102.57937&dec=30.78694&size=5.00arcmin&format=fits',
      date: '2022-03-09 03:38:21.583',
      ddec: -2.49265,
      dec: 30.78694,
      delta: 4.32266331785887,
      dra: -1.37111,
      drh: -1.1000004,
      elong: 112.4326,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59647.15146508102,
      mjd_stop: 59647.151812303244,
      phase: 11.0406,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220309_2b_n32021_01_0001.arch?ra=102.57937&dec=30.78694&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220309_2b_n32021_01_0001.arch',
      ra: 102.57937,
      rh: 4.79009745376,
      sangle: 278.266,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 196.4545,
      unc_a: 0.197,
      unc_b: 0.097,
      unc_theta: 2.69,
      vangle: 87.29700000000003,
      vmag: 19.472,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Mar09/703_20220309_2B_N32021_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220309_2b_n32021_01_0002.arch?ra=102.57931&dec=30.78685&size=5.00arcmin&format=fits',
      date: '2022-03-09 03:46:33.807',
      ddec: -2.50121,
      dec: 30.78685,
      delta: 4.32274228504863,
      dra: -1.36549,
      drh: -1.1000307,
      elong: 112.4268,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59647.157162118056,
      mjd_stop: 59647.15750934028,
      phase: 11.0411,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220309_2b_n32021_01_0002.arch?ra=102.57931&dec=30.78685&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220309_2b_n32021_01_0002.arch',
      ra: 102.57931,
      rh: 4.790093834653,
      sangle: 278.265,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 196.455,
      unc_a: 0.197,
      unc_b: 0.097,
      unc_theta: 2.69,
      vangle: 87.29700000000003,
      vmag: 19.472,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Mar09/703_20220309_2B_N32021_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220309_2b_n32021_01_0003.arch?ra=102.57925&dec=30.78675&size=5.00arcmin&format=fits',
      date: '2022-03-09 03:54:46.213',
      ddec: -2.50972,
      dec: 30.78675,
      delta: 4.32282132405143,
      dra: -1.3593,
      drh: -1.1000611,
      elong: 112.4211,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59647.162861261575,
      mjd_stop: 59647.1632084838,
      phase: 11.0415,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220309_2b_n32021_01_0003.arch?ra=102.57925&dec=30.78675&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220309_2b_n32021_01_0003.arch',
      ra: 102.57925,
      rh: 4.790090214108,
      sangle: 278.264,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 196.4555,
      unc_a: 0.197,
      unc_b: 0.097,
      unc_theta: 2.69,
      vangle: 87.29700000000003,
      vmag: 19.472,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Mar09/703_20220309_2B_N32021_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220309_2b_n32021_01_0004.arch?ra=102.57919&dec=30.78666&size=5.00arcmin&format=fits',
      date: '2022-03-09 04:02:58.270',
      ddec: -2.51816,
      dec: 30.78666,
      delta: 4.32290034934894,
      dra: -1.35255,
      drh: -1.1000914,
      elong: 112.4154,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59647.16855636574,
      mjd_stop: 59647.16890358796,
      phase: 11.042,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220309_2b_n32021_01_0004.arch?ra=102.57919&dec=30.78666&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220309_2b_n32021_01_0004.arch',
      ra: 102.57919,
      rh: 4.79008659603,
      sangle: 278.26300000000003,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 196.4559,
      unc_a: 0.197,
      unc_b: 0.097,
      unc_theta: 2.69,
      vangle: 87.29700000000003,
      vmag: 19.472,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Mar16/703_20220316_2B_N28022_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220316_2b_n28022_01_0003.arch?ra=102.63423&dec=30.66083&size=5.00arcmin&format=fits',
      date: '2022-03-16 05:31:36.879',
      ddec: -3.02522,
      dec: 30.66083,
      delta: 4.4228167471771,
      dra: 2.574414,
      drh: -1.1376704,
      elong: 105.5036,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59654.23011434028,
      mjd_stop: 59654.2304615625,
      phase: 11.5498,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220316_2b_n28022_01_0003.arch?ra=102.63423&dec=30.66083&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220316_2b_n28022_01_0003.arch',
      ra: 102.63423,
      rh: 4.785523718831,
      sangle: 277.185,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 197.0357,
      unc_a: 0.192,
      unc_b: 0.094,
      unc_theta: 2.674,
      vangle: 87.33499999999998,
      vmag: 19.518,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Mar16/703_20220316_2B_N28022_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220316_2b_n28022_01_0004.arch?ra=102.63432&dec=30.66074&size=5.00arcmin&format=fits',
      date: '2022-03-16 05:38:03.073',
      ddec: -3.03002,
      dec: 30.66074,
      delta: 4.42288182491694,
      dra: 2.585384,
      drh: -1.1376941,
      elong: 105.4992,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59654.23458417824,
      mjd_stop: 59654.23493140046,
      phase: 11.5501,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220316_2b_n28022_01_0004.arch?ra=102.63432&dec=30.66074&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220316_2b_n28022_01_0004.arch',
      ra: 102.63432,
      rh: 4.785520782097,
      sangle: 277.18399999999997,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 197.036,
      unc_a: 0.192,
      unc_b: 0.094,
      unc_theta: 2.674,
      vangle: 87.33499999999998,
      vmag: 19.518,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Mar16/703_20220316_2B_N28022_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220316_2b_n28022_01_0001.arch?ra=102.63405&dec=30.66101&size=5.00arcmin&format=fits',
      date: '2022-03-16 05:18:45.803',
      ddec: -3.01527,
      dec: 30.66101,
      delta: 4.42268687108351,
      dra: 2.553282,
      drh: -1.1376229,
      elong: 105.5123,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59654.22118984954,
      mjd_stop: 59654.22153707176,
      phase: 11.5493,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220316_2b_n28022_01_0001.arch?ra=102.63405&dec=30.66101&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220316_2b_n28022_01_0001.arch',
      ra: 102.63405,
      rh: 4.785529582138,
      sangle: 277.18600000000004,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 197.0349,
      unc_a: 0.192,
      unc_b: 0.094,
      unc_theta: 2.674,
      vangle: 87.33499999999998,
      vmag: 19.518,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Mar16/703_20220316_2B_N28022_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220316_2b_n28022_01_0002.arch?ra=102.63414&dec=30.66092&size=5.00arcmin&format=fits',
      date: '2022-03-16 05:25:11.386',
      ddec: -3.0203,
      dec: 30.66092,
      delta: 4.4227518068411,
      dra: 2.563718,
      drh: -1.1376466,
      elong: 105.5079,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59654.22565261574,
      mjd_stop: 59654.22599983796,
      phase: 11.5495,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220316_2b_n28022_01_0002.arch?ra=102.63414&dec=30.66092&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220316_2b_n28022_01_0002.arch',
      ra: 102.63414,
      rh: 4.785526650173,
      sangle: 277.185,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 197.0353,
      unc_a: 0.192,
      unc_b: 0.094,
      unc_theta: 2.674,
      vangle: 87.33499999999998,
      vmag: 19.518,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Jan11/703_20220111_2B_N28023_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220111_2b_n28023_01_0001.arch?ra=109.0593&dec=30.57995&size=5.00arcmin&format=fits',
      date: '2022-01-11 05:38:18.746',
      ddec: 4.65572,
      dec: 30.57995,
      delta: 3.84789929031313,
      dra: -23.923,
      drh: -0.7955109,
      elong: 170.7911,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59590.2347655787,
      mjd_stop: 59590.235112800925,
      phase: 1.8684,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220111_2b_n28023_01_0001.arch?ra=109.0593&dec=30.57995&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220111_2b_n28023_01_0001.arch',
      ra: 109.0593,
      rh: 4.821257896419,
      sangle: 340.59299999999996,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 191.8163,
      unc_a: 0.224,
      unc_b: 0.111,
      unc_theta: -0.882,
      vangle: 90.86700000000002,
      vmag: 19.245,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Jan11/703_20220111_2B_N28023_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220111_2b_n28023_01_0004.arch?ra=109.05631&dec=30.58045&size=5.00arcmin&format=fits',
      date: '2022-01-11 06:01:31.173',
      ddec: 4.629148,
      dec: 30.58045,
      delta: 3.84790924989147,
      dra: -23.9416,
      drh: -0.7955975,
      elong: 170.7823,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59590.250881631946,
      mjd_stop: 59590.25122885417,
      phase: 1.8702,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220111_2b_n28023_01_0004.arch?ra=109.05631&dec=30.58045&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220111_2b_n28023_01_0004.arch',
      ra: 109.05631,
      rh: 4.821250491587,
      sangle: 340.485,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 191.8176,
      unc_a: 0.224,
      unc_b: 0.111,
      unc_theta: -0.881,
      vangle: 90.86599999999999,
      vmag: 19.245,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Jan11/703_20220111_2B_N28023_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220111_2b_n28023_01_0002.arch?ra=109.0583&dec=30.58012&size=5.00arcmin&format=fits',
      date: '2022-01-11 05:46:02.930',
      ddec: 4.646974,
      dec: 30.58012,
      delta: 3.84790256834131,
      dra: -23.9297,
      drh: -0.7955398,
      elong: 170.7882,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59590.240138078705,
      mjd_stop: 59590.24048530093,
      phase: 1.869,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220111_2b_n28023_01_0002.arch?ra=109.0583&dec=30.58012&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220111_2b_n28023_01_0002.arch',
      ra: 109.0583,
      rh: 4.821255428009,
      sangle: 340.557,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 191.8167,
      unc_a: 0.224,
      unc_b: 0.111,
      unc_theta: -0.882,
      vangle: 90.86700000000002,
      vmag: 19.245,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Jan11/703_20220111_2B_N28023_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220111_2b_n28023_01_0003.arch?ra=109.0573&dec=30.58028&size=5.00arcmin&format=fits',
      date: '2022-01-11 05:53:46.957',
      ddec: 4.638116,
      dec: 30.58028,
      delta: 3.84790588722451,
      dra: -23.936,
      drh: -0.7955686,
      elong: 170.7853,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59590.245508761574,
      mjd_stop: 59590.2458559838,
      phase: 1.8696,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220111_2b_n28023_01_0003.arch?ra=109.0573&dec=30.58028&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220111_2b_n28023_01_0003.arch',
      ra: 109.0573,
      rh: 4.821252960345,
      sangle: 340.52099999999996,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 191.8171,
      unc_a: 0.224,
      unc_b: 0.111,
      unc_theta: -0.881,
      vangle: 90.86599999999999,
      vmag: 19.245,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Mar23/703_20220323_2B_N28022_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220323_2b_n28022_01_0004.arch?ra=102.8879&dec=30.52042&size=5.00arcmin&format=fits',
      date: '2022-03-23 04:16:22.227',
      ddec: -3.31849,
      dec: 30.52042,
      delta: 4.52440903552074,
      dra: 6.116411,
      drh: -1.1745953,
      elong: 98.8957,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59661.17786142361,
      mjd_stop: 59661.17820864583,
      phase: 11.8816,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220323_2b_n28022_01_0004.arch?ra=102.8879&dec=30.52042&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220323_2b_n28022_01_0004.arch',
      ra: 102.8879,
      rh: 4.780884926872,
      sangle: 276.327,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 197.6071,
      unc_a: 0.187,
      unc_b: 0.092,
      unc_theta: 2.551,
      vangle: 87.48500000000001,
      vmag: 19.563,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Mar23/703_20220323_2B_N28022_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220323_2b_n28022_01_0001.arch?ra=102.88702&dec=30.52083&size=5.00arcmin&format=fits',
      date: '2022-03-23 03:49:37.419',
      ddec: -3.29496,
      dec: 30.52083,
      delta: 4.52413269180767,
      dra: 6.083768,
      drh: -1.1744967,
      elong: 98.9133,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59661.159287256945,
      mjd_stop: 59661.15963447917,
      phase: 11.8808,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220323_2b_n28022_01_0001.arch?ra=102.88702&dec=30.52083&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220323_2b_n28022_01_0001.arch',
      ra: 102.88702,
      rh: 4.780897525706,
      sangle: 276.329,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 197.6056,
      unc_a: 0.187,
      unc_b: 0.092,
      unc_theta: 2.551,
      vangle: 87.48399999999998,
      vmag: 19.563,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Mar23/703_20220323_2B_N28022_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220323_2b_n28022_01_0003.arch?ra=102.88761&dec=30.52056&size=5.00arcmin&format=fits',
      date: '2022-03-23 04:07:26.637',
      ddec: -3.31079,
      dec: 30.52056,
      delta: 4.52431676505843,
      dra: 6.104937,
      drh: -1.1745624,
      elong: 98.9016,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59661.171662465276,
      mjd_stop: 59661.1720096875,
      phase: 11.8813,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220323_2b_n28022_01_0003.arch?ra=102.88761&dec=30.52056&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220323_2b_n28022_01_0003.arch',
      ra: 102.88761,
      rh: 4.780889131735,
      sangle: 276.327,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 197.6066,
      unc_a: 0.187,
      unc_b: 0.092,
      unc_theta: 2.551,
      vangle: 87.48399999999998,
      vmag: 19.563,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Mar23/703_20220323_2B_N28022_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220323_2b_n28022_01_0002.arch?ra=102.88731&dec=30.52069&size=5.00arcmin&format=fits',
      date: '2022-03-23 03:58:31.896',
      ddec: -3.30295,
      dec: 30.52069,
      delta: 4.52422468383219,
      dra: 6.094056,
      drh: -1.1745295,
      elong: 98.9075,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59661.165473333334,
      mjd_stop: 59661.16582055556,
      phase: 11.8811,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220323_2b_n28022_01_0002.arch?ra=102.88731&dec=30.52069&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220323_2b_n28022_01_0002.arch',
      ra: 102.88731,
      rh: 4.780893329815,
      sangle: 276.328,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 197.6061,
      unc_a: 0.187,
      unc_b: 0.092,
      unc_theta: 2.551,
      vangle: 87.48399999999998,
      vmag: 19.563,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Dec27/703_20211227_2B_N28023_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211227_2b_n28023_01_0004.arch?ra=111.55215&dec=30.07996&size=5.00arcmin&format=fits',
      date: '2021-12-28 07:18:20.624',
      ddec: 6.278812,
      dec: 30.07996,
      delta: 3.86936218316519,
      dra: -22.7351,
      drh: -0.7206412,
      elong: 165.366,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59576.30423175926,
      mjd_stop: 59576.30457898148,
      phase: 2.9552,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211227_2b_n28023_01_0004.arch?ra=111.55215&dec=30.07996&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211227_2b_n28023_01_0004.arch',
      ra: 111.55215,
      rh: 4.827357159982,
      sangle: 65.74199999999999,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 190.6891,
      unc_a: 0.222,
      unc_b: 0.111,
      unc_theta: -2.151,
      vangle: 92.18700000000001,
      vmag: 19.263,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Dec27/703_20211227_2B_N28023_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211227_2b_n28023_01_0003.arch?ra=111.55307&dec=30.07975&size=5.00arcmin&format=fits',
      date: '2021-12-28 07:10:47.448',
      ddec: 6.287269,
      dec: 30.07975,
      delta: 3.86938218291453,
      dra: -22.7291,
      drh: -0.720613,
      elong: 165.3609,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59576.29898666667,
      mjd_stop: 59576.29933388889,
      phase: 2.9562,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211227_2b_n28023_01_0003.arch?ra=111.55307&dec=30.07975&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211227_2b_n28023_01_0003.arch',
      ra: 111.55307,
      rh: 4.827359343022,
      sangle: 65.756,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 190.6887,
      unc_a: 0.222,
      unc_b: 0.111,
      unc_theta: -2.151,
      vangle: 92.18700000000001,
      vmag: 19.263,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Dec27/703_20211227_2B_N28023_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211227_2b_n28023_01_0002.arch?ra=111.55399&dec=30.07953&size=5.00arcmin&format=fits',
      date: '2021-12-28 07:03:14.819',
      ddec: 6.29563,
      dec: 30.07953,
      delta: 3.86940219946038,
      dra: -22.7226,
      drh: -0.7205848,
      elong: 165.3557,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59576.293747905096,
      mjd_stop: 59576.29409512731,
      phase: 2.9572,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211227_2b_n28023_01_0002.arch?ra=111.55399&dec=30.07953&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211227_2b_n28023_01_0002.arch',
      ra: 111.55399,
      rh: 4.827361523341,
      sangle: 65.77099999999999,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 190.6883,
      unc_a: 0.222,
      unc_b: 0.111,
      unc_theta: -2.152,
      vangle: 92.18799999999999,
      vmag: 19.263,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Dec27/703_20211227_2B_N28023_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211227_2b_n28023_01_0001.arch?ra=111.55491&dec=30.07931&size=5.00arcmin&format=fits',
      date: '2021-12-28 06:55:42.175',
      ddec: 6.303897,
      dec: 30.07931,
      delta: 3.86942225721867,
      dra: -22.7156,
      drh: -0.7205566,
      elong: 165.3506,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59576.288508969905,
      mjd_stop: 59576.28885619213,
      phase: 2.9582,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211227_2b_n28023_01_0001.arch?ra=111.55491&dec=30.07931&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211227_2b_n28023_01_0001.arch',
      ra: 111.55491,
      rh: 4.827363703648,
      sangle: 65.785,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 190.6878,
      unc_a: 0.222,
      unc_b: 0.111,
      unc_theta: -2.152,
      vangle: 92.18799999999999,
      vmag: 19.263,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Mar26/703_20220326_2B_N28022_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220326_2b_n28022_01_0003.arch?ra=103.05709&dec=30.45494&size=5.00arcmin&format=fits',
      date: '2022-03-26 04:41:18.483',
      ddec: -3.47511,
      dec: 30.45494,
      delta: 4.56918640261591,
      dra: 7.666936,
      drh: -1.1906161,
      elong: 96.0824,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59664.195179201386,
      mjd_stop: 59664.19552642361,
      phase: 11.9758,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220326_2b_n28022_01_0003.arch?ra=103.05709&dec=30.45494&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220326_2b_n28022_01_0003.arch',
      ra: 103.05709,
      rh: 4.778824238175,
      sangle: 276.005,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 197.8556,
      unc_a: 0.185,
      unc_b: 0.091,
      unc_theta: 2.466,
      vangle: 87.582,
      vmag: 19.583,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Mar26/703_20220326_2B_N28022_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220326_2b_n28022_01_0001.arch?ra=103.05639&dec=30.4552&size=5.00arcmin&format=fits',
      date: '2022-03-26 04:24:28.487',
      ddec: -3.46203,
      dec: 30.4552,
      delta: 4.56901101824537,
      dra: 7.641904,
      drh: -1.190554,
      elong: 96.0934,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59664.18348943287,
      mjd_stop: 59664.183836655095,
      phase: 11.9755,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220326_2b_n28022_01_0001.arch?ra=103.05639&dec=30.4552&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220326_2b_n28022_01_0001.arch',
      ra: 103.05639,
      rh: 4.778832275601,
      sangle: 276.007,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 197.8547,
      unc_a: 0.185,
      unc_b: 0.091,
      unc_theta: 2.466,
      vangle: 87.582,
      vmag: 19.583,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Mar26/703_20220326_2B_N28022_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220326_2b_n28022_01_0002.arch?ra=103.05674&dec=30.45507&size=5.00arcmin&format=fits',
      date: '2022-03-26 04:32:53.529',
      ddec: -3.46866,
      dec: 30.45507,
      delta: 4.56909870097506,
      dra: 7.654192,
      drh: -1.190585,
      elong: 96.0879,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59664.18933482639,
      mjd_stop: 59664.189682048614,
      phase: 11.9756,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220326_2b_n28022_01_0002.arch?ra=103.05674&dec=30.45507&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220326_2b_n28022_01_0002.arch',
      ra: 103.05674,
      rh: 4.77882825659,
      sangle: 276.006,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 197.8551,
      unc_a: 0.185,
      unc_b: 0.091,
      unc_theta: 2.466,
      vangle: 87.582,
      vmag: 19.583,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Mar26/703_20220326_2B_N28022_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220326_2b_n28022_01_0004.arch?ra=103.05744&dec=30.4548&size=5.00arcmin&format=fits',
      date: '2022-03-26 04:49:43.942',
      ddec: -3.48138,
      dec: 30.4548,
      delta: 4.56927422524434,
      dra: 7.680137,
      drh: -1.1906471,
      elong: 96.0769,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59664.201029421296,
      mjd_stop: 59664.20137664352,
      phase: 11.976,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220326_2b_n28022_01_0004.arch?ra=103.05744&dec=30.4548&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220326_2b_n28022_01_0004.arch',
      ra: 103.05744,
      rh: 4.778820215636,
      sangle: 276.005,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 197.8561,
      unc_a: 0.185,
      unc_b: 0.091,
      unc_theta: 2.466,
      vangle: 87.58300000000003,
      vmag: 19.583,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Dec16/703_20211216_2B_N28024_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211216_2b_n28024_01_0002.arch?ra=113.3019&dec=29.60178&size=5.00arcmin&format=fits',
      date: '2021-12-17 08:44:53.623',
      ddec: 6.927563,
      dec: 29.60178,
      delta: 3.92854617161289,
      dra: -19.5527,
      drh: -0.6617759,
      elong: 153.89,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59565.36433591435,
      mjd_stop: 59565.36468313658,
      phase: 5.1479,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211216_2b_n28024_01_0002.arch?ra=113.3019&dec=29.60178&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211216_2b_n28024_01_0002.arch',
      ra: 113.3019,
      rh: 4.831724602327,
      sangle: 82.91399999999999,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 189.806,
      unc_a: 0.218,
      unc_b: 0.109,
      unc_theta: -2.989,
      vangle: 93.077,
      vmag: 19.299,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Dec16/703_20211216_2B_N28024_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211216_2b_n28024_01_0004.arch?ra=113.30021&dec=29.6023&size=5.00arcmin&format=fits',
      date: '2021-12-17 09:01:07.629',
      ddec: 6.909824,
      dec: 29.6023,
      delta: 3.92846694304252,
      dra: -19.5598,
      drh: -0.6618366,
      elong: 153.9025,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59565.375609131945,
      mjd_stop: 59565.37595635417,
      phase: 5.1456,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211216_2b_n28024_01_0004.arch?ra=113.30021&dec=29.6023&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211216_2b_n28024_01_0004.arch',
      ra: 113.30021,
      rh: 4.831720293246,
      sangle: 82.904,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 189.8069,
      unc_a: 0.218,
      unc_b: 0.109,
      unc_theta: -2.988,
      vangle: 93.07600000000002,
      vmag: 19.299,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Dec16/703_20211216_2B_N28024_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211216_2b_n28024_01_0001.arch?ra=113.30273&dec=29.60153&size=5.00arcmin&format=fits',
      date: '2021-12-17 08:36:53.365',
      ddec: 6.936258,
      dec: 29.60153,
      delta: 3.92858530836097,
      dra: -19.5482,
      drh: -0.661746,
      elong: 153.8839,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59565.35877737268,
      mjd_stop: 59565.35912459491,
      phase: 5.1491,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211216_2b_n28024_01_0001.arch?ra=113.30273&dec=29.60153&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211216_2b_n28024_01_0001.arch',
      ra: 113.30273,
      rh: 4.831726726882,
      sangle: 82.91899999999998,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 189.8056,
      unc_a: 0.218,
      unc_b: 0.109,
      unc_theta: -2.989,
      vangle: 93.077,
      vmag: 19.299,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Dec16/703_20211216_2B_N28024_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211216_2b_n28024_01_0003.arch?ra=113.30106&dec=29.60204&size=5.00arcmin&format=fits',
      date: '2021-12-17 08:52:57.806',
      ddec: 6.918759,
      dec: 29.60204,
      delta: 3.92850676251555,
      dra: -19.5565,
      drh: -0.6618061,
      elong: 153.8962,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59565.36993988426,
      mjd_stop: 59565.37028710648,
      phase: 5.1468,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211216_2b_n28024_01_0003.arch?ra=113.30106&dec=29.60204&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211216_2b_n28024_01_0003.arch',
      ra: 113.30106,
      rh: 4.831722460312,
      sangle: 82.90899999999999,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 189.8065,
      unc_a: 0.218,
      unc_b: 0.109,
      unc_theta: -2.988,
      vangle: 93.07600000000002,
      vmag: 19.299,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Dec12/703_20211212_2B_N28024_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211212_2b_n28024_01_0001.arch?ra=113.88051&dec=29.41149&size=5.00arcmin&format=fits',
      date: '2021-12-13 05:43:11.129',
      ddec: 7.199254,
      dec: 29.41149,
      delta: 3.959865018932,
      dra: -17.7282,
      drh: -0.6395591,
      elong: 149.3826,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59561.238149641205,
      mjd_stop: 59561.23849686343,
      phase: 5.9599,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211212_2b_n28024_01_0001.arch?ra=113.88051&dec=29.41149&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211212_2b_n28024_01_0001.arch',
      ra: 113.88051,
      rh: 4.83327525924,
      sangle: 86.16300000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 189.4734,
      unc_a: 0.215,
      unc_b: 0.108,
      unc_theta: -3.252,
      vangle: 93.36099999999999,
      vmag: 19.318,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Dec12/703_20211212_2B_N28024_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211212_2b_n28024_01_0002.arch?ra=113.87977&dec=29.41176&size=5.00arcmin&format=fits',
      date: '2021-12-13 05:51:03.188',
      ddec: 7.194541,
      dec: 29.41176,
      delta: 3.9598195870354,
      dra: -17.7446,
      drh: -0.6395885,
      elong: 149.3887,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59561.243613287035,
      mjd_stop: 59561.24396050926,
      phase: 5.9589,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211212_2b_n28024_01_0002.arch?ra=113.87977&dec=29.41176&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211212_2b_n28024_01_0002.arch',
      ra: 113.87977,
      rh: 4.833273240955,
      sangle: 86.15899999999999,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 189.4738,
      unc_a: 0.215,
      unc_b: 0.108,
      unc_theta: -3.252,
      vangle: 93.36099999999999,
      vmag: 19.318,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Dec12/703_20211212_2B_N28024_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211212_2b_n28024_01_0004.arch?ra=113.87829&dec=29.41228&size=5.00arcmin&format=fits',
      date: '2021-12-13 06:06:46.209',
      ddec: 7.184427,
      dec: 29.41228,
      delta: 3.95972891782053,
      dra: -17.7762,
      drh: -0.6396473,
      elong: 149.4007,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59561.254527881945,
      mjd_stop: 59561.25487510417,
      phase: 5.9567,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211212_2b_n28024_01_0004.arch?ra=113.87829&dec=29.41228&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211212_2b_n28024_01_0004.arch',
      ra: 113.87829,
      rh: 4.833269208799,
      sangle: 86.15199999999999,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 189.4747,
      unc_a: 0.215,
      unc_b: 0.108,
      unc_theta: -3.251,
      vangle: 93.36000000000001,
      vmag: 19.318,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Dec12/703_20211212_2B_N28024_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211212_2b_n28024_01_0003.arch?ra=113.87903&dec=29.41202&size=5.00arcmin&format=fits',
      date: '2021-12-13 05:58:54.556',
      ddec: 7.1896,
      dec: 29.41202,
      delta: 3.95977425095271,
      dra: -17.7605,
      drh: -0.6396179,
      elong: 149.3947,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59561.24906893518,
      mjd_stop: 59561.24941615741,
      phase: 5.9578,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211212_2b_n28024_01_0003.arch?ra=113.87903&dec=29.41202&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211212_2b_n28024_01_0003.arch',
      ra: 113.87903,
      rh: 4.833271225533,
      sangle: 86.156,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 189.4742,
      unc_a: 0.215,
      unc_b: 0.108,
      unc_theta: -3.251,
      vangle: 93.36099999999999,
      vmag: 19.318,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Dec06/703_20211206_2B_N28024_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211206_2b_n28024_01_0002.arch?ra=114.62174&dec=29.13311&size=5.00arcmin&format=fits',
      date: '2021-12-07 05:55:16.050',
      ddec: 7.189127,
      dec: 29.13311,
      delta: 4.01341581229666,
      dra: -15.0588,
      drh: -0.6072849,
      elong: 142.8341,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59555.24653993056,
      mjd_stop: 59555.246887152774,
      phase: 7.076,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211206_2b_n28024_01_0002.arch?ra=114.62174&dec=29.13311&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211206_2b_n28024_01_0002.arch',
      ra: 114.62174,
      rh: 4.835432691768,
      sangle: 89.59300000000002,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 188.9907,
      unc_a: 0.212,
      unc_b: 0.107,
      unc_theta: -3.575,
      vangle: 93.71600000000001,
      vmag: 19.349,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Dec06/703_20211206_2B_N28024_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211206_2b_n28024_01_0003.arch?ra=114.62111&dec=29.13337&size=5.00arcmin&format=fits',
      date: '2021-12-07 06:03:09.064',
      ddec: 7.185112,
      dec: 29.13337,
      delta: 4.01336177100534,
      dra: -15.0759,
      drh: -0.6073144,
      elong: 142.8401,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59555.25201462963,
      mjd_stop: 59555.25236185185,
      phase: 7.075,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211206_2b_n28024_01_0003.arch?ra=114.62111&dec=29.13337&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211206_2b_n28024_01_0003.arch',
      ra: 114.62111,
      rh: 4.835430771436,
      sangle: 89.58999999999997,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 188.9912,
      unc_a: 0.212,
      unc_b: 0.107,
      unc_theta: -3.575,
      vangle: 93.71600000000001,
      vmag: 19.349,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Dec06/703_20211206_2B_N28024_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211206_2b_n28024_01_0001.arch?ra=114.62237&dec=29.13285&size=5.00arcmin&format=fits',
      date: '2021-12-07 05:47:23.349',
      ddec: 7.192895,
      dec: 29.13285,
      delta: 4.01346984358169,
      dra: -15.0415,
      drh: -0.6072554,
      elong: 142.8281,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59555.241068854164,
      mjd_stop: 59555.24141607639,
      phase: 7.077,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211206_2b_n28024_01_0001.arch?ra=114.62237&dec=29.13285&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211206_2b_n28024_01_0001.arch',
      ra: 114.62237,
      rh: 4.835434610736,
      sangle: 89.596,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 188.9903,
      unc_a: 0.212,
      unc_b: 0.107,
      unc_theta: -3.575,
      vangle: 93.71699999999998,
      vmag: 19.349,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Dec06/703_20211206_2B_N28024_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211206_2b_n28024_01_0004.arch?ra=114.62048&dec=29.13363&size=5.00arcmin&format=fits',
      date: '2021-12-07 06:11:02.017',
      ddec: 7.180857,
      dec: 29.13363,
      delta: 4.0133077635746,
      dra: -15.0926,
      drh: -0.6073439,
      elong: 142.8462,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59555.25748862269,
      mjd_stop: 59555.25783584491,
      phase: 7.074,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211206_2b_n28024_01_0004.arch?ra=114.62048&dec=29.13363&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211206_2b_n28024_01_0004.arch',
      ra: 114.62048,
      rh: 4.835428851258,
      sangle: 89.58800000000002,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 188.9916,
      unc_a: 0.212,
      unc_b: 0.107,
      unc_theta: -3.574,
      vangle: 93.71600000000001,
      vmag: 19.349,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Dec08/703_20211208_2B_N28024_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211208_2b_n28024_01_0001.arch?ra=114.38085&dec=29.22877&size=5.00arcmin&format=fits',
      date: '2021-12-09 07:19:56.926',
      ddec: 7.152411,
      dec: 29.22877,
      delta: 3.99396389587822,
      dra: -16.156,
      drh: -0.6183765,
      elong: 145.0819,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59557.30534636574,
      mjd_stop: 59557.305693587965,
      phase: 6.7022,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211208_2b_n28024_01_0001.arch?ra=114.38085&dec=29.22877&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211208_2b_n28024_01_0001.arch',
      ra: 114.38085,
      rh: 4.834703959289,
      sangle: 88.54399999999998,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 189.1565,
      unc_a: 0.213,
      unc_b: 0.108,
      unc_theta: -3.472,
      vangle: 93.60199999999998,
      vmag: 19.337,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Dec08/703_20211208_2B_N28024_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211208_2b_n28024_01_0002.arch?ra=114.3802&dec=29.22902&size=5.00arcmin&format=fits',
      date: '2021-12-09 07:27:32.389',
      ddec: 7.146071,
      dec: 29.22902,
      delta: 3.99391486911302,
      dra: -16.168,
      drh: -0.6184049,
      elong: 145.0878,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59557.31061792824,
      mjd_stop: 59557.310965150464,
      phase: 6.7012,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211208_2b_n28024_01_0002.arch?ra=114.3802&dec=29.22902&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211208_2b_n28024_01_0002.arch',
      ra: 114.3802,
      rh: 4.834702076446,
      sangle: 88.541,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 189.1569,
      unc_a: 0.213,
      unc_b: 0.108,
      unc_theta: -3.472,
      vangle: 93.60199999999998,
      vmag: 19.337,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Dec08/703_20211208_2B_N28024_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211208_2b_n28024_01_0003.arch?ra=114.37954&dec=29.22927&size=5.00arcmin&format=fits',
      date: '2021-12-09 07:35:07.289',
      ddec: 7.139586,
      dec: 29.22927,
      delta: 3.99386593832267,
      dra: -16.1795,
      drh: -0.6184332,
      elong: 145.0936,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59557.31588297454,
      mjd_stop: 59557.31623019676,
      phase: 6.7002,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211208_2b_n28024_01_0003.arch?ra=114.37954&dec=29.22927&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211208_2b_n28024_01_0003.arch',
      ra: 114.37954,
      rh: 4.834700195845,
      sangle: 88.53800000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 189.1574,
      unc_a: 0.213,
      unc_b: 0.108,
      unc_theta: -3.471,
      vangle: 93.60199999999998,
      vmag: 19.337,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Dec08/703_20211208_2B_N28024_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211208_2b_n28024_01_0004.arch?ra=114.37889&dec=29.22951&size=5.00arcmin&format=fits',
      date: '2021-12-09 07:42:42.240',
      ddec: 7.132956,
      dec: 29.22951,
      delta: 3.99381703805295,
      dra: -16.1906,
      drh: -0.6184616,
      elong: 145.0994,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59557.32114861111,
      mjd_stop: 59557.321495833334,
      phase: 6.6992,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211208_2b_n28024_01_0004.arch?ra=114.37889&dec=29.22951&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211208_2b_n28024_01_0004.arch',
      ra: 114.37889,
      rh: 4.834698314947,
      sangle: 88.53500000000003,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 189.1578,
      unc_a: 0.213,
      unc_b: 0.108,
      unc_theta: -3.471,
      vangle: 93.601,
      vmag: 19.337,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Apr01/703_20220401_2B_N28022_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220401_2b_n28022_01_0002.arch?ra=103.4955&dec=30.31764&size=5.00arcmin&format=fits',
      date: '2022-04-01 04:35:02.231',
      ddec: -3.72111,
      dec: 30.31764,
      delta: 4.65872011432155,
      dra: 10.5873,
      drh: -1.2224229,
      elong: 90.5919,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59670.19082443287,
      mjd_stop: 59670.19117165509,
      phase: 12.0764,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220401_2b_n28022_01_0002.arch?ra=103.4955&dec=30.31764&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220401_2b_n28022_01_0002.arch',
      ra: 103.4955,
      rh: 4.774646682738,
      sangle: 275.446,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 198.3501,
      unc_a: 0.181,
      unc_b: 0.089,
      unc_theta: 2.243,
      vangle: 87.83300000000003,
      vmag: 19.622,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Apr01/703_20220401_2B_N28022_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220401_2b_n28022_01_0004.arch?ra=103.49637&dec=30.31738&size=5.00arcmin&format=fits',
      date: '2022-04-01 04:50:15.008',
      ddec: -3.73133,
      dec: 30.31738,
      delta: 4.65887959133361,
      dra: 10.6122,
      drh: -1.2224789,
      elong: 90.5822,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59670.201388981484,
      mjd_stop: 59670.2017362037,
      phase: 12.0765,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220401_2b_n28022_01_0004.arch?ra=103.49637&dec=30.31738&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220401_2b_n28022_01_0004.arch',
      ra: 103.49637,
      rh: 4.774639224558,
      sangle: 275.445,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 198.351,
      unc_a: 0.181,
      unc_b: 0.089,
      unc_theta: 2.243,
      vangle: 87.834,
      vmag: 19.622,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Apr01/703_20220401_2B_N28022_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220401_2b_n28022_01_0003.arch?ra=103.49594&dec=30.31751&size=5.00arcmin&format=fits',
      date: '2022-04-01 04:42:38.335',
      ddec: -3.7263,
      dec: 30.31751,
      delta: 4.65879979109109,
      dra: 10.59958,
      drh: -1.2224509,
      elong: 90.587,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59670.19610341435,
      mjd_stop: 59670.19645063658,
      phase: 12.0765,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220401_2b_n28022_01_0003.arch?ra=103.49594&dec=30.31751&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220401_2b_n28022_01_0003.arch',
      ra: 103.49594,
      rh: 4.774642956015,
      sangle: 275.446,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 198.3505,
      unc_a: 0.181,
      unc_b: 0.089,
      unc_theta: 2.243,
      vangle: 87.834,
      vmag: 19.622,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Apr01/703_20220401_2B_N28022_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220401_2b_n28022_01_0001.arch?ra=103.49507&dec=30.31777&size=5.00arcmin&format=fits',
      date: '2022-04-01 04:27:25.988',
      ddec: -3.71576,
      dec: 30.31777,
      delta: 4.65864043812599,
      dra: 10.57535,
      drh: -1.2223949,
      elong: 90.5967,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59670.18554384259,
      mjd_stop: 59670.185891064815,
      phase: 12.0764,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220401_2b_n28022_01_0001.arch?ra=103.49507&dec=30.31777&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220401_2b_n28022_01_0001.arch',
      ra: 103.49507,
      rh: 4.77465041051,
      sangle: 275.447,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 198.3497,
      unc_a: 0.181,
      unc_b: 0.089,
      unc_theta: 2.243,
      vangle: 87.83300000000003,
      vmag: 19.622,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Apr05/703_20220405_2B_N28022_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220405_2b_n28022_01_0004.arch?ra=103.85722&dec=30.22195&size=5.00arcmin&format=fits',
      date: '2022-04-05 03:44:59.755',
      ddec: -3.84057,
      dec: 30.22195,
      delta: 4.71796409125938,
      dra: 12.37275,
      drh: -1.2434382,
      elong: 87.0328,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59674.15607355324,
      mjd_stop: 59674.15642077546,
      phase: 12.0819,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220405_2b_n28022_01_0004.arch?ra=103.85722&dec=30.22195&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220405_2b_n28022_01_0004.arch',
      ra: 103.85722,
      rh: 4.771823360847,
      sangle: 275.126,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 198.6776,
      unc_a: 0.179,
      unc_b: 0.088,
      unc_theta: 2.059,
      vangle: 88.03800000000001,
      vmag: 19.647,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Apr05/703_20220405_2B_N28022_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220405_2b_n28022_01_0002.arch?ra=103.85614&dec=30.22224&size=5.00arcmin&format=fits',
      date: '2022-04-05 03:28:41.042',
      ddec: -3.82769,
      dec: 30.22224,
      delta: 4.71779391804076,
      dra: 12.3517,
      drh: -1.2433782,
      elong: 87.043,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59674.14474585648,
      mjd_stop: 59674.1450930787,
      phase: 12.082,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220405_2b_n28022_01_0002.arch?ra=103.85614&dec=30.22224&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220405_2b_n28022_01_0002.arch',
      ra: 103.85614,
      rh: 4.771831494881,
      sangle: 275.127,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 198.6767,
      unc_a: 0.179,
      unc_b: 0.088,
      unc_theta: 2.06,
      vangle: 88.03800000000001,
      vmag: 19.647,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Apr05/703_20220405_2B_N28022_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220405_2b_n28022_01_0003.arch?ra=103.85668&dec=30.2221&size=5.00arcmin&format=fits',
      date: '2022-04-05 03:36:50.144',
      ddec: -3.8342,
      dec: 30.2221,
      delta: 4.71787894400676,
      dra: 12.36199,
      drh: -1.2434082,
      elong: 87.0379,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59674.15040675926,
      mjd_stop: 59674.150753981485,
      phase: 12.0819,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220405_2b_n28022_01_0003.arch?ra=103.85668&dec=30.2221&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220405_2b_n28022_01_0003.arch',
      ra: 103.85668,
      rh: 4.771827430028,
      sangle: 275.127,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 198.6772,
      unc_a: 0.179,
      unc_b: 0.088,
      unc_theta: 2.059,
      vangle: 88.03800000000001,
      vmag: 19.647,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Apr05/703_20220405_2B_N28022_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220405_2b_n28022_01_0001.arch?ra=103.8556&dec=30.22238&size=5.00arcmin&format=fits',
      date: '2022-04-05 03:20:32.029',
      ddec: -3.82104,
      dec: 30.22238,
      delta: 4.71770894103114,
      dra: 12.34185,
      drh: -1.2433482,
      elong: 87.0481,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59674.1390859838,
      mjd_stop: 59674.139433206015,
      phase: 12.082,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220405_2b_n28022_01_0001.arch?ra=103.8556&dec=30.22238&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220405_2b_n28022_01_0001.arch',
      ra: 103.8556,
      rh: 4.771835558896,
      sangle: 275.128,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 198.6762,
      unc_a: 0.179,
      unc_b: 0.088,
      unc_theta: 2.06,
      vangle: 88.03699999999998,
      vmag: 19.646,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Dec03/703_20211203_2B_N28024_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211203_2b_n28024_01_0003.arch?ra=114.94027&dec=28.9968&size=5.00arcmin&format=fits',
      date: '2021-12-04 06:58:30.967',
      ddec: 7.084148,
      dec: 28.9968,
      delta: 4.04314519104533,
      dra: -13.7196,
      drh: -0.5913563,
      elong: 139.6202,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59552.29046258102,
      mjd_stop: 59552.29080980324,
      phase: 7.5923,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211203_2b_n28024_01_0003.arch?ra=114.94027&dec=28.9968&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211203_2b_n28024_01_0003.arch',
      ra: 114.94027,
      rh: 4.83645595819,
      sangle: 90.91300000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 188.7528,
      unc_a: 0.21,
      unc_b: 0.106,
      unc_theta: -3.707,
      vangle: 93.86500000000001,
      vmag: 19.365,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Dec03/703_20211203_2B_N28024_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211203_2b_n28024_01_0001.arch?ra=114.94142&dec=28.99628&size=5.00arcmin&format=fits',
      date: '2021-12-04 06:42:44.879',
      ddec: 7.094135,
      dec: 28.99628,
      delta: 4.04326087212089,
      dra: -13.6886,
      drh: -0.5912973,
      elong: 139.6082,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59552.27951248843,
      mjd_stop: 59552.279859710645,
      phase: 7.5942,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211203_2b_n28024_01_0001.arch?ra=114.94142&dec=28.99628&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211203_2b_n28024_01_0001.arch',
      ra: 114.94142,
      rh: 4.836459698092,
      sangle: 90.918,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 188.7519,
      unc_a: 0.21,
      unc_b: 0.106,
      unc_theta: -3.707,
      vangle: 93.86500000000001,
      vmag: 19.366,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Dec03/703_20211203_2B_N28024_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211203_2b_n28024_01_0004.arch?ra=114.9397&dec=28.99706&size=5.00arcmin&format=fits',
      date: '2021-12-04 07:06:24.249',
      ddec: 7.078837,
      dec: 28.99706,
      delta: 4.04308736908466,
      dra: -13.7345,
      drh: -0.5913858,
      elong: 139.6262,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59552.29594038195,
      mjd_stop: 59552.296287604164,
      phase: 7.5914,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211203_2b_n28024_01_0004.arch?ra=114.9397&dec=28.99706&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211203_2b_n28024_01_0004.arch',
      ra: 114.9397,
      rh: 4.836454087159,
      sangle: 90.911,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 188.7532,
      unc_a: 0.21,
      unc_b: 0.106,
      unc_theta: -3.707,
      vangle: 93.86399999999998,
      vmag: 19.365,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Dec03/703_20211203_2B_N28024_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211203_2b_n28024_01_0002.arch?ra=114.94085&dec=28.99654&size=5.00arcmin&format=fits',
      date: '2021-12-04 06:50:38.165',
      ddec: 7.089246,
      dec: 28.99654,
      delta: 4.04320298643465,
      dra: -13.7043,
      drh: -0.5913268,
      elong: 139.6142,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59552.28499033565,
      mjd_stop: 59552.28533755787,
      phase: 7.5933,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211203_2b_n28024_01_0002.arch?ra=114.94085&dec=28.99654&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211203_2b_n28024_01_0002.arch',
      ra: 114.94085,
      rh: 4.836457827231,
      sangle: 90.91500000000002,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 188.7524,
      unc_a: 0.21,
      unc_b: 0.106,
      unc_theta: -3.707,
      vangle: 93.86500000000001,
      vmag: 19.366,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov30/703_20211130_2B_N28024_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211130_2b_n28024_01_0004.arch?ra=115.31445&dec=28.81723&size=5.00arcmin&format=fits',
      date: '2021-11-30 08:00:31.983',
      ddec: 6.887242,
      dec: 28.81723,
      delta: 4.08611169655583,
      dra: -11.7768,
      drh: -0.5700293,
      elong: 135.3486,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59548.33352989583,
      mjd_stop: 59548.33387711806,
      phase: 8.2427,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211130_2b_n28024_01_0004.arch?ra=115.31445&dec=28.81723&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211130_2b_n28024_01_0004.arch',
      ra: 115.31445,
      rh: 4.837783111206,
      sangle: 92.40899999999999,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 188.4345,
      unc_a: 0.208,
      unc_b: 0.105,
      unc_theta: -3.855,
      vangle: 94.03399999999999,
      vmag: 19.39,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov30/703_20211130_2B_N28024_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211130_2b_n28024_01_0003.arch?ra=115.31491&dec=28.81699&size=5.00arcmin&format=fits',
      date: '2021-11-30 07:52:59.593',
      ddec: 6.892972,
      dec: 28.81699,
      delta: 4.08617147929591,
      dra: -11.7644,
      drh: -0.570001,
      elong: 135.3429,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59548.328293900464,
      mjd_stop: 59548.32864112269,
      phase: 8.2436,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211130_2b_n28024_01_0003.arch?ra=115.31491&dec=28.81699&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211130_2b_n28024_01_0003.arch',
      ra: 115.31491,
      rh: 4.837784835068,
      sangle: 92.411,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 188.434,
      unc_a: 0.208,
      unc_b: 0.105,
      unc_theta: -3.855,
      vangle: 94.03399999999999,
      vmag: 19.39,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov30/703_20211130_2B_N28024_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211130_2b_n28024_01_0002.arch?ra=115.31538&dec=28.81675&size=5.00arcmin&format=fits',
      date: '2021-11-30 07:45:26.727',
      ddec: 6.89855,
      dec: 28.81675,
      delta: 4.08623135767331,
      dra: -11.7516,
      drh: -0.5699728,
      elong: 135.3372,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59548.323052395834,
      mjd_stop: 59548.32339961806,
      phase: 8.2444,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211130_2b_n28024_01_0002.arch?ra=115.31538&dec=28.81675&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211130_2b_n28024_01_0002.arch',
      ra: 115.31538,
      rh: 4.837786560659,
      sangle: 92.41300000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 188.4336,
      unc_a: 0.208,
      unc_b: 0.105,
      unc_theta: -3.855,
      vangle: 94.03399999999999,
      vmag: 19.39,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov30/703_20211130_2B_N28024_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211130_2b_n28024_01_0001.arch?ra=115.31585&dec=28.81651&size=5.00arcmin&format=fits',
      date: '2021-11-30 07:37:53.492',
      ddec: 6.903969,
      dec: 28.81651,
      delta: 4.08629131690652,
      dra: -11.7384,
      drh: -0.5699445,
      elong: 135.3314,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59548.31780662037,
      mjd_stop: 59548.31815384259,
      phase: 8.2453,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211130_2b_n28024_01_0001.arch?ra=115.31585&dec=28.81651&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211130_2b_n28024_01_0001.arch',
      ra: 115.31585,
      rh: 4.83778828757,
      sangle: 92.41500000000002,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 188.4332,
      unc_a: 0.208,
      unc_b: 0.105,
      unc_theta: -3.855,
      vangle: 94.03399999999999,
      vmag: 19.39,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov27/703_20211127_2B_N28024_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211127_2b_n28024_01_0001.arch?ra=115.55492&dec=28.68544&size=5.00arcmin&format=fits',
      date: '2021-11-27 08:37:48.534',
      ddec: 6.701436,
      dec: 28.68544,
      delta: 4.12062385446015,
      dra: -10.2468,
      drh: -0.5539956,
      elong: 132.1664,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59545.35941590278,
      mjd_stop: 59545.359763125,
      phase: 8.6988,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211127_2b_n28024_01_0001.arch?ra=115.55492&dec=28.68544&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211127_2b_n28024_01_0001.arch',
      ra: 115.55492,
      rh: 4.838748542001,
      sangle: 93.37200000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 188.1953,
      unc_a: 0.206,
      unc_b: 0.105,
      unc_theta: -3.944,
      vangle: 94.13900000000001,
      vmag: 19.409,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov27/703_20211127_2B_N28024_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211127_2b_n28024_01_0002.arch?ra=115.5545&dec=28.68568&size=5.00arcmin&format=fits',
      date: '2021-11-27 08:45:36.596',
      ddec: 6.695041,
      dec: 28.68568,
      delta: 4.12055881752339,
      dra: -10.2577,
      drh: -0.5540248,
      elong: 132.1722,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59545.364833287036,
      mjd_stop: 59545.36518050926,
      phase: 8.698,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211127_2b_n28024_01_0002.arch?ra=115.5545&dec=28.68568&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211127_2b_n28024_01_0002.arch',
      ra: 115.5545,
      rh: 4.838746808493,
      sangle: 93.37,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 188.1958,
      unc_a: 0.206,
      unc_b: 0.105,
      unc_theta: -3.944,
      vangle: 94.13799999999998,
      vmag: 19.409,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov27/703_20211127_2B_N28024_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211127_2b_n28024_01_0004.arch?ra=115.55365&dec=28.68616&size=5.00arcmin&format=fits',
      date: '2021-11-27 09:01:13.522',
      ddec: 6.681864,
      dec: 28.68616,
      delta: 4.12042874738823,
      dra: -10.2781,
      drh: -0.5540832,
      elong: 132.184,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59545.375677337965,
      mjd_stop: 59545.37602456019,
      phase: 8.6963,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211127_2b_n28024_01_0004.arch?ra=115.55365&dec=28.68616&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211127_2b_n28024_01_0004.arch',
      ra: 115.55365,
      rh: 4.838743338233,
      sangle: 93.36700000000002,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 188.1966,
      unc_a: 0.206,
      unc_b: 0.105,
      unc_theta: -3.943,
      vangle: 94.13799999999998,
      vmag: 19.408,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov27/703_20211127_2B_N28024_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211127_2b_n28024_01_0003.arch?ra=115.55407&dec=28.68592&size=5.00arcmin&format=fits',
      date: '2021-11-27 08:53:25.074',
      ddec: 6.688512,
      dec: 28.68592,
      delta: 4.12049376097475,
      dra: -10.2681,
      drh: -0.554054,
      elong: 132.1781,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59545.37025548611,
      mjd_stop: 59545.37060270833,
      phase: 8.6972,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211127_2b_n28024_01_0003.arch?ra=115.55407&dec=28.68592&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211127_2b_n28024_01_0003.arch',
      ra: 115.55407,
      rh: 4.838745073353,
      sangle: 93.36900000000003,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 188.1962,
      unc_a: 0.206,
      unc_b: 0.105,
      unc_theta: -3.943,
      vangle: 94.13799999999998,
      vmag: 19.409,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Apr14/703_20220414_2B_N28022_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220414_2b_n28022_01_0001.arch?ra=104.87307&dec=29.99142&size=5.00arcmin&format=fits',
      date: '2022-04-14 03:30:00.570',
      ddec: -4.16568,
      dec: 29.99142,
      delta: 4.85087746441756,
      dra: 16.21168,
      drh: -1.2910227,
      elong: 79.1685,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59683.14566631945,
      mjd_stop: 59683.146013541664,
      phase: 11.9273,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220414_2b_n28022_01_0001.arch?ra=104.87307&dec=29.99142&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220414_2b_n28022_01_0001.arch',
      ra: 104.87307,
      rh: 4.765244522448,
      sangle: 274.516,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 199.4217,
      unc_a: 0.174,
      unc_b: 0.085,
      unc_theta: 1.543,
      vangle: 88.60700000000003,
      vmag: 19.701,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Apr14/703_20220414_2B_N28022_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220414_2b_n28022_01_0003.arch?ra=104.87439&dec=29.99113&size=5.00arcmin&format=fits',
      date: '2022-04-14 03:45:15.384',
      ddec: -4.17619,
      dec: 29.99113,
      delta: 4.85103346927795,
      dra: 16.23343,
      drh: -1.2910787,
      elong: 79.1593,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59683.156254444446,
      mjd_stop: 59683.15660166667,
      phase: 11.927,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220414_2b_n28022_01_0003.arch?ra=104.87439&dec=29.99113&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220414_2b_n28022_01_0003.arch',
      ra: 104.87439,
      rh: 4.765236628146,
      sangle: 274.515,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 199.4226,
      unc_a: 0.174,
      unc_b: 0.085,
      unc_theta: 1.542,
      vangle: 88.608,
      vmag: 19.701,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Apr14/703_20220414_2B_N28022_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220414_2b_n28022_01_0002.arch?ra=104.87373&dec=29.99128&size=5.00arcmin&format=fits',
      date: '2022-04-14 03:37:38.389',
      ddec: -4.17101,
      dec: 29.99128,
      delta: 4.85095552465643,
      dra: 16.22239,
      drh: -1.2910507,
      elong: 79.1639,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59683.15096515046,
      mjd_stop: 59683.15131237268,
      phase: 11.9271,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220414_2b_n28022_01_0002.arch?ra=104.87373&dec=29.99128&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220414_2b_n28022_01_0002.arch',
      ra: 104.87373,
      rh: 4.765240571784,
      sangle: 274.516,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 199.4222,
      unc_a: 0.174,
      unc_b: 0.085,
      unc_theta: 1.542,
      vangle: 88.60700000000003,
      vmag: 19.701,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Apr14/703_20220414_2B_N28022_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220414_2b_n28022_01_0004.arch?ra=104.87505&dec=29.99099&size=5.00arcmin&format=fits',
      date: '2022-04-14 03:52:52.544',
      ddec: -4.18121,
      dec: 29.99099,
      delta: 4.85111146615075,
      dra: 16.24479,
      drh: -1.2911067,
      elong: 79.1547,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59683.161545648145,
      mjd_stop: 59683.16189287037,
      phase: 11.9268,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220414_2b_n28022_01_0004.arch?ra=104.87505&dec=29.99099&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220414_2b_n28022_01_0004.arch',
      ra: 104.87505,
      rh: 4.765232682999,
      sangle: 274.515,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 199.423,
      unc_a: 0.174,
      unc_b: 0.085,
      unc_theta: 1.542,
      vangle: 88.608,
      vmag: 19.701,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov16/703_20211116_2B_N28024_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211116_2b_n28024_01_0001.arch?ra=116.13253&dec=28.23154&size=5.00arcmin&format=fits',
      date: '2021-11-16 07:51:17.594',
      ddec: 5.901689,
      dec: 28.23154,
      delta: 4.26294483445529,
      dra: -4.18143,
      drh: -0.4944924,
      elong: 120.6152,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59534.327113356485,
      mjd_stop: 59534.3274605787,
      phase: 10.1278,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211116_2b_n28024_01_0001.arch?ra=116.13253&dec=28.23154&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211116_2b_n28024_01_0001.arch',
      ra: 116.13253,
      rh: 4.842089131444,
      sangle: 96.07999999999998,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 187.3092,
      unc_a: 0.198,
      unc_b: 0.101,
      unc_theta: -4.113,
      vangle: 94.36200000000002,
      vmag: 19.485,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov16/703_20211116_2B_N28024_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211116_2b_n28024_01_0002.arch?ra=116.13236&dec=28.23175&size=5.00arcmin&format=fits',
      date: '2021-11-16 07:58:55.292',
      ddec: 5.897824,
      dec: 28.23175,
      delta: 4.2628709199958,
      dra: -4.19647,
      drh: -0.494521,
      elong: 120.6207,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59534.332410787036,
      mjd_stop: 59534.33275800926,
      phase: 10.1272,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211116_2b_n28024_01_0002.arch?ra=116.13236&dec=28.23175&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211116_2b_n28024_01_0002.arch',
      ra: 116.13236,
      rh: 4.842087618368,
      sangle: 96.07900000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 187.3096,
      unc_a: 0.198,
      unc_b: 0.101,
      unc_theta: -4.112,
      vangle: 94.36200000000002,
      vmag: 19.485,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov16/703_20211116_2B_N28024_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211116_2b_n28024_01_0003.arch?ra=116.13219&dec=28.23196&size=5.00arcmin&format=fits',
      date: '2021-11-16 08:06:33.149',
      ddec: 5.893767,
      dec: 28.23196,
      delta: 4.26279700579919,
      dra: -4.21121,
      drh: -0.4945496,
      elong: 120.6263,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59534.33771005787,
      mjd_stop: 59534.338057280096,
      phase: 10.1266,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211116_2b_n28024_01_0003.arch?ra=116.13219&dec=28.23196&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211116_2b_n28024_01_0003.arch',
      ra: 116.13219,
      rh: 4.842086104679,
      sangle: 96.07799999999997,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 187.3101,
      unc_a: 0.198,
      unc_b: 0.101,
      unc_theta: -4.112,
      vangle: 94.36200000000002,
      vmag: 19.485,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov16/703_20211116_2B_N28024_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211116_2b_n28024_01_0004.arch?ra=116.13202&dec=28.23217&size=5.00arcmin&format=fits',
      date: '2021-11-16 08:14:10.781',
      ddec: 5.889528,
      dec: 28.23217,
      delta: 4.2627231547593,
      dra: -4.22562,
      drh: -0.4945782,
      elong: 120.6318,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59534.343006724535,
      mjd_stop: 59534.34335394676,
      phase: 10.1261,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211116_2b_n28024_01_0004.arch?ra=116.13202&dec=28.23217&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211116_2b_n28024_01_0004.arch',
      ra: 116.13202,
      rh: 4.842084591646,
      sangle: 96.077,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 187.3105,
      unc_a: 0.198,
      unc_b: 0.101,
      unc_theta: -4.112,
      vangle: 94.36200000000002,
      vmag: 19.485,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Apr18/703_20220418_2B_N28022_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220418_2b_n28022_01_0002.arch?ra=105.40578&dec=29.88296&size=5.00arcmin&format=fits',
      date: '2022-04-18 03:28:27.095',
      ddec: -4.31198,
      dec: 29.88296,
      delta: 4.90885722840251,
      dra: 17.78231,
      drh: -1.3121627,
      elong: 75.7564,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59687.14458443287,
      mjd_stop: 59687.14493165509,
      phase: 11.7891,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220418_2b_n28022_01_0002.arch?ra=105.40578&dec=29.88296&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220418_2b_n28022_01_0002.arch',
      ra: 105.40578,
      rh: 4.762238653426,
      sangle: 274.284,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 199.7534,
      unc_a: 0.171,
      unc_b: 0.084,
      unc_theta: 1.273,
      vangle: 88.90199999999999,
      vmag: 19.725,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Apr18/703_20220418_2B_N28022_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220418_2b_n28022_01_0004.arch?ra=105.40736&dec=29.88263&size=5.00arcmin&format=fits',
      date: '2022-04-18 03:45:01.321',
      ddec: -4.32269,
      dec: 29.88263,
      delta: 4.90902431265244,
      dra: 17.80667,
      drh: -1.3122235,
      elong: 75.7466,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59687.15609167824,
      mjd_stop: 59687.15643890046,
      phase: 11.7887,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220418_2b_n28022_01_0004.arch?ra=105.40736&dec=29.88263&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220418_2b_n28022_01_0004.arch',
      ra: 105.40736,
      rh: 4.762229933334,
      sangle: 274.283,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 199.7544,
      unc_a: 0.171,
      unc_b: 0.084,
      unc_theta: 1.273,
      vangle: 88.90300000000002,
      vmag: 19.725,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Apr18/703_20220418_2B_N28022_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220418_2b_n28022_01_0001.arch?ra=105.40499&dec=29.88312&size=5.00arcmin&format=fits',
      date: '2022-04-18 03:20:10.239',
      ddec: -4.30636,
      dec: 29.88312,
      delta: 4.90877377175525,
      dra: 17.77071,
      drh: -1.3121323,
      elong: 75.7613,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59687.138833784724,
      mjd_stop: 59687.13918100695,
      phase: 11.7894,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220418_2b_n28022_01_0001.arch?ra=105.40499&dec=29.88312&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220418_2b_n28022_01_0001.arch',
      ra: 105.40499,
      rh: 4.762243011065,
      sangle: 274.284,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 199.7529,
      unc_a: 0.171,
      unc_b: 0.084,
      unc_theta: 1.274,
      vangle: 88.90199999999999,
      vmag: 19.725,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Apr18/703_20220418_2B_N28022_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220418_2b_n28022_01_0003.arch?ra=105.40657&dec=29.8828&size=5.00arcmin&format=fits',
      date: '2022-04-18 03:36:44.250',
      ddec: -4.31742,
      dec: 29.8828,
      delta: 4.90894076382093,
      dra: 17.7943,
      drh: -1.3121931,
      elong: 75.7515,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59687.150338541665,
      mjd_stop: 59687.15068576389,
      phase: 11.7889,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220418_2b_n28022_01_0003.arch?ra=105.40657&dec=29.8828&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220418_2b_n28022_01_0003.arch',
      ra: 105.40657,
      rh: 4.762234293062,
      sangle: 274.283,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 199.7539,
      unc_a: 0.171,
      unc_b: 0.084,
      unc_theta: 1.273,
      vangle: 88.90300000000002,
      vmag: 19.725,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov15/703_20211115_2B_N28024_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211115_2b_n28024_01_0002.arch?ra=116.1602&dec=28.19368&size=5.00arcmin&format=fits',
      date: '2021-11-15 07:50:02.924',
      ddec: 5.810724,
      dec: 28.19368,
      delta: 4.27682279703947,
      dra: -3.63055,
      drh: -0.4890922,
      elong: 119.5886,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59533.32624912037,
      mjd_stop: 59533.326596342595,
      phase: 10.2363,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211115_2b_n28024_01_0002.arch?ra=116.1602&dec=28.19368&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211115_2b_n28024_01_0002.arch',
      ra: 116.1602,
      rh: 4.842373433528,
      sangle: 96.274,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 187.2289,
      unc_a: 0.197,
      unc_b: 0.101,
      unc_theta: -4.116,
      vangle: 94.37,
      vmag: 19.492,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov15/703_20211115_2B_N28024_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211115_2b_n28024_01_0003.arch?ra=116.1599&dec=28.19409&size=5.00arcmin&format=fits',
      date: '2021-11-15 08:05:13.970',
      ddec: 5.803167,
      dec: 28.19409,
      delta: 4.27667416462576,
      dra: -3.66054,
      drh: -0.4891491,
      elong: 119.5995,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59533.33679363426,
      mjd_stop: 59533.33714085648,
      phase: 10.2352,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211115_2b_n28024_01_0003.arch?ra=116.1599&dec=28.19409&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211115_2b_n28024_01_0003.arch',
      ra: 116.1599,
      rh: 4.842370454557,
      sangle: 96.27199999999999,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 187.2297,
      unc_a: 0.197,
      unc_b: 0.101,
      unc_theta: -4.116,
      vangle: 94.36900000000003,
      vmag: 19.492,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov15/703_20211115_2B_N28024_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211115_2b_n28024_01_0004.arch?ra=116.15974&dec=28.19431&size=5.00arcmin&format=fits',
      date: '2021-11-15 08:13:31.032',
      ddec: 5.798728,
      dec: 28.19431,
      delta: 4.27659311462911,
      dra: -3.67638,
      drh: -0.4891802,
      elong: 119.6055,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59533.34254666667,
      mjd_stop: 59533.34289388889,
      phase: 10.2346,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211115_2b_n28024_01_0004.arch?ra=116.15974&dec=28.19431&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211115_2b_n28024_01_0004.arch',
      ra: 116.15974,
      rh: 4.8423688291,
      sangle: 96.27100000000002,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 187.2302,
      unc_a: 0.197,
      unc_b: 0.101,
      unc_theta: -4.116,
      vangle: 94.36900000000003,
      vmag: 19.492,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov15/703_20211115_2B_N28024_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211115_2b_n28024_01_0001.arch?ra=116.16035&dec=28.19346&size=5.00arcmin&format=fits',
      date: '2021-11-15 07:41:45.979',
      ddec: 5.81452,
      dec: 28.19346,
      delta: 4.27690391190642,
      dra: -3.6137,
      drh: -0.4890612,
      elong: 119.5826,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59533.32049744213,
      mjd_stop: 59533.320844664355,
      phase: 10.237,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211115_2b_n28024_01_0001.arch?ra=116.16035&dec=28.19346&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211115_2b_n28024_01_0001.arch',
      ra: 116.16035,
      rh: 4.842375058311,
      sangle: 96.27499999999998,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 187.2284,
      unc_a: 0.197,
      unc_b: 0.101,
      unc_theta: -4.116,
      vangle: 94.37,
      vmag: 19.492,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Apr22/703_20220422_2B_N28022_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220422_2b_n28022_01_0002.arch?ra=105.98439&dec=29.77083&size=5.00arcmin&format=fits',
      date: '2022-04-22 03:26:21.350',
      ddec: -4.4597,
      dec: 29.77083,
      delta: 4.96585300778243,
      dra: 19.27613,
      drh: -1.3332825,
      elong: 72.3935,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59691.14312905093,
      mjd_stop: 59691.14347627315,
      phase: 11.6115,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220422_2b_n28022_01_0002.arch?ra=105.98439&dec=29.77083&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220422_2b_n28022_01_0002.arch',
      ra: 105.98439,
      rh: 4.75918426834,
      sangle: 274.068,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 200.0855,
      unc_a: 0.169,
      unc_b: 0.083,
      unc_theta: 0.982,
      vangle: 89.221,
      vmag: 19.747,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Apr22/703_20220422_2B_N28022_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220422_2b_n28022_01_0003.arch?ra=105.98518&dec=29.77067&size=5.00arcmin&format=fits',
      date: '2022-04-22 03:34:01.503',
      ddec: -4.46445,
      dec: 29.77067,
      delta: 4.96592893905228,
      dra: 19.28748,
      drh: -1.3333106,
      elong: 72.389,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59691.14845489583,
      mjd_stop: 59691.148802118056,
      phase: 11.6112,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220422_2b_n28022_01_0003.arch?ra=105.98518&dec=29.77067&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220422_2b_n28022_01_0003.arch',
      ra: 105.98518,
      rh: 4.759180167547,
      sangle: 274.068,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 200.0859,
      unc_a: 0.169,
      unc_b: 0.083,
      unc_theta: 0.982,
      vangle: 89.22199999999998,
      vmag: 19.747,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Apr22/703_20220422_2B_N28022_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220422_2b_n28022_01_0004.arch?ra=105.98597&dec=29.77052&size=5.00arcmin&format=fits',
      date: '2022-04-22 03:41:41.420',
      ddec: -4.46905,
      dec: 29.77052,
      delta: 4.96600485348046,
      dra: 19.29913,
      drh: -1.3333387,
      elong: 72.3845,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59691.15377800926,
      mjd_stop: 59691.15412523148,
      phase: 11.611,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220422_2b_n28022_01_0004.arch?ra=105.98597&dec=29.77052&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220422_2b_n28022_01_0004.arch',
      ra: 105.98597,
      rh: 4.759176068771,
      sangle: 274.068,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 200.0864,
      unc_a: 0.169,
      unc_b: 0.083,
      unc_theta: 0.981,
      vangle: 89.22199999999998,
      vmag: 19.747,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Apr22/703_20220422_2B_N28022_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220422_2b_n28022_01_0001.arch?ra=105.98361&dec=29.77099&size=5.00arcmin&format=fits',
      date: '2022-04-22 03:18:41.548',
      ddec: -4.45479,
      dec: 29.77099,
      delta: 4.96577715737565,
      dra: 19.2651,
      drh: -1.3332544,
      elong: 72.398,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59691.13780726852,
      mjd_stop: 59691.13815449074,
      phase: 11.6117,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220422_2b_n28022_01_0001.arch?ra=105.98361&dec=29.77099&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220422_2b_n28022_01_0001.arch',
      ra: 105.98361,
      rh: 4.759188365918,
      sangle: 274.069,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 200.085,
      unc_a: 0.169,
      unc_b: 0.083,
      unc_theta: 0.983,
      vangle: 89.221,
      vmag: 19.747,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Apr26/703_20220426_2B_N28022_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220426_2b_n28022_01_0002.arch?ra=106.60399&dec=29.65544&size=5.00arcmin&format=fits',
      date: '2022-04-26 03:00:29.840',
      ddec: -4.59549,
      dec: 29.65544,
      delta: 5.02145552604509,
      dra: 20.66551,
      drh: -1.354296,
      elong: 69.0905,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59695.12517175926,
      mjd_stop: 59695.125518981484,
      phase: 11.3971,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220426_2b_n28022_01_0002.arch?ra=106.60399&dec=29.65544&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220426_2b_n28022_01_0002.arch',
      ra: 106.60399,
      rh: 4.756094037735,
      sangle: 273.866,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 200.4166,
      unc_a: 0.167,
      unc_b: 0.082,
      unc_theta: 0.672,
      vangle: 89.56,
      vmag: 19.769,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Apr26/703_20220426_2B_N28022_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220426_2b_n28022_01_0004.arch?ra=106.60583&dec=29.65509&size=5.00arcmin&format=fits',
      date: '2022-04-26 03:17:09.854',
      ddec: -4.60604,
      dec: 29.65509,
      delta: 5.02161685081034,
      dra: 20.68903,
      drh: -1.3543571,
      elong: 69.0809,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59695.13674599537,
      mjd_stop: 59695.13709321759,
      phase: 11.3964,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220426_2b_n28022_01_0004.arch?ra=106.60583&dec=29.65509&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220426_2b_n28022_01_0004.arch',
      ra: 106.60583,
      rh: 4.756084985223,
      sangle: 273.865,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 200.4175,
      unc_a: 0.167,
      unc_b: 0.082,
      unc_theta: 0.671,
      vangle: 89.56099999999998,
      vmag: 19.769,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Apr26/703_20220426_2B_N28022_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220426_2b_n28022_01_0001.arch?ra=106.60308&dec=29.65561&size=5.00arcmin&format=fits',
      date: '2022-04-26 02:52:09.897',
      ddec: -4.58996,
      dec: 29.65561,
      delta: 5.02137491592477,
      dra: 20.65432,
      drh: -1.3542655,
      elong: 69.0953,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59695.11938538194,
      mjd_stop: 59695.119732604166,
      phase: 11.3974,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220426_2b_n28022_01_0001.arch?ra=106.60308&dec=29.65561&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220426_2b_n28022_01_0001.arch',
      ra: 106.60308,
      rh: 4.756098563258,
      sangle: 273.866,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 200.4161,
      unc_a: 0.167,
      unc_b: 0.082,
      unc_theta: 0.673,
      vangle: 89.56,
      vmag: 19.769,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Apr26/703_20220426_2B_N28022_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220426_2b_n28022_01_0003.arch?ra=106.60491&dec=29.65526&size=5.00arcmin&format=fits',
      date: '2022-04-26 03:08:49.731',
      ddec: -4.60085,
      dec: 29.65526,
      delta: 5.02153615607447,
      dra: 20.67708,
      drh: -1.3543266,
      elong: 69.0857,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59695.13095753472,
      mjd_stop: 59695.13130475694,
      phase: 11.3967,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220426_2b_n28022_01_0003.arch?ra=106.60491&dec=29.65526&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220426_2b_n28022_01_0003.arch',
      ra: 106.60491,
      rh: 4.75608951258,
      sangle: 273.866,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 200.4171,
      unc_a: 0.167,
      unc_b: 0.082,
      unc_theta: 0.672,
      vangle: 89.56099999999998,
      vmag: 19.769,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Apr30/703_20220430_2B_N28023_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220430_2b_n28023_01_0001.arch?ra=107.26888&dec=29.5354&size=5.00arcmin&format=fits',
      date: '2022-04-30 03:11:45.082',
      ddec: -4.75822,
      dec: 29.5354,
      delta: 5.07606060344047,
      dra: 22.03211,
      drh: -1.3754259,
      elong: 65.8105,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59699.132987060184,
      mjd_stop: 59699.13333428241,
      phase: 11.1455,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220430_2b_n28023_01_0001.arch?ra=107.26888&dec=29.5354&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220430_2b_n28023_01_0001.arch',
      ra: 107.26888,
      rh: 4.752935029378,
      sangle: 273.669,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 200.7503,
      unc_a: 0.165,
      unc_b: 0.081,
      unc_theta: 0.341,
      vangle: 89.92200000000003,
      vmag: 19.79,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Apr30/703_20220430_2B_N28023_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220430_2b_n28023_01_0004.arch?ra=107.27159&dec=29.5349&size=5.00arcmin&format=fits',
      date: '2022-04-30 03:34:51.809',
      ddec: -4.77096,
      dec: 29.5349,
      delta: 5.07627870698008,
      dra: 22.06729,
      drh: -1.3755104,
      elong: 65.7974,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59699.1490371412,
      mjd_stop: 59699.14938436342,
      phase: 11.1445,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220430_2b_n28023_01_0004.arch?ra=107.27159&dec=29.5349&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220430_2b_n28023_01_0004.arch',
      ra: 107.27159,
      rh: 4.752922280206,
      sangle: 273.668,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 200.7516,
      unc_a: 0.165,
      unc_b: 0.081,
      unc_theta: 0.34,
      vangle: 89.923,
      vmag: 19.79,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Apr30/703_20220430_2B_N28023_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220430_2b_n28023_01_0003.arch?ra=107.27068&dec=29.53507&size=5.00arcmin&format=fits',
      date: '2022-04-30 03:27:09.205',
      ddec: -4.76688,
      dec: 29.53507,
      delta: 5.07620592921675,
      dra: 22.05528,
      drh: -1.3754822,
      elong: 65.8018,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59699.14368292824,
      mjd_stop: 59699.14403015046,
      phase: 11.1448,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220430_2b_n28023_01_0003.arch?ra=107.27068&dec=29.53507&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220430_2b_n28023_01_0003.arch',
      ra: 107.27068,
      rh: 4.752926533342,
      sangle: 273.668,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 200.7512,
      unc_a: 0.165,
      unc_b: 0.081,
      unc_theta: 0.34,
      vangle: 89.923,
      vmag: 19.79,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Apr30/703_20220430_2B_N28023_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220430_2b_n28023_01_0002.arch?ra=107.26978&dec=29.53524&size=5.00arcmin&format=fits',
      date: '2022-04-30 03:19:27.441',
      ddec: -4.76263,
      dec: 29.53524,
      delta: 5.07613330296321,
      dra: 22.04356,
      drh: -1.3754541,
      elong: 65.8061,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59699.1383384375,
      mjd_stop: 59699.138685659724,
      phase: 11.1452,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220430_2b_n28023_01_0002.arch?ra=107.26978&dec=29.53524&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220430_2b_n28023_01_0002.arch',
      ra: 107.26978,
      rh: 4.752930778669,
      sangle: 273.669,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 200.7507,
      unc_a: 0.165,
      unc_b: 0.081,
      unc_theta: 0.341,
      vangle: 89.92200000000003,
      vmag: 19.79,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov12/703_20211112_2B_N28024_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211112_2b_n28024_01_0002.arch?ra=116.21838&dec=28.08432&size=5.00arcmin&format=fits',
      date: '2021-11-12 08:03:45.178',
      ddec: 5.5182,
      dec: 28.08432,
      delta: 4.3191029923101,
      dra: -2.01531,
      drh: -0.4729551,
      elong: 116.5431,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59530.33576594907,
      mjd_stop: 59530.336113171295,
      phase: 10.5393,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211112_2b_n28024_01_0002.arch?ra=116.21838&dec=28.08432&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211112_2b_n28024_01_0002.arch',
      ra: 116.21838,
      rh: 4.843204301081,
      sangle: 96.81299999999999,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 186.989,
      unc_a: 0.195,
      unc_b: 0.1,
      unc_theta: -4.113,
      vangle: 94.38,
      vmag: 19.514,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov12/703_20211112_2B_N28024_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211112_2b_n28024_01_0001.arch?ra=116.21846&dec=28.08412&size=5.00arcmin&format=fits',
      date: '2021-11-12 07:56:04.169',
      ddec: 5.52169,
      dec: 28.08412,
      delta: 4.31918035658823,
      dra: -1.99989,
      drh: -0.4729263,
      elong: 116.5376,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59530.33043019676,
      mjd_stop: 59530.33077741898,
      phase: 10.5398,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211112_2b_n28024_01_0001.arch?ra=116.21846&dec=28.08412&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211112_2b_n28024_01_0001.arch',
      ra: 116.21846,
      rh: 4.843205758643,
      sangle: 96.81400000000002,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 186.9885,
      unc_a: 0.195,
      unc_b: 0.1,
      unc_theta: -4.113,
      vangle: 94.38,
      vmag: 19.514,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov12/703_20211112_2B_N28024_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211112_2b_n28024_01_0003.arch?ra=116.2183&dec=28.08451&size=5.00arcmin&format=fits',
      date: '2021-11-12 08:11:27.104',
      ddec: 5.514506,
      dec: 28.08451,
      delta: 4.31902549859323,
      dra: -2.03047,
      drh: -0.472984,
      elong: 116.5486,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59530.34111231482,
      mjd_stop: 59530.341459537034,
      phase: 10.5388,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211112_2b_n28024_01_0003.arch?ra=116.2183&dec=28.08451&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211112_2b_n28024_01_0003.arch',
      ra: 116.2183,
      rh: 4.843202840531,
      sangle: 96.81200000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 186.9894,
      unc_a: 0.195,
      unc_b: 0.1,
      unc_theta: -4.113,
      vangle: 94.38,
      vmag: 19.514,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov12/703_20211112_2B_N28024_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211112_2b_n28024_01_0004.arch?ra=116.21821&dec=28.08471&size=5.00arcmin&format=fits',
      date: '2021-11-12 08:19:08.433',
      ddec: 5.510625,
      dec: 28.08471,
      delta: 4.31894813042804,
      dra: -2.04531,
      drh: -0.4730128,
      elong: 116.554,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59530.346451770834,
      mjd_stop: 59530.34679899306,
      phase: 10.5383,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211112_2b_n28024_01_0004.arch?ra=116.21821&dec=28.08471&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211112_2b_n28024_01_0004.arch',
      ra: 116.21821,
      rh: 4.843201381779,
      sangle: 96.81099999999998,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 186.9898,
      unc_a: 0.195,
      unc_b: 0.1,
      unc_theta: -4.113,
      vangle: 94.38,
      vmag: 19.514,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov10/703_20211110_2B_N28024_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211110_2b_n28024_01_0003.arch?ra=116.2367&dec=28.01547&size=5.00arcmin&format=fits',
      date: '2021-11-10 08:46:54.396',
      ddec: 5.297419,
      dec: 28.01547,
      delta: 4.34757462371596,
      dra: -1.00565,
      drh: -0.462323,
      elong: 114.5551,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59528.36573375,
      mjd_stop: 59528.36608097222,
      phase: 10.7215,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211110_2b_n28024_01_0003.arch?ra=116.2367&dec=28.01547&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211110_2b_n28024_01_0003.arch',
      ra: 116.2367,
      rh: 4.843736420053,
      sangle: 97.137,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 186.831,
      unc_a: 0.194,
      unc_b: 0.1,
      unc_theta: -4.102,
      vangle: 94.37700000000001,
      vmag: 19.529,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov10/703_20211110_2B_N28024_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211110_2b_n28024_01_0004.arch?ra=116.23666&dec=28.01566&size=5.00arcmin&format=fits',
      date: '2021-11-10 08:54:31.644',
      ddec: 5.292996,
      dec: 28.01566,
      delta: 4.34749675798462,
      dra: -1.01907,
      drh: -0.4623515,
      elong: 114.5605,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59528.37102597222,
      mjd_stop: 59528.371373194444,
      phase: 10.721,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211110_2b_n28024_01_0004.arch?ra=116.23666&dec=28.01566&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211110_2b_n28024_01_0004.arch',
      ra: 116.23666,
      rh: 4.843735006792,
      sangle: 97.13600000000002,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 186.8314,
      unc_a: 0.194,
      unc_b: 0.1,
      unc_theta: -4.102,
      vangle: 94.37700000000001,
      vmag: 19.529,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov10/703_20211110_2B_N28024_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211110_2b_n28024_01_0001.arch?ra=116.23678&dec=28.0151&size=5.00arcmin&format=fits',
      date: '2021-11-10 08:31:39.841',
      ddec: 5.305756,
      dec: 28.0151,
      delta: 4.34773044812447,
      dra: -0.97778,
      drh: -0.4622658,
      elong: 114.5443,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59528.355148622686,
      mjd_stop: 59528.35549584491,
      phase: 10.7224,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211110_2b_n28024_01_0001.arch?ra=116.23678&dec=28.0151&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211110_2b_n28024_01_0001.arch',
      ra: 116.23678,
      rh: 4.843739246494,
      sangle: 97.13900000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 186.8301,
      unc_a: 0.194,
      unc_b: 0.1,
      unc_theta: -4.102,
      vangle: 94.37700000000001,
      vmag: 19.529,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov10/703_20211110_2B_N28024_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211110_2b_n28024_01_0002.arch?ra=116.23674&dec=28.01528&size=5.00arcmin&format=fits',
      date: '2021-11-10 08:39:17.174',
      ddec: 5.301674,
      dec: 28.01528,
      delta: 4.34765251293908,
      dra: -0.99189,
      drh: -0.4622944,
      elong: 114.5497,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59528.36044182871,
      mjd_stop: 59528.36078905092,
      phase: 10.7219,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211110_2b_n28024_01_0002.arch?ra=116.23674&dec=28.01528&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211110_2b_n28024_01_0002.arch',
      ra: 116.23674,
      rh: 4.843737833146,
      sangle: 97.13799999999998,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 186.8305,
      unc_a: 0.194,
      unc_b: 0.1,
      unc_theta: -4.102,
      vangle: 94.37700000000001,
      vmag: 19.529,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22May04/703_20220504_2B_N28023_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220504_2b_n28023_01_0001.arch?ra=107.97146&dec=29.4116&size=5.00arcmin&format=fits',
      date: '2022-05-04 03:13:04.573',
      ddec: -4.91778,
      dec: 29.4116,
      delta: 5.12901510030426,
      dra: 23.30367,
      drh: -1.3964993,
      elong: 62.5796,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59703.13390709491,
      mjd_stop: 59703.13425431713,
      phase: 10.8606,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220504_2b_n28023_01_0001.arch?ra=107.97146&dec=29.4116&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220504_2b_n28023_01_0001.arch',
      ra: 107.97146,
      rh: 4.749732692537,
      sangle: 273.474,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 201.0838,
      unc_a: 0.164,
      unc_b: 0.08,
      unc_theta: -0.006,
      vangle: 90.30099999999999,
      vmag: 19.809,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22May04/703_20220504_2B_N28023_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220504_2b_n28023_01_0003.arch?ra=107.97352&dec=29.41122&size=5.00arcmin&format=fits',
      date: '2022-05-04 03:29:39.888',
      ddec: -4.9263,
      dec: 29.41122,
      delta: 5.12916697408901,
      dra: 23.32928,
      drh: -1.3965599,
      elong: 62.5703,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59703.145426944444,
      mjd_stop: 59703.14577416667,
      phase: 10.8597,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220504_2b_n28023_01_0003.arch?ra=107.97352&dec=29.41122&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220504_2b_n28023_01_0003.arch',
      ra: 107.97352,
      rh: 4.749723401757,
      sangle: 273.473,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 201.0848,
      unc_a: 0.164,
      unc_b: 0.08,
      unc_theta: -0.007,
      vangle: 90.30200000000002,
      vmag: 19.809,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22May04/703_20220504_2B_N28023_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220504_2b_n28023_01_0002.arch?ra=107.97249&dec=29.41141&size=5.00arcmin&format=fits',
      date: '2022-05-04 03:21:22.001',
      ddec: -4.92214,
      dec: 29.41141,
      delta: 5.12909099162407,
      dra: 23.31632,
      drh: -1.3965296,
      elong: 62.575,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59703.13966436343,
      mjd_stop: 59703.140011585645,
      phase: 10.8602,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220504_2b_n28023_01_0002.arch?ra=107.97249&dec=29.41141&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220504_2b_n28023_01_0002.arch',
      ra: 107.97249,
      rh: 4.74972804934,
      sangle: 273.474,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 201.0843,
      unc_a: 0.164,
      unc_b: 0.08,
      unc_theta: -0.007,
      vangle: 90.30200000000002,
      vmag: 19.809,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22May04/703_20220504_2B_N28023_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220504_2b_n28023_01_0004.arch?ra=107.97455&dec=29.41104&size=5.00arcmin&format=fits',
      date: '2022-05-04 03:37:57.457',
      ddec: -4.93026,
      dec: 29.41104,
      delta: 5.12924292792149,
      dra: 23.34252,
      drh: -1.3965902,
      elong: 62.5657,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59703.15118584491,
      mjd_stop: 59703.15153306713,
      phase: 10.8593,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220504_2b_n28023_01_0004.arch?ra=107.97455&dec=29.41104&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20220504_2b_n28023_01_0004.arch',
      ra: 107.97455,
      rh: 4.749718757041,
      sangle: 273.473,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 201.0853,
      unc_a: 0.164,
      unc_b: 0.08,
      unc_theta: -0.008,
      vangle: 90.303,
      vmag: 19.809,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Oct13/703_20211013_2B_N28024_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211013_2b_n28024_01_0001.arch?ra=114.88225&dec=27.33187&size=5.00arcmin&format=fits',
      date: '2021-10-13 10:06:25.802',
      ddec: 2.348212,
      dec: 27.33187,
      delta: 4.78218947318192,
      dra: 12.82114,
      drh: -0.311391,
      elong: 87.9375,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59500.42095835648,
      mjd_stop: 59500.421305578704,
      phase: 11.866,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211013_2b_n28024_01_0001.arch?ra=114.88225&dec=27.33187&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211013_2b_n28024_01_0001.arch',
      ra: 114.88225,
      rh: 4.849980897956,
      sangle: 99.892,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 184.5937,
      unc_a: 0.176,
      unc_b: 0.091,
      unc_theta: -3.245,
      vangle: 93.57400000000001,
      vmag: 19.741,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Oct13/703_20211013_2B_N28024_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211013_2b_n28024_01_0004.arch?ra=114.88378&dec=27.33212&size=5.00arcmin&format=fits',
      date: '2021-10-13 10:29:21.574',
      ddec: 2.337533,
      dec: 27.33212,
      delta: 4.78193317647577,
      dra: 12.78323,
      drh: -0.311477,
      elong: 87.9521,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59500.436881643516,
      mjd_stop: 59500.43722886574,
      phase: 11.866,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211013_2b_n28024_01_0004.arch?ra=114.88378&dec=27.33212&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211013_2b_n28024_01_0004.arch',
      ra: 114.88378,
      rh: 4.849978033597,
      sangle: 99.89100000000002,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 184.5949,
      unc_a: 0.176,
      unc_b: 0.091,
      unc_theta: -3.245,
      vangle: 93.57499999999999,
      vmag: 19.741,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Oct13/703_20211013_2B_N28024_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211013_2b_n28024_01_0003.arch?ra=114.88327&dec=27.33204&size=5.00arcmin&format=fits',
      date: '2021-10-13 10:21:43.108',
      ddec: 2.341249,
      dec: 27.33204,
      delta: 4.78201856226782,
      dra: 12.79557,
      drh: -0.3114484,
      elong: 87.9472,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59500.43157532407,
      mjd_stop: 59500.4319225463,
      phase: 11.866,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211013_2b_n28024_01_0003.arch?ra=114.88327&dec=27.33204&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211013_2b_n28024_01_0003.arch',
      ra: 114.88327,
      rh: 4.849978988212,
      sangle: 99.89100000000002,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 184.5945,
      unc_a: 0.176,
      unc_b: 0.091,
      unc_theta: -3.245,
      vangle: 93.57400000000001,
      vmag: 19.741,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Oct13/703_20211013_2B_N28024_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211013_2b_n28024_01_0002.arch?ra=114.88276&dec=27.33195&size=5.00arcmin&format=fits',
      date: '2021-10-13 10:14:04.202',
      ddec: 2.344813,
      dec: 27.33195,
      delta: 4.78210405354715,
      dra: 12.80821,
      drh: -0.3114197,
      elong: 87.9424,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59500.426263912035,
      mjd_stop: 59500.42661113426,
      phase: 11.866,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211013_2b_n28024_01_0002.arch?ra=114.88276&dec=27.33195&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211013_2b_n28024_01_0002.arch',
      ra: 114.88276,
      rh: 4.849979943655,
      sangle: 99.892,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 184.5941,
      unc_a: 0.176,
      unc_b: 0.091,
      unc_theta: -3.245,
      vangle: 93.57400000000001,
      vmag: 19.741,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Oct30/703_20211030_2B_N28024_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211030_2b_n28024_01_0004.arch?ra=116.05376&dec=27.6813&size=5.00arcmin&format=fits',
      date: '2021-10-30 09:34:04.502',
      ddec: 4.112403,
      dec: 27.6813,
      delta: 4.51326859683586,
      dra: 4.780352,
      drh: -0.4031119,
      elong: 103.7556,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59517.39848960648,
      mjd_stop: 59517.3988368287,
      phase: 11.4835,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211030_2b_n28024_01_0004.arch?ra=116.05376&dec=27.6813&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211030_2b_n28024_01_0004.arch',
      ra: 116.05376,
      rh: 4.846477559362,
      sangle: 98.572,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 185.9521,
      unc_a: 0.187,
      unc_b: 0.096,
      unc_theta: -3.913,
      vangle: 94.22300000000001,
      vmag: 19.613,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Oct30/703_20211030_2B_N28024_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211030_2b_n28024_01_0002.arch?ra=116.05336&dec=27.681&size=5.00arcmin&format=fits',
      date: '2021-10-30 09:18:19.330',
      ddec: 4.120676,
      dec: 27.681,
      delta: 4.5134402021533,
      dra: 4.807606,
      drh: -0.4030528,
      elong: 103.745,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59517.38755011574,
      mjd_stop: 59517.38789733796,
      phase: 11.4841,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211030_2b_n28024_01_0002.arch?ra=116.05336&dec=27.681&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211030_2b_n28024_01_0002.arch',
      ra: 116.05336,
      rh: 4.846480106301,
      sangle: 98.57299999999998,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 185.9512,
      unc_a: 0.187,
      unc_b: 0.096,
      unc_theta: -3.913,
      vangle: 94.22199999999998,
      vmag: 19.613,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Oct30/703_20211030_2B_N28024_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211030_2b_n28024_01_0001.arch?ra=116.05317&dec=27.68085&size=5.00arcmin&format=fits',
      date: '2021-10-30 09:10:26.772',
      ddec: 4.124546,
      dec: 27.68085,
      delta: 4.51352604048797,
      dra: 4.821747,
      drh: -0.4030233,
      elong: 103.7396,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59517.38208069444,
      mjd_stop: 59517.382427916666,
      phase: 11.4844,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211030_2b_n28024_01_0001.arch?ra=116.05317&dec=27.68085&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211030_2b_n28024_01_0001.arch',
      ra: 116.05317,
      rh: 4.846481379555,
      sangle: 98.57400000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 185.9508,
      unc_a: 0.187,
      unc_b: 0.096,
      unc_theta: -3.913,
      vangle: 94.22199999999998,
      vmag: 19.613,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Oct30/703_20211030_2B_N28024_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211030_2b_n28024_01_0003.arch?ra=116.05356&dec=27.68115&size=5.00arcmin&format=fits',
      date: '2021-10-30 09:26:12.250',
      ddec: 4.116623,
      dec: 27.68115,
      delta: 4.51335432493852,
      dra: 4.793794,
      drh: -0.4030824,
      elong: 103.7503,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59517.39302372685,
      mjd_stop: 59517.393370949074,
      phase: 11.4838,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211030_2b_n28024_01_0003.arch?ra=116.05356&dec=27.68115&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211030_2b_n28024_01_0003.arch',
      ra: 116.05356,
      rh: 4.846478831978,
      sangle: 98.57299999999998,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 185.9516,
      unc_a: 0.187,
      unc_b: 0.096,
      unc_theta: -3.913,
      vangle: 94.22199999999998,
      vmag: 19.613,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Oct02/703_20211002_2B_N28024_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211002_2b_n28024_01_0003.arch?ra=113.62245&dec=27.20541&size=5.00arcmin&format=fits',
      date: '2021-10-02 11:24:07.072',
      ddec: 1.350721,
      dec: 27.20541,
      delta: 4.95522959942758,
      dra: 17.23529,
      drh: -0.2522347,
      elong: 78.266,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59489.47490824074,
      mjd_stop: 59489.47525546296,
      phase: 11.6542,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211002_2b_n28024_01_0003.arch?ra=113.62245&dec=27.20541&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211002_2b_n28024_01_0003.arch',
      ra: 113.62245,
      rh: 4.851762652495,
      sangle: 100.35300000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 183.7192,
      unc_a: 0.17,
      unc_b: 0.088,
      unc_theta: -2.614,
      vangle: 92.93299999999999,
      vmag: 19.82,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Oct02/703_20211002_2B_N28024_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211002_2b_n28024_01_0002.arch?ra=113.62176&dec=27.20536&size=5.00arcmin&format=fits',
      date: '2021-10-02 11:16:25.800',
      ddec: 1.354692,
      dec: 27.20536,
      delta: 4.95531359427165,
      dra: 17.24639,
      drh: -0.2522059,
      elong: 78.2613,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59489.469569444445,
      mjd_stop: 59489.46991666667,
      phase: 11.654,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211002_2b_n28024_01_0002.arch?ra=113.62176&dec=27.20536&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211002_2b_n28024_01_0002.arch',
      ra: 113.62176,
      rh: 4.851763430265,
      sangle: 100.35300000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 183.7188,
      unc_a: 0.17,
      unc_b: 0.088,
      unc_theta: -2.614,
      vangle: 92.93299999999999,
      vmag: 19.82,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Oct02/703_20211002_2B_N28024_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211002_2b_n28024_01_0004.arch?ra=113.62314&dec=27.20546&size=5.00arcmin&format=fits',
      date: '2021-10-02 11:31:47.663',
      ddec: 1.346619,
      dec: 27.20546,
      delta: 4.95514575384048,
      dra: 17.22454,
      drh: -0.2522636,
      elong: 78.2706,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59489.48023915509,
      mjd_stop: 59489.480586377314,
      phase: 11.6544,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211002_2b_n28024_01_0004.arch?ra=113.62314&dec=27.20546&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211002_2b_n28024_01_0004.arch',
      ra: 113.62314,
      rh: 4.851761875785,
      sangle: 100.35300000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 183.7196,
      unc_a: 0.17,
      unc_b: 0.088,
      unc_theta: -2.615,
      vangle: 92.93299999999999,
      vmag: 19.82,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Oct02/703_20211002_2B_N28024_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211002_2b_n28024_01_0001.arch?ra=113.62107&dec=27.20531&size=5.00arcmin&format=fits',
      date: '2021-10-02 11:08:43.823',
      ddec: 1.358528,
      dec: 27.20531,
      delta: 4.95539774203804,
      dra: 17.25782,
      drh: -0.252177,
      elong: 78.2566,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59489.46422248842,
      mjd_stop: 59489.464569710646,
      phase: 11.6539,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211002_2b_n28024_01_0001.arch?ra=113.62107&dec=27.20531&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211002_2b_n28024_01_0001.arch',
      ra: 113.62107,
      rh: 4.851764209135,
      sangle: 100.35300000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 183.7184,
      unc_a: 0.17,
      unc_b: 0.088,
      unc_theta: -2.614,
      vangle: 92.93200000000002,
      vmag: 19.82,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Oct09/703_20211009_2B_N28024_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211009_2b_n28024_01_0004.arch?ra=114.46656&dec=27.27768&size=5.00arcmin&format=fits',
      date: '2021-10-09 10:33:57.084',
      ddec: 1.965778,
      dec: 27.27768,
      delta: 4.84553497051153,
      dra: 14.49297,
      drh: -0.2898784,
      elong: 84.3749,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59496.44007041667,
      mjd_stop: 59496.440417638885,
      phase: 11.8277,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211009_2b_n28024_01_0004.arch?ra=114.46656&dec=27.27768&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211009_2b_n28024_01_0004.arch',
      ra: 114.46656,
      rh: 4.85067216752,
      sangle: 100.08800000000002,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 184.2755,
      unc_a: 0.174,
      unc_b: 0.09,
      unc_theta: -3.032,
      vangle: 93.35899999999998,
      vmag: 19.77,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Oct09/703_20211009_2B_N28024_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211009_2b_n28024_01_0002.arch?ra=114.46518&dec=27.27751&size=5.00arcmin&format=fits',
      date: '2021-10-09 10:15:39.475',
      ddec: 1.973866,
      dec: 27.27751,
      delta: 4.84573859952168,
      dra: 14.52332,
      drh: -0.2898097,
      elong: 84.3635,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59496.4273666088,
      mjd_stop: 59496.42771383102,
      phase: 11.8275,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211009_2b_n28024_01_0002.arch?ra=114.46518&dec=27.27751&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211009_2b_n28024_01_0002.arch',
      ra: 114.46518,
      rh: 4.85067429432,
      sangle: 100.089,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 184.2745,
      unc_a: 0.174,
      unc_b: 0.09,
      unc_theta: -3.031,
      vangle: 93.358,
      vmag: 19.77,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Oct09/703_20211009_2B_N28024_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211009_2b_n28024_01_0003.arch?ra=114.46603&dec=27.27762&size=5.00arcmin&format=fits',
      date: '2021-10-09 10:26:57.902',
      ddec: 1.968975,
      dec: 27.27762,
      delta: 4.84561272259648,
      dra: 14.50437,
      drh: -0.2898521,
      elong: 84.3705,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59496.43521877315,
      mjd_stop: 59496.43556599537,
      phase: 11.8276,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211009_2b_n28024_01_0003.arch?ra=114.46603&dec=27.27762&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211009_2b_n28024_01_0003.arch',
      ra: 114.46603,
      rh: 4.850672979814,
      sangle: 100.08800000000002,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 184.2751,
      unc_a: 0.174,
      unc_b: 0.09,
      unc_theta: -3.031,
      vangle: 93.35899999999998,
      vmag: 19.77,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Oct09/703_20211009_2B_N28024_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211009_2b_n28024_01_0001.arch?ra=114.46465&dec=27.27745&size=5.00arcmin&format=fits',
      date: '2021-10-09 10:08:40.477',
      ddec: 1.976707,
      dec: 27.27745,
      delta: 4.84581636415089,
      dra: 14.53532,
      drh: -0.2897835,
      elong: 84.3591,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59496.42251709491,
      mjd_stop: 59496.42286431713,
      phase: 11.8275,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211009_2b_n28024_01_0001.arch?ra=114.46465&dec=27.27745&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211009_2b_n28024_01_0001.arch',
      ra: 114.46465,
      rh: 4.850675106066,
      sangle: 100.089,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 184.2741,
      unc_a: 0.174,
      unc_b: 0.09,
      unc_theta: -3.031,
      vangle: 93.358,
      vmag: 19.771,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Oct15/703_20211015_2B_N28024_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211015_2b_n28024_01_0004.arch?ra=115.0727&dec=27.36303&size=5.00arcmin&format=fits',
      date: '2021-10-15 10:19:55.806',
      ddec: 2.534288,
      dec: 27.36303,
      delta: 4.75013751933246,
      dra: 11.91432,
      drh: -0.322249,
      elong: 89.7561,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59502.43033340278,
      mjd_stop: 59502.430680625,
      phase: 11.8677,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211015_2b_n28024_01_0004.arch?ra=115.0727&dec=27.36303&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211015_2b_n28024_01_0004.arch',
      ra: 115.0727,
      rh: 4.849613190881,
      sangle: 99.779,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 184.7543,
      unc_a: 0.177,
      unc_b: 0.092,
      unc_theta: -3.344,
      vangle: 93.67399999999998,
      vmag: 19.726,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Oct15/703_20211015_2B_N28024_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211015_2b_n28024_01_0003.arch?ra=115.07223&dec=27.36294&size=5.00arcmin&format=fits',
      date: '2021-10-15 10:12:14.969',
      ddec: 2.537996,
      dec: 27.36294,
      delta: 4.75022341738959,
      dra: 11.92692,
      drh: -0.3222202,
      elong: 89.7512,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59502.4249996412,
      mjd_stop: 59502.42534686343,
      phase: 11.8677,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211015_2b_n28024_01_0003.arch?ra=115.07223&dec=27.36294&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211015_2b_n28024_01_0003.arch',
      ra: 115.07223,
      rh: 4.849614183619,
      sangle: 99.77999999999997,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 184.7539,
      unc_a: 0.177,
      unc_b: 0.092,
      unc_theta: -3.344,
      vangle: 93.673,
      vmag: 19.726,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Oct15/703_20211015_2B_N28024_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211015_2b_n28024_01_0002.arch?ra=115.07175&dec=27.36285&size=5.00arcmin&format=fits',
      date: '2021-10-15 10:04:34.117',
      ddec: 2.541544,
      dec: 27.36285,
      delta: 4.7503093419377,
      dra: 11.93983,
      drh: -0.3221914,
      elong: 89.7463,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59502.41966570602,
      mjd_stop: 59502.42001292824,
      phase: 11.8677,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211015_2b_n28024_01_0002.arch?ra=115.07175&dec=27.36285&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211015_2b_n28024_01_0002.arch',
      ra: 115.07175,
      rh: 4.8496151763,
      sangle: 99.77999999999997,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 184.7534,
      unc_a: 0.177,
      unc_b: 0.092,
      unc_theta: -3.344,
      vangle: 93.673,
      vmag: 19.726,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Oct15/703_20211015_2B_N28024_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211015_2b_n28024_01_0001.arch?ra=115.07127&dec=27.36276&size=5.00arcmin&format=fits',
      date: '2021-10-15 09:56:52.698',
      ddec: 2.544931,
      dec: 27.36276,
      delta: 4.75039539500172,
      dra: 11.95305,
      drh: -0.3221625,
      elong: 89.7414,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59502.41432520833,
      mjd_stop: 59502.414672430554,
      phase: 11.8677,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211015_2b_n28024_01_0001.arch?ra=115.07127&dec=27.36276&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211015_2b_n28024_01_0001.arch',
      ra: 115.07127,
      rh: 4.849616170114,
      sangle: 99.77999999999997,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 184.753,
      unc_a: 0.177,
      unc_b: 0.092,
      unc_theta: -3.344,
      vangle: 93.673,
      vmag: 19.726,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Oct29/703_20211029_2B_N28024_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211029_2b_n28024_01_0001.arch?ra=116.0141&dec=27.65544&size=5.00arcmin&format=fits',
      date: '2021-10-29 09:51:51.484',
      ddec: 3.995036,
      dec: 27.65544,
      delta: 4.52864414814173,
      dra: 5.264186,
      drh: -0.3977779,
      elong: 102.8061,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59516.41083893518,
      mjd_stop: 59516.411186157406,
      phase: 11.5315,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211029_2b_n28024_01_0001.arch?ra=116.0141&dec=27.65544&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211029_2b_n28024_01_0001.arch',
      ra: 116.0141,
      rh: 4.846706000064,
      sangle: 98.67500000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 185.873,
      unc_a: 0.186,
      unc_b: 0.096,
      unc_theta: -3.886,
      vangle: 94.19799999999998,
      vmag: 19.62,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Oct29/703_20211029_2B_N28024_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211029_2b_n28024_01_0003.arch?ra=116.01452&dec=27.65572&size=5.00arcmin&format=fits',
      date: '2021-10-29 10:07:03.256',
      ddec: 3.985925,
      dec: 27.65572,
      delta: 4.52847821046357,
      dra: 5.240709,
      drh: -0.3978349,
      elong: 102.8164,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59516.42139185185,
      mjd_stop: 59516.421739074074,
      phase: 11.531,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211029_2b_n28024_01_0003.arch?ra=116.01452&dec=27.65572&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211029_2b_n28024_01_0003.arch',
      ra: 116.01452,
      rh: 4.846703575286,
      sangle: 98.67399999999998,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 185.8738,
      unc_a: 0.186,
      unc_b: 0.096,
      unc_theta: -3.886,
      vangle: 94.19799999999998,
      vmag: 19.62,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Oct29/703_20211029_2B_N28024_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211029_2b_n28024_01_0004.arch?ra=116.01473&dec=27.65586&size=5.00arcmin&format=fits',
      date: '2021-10-29 10:14:38.993',
      ddec: 3.981167,
      dec: 27.65586,
      delta: 4.52839531274836,
      dra: 5.229536,
      drh: -0.3978634,
      elong: 102.8215,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59516.42666658565,
      mjd_stop: 59516.42701380787,
      phase: 11.5307,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211029_2b_n28024_01_0004.arch?ra=116.01473&dec=27.65586&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211029_2b_n28024_01_0004.arch',
      ra: 116.01473,
      rh: 4.846702363163,
      sangle: 98.673,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 185.8742,
      unc_a: 0.186,
      unc_b: 0.096,
      unc_theta: -3.886,
      vangle: 94.19799999999998,
      vmag: 19.62,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Oct29/703_20211029_2B_N28024_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211029_2b_n28024_01_0002.arch?ra=116.01431&dec=27.65558&size=5.00arcmin&format=fits',
      date: '2021-10-29 09:59:27.252',
      ddec: 3.990551,
      dec: 27.65558,
      delta: 4.52856118634496,
      dra: 5.252267,
      drh: -0.3978064,
      elong: 102.8112,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59516.41611402778,
      mjd_stop: 59516.41646125,
      phase: 11.5312,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211029_2b_n28024_01_0002.arch?ra=116.01431&dec=27.65558&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211029_2b_n28024_01_0002.arch',
      ra: 116.01431,
      rh: 4.846704788032,
      sangle: 98.67399999999998,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 185.8734,
      unc_a: 0.186,
      unc_b: 0.096,
      unc_theta: -3.886,
      vangle: 94.19799999999998,
      vmag: 19.62,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov06/703_20211106_2B_N28024_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211106_2b_n28024_01_0004.arch?ra=116.22548&dec=27.88331&size=5.00arcmin&format=fits',
      date: '2021-11-06 08:28:29.739',
      ddec: 4.894512,
      dec: 27.88331,
      delta: 4.40693620536794,
      dra: 1.197981,
      drh: -0.4406626,
      elong: 110.5498,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59524.352948368054,
      mjd_stop: 59524.35329559028,
      phase: 11.0497,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211106_2b_n28024_01_0004.arch?ra=116.22548&dec=27.88331&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211106_2b_n28024_01_0004.arch',
      ra: 116.22548,
      rh: 4.844782879814,
      sangle: 97.73000000000002,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 186.5093,
      unc_a: 0.191,
      unc_b: 0.098,
      unc_theta: -4.058,
      vangle: 94.34699999999998,
      vmag: 19.559,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov06/703_20211106_2B_N28024_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211106_2b_n28024_01_0002.arch?ra=116.22538&dec=27.88295&size=5.00arcmin&format=fits',
      date: '2021-11-06 08:12:29.128',
      ddec: 4.901319,
      dec: 27.88295,
      delta: 4.40710483004173,
      dra: 1.229747,
      drh: -0.4406025,
      elong: 110.5386,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59524.341830185185,
      mjd_stop: 59524.34217740741,
      phase: 11.0506,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211106_2b_n28024_01_0002.arch?ra=116.22538&dec=27.88295&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211106_2b_n28024_01_0002.arch',
      ra: 116.22538,
      rh: 4.84478570949,
      sangle: 97.731,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 186.5084,
      unc_a: 0.191,
      unc_b: 0.098,
      unc_theta: -4.058,
      vangle: 94.34699999999998,
      vmag: 19.56,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov06/703_20211106_2B_N28024_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211106_2b_n28024_01_0003.arch?ra=116.22543&dec=27.88313&size=5.00arcmin&format=fits',
      date: '2021-11-06 08:20:29.515',
      ddec: 4.898021,
      dec: 27.88313,
      delta: 4.40702049109339,
      dra: 1.213711,
      drh: -0.4406325,
      elong: 110.5442,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59524.347390219904,
      mjd_stop: 59524.34773744213,
      phase: 11.0501,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211106_2b_n28024_01_0003.arch?ra=116.22543&dec=27.88313&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211106_2b_n28024_01_0003.arch',
      ra: 116.22543,
      rh: 4.84478429446,
      sangle: 97.731,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 186.5088,
      unc_a: 0.191,
      unc_b: 0.098,
      unc_theta: -4.058,
      vangle: 94.34699999999998,
      vmag: 19.56,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov06/703_20211106_2B_N28024_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211106_2b_n28024_01_0001.arch?ra=116.22532&dec=27.88277&size=5.00arcmin&format=fits',
      date: '2021-11-06 08:04:28.442',
      ddec: 4.904403,
      dec: 27.88277,
      delta: 4.40718924496012,
      dra: 1.246076,
      drh: -0.4405725,
      elong: 110.533,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59524.33626668982,
      mjd_stop: 59524.33661391203,
      phase: 11.051,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211106_2b_n28024_01_0001.arch?ra=116.22532&dec=27.88277&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211106_2b_n28024_01_0001.arch',
      ra: 116.22532,
      rh: 4.844787125305,
      sangle: 97.73200000000003,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 186.5079,
      unc_a: 0.191,
      unc_b: 0.098,
      unc_theta: -4.058,
      vangle: 94.34699999999998,
      vmag: 19.56,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Oct11/703_20211011_2B_N28024_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211011_2b_n28024_01_0002.arch?ra=114.68017&dec=27.30348&size=5.00arcmin&format=fits',
      date: '2021-10-11 10:12:59.667',
      ddec: 2.157623,
      dec: 27.30348,
      delta: 4.81397764719759,
      dra: 13.67752,
      drh: -0.3006079,
      elong: 86.1451,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59498.425516979165,
      mjd_stop: 59498.42586420139,
      phase: 11.8525,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211011_2b_n28024_01_0002.arch?ra=114.68017&dec=27.30348&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211011_2b_n28024_01_0002.arch',
      ra: 114.68017,
      rh: 4.850333583702,
      sangle: 99.995,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 184.4342,
      unc_a: 0.175,
      unc_b: 0.09,
      unc_theta: -3.14,
      vangle: 93.469,
      vmag: 19.756,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Oct11/703_20211011_2B_N28024_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211011_2b_n28024_01_0001.arch?ra=114.67962&dec=27.3034&size=5.00arcmin&format=fits',
      date: '2021-10-11 10:05:18.610',
      ddec: 2.16085,
      dec: 27.3034,
      delta: 4.81406343865154,
      dra: 13.6907,
      drh: -0.300579,
      elong: 86.1403,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59498.420180671295,
      mjd_stop: 59498.42052789352,
      phase: 11.8525,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211011_2b_n28024_01_0001.arch?ra=114.67962&dec=27.3034&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211011_2b_n28024_01_0001.arch',
      ra: 114.67962,
      rh: 4.850334510209,
      sangle: 99.995,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 184.4337,
      unc_a: 0.175,
      unc_b: 0.09,
      unc_theta: -3.14,
      vangle: 93.469,
      vmag: 19.756,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Oct11/703_20211011_2B_N28024_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211011_2b_n28024_01_0004.arch?ra=114.68126&dec=27.30363&size=5.00arcmin&format=fits',
      date: '2021-10-11 10:28:22.248',
      ddec: 2.150673,
      dec: 27.30363,
      delta: 4.81380604332628,
      dra: 13.65199,
      drh: -0.3006656,
      elong: 86.1548,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59498.436195,
      mjd_stop: 59498.436542222225,
      phase: 11.8526,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211011_2b_n28024_01_0004.arch?ra=114.68126&dec=27.30363&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211011_2b_n28024_01_0004.arch',
      ra: 114.68126,
      rh: 4.850331729481,
      sangle: 99.99400000000003,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 184.435,
      unc_a: 0.175,
      unc_b: 0.09,
      unc_theta: -3.141,
      vangle: 93.469,
      vmag: 19.756,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Oct11/703_20211011_2B_N28024_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211011_2b_n28024_01_0003.arch?ra=114.68071&dec=27.30355&size=5.00arcmin&format=fits',
      date: '2021-10-11 10:20:40.988',
      ddec: 2.154229,
      dec: 27.30355,
      delta: 4.81389182828568,
      dra: 13.66461,
      drh: -0.3006367,
      elong: 86.15,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59498.430856342595,
      mjd_stop: 59498.43120356482,
      phase: 11.8526,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211011_2b_n28024_01_0003.arch?ra=114.68071&dec=27.30355&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211011_2b_n28024_01_0003.arch',
      ra: 114.68071,
      rh: 4.850332656574,
      sangle: 99.99400000000003,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 184.4346,
      unc_a: 0.175,
      unc_b: 0.09,
      unc_theta: -3.141,
      vangle: 93.469,
      vmag: 19.756,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Sep16/703_20210916_2B_N28023_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210916_2b_n28023_01_0004.arch?ra=111.18654&dec=27.12959&size=5.00arcmin&format=fits',
      date: '2021-09-16 11:30:45.315',
      ddec: 0.319407,
      dec: 27.12959,
      delta: 5.19576430017347,
      dra: 22.68981,
      drh: -0.1657644,
      elong: 64.7951,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59473.479517534724,
      mjd_stop: 59473.47986475695,
      phase: 10.8016,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210916_2b_n28023_01_0004.arch?ra=111.18654&dec=27.12959&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210916_2b_n28023_01_0004.arch',
      ra: 111.18654,
      rh: 4.853693606406,
      sangle: 100.69,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 182.4429,
      unc_a: 0.163,
      unc_b: 0.084,
      unc_theta: -1.467,
      vangle: 91.74400000000003,
      vmag: 19.924,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Sep16/703_20210916_2B_N28023_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210916_2b_n28023_01_0001.arch?ra=111.18397&dec=27.12956&size=5.00arcmin&format=fits',
      date: '2021-09-16 11:08:56.540',
      ddec: 0.32758,
      dec: 27.12956,
      delta: 5.19598438650319,
      dra: 22.72427,
      drh: -0.1656825,
      elong: 64.7825,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59473.46436967592,
      mjd_stop: 59473.46471689815,
      phase: 10.8005,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210916_2b_n28023_01_0001.arch?ra=111.18397&dec=27.12956&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210916_2b_n28023_01_0001.arch',
      ra: 111.18397,
      rh: 4.85369505638,
      sangle: 100.69,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 182.4417,
      unc_a: 0.163,
      unc_b: 0.084,
      unc_theta: -1.466,
      vangle: 91.74200000000002,
      vmag: 19.925,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Sep16/703_20210916_2B_N28023_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210916_2b_n28023_01_0003.arch?ra=111.18572&dec=27.12958&size=5.00arcmin&format=fits',
      date: '2021-09-16 11:23:45.913',
      ddec: 0.322167,
      dec: 27.12958,
      delta: 5.19583481252329,
      dra: 22.70065,
      drh: -0.1657382,
      elong: 64.791,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59473.47466334491,
      mjd_stop: 59473.47501056713,
      phase: 10.8012,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210916_2b_n28023_01_0003.arch?ra=111.18572&dec=27.12958&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210916_2b_n28023_01_0003.arch',
      ra: 111.18572,
      rh: 4.853694071134,
      sangle: 100.69,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 182.4425,
      unc_a: 0.163,
      unc_b: 0.084,
      unc_theta: -1.467,
      vangle: 91.743,
      vmag: 19.924,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Sep16/703_20210916_2B_N28023_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210916_2b_n28023_01_0002.arch?ra=111.18489&dec=27.12957&size=5.00arcmin&format=fits',
      date: '2021-09-16 11:16:46.805',
      ddec: 0.324794,
      dec: 27.12957,
      delta: 5.19590529001789,
      dra: 22.71168,
      drh: -0.165712,
      elong: 64.787,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59473.46981255787,
      mjd_stop: 59473.470159780096,
      phase: 10.8009,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210916_2b_n28023_01_0002.arch?ra=111.18489&dec=27.12957&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210916_2b_n28023_01_0002.arch',
      ra: 111.18489,
      rh: 4.853694535462,
      sangle: 100.69,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 182.4421,
      unc_a: 0.163,
      unc_b: 0.084,
      unc_theta: -1.467,
      vangle: 91.743,
      vmag: 19.924,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Sep08/703_20210908_2B_N28023_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210908_2b_n28023_01_0004.arch?ra=109.74322&dec=27.12702&size=5.00arcmin&format=fits',
      date: '2021-09-08 11:22:05.248',
      ddec: -0.02257,
      dec: 27.12702,
      delta: 5.30736214960038,
      dra: 25.00761,
      drh: -0.1224729,
      elong: 58.3043,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59465.47349824074,
      mjd_stop: 59465.47384546296,
      phase: 10.1706,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210908_2b_n28023_01_0004.arch?ra=109.74322&dec=27.12702&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210908_2b_n28023_01_0004.arch',
      ra: 109.74322,
      rh: 4.854360047846,
      sangle: 100.77600000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 181.8045,
      unc_a: 0.16,
      unc_b: 0.082,
      unc_theta: -0.809,
      vangle: 91.05399999999997,
      vmag: 19.971,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Sep08/703_20210908_2B_N28023_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210908_2b_n28023_01_0001.arch?ra=109.74045&dec=27.12701&size=5.00arcmin&format=fits',
      date: '2021-09-08 11:00:44.699',
      ddec: -0.01658,
      dec: 27.12701,
      delta: 5.30756474238732,
      dra: 25.04269,
      drh: -0.1223928,
      elong: 58.2924,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59465.45867707176,
      mjd_stop: 59465.45902429398,
      phase: 10.1693,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210908_2b_n28023_01_0001.arch?ra=109.74045&dec=27.12701&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210908_2b_n28023_01_0001.arch',
      ra: 109.74045,
      rh: 4.854361095946,
      sangle: 100.77600000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 181.8033,
      unc_a: 0.16,
      unc_b: 0.082,
      unc_theta: -0.807,
      vangle: 91.053,
      vmag: 19.971,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Sep08/703_20210908_2B_N28023_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210908_2b_n28023_01_0003.arch?ra=109.7423&dec=27.12702&size=5.00arcmin&format=fits',
      date: '2021-09-08 11:14:58.659',
      ddec: -0.02043,
      dec: 27.12702,
      delta: 5.30742962906937,
      dra: 25.01914,
      drh: -0.1224462,
      elong: 58.3004,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59465.46856086805,
      mjd_stop: 59465.468908090275,
      phase: 10.1702,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210908_2b_n28023_01_0003.arch?ra=109.7423&dec=27.12702&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210908_2b_n28023_01_0003.arch',
      ra: 109.7423,
      rh: 4.854360397075,
      sangle: 100.77600000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 181.8041,
      unc_a: 0.16,
      unc_b: 0.082,
      unc_theta: -0.808,
      vangle: 91.05399999999997,
      vmag: 19.971,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Sep08/703_20210908_2B_N28023_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210908_2b_n28023_01_0002.arch?ra=109.74137&dec=27.12702&size=5.00arcmin&format=fits',
      date: '2021-09-08 11:07:51.159',
      ddec: -0.01843,
      dec: 27.12702,
      delta: 5.30749726317967,
      dra: 25.03086,
      drh: -0.1224195,
      elong: 58.2964,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59465.46361295139,
      mjd_stop: 59465.46396017361,
      phase: 10.1698,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210908_2b_n28023_01_0002.arch?ra=109.74137&dec=27.12702&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210908_2b_n28023_01_0002.arch',
      ra: 109.74137,
      rh: 4.854360746974,
      sangle: 100.77600000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 181.8037,
      unc_a: 0.16,
      unc_b: 0.082,
      unc_theta: -0.808,
      vangle: 91.053,
      vmag: 19.971,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov05/703_20211105_2B_N28024_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211105_2b_n28024_01_0003.arch?ra=116.21277&dec=27.85261&size=5.00arcmin&format=fits',
      date: '2021-11-05 08:50:10.389',
      ddec: 4.778411,
      dec: 27.85261,
      delta: 4.42175305032148,
      dra: 1.695131,
      drh: -0.4353452,
      elong: 109.5762,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59523.3680021875,
      mjd_stop: 59523.36834940972,
      phase: 11.1215,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211105_2b_n28024_01_0003.arch?ra=116.21277&dec=27.85261&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211105_2b_n28024_01_0003.arch',
      ra: 116.21277,
      rh: 4.845032061732,
      sangle: 97.86200000000002,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 186.4303,
      unc_a: 0.191,
      unc_b: 0.098,
      unc_theta: -4.042,
      vangle: 94.33499999999998,
      vmag: 19.567,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov05/703_20211105_2B_N28024_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211105_2b_n28024_01_0001.arch?ra=116.21263&dec=27.85227&size=5.00arcmin&format=fits',
      date: '2021-11-05 08:34:40.904',
      ddec: 4.785849,
      dec: 27.85227,
      delta: 4.4219171017436,
      dra: 1.724379,
      drh: -0.4352871,
      elong: 109.5655,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59523.357244259256,
      mjd_stop: 59523.35759148148,
      phase: 11.1223,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211105_2b_n28024_01_0001.arch?ra=116.21263&dec=27.85227&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211105_2b_n28024_01_0001.arch',
      ra: 116.21263,
      rh: 4.845034766686,
      sangle: 97.86399999999998,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 186.4295,
      unc_a: 0.191,
      unc_b: 0.098,
      unc_theta: -4.042,
      vangle: 94.33499999999998,
      vmag: 19.567,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov05/703_20211105_2B_N28024_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211105_2b_n28024_01_0002.arch?ra=116.2127&dec=27.85244&size=5.00arcmin&format=fits',
      date: '2021-11-05 08:42:25.902',
      ddec: 4.782221,
      dec: 27.85244,
      delta: 4.42183501832012,
      dra: 1.709591,
      drh: -0.4353162,
      elong: 109.5708,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59523.362626180555,
      mjd_stop: 59523.36297340278,
      phase: 11.1219,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211105_2b_n28024_01_0002.arch?ra=116.2127&dec=27.85244&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211105_2b_n28024_01_0002.arch',
      ra: 116.2127,
      rh: 4.845033413511,
      sangle: 97.863,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 186.4299,
      unc_a: 0.191,
      unc_b: 0.098,
      unc_theta: -4.042,
      vangle: 94.33499999999998,
      vmag: 19.567,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Nov05/703_20211105_2B_N28024_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211105_2b_n28024_01_0004.arch?ra=116.21283&dec=27.85278&size=5.00arcmin&format=fits',
      date: '2021-11-05 08:57:55.366',
      ddec: 4.774416,
      dec: 27.85278,
      delta: 4.42167102206352,
      dra: 1.680981,
      drh: -0.4353743,
      elong: 109.5816,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59523.37338386574,
      mjd_stop: 59523.373731087966,
      phase: 11.1211,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211105_2b_n28024_01_0004.arch?ra=116.21283&dec=27.85278&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20211105_2b_n28024_01_0004.arch',
      ra: 116.21283,
      rh: 4.845030708436,
      sangle: 97.86200000000002,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 186.4307,
      unc_a: 0.191,
      unc_b: 0.098,
      unc_theta: -4.042,
      vangle: 94.33499999999998,
      vmag: 19.567,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Apr03/703_20210403_2B_N24016_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210403_2b_n24016_01_0001.arch?ra=72.54132&dec=25.79161&size=5.00arcmin&format=fits',
      date: '2021-04-03 02:47:23.640',
      ddec: 3.047316,
      dec: 25.79161,
      delta: 5.23003955768595,
      dra: 23.80959,
      drh: 0.7332978,
      elong: 61.0745,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59307.11607222222,
      mjd_stop: 59307.11641944444,
      phase: 10.4463,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210403_2b_n24016_01_0001.arch?ra=72.54132&dec=25.79161&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210403_2b_n24016_01_0001.arch',
      ra: 72.54132,
      rh: 4.826400829508,
      sangle: 261.308,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 169.1443,
      unc_a: 0.158,
      unc_b: 0.084,
      unc_theta: 15.993,
      vangle: 74.007,
      vmag: 19.916,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Apr03/703_20210403_2B_N24016_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210403_2b_n24016_01_0003.arch?ra=72.54315&dec=25.79182&size=5.00arcmin&format=fits',
      date: '2021-04-03 03:02:17.366',
      ddec: 3.040908,
      dec: 25.79182,
      delta: 5.23018971667017,
      dra: 23.83169,
      drh: 0.7332421,
      elong: 61.0659,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59307.126416273146,
      mjd_stop: 59307.12676349537,
      phase: 10.4454,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210403_2b_n24016_01_0003.arch?ra=72.54315&dec=25.79182&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210403_2b_n24016_01_0003.arch',
      ra: 72.54315,
      rh: 4.826405209834,
      sangle: 261.308,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 169.1451,
      unc_a: 0.158,
      unc_b: 0.084,
      unc_theta: 15.992,
      vangle: 74.00800000000001,
      vmag: 19.916,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Apr03/703_20210403_2B_N24016_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210403_2b_n24016_01_0004.arch?ra=72.54406&dec=25.79193&size=5.00arcmin&format=fits',
      date: '2021-04-03 03:09:44.074',
      ddec: 3.037911,
      dec: 25.79193,
      delta: 5.23026479664645,
      dra: 23.84309,
      drh: 0.7332143,
      elong: 61.0617,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59307.13158650463,
      mjd_stop: 59307.13193372685,
      phase: 10.445,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210403_2b_n24016_01_0004.arch?ra=72.54406&dec=25.79193&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210403_2b_n24016_01_0004.arch',
      ra: 72.54406,
      rh: 4.826407399112,
      sangle: 261.308,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 169.1455,
      unc_a: 0.158,
      unc_b: 0.084,
      unc_theta: 15.992,
      vangle: 74.00800000000001,
      vmag: 19.916,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Apr03/703_20210403_2B_N24016_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210403_2b_n24016_01_0002.arch?ra=72.54224&dec=25.79172&size=5.00arcmin&format=fits',
      date: '2021-04-03 02:54:50.333',
      ddec: 3.044045,
      dec: 25.79172,
      delta: 5.23011459947526,
      dra: 23.82051,
      drh: 0.7332699,
      elong: 61.0702,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59307.12124228009,
      mjd_stop: 59307.12158950231,
      phase: 10.4458,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210403_2b_n24016_01_0002.arch?ra=72.54224&dec=25.79172&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210403_2b_n24016_01_0002.arch',
      ra: 72.54224,
      rh: 4.826403018879,
      sangle: 261.308,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 169.1447,
      unc_a: 0.158,
      unc_b: 0.084,
      unc_theta: 15.993,
      vangle: 74.00800000000001,
      vmag: 19.916,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Apr09/703_20210409_2B_N24016_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210409_2b_n24016_01_0001.arch?ra=73.64858&dec=25.92193&size=5.00arcmin&format=fits',
      date: '2021-04-09 02:55:47.465',
      ddec: 3.090721,
      dec: 25.92193,
      delta: 5.3141434041248,
      dra: 25.51371,
      drh: 0.7009598,
      elong: 56.1811,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59313.12190353009,
      mjd_stop: 59313.12225075231,
      phase: 9.9231,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210409_2b_n24016_01_0001.arch?ra=73.64858&dec=25.92193&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210409_2b_n24016_01_0001.arch',
      ra: 73.64858,
      rh: 4.82888810807,
      sangle: 261.335,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 169.6279,
      unc_a: 0.156,
      unc_b: 0.083,
      unc_theta: 15.485,
      vangle: 74.493,
      vmag: 19.953,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Apr09/703_20210409_2B_N24016_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210409_2b_n24016_01_0004.arch?ra=73.65201&dec=25.92231&size=5.00arcmin&format=fits',
      date: '2021-04-09 03:21:54.580',
      ddec: 3.081677,
      dec: 25.92231,
      delta: 5.31439387440281,
      dra: 25.55483,
      drh: 0.7008621,
      elong: 56.1663,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59313.14004143519,
      mjd_stop: 59313.140388657404,
      phase: 9.9215,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210409_2b_n24016_01_0004.arch?ra=73.65201&dec=25.92231&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210409_2b_n24016_01_0004.arch',
      ra: 73.65201,
      rh: 4.828895449888,
      sangle: 261.335,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 169.6294,
      unc_a: 0.156,
      unc_b: 0.083,
      unc_theta: 15.484,
      vangle: 74.494,
      vmag: 19.953,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Apr09/703_20210409_2B_N24016_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210409_2b_n24016_01_0003.arch?ra=73.65088&dec=25.92219&size=5.00arcmin&format=fits',
      date: '2021-04-09 03:13:17.359',
      ddec: 3.08446,
      dec: 25.92219,
      delta: 5.31431118917255,
      dra: 25.541,
      drh: 0.7008944,
      elong: 56.1712,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59313.13405508102,
      mjd_stop: 59313.13440230324,
      phase: 9.922,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210409_2b_n24016_01_0003.arch?ra=73.65088&dec=25.92219&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210409_2b_n24016_01_0003.arch',
      ra: 73.65088,
      rh: 4.828893026859,
      sangle: 261.335,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 169.6289,
      unc_a: 0.156,
      unc_b: 0.083,
      unc_theta: 15.484,
      vangle: 74.494,
      vmag: 19.953,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Apr09/703_20210409_2B_N24016_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210409_2b_n24016_01_0002.arch?ra=73.64971&dec=25.92206&size=5.00arcmin&format=fits',
      date: '2021-04-09 03:04:24.610',
      ddec: 3.087536,
      dec: 25.92206,
      delta: 5.31422603995906,
      dra: 25.52701,
      drh: 0.7009276,
      elong: 56.1762,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59313.12788900463,
      mjd_stop: 59313.128236226854,
      phase: 9.9226,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210409_2b_n24016_01_0002.arch?ra=73.64971&dec=25.92206&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210409_2b_n24016_01_0002.arch',
      ra: 73.64971,
      rh: 4.828890530969,
      sangle: 261.335,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 169.6284,
      unc_a: 0.156,
      unc_b: 0.083,
      unc_theta: 15.485,
      vangle: 74.493,
      vmag: 19.953,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Apr06/703_20210406_2B_N24016_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210406_2b_n24016_01_0003.arch?ra=73.09019&dec=25.85701&size=5.00arcmin&format=fits',
      date: '2021-04-06 03:28:11.629',
      ddec: 3.06131,
      dec: 25.85701,
      delta: 5.27299464768451,
      dra: 24.73859,
      drh: 0.7169943,
      elong: 58.5962,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59310.14440542824,
      mjd_stop: 59310.14475265046,
      phase: 10.1908,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210406_2b_n24016_01_0003.arch?ra=73.09019&dec=25.85701&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210406_2b_n24016_01_0003.arch',
      ra: 73.09019,
      rh: 4.827669013032,
      sangle: 261.322,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 169.3882,
      unc_a: 0.157,
      unc_b: 0.083,
      unc_theta: 15.741,
      vangle: 74.24700000000001,
      vmag: 19.935,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Apr06/703_20210406_2B_N24016_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210406_2b_n24016_01_0002.arch?ra=73.08908&dec=25.85688&size=5.00arcmin&format=fits',
      date: '2021-04-06 03:19:27.670',
      ddec: 3.064199,
      dec: 25.85688,
      delta: 5.27290863185905,
      dra: 24.72449,
      drh: 0.717027,
      elong: 58.6012,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59310.138341087964,
      mjd_stop: 59310.13868831019,
      phase: 10.1913,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210406_2b_n24016_01_0002.arch?ra=73.08908&dec=25.85688&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210406_2b_n24016_01_0002.arch',
      ra: 73.08908,
      rh: 4.827666501944,
      sangle: 261.322,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 169.3877,
      unc_a: 0.157,
      unc_b: 0.083,
      unc_theta: 15.742,
      vangle: 74.24700000000001,
      vmag: 19.935,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Apr06/703_20210406_2B_N24016_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210406_2b_n24016_01_0001.arch?ra=73.08797&dec=25.85676&size=5.00arcmin&format=fits',
      date: '2021-04-06 03:10:44.091',
      ddec: 3.067292,
      dec: 25.85676,
      delta: 5.27282269735239,
      dra: 24.71067,
      drh: 0.7170596,
      elong: 58.6062,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59310.13228114583,
      mjd_stop: 59310.132628368054,
      phase: 10.1919,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210406_2b_n24016_01_0001.arch?ra=73.08797&dec=25.85676&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210406_2b_n24016_01_0001.arch',
      ra: 73.08797,
      rh: 4.827663992563,
      sangle: 261.322,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 169.3872,
      unc_a: 0.157,
      unc_b: 0.083,
      unc_theta: 15.743,
      vangle: 74.24600000000001,
      vmag: 19.935,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Apr06/703_20210406_2B_N24016_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210406_2b_n24016_01_0004.arch?ra=73.0913&dec=25.85714&size=5.00arcmin&format=fits',
      date: '2021-04-06 03:36:54.767',
      ddec: 3.058633,
      dec: 25.85714,
      delta: 5.27308054604778,
      dra: 24.75291,
      drh: 0.7169617,
      elong: 58.5913,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59310.1504602662,
      mjd_stop: 59310.150807488426,
      phase: 10.1903,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210406_2b_n24016_01_0004.arch?ra=73.0913&dec=25.85714&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210406_2b_n24016_01_0004.arch',
      ra: 73.0913,
      rh: 4.827671520071,
      sangle: 261.322,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 169.3887,
      unc_a: 0.157,
      unc_b: 0.083,
      unc_theta: 15.741,
      vangle: 74.24799999999999,
      vmag: 19.935,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Mar31/703_20210331_2B_N24016_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210331_2b_n24016_01_0003.arch?ra=72.02388&dec=25.7284&size=5.00arcmin&format=fits',
      date: '2021-03-31 03:43:30.030',
      ddec: 2.98409,
      dec: 25.7284,
      delta: 5.18705362103141,
      dra: 22.99323,
      drh: 0.7492343,
      elong: 63.519,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59304.155035069445,
      mjd_stop: 59304.15538229167,
      phase: 10.6791,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210331_2b_n24016_01_0003.arch?ra=72.02388&dec=25.7284&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210331_2b_n24016_01_0003.arch',
      ra: 72.02388,
      rh: 4.825133265,
      sangle: 261.293,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 168.9056,
      unc_a: 0.159,
      unc_b: 0.085,
      unc_theta: 16.23,
      vangle: 73.781,
      vmag: 19.897,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Mar31/703_20210331_2B_N24016_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210331_2b_n24016_01_0002.arch?ra=72.02313&dec=25.72831&size=5.00arcmin&format=fits',
      date: '2021-03-31 03:37:09.368',
      ddec: 2.98623,
      dec: 25.72831,
      delta: 5.18698816707076,
      dra: 22.98279,
      drh: 0.749258,
      elong: 63.5227,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59304.15062925926,
      mjd_stop: 59304.150976481484,
      phase: 10.6794,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210331_2b_n24016_01_0002.arch?ra=72.02313&dec=25.72831&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210331_2b_n24016_01_0002.arch',
      ra: 72.02313,
      rh: 4.825131358656,
      sangle: 261.293,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 168.9053,
      unc_a: 0.159,
      unc_b: 0.085,
      unc_theta: 16.23,
      vangle: 73.781,
      vmag: 19.897,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Mar31/703_20210331_2B_N24016_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210331_2b_n24016_01_0004.arch?ra=72.02463&dec=25.72849&size=5.00arcmin&format=fits',
      date: '2021-03-31 03:49:51.260',
      ddec: 2.982058,
      dec: 25.72849,
      delta: 5.18711918254802,
      dra: 23.00383,
      drh: 0.7492105,
      elong: 63.5153,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59304.159447453705,
      mjd_stop: 59304.15979467593,
      phase: 10.6787,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210331_2b_n24016_01_0004.arch?ra=72.02463&dec=25.72849&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210331_2b_n24016_01_0004.arch',
      ra: 72.02463,
      rh: 4.825135174129,
      sangle: 261.293,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 168.906,
      unc_a: 0.159,
      unc_b: 0.085,
      unc_theta: 16.23,
      vangle: 73.78200000000001,
      vmag: 19.897,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Mar31/703_20210331_2B_N24016_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210331_2b_n24016_01_0001.arch?ra=72.02238&dec=25.72822&size=5.00arcmin&format=fits',
      date: '2021-03-31 03:30:48.001',
      ddec: 2.988482,
      dec: 25.72822,
      delta: 5.18692260240027,
      dra: 22.97248,
      drh: 0.7492817,
      elong: 63.5264,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59304.14621528935,
      mjd_stop: 59304.146562511574,
      phase: 10.6797,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210331_2b_n24016_01_0001.arch?ra=72.02238&dec=25.72822&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210331_2b_n24016_01_0001.arch',
      ra: 72.02238,
      rh: 4.825129448721,
      sangle: 261.293,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 168.9049,
      unc_a: 0.159,
      unc_b: 0.085,
      unc_theta: 16.231,
      vangle: 73.781,
      vmag: 19.897,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Mar10/703_20210310_2B_N24015_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210310_2b_n24015_01_0003.arch?ra=68.98371&dec=25.32537&size=5.00arcmin&format=fits',
      date: '2021-03-10 04:28:02.589',
      ddec: 2.302641,
      dec: 25.32537,
      delta: 4.86029236792518,
      dra: 15.46014,
      drh: 0.8619515,
      elong: 81.5412,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59283.18596746528,
      mjd_stop: 59283.1863146875,
      phase: 11.7695,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210310_2b_n24015_01_0003.arch?ra=68.98371&dec=25.32537&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210310_2b_n24015_01_0003.arch',
      ra: 68.98371,
      rh: 4.815377636999,
      sangle: 261.299,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 167.2122,
      unc_a: 0.168,
      unc_b: 0.09,
      unc_theta: 17.606,
      vangle: 72.482,
      vmag: 19.748,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Mar10/703_20210310_2B_N24015_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210310_2b_n24015_01_0001.arch?ra=68.98241&dec=25.32519&size=5.00arcmin&format=fits',
      date: '2021-03-10 04:11:34.648',
      ddec: 2.309536,
      dec: 25.32519,
      delta: 4.86010457373802,
      dra: 15.43231,
      drh: 0.8620129,
      elong: 81.5515,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59283.17453296296,
      mjd_stop: 59283.174880185186,
      phase: 11.7698,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210310_2b_n24015_01_0001.arch?ra=68.98241&dec=25.32519&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210310_2b_n24015_01_0001.arch',
      ra: 68.98241,
      rh: 4.815371945028,
      sangle: 261.3,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 167.2113,
      unc_a: 0.168,
      unc_b: 0.09,
      unc_theta: 17.606,
      vangle: 72.481,
      vmag: 19.748,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Mar10/703_20210310_2B_N24015_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210310_2b_n24015_01_0004.arch?ra=68.98436&dec=25.32546&size=5.00arcmin&format=fits',
      date: '2021-03-10 04:36:16.655',
      ddec: 2.299465,
      dec: 25.32546,
      delta: 4.86038631815226,
      dra: 15.47452,
      drh: 0.8619208,
      elong: 81.536,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59283.19168582176,
      mjd_stop: 59283.19203304398,
      phase: 11.7694,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210310_2b_n24015_01_0004.arch?ra=68.98436&dec=25.32546&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210310_2b_n24015_01_0004.arch',
      ra: 68.98436,
      rh: 4.815380483383,
      sangle: 261.299,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 167.2127,
      unc_a: 0.168,
      unc_b: 0.09,
      unc_theta: 17.605,
      vangle: 72.482,
      vmag: 19.748,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Mar10/703_20210310_2B_N24015_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210310_2b_n24015_01_0002.arch?ra=68.98306&dec=25.32528&size=5.00arcmin&format=fits',
      date: '2021-03-10 04:19:48.567',
      ddec: 2.305999,
      dec: 25.32528,
      delta: 4.86019844897072,
      dra: 15.44607,
      drh: 0.8619822,
      elong: 81.5464,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59283.18024961805,
      mjd_stop: 59283.18059684028,
      phase: 11.7697,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210310_2b_n24015_01_0002.arch?ra=68.98306&dec=25.32528&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210310_2b_n24015_01_0002.arch',
      ra: 68.98306,
      rh: 4.815374790767,
      sangle: 261.299,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 167.2117,
      unc_a: 0.168,
      unc_b: 0.09,
      unc_theta: 17.606,
      vangle: 72.481,
      vmag: 19.748,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Mar07/703_20210307_2B_N24015_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210307_2b_n24015_01_0002.arch?ra=68.6496&dec=25.27721&size=5.00arcmin&format=fits',
      date: '2021-03-07 04:13:20.408',
      ddec: 2.162735,
      dec: 25.27721,
      delta: 4.81118545781972,
      dra: 14.15449,
      drh: 0.8781114,
      elong: 84.2415,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59280.17575703704,
      mjd_stop: 59280.17610425926,
      phase: 11.8338,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210307_2b_n24015_01_0002.arch?ra=68.6496&dec=25.27721&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210307_2b_n24015_01_0002.arch',
      ra: 68.6496,
      rh: 4.813865192568,
      sangle: 261.331,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 166.9686,
      unc_a: 0.17,
      unc_b: 0.091,
      unc_theta: 17.753,
      vangle: 72.34299999999999,
      vmag: 19.724,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Mar07/703_20210307_2B_N24015_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210307_2b_n24015_01_0001.arch?ra=68.649&dec=25.27713&size=5.00arcmin&format=fits',
      date: '2021-03-07 04:05:03.198',
      ddec: 2.166664,
      dec: 25.27713,
      delta: 4.81109043955929,
      dra: 14.14114,
      drh: 0.8781422,
      elong: 84.2467,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59280.17000229167,
      mjd_stop: 59280.170349513886,
      phase: 11.8338,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210307_2b_n24015_01_0001.arch?ra=68.649&dec=25.27713&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210307_2b_n24015_01_0001.arch',
      ra: 68.649,
      rh: 4.813862274266,
      sangle: 261.331,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 166.9681,
      unc_a: 0.17,
      unc_b: 0.091,
      unc_theta: 17.753,
      vangle: 72.34299999999999,
      vmag: 19.724,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Mar07/703_20210307_2B_N24015_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210307_2b_n24015_01_0003.arch?ra=68.6502&dec=25.2773&size=5.00arcmin&format=fits',
      date: '2021-03-07 04:21:37.947',
      ddec: 2.158976,
      dec: 25.2773,
      delta: 4.81128056630028,
      dra: 14.1682,
      drh: 0.8780804,
      elong: 84.2362,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59280.18151559028,
      mjd_stop: 59280.1818628125,
      phase: 11.8337,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210307_2b_n24015_01_0003.arch?ra=68.6502&dec=25.2773&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210307_2b_n24015_01_0003.arch',
      ra: 68.6502,
      rh: 4.813868112699,
      sangle: 261.331,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 166.969,
      unc_a: 0.17,
      unc_b: 0.091,
      unc_theta: 17.753,
      vangle: 72.34299999999999,
      vmag: 19.724,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Mar07/703_20210307_2B_N24015_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210307_2b_n24015_01_0004.arch?ra=68.6508&dec=25.27738&size=5.00arcmin&format=fits',
      date: '2021-03-07 04:29:55.203',
      ddec: 2.155396,
      dec: 25.27738,
      delta: 4.81137564683268,
      dra: 14.18224,
      drh: 0.8780496,
      elong: 84.231,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59280.18727086805,
      mjd_stop: 59280.18761809028,
      phase: 11.8336,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210307_2b_n24015_01_0004.arch?ra=68.6508&dec=25.27738&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210307_2b_n24015_01_0004.arch',
      ra: 68.6508,
      rh: 4.813871031065,
      sangle: 261.331,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 166.9695,
      unc_a: 0.17,
      unc_b: 0.091,
      unc_theta: 17.753,
      vangle: 72.344,
      vmag: 19.724,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Mar03/703_20210303_2B_N24015_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210303_2b_n24015_01_0004.arch?ra=68.25244&dec=25.21848&size=5.00arcmin&format=fits',
      date: '2021-03-03 04:48:34.351',
      ddec: 1.937011,
      dec: 25.21848,
      delta: 4.74597685746696,
      dra: 12.4421,
      drh: 0.8994447,
      elong: 87.8535,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59276.20022396991,
      mjd_stop: 59276.20057119213,
      phase: 11.8785,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210303_2b_n24015_01_0004.arch?ra=68.25244&dec=25.21848&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210303_2b_n24015_01_0004.arch',
      ra: 68.25244,
      rh: 4.811824694178,
      sangle: 261.389,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 166.6466,
      unc_a: 0.172,
      unc_b: 0.092,
      unc_theta: 17.926,
      vangle: 72.18100000000001,
      vmag: 19.693,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Mar03/703_20210303_2B_N24015_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210303_2b_n24015_01_0003.arch?ra=68.25196&dec=25.21842&size=5.00arcmin&format=fits',
      date: '2021-03-03 04:41:00.400',
      ddec: 1.9402,
      dec: 25.21842,
      delta: 4.74588963681414,
      dra: 12.42887,
      drh: 0.8994729,
      elong: 87.8584,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59276.19496990741,
      mjd_stop: 59276.195317129626,
      phase: 11.8786,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210303_2b_n24015_01_0003.arch?ra=68.25196&dec=25.21842&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210303_2b_n24015_01_0003.arch',
      ra: 68.25196,
      rh: 4.811821965054,
      sangle: 261.389,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 166.6461,
      unc_a: 0.172,
      unc_b: 0.092,
      unc_theta: 17.927,
      vangle: 72.18,
      vmag: 19.693,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Mar03/703_20210303_2B_N24015_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210303_2b_n24015_01_0001.arch?ra=68.25099&dec=25.21828&size=5.00arcmin&format=fits',
      date: '2021-03-03 04:25:51.631',
      ddec: 1.947037,
      dec: 25.21828,
      delta: 4.74571509470977,
      dra: 12.40323,
      drh: 0.8995293,
      elong: 87.8681,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59276.184451747686,
      mjd_stop: 59276.18479896991,
      phase: 11.8786,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210303_2b_n24015_01_0001.arch?ra=68.25099&dec=25.21828&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210303_2b_n24015_01_0001.arch',
      ra: 68.25099,
      rh: 4.811816501338,
      sangle: 261.389,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 166.6453,
      unc_a: 0.172,
      unc_b: 0.092,
      unc_theta: 17.927,
      vangle: 72.18,
      vmag: 19.693,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Mar03/703_20210303_2B_N24015_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210303_2b_n24015_01_0002.arch?ra=68.25147&dec=25.21835&size=5.00arcmin&format=fits',
      date: '2021-03-03 04:33:26.169',
      ddec: 1.943544,
      dec: 25.21835,
      delta: 4.74580238396595,
      dra: 12.41591,
      drh: 0.8995011,
      elong: 87.8632,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59276.189712604166,
      mjd_stop: 59276.19005982639,
      phase: 11.8786,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210303_2b_n24015_01_0002.arch?ra=68.25147&dec=25.21835&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210303_2b_n24015_01_0002.arch',
      ra: 68.25147,
      rh: 4.811819234162,
      sangle: 261.389,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 166.6457,
      unc_a: 0.172,
      unc_b: 0.092,
      unc_theta: 17.927,
      vangle: 72.18,
      vmag: 19.693,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Feb24/703_20210224_2B_N24015_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210224_2b_n24015_01_0001.arch?ra=67.68282&dec=25.1302&size=5.00arcmin&format=fits',
      date: '2021-02-24 05:23:54.766',
      ddec: 1.513701,
      dec: 25.1302,
      delta: 4.63125165094474,
      dra: 9.225241,
      drh: 0.9368518,
      elong: 94.3242,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59269.224765810184,
      mjd_stop: 59269.22511303241,
      phase: 11.8412,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210224_2b_n24015_01_0001.arch?ra=67.68282&dec=25.1302&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210224_2b_n24015_01_0001.arch',
      ra: 67.68282,
      rh: 4.808126124187,
      sangle: 261.544,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 166.0809,
      unc_a: 0.176,
      unc_b: 0.094,
      unc_theta: 18.167,
      vangle: 71.95400000000001,
      vmag: 19.637,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Feb24/703_20210224_2B_N24015_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210224_2b_n24015_01_0004.arch?ra=67.68391&dec=25.13037&size=5.00arcmin&format=fits',
      date: '2021-02-24 05:47:00.413',
      ddec: 1.505533,
      dec: 25.13037,
      delta: 4.63151794082599,
      dra: 9.269604,
      drh: 0.9367658,
      elong: 94.3089,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59269.24080339121,
      mjd_stop: 59269.24115061342,
      phase: 11.8415,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210224_2b_n24015_01_0004.arch?ra=67.68391&dec=25.13037&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210224_2b_n24015_01_0004.arch',
      ra: 67.68391,
      rh: 4.808134800525,
      sangle: 261.543,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 166.0822,
      unc_a: 0.176,
      unc_b: 0.094,
      unc_theta: 18.167,
      vangle: 71.95400000000001,
      vmag: 19.637,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Feb24/703_20210224_2B_N24015_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210224_2b_n24015_01_0002.arch?ra=67.68318&dec=25.13026&size=5.00arcmin&format=fits',
      date: '2021-02-24 05:31:36.388',
      ddec: 1.510805,
      dec: 25.13026,
      delta: 4.63134034481638,
      dra: 9.239776,
      drh: 0.9368231,
      elong: 94.3191,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59269.23010865741,
      mjd_stop: 59269.23045587963,
      phase: 11.8413,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210224_2b_n24015_01_0002.arch?ra=67.68318&dec=25.13026&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210224_2b_n24015_01_0002.arch',
      ra: 67.68318,
      rh: 4.808129014758,
      sangle: 261.543,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 166.0813,
      unc_a: 0.176,
      unc_b: 0.094,
      unc_theta: 18.167,
      vangle: 71.95400000000001,
      vmag: 19.637,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Feb24/703_20210224_2B_N24015_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210224_2b_n24015_01_0003.arch?ra=67.68354&dec=25.13031&size=5.00arcmin&format=fits',
      date: '2021-02-24 05:39:18.131',
      ddec: 1.508082,
      dec: 25.13031,
      delta: 4.63142908168105,
      dra: 9.254563,
      drh: 0.9367945,
      elong: 94.314,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59269.23545290509,
      mjd_stop: 59269.23580012732,
      phase: 11.8414,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210224_2b_n24015_01_0003.arch?ra=67.68354&dec=25.13031&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210224_2b_n24015_01_0003.arch',
      ra: 67.68354,
      rh: 4.808131905998,
      sangle: 261.543,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 166.0818,
      unc_a: 0.176,
      unc_b: 0.094,
      unc_theta: 18.167,
      vangle: 71.95400000000001,
      vmag: 19.637,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Feb26/703_20210226_2B_N24015_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210226_2b_n24015_01_0003.arch?ra=67.82534&dec=25.15292&size=5.00arcmin&format=fits',
      date: '2021-02-26 04:12:01.739',
      ddec: 1.669321,
      dec: 25.15292,
      delta: 4.66330224958515,
      dra: 10.06481,
      drh: 0.9263973,
      elong: 92.498,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59271.1748465162,
      mjd_stop: 59271.175193738425,
      phase: 11.8669,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210226_2b_n24015_01_0003.arch?ra=67.82534&dec=25.15292&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210226_2b_n24015_01_0003.arch',
      ra: 67.82534,
      rh: 4.809175281685,
      sangle: 261.493,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 166.2391,
      unc_a: 0.175,
      unc_b: 0.094,
      unc_theta: 18.108,
      vangle: 72.00999999999999,
      vmag: 19.653,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Feb26/703_20210226_2B_N24015_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210226_2b_n24015_01_0004.arch?ra=67.82577&dec=25.15299&size=5.00arcmin&format=fits',
      date: '2021-02-26 04:20:27.357',
      ddec: 1.66476,
      dec: 25.15299,
      delta: 4.6633993021955,
      dra: 10.07778,
      drh: 0.9263659,
      elong: 92.4925,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59271.18069857639,
      mjd_stop: 59271.181045798614,
      phase: 11.867,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210226_2b_n24015_01_0004.arch?ra=67.82577&dec=25.15299&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210226_2b_n24015_01_0004.arch',
      ra: 67.82577,
      rh: 4.809178412413,
      sangle: 261.493,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 166.2396,
      unc_a: 0.175,
      unc_b: 0.094,
      unc_theta: 18.108,
      vangle: 72.00999999999999,
      vmag: 19.653,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Feb26/703_20210226_2B_N24015_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210226_2b_n24015_01_0001.arch?ra=67.82447&dec=25.15279&size=5.00arcmin&format=fits',
      date: '2021-02-26 03:55:10.371',
      ddec: 1.678909,
      dec: 25.15279,
      delta: 4.66310822210376,
      dra: 10.04019,
      drh: 0.9264601,
      elong: 92.509,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59271.163140868055,
      mjd_stop: 59271.16348809028,
      phase: 11.8667,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210226_2b_n24015_01_0001.arch?ra=67.82447&dec=25.15279&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210226_2b_n24015_01_0001.arch',
      ra: 67.82447,
      rh: 4.809169019092,
      sangle: 261.494,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 166.2382,
      unc_a: 0.175,
      unc_b: 0.094,
      unc_theta: 18.109,
      vangle: 72.00899999999999,
      vmag: 19.653,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Feb26/703_20210226_2B_N24015_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210226_2b_n24015_01_0002.arch?ra=67.8249&dec=25.15286&size=5.00arcmin&format=fits',
      date: '2021-02-26 04:03:36.190',
      ddec: 1.674038,
      dec: 25.15286,
      delta: 4.66320524422121,
      dra: 10.05228,
      drh: 0.9264287,
      elong: 92.5035,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59271.16899525463,
      mjd_stop: 59271.16934247685,
      phase: 11.8668,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210226_2b_n24015_01_0002.arch?ra=67.8249&dec=25.15286&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210226_2b_n24015_01_0002.arch',
      ra: 67.8249,
      rh: 4.809172151277,
      sangle: 261.493,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 166.2387,
      unc_a: 0.175,
      unc_b: 0.094,
      unc_theta: 18.109,
      vangle: 72.00999999999999,
      vmag: 19.653,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Feb09/703_20210209_2B_N24015_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210209_2b_n24015_01_0002.arch?ra=67.05663&dec=25.01318&size=5.00arcmin&format=fits',
      date: '2021-02-09 04:57:03.641',
      ddec: 0.523077,
      dec: 25.01318,
      delta: 4.38875329837657,
      dra: 1.356887,
      drh: 1.0172834,
      elong: 108.8848,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59254.20611853009,
      mjd_stop: 59254.206465752315,
      phase: 11.2121,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210209_2b_n24015_01_0002.arch?ra=67.05663&dec=25.01318&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210209_2b_n24015_01_0002.arch',
      ra: 67.05663,
      rh: 4.799651727784,
      sangle: 262.165,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 164.86,
      unc_a: 0.185,
      unc_b: 0.099,
      unc_theta: 18.392,
      vangle: 71.737,
      vmag: 19.513,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Feb09/703_20210209_2B_N24015_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210209_2b_n24015_01_0001.arch?ra=67.05658&dec=25.01316&size=5.00arcmin&format=fits',
      date: '2021-02-09 04:49:28.539',
      ddec: 0.527838,
      dec: 25.01316,
      delta: 4.38867007764259,
      dra: 1.345608,
      drh: 1.0173116,
      elong: 108.8901,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59254.20085114583,
      mjd_stop: 59254.20119836806,
      phase: 11.2118,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210209_2b_n24015_01_0001.arch?ra=67.05658&dec=25.01316&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210209_2b_n24015_01_0001.arch',
      ra: 67.05658,
      rh: 4.799648633276,
      sangle: 262.166,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 164.8596,
      unc_a: 0.185,
      unc_b: 0.099,
      unc_theta: 18.392,
      vangle: 71.737,
      vmag: 19.513,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Feb09/703_20210209_2B_N24015_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210209_2b_n24015_01_0003.arch?ra=67.05668&dec=25.0132&size=5.00arcmin&format=fits',
      date: '2021-02-09 05:04:38.612',
      ddec: 0.518437,
      dec: 25.0132,
      delta: 4.38883652677108,
      dra: 1.36856,
      drh: 1.0172552,
      elong: 108.8795,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59254.21138439815,
      mjd_stop: 59254.21173162037,
      phase: 11.2125,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210209_2b_n24015_01_0003.arch?ra=67.05668&dec=25.0132&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210209_2b_n24015_01_0003.arch',
      ra: 67.05668,
      rh: 4.799654821316,
      sangle: 262.165,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 164.8604,
      unc_a: 0.185,
      unc_b: 0.099,
      unc_theta: 18.392,
      vangle: 71.737,
      vmag: 19.513,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Feb09/703_20210209_2B_N24015_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210209_2b_n24015_01_0004.arch?ra=67.05674&dec=25.01322&size=5.00arcmin&format=fits',
      date: '2021-02-09 05:12:14.025',
      ddec: 0.51392,
      dec: 25.01322,
      delta: 4.3889198669577,
      dra: 1.380632,
      drh: 1.017227,
      elong: 108.8742,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59254.21665538195,
      mjd_stop: 59254.217002604164,
      phase: 11.2129,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210209_2b_n24015_01_0004.arch?ra=67.05674&dec=25.01322&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210209_2b_n24015_01_0004.arch',
      ra: 67.05674,
      rh: 4.799657917767,
      sangle: 262.165,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 164.8608,
      unc_a: 0.185,
      unc_b: 0.099,
      unc_theta: 18.392,
      vangle: 71.737,
      vmag: 19.513,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Feb05/703_20210205_2B_N24015_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210205_2b_n24015_01_0002.arch?ra=67.03861&dec=24.99992&size=5.00arcmin&format=fits',
      date: '2021-02-05 03:41:34.296',
      ddec: 0.30764,
      dec: 24.99992,
      delta: 4.32597027193677,
      dra: -0.93838,
      drh: 1.0389604,
      elong: 112.9666,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59250.15369555556,
      mjd_stop: 59250.15404277778,
      phase: 10.9042,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210205_2b_n24015_01_0002.arch?ra=67.03861&dec=24.99992&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210205_2b_n24015_01_0002.arch',
      ra: 67.03861,
      rh: 4.797245652453,
      sangle: 262.41700000000003,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 164.5298,
      unc_a: 0.188,
      unc_b: 0.101,
      unc_theta: 18.379,
      vangle: 71.74600000000001,
      vmag: 19.48,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Feb05/703_20210205_2B_N24015_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210205_2b_n24015_01_0004.arch?ra=67.03853&dec=24.99994&size=5.00arcmin&format=fits',
      date: '2021-02-05 03:56:48.235',
      ddec: 0.296062,
      dec: 24.99994,
      delta: 4.32613253253873,
      dra: -0.92489,
      drh: 1.0389038,
      elong: 112.9558,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59250.164273553244,
      mjd_stop: 59250.16462077546,
      phase: 10.9051,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210205_2b_n24015_01_0004.arch?ra=67.03853&dec=24.99994&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210205_2b_n24015_01_0004.arch',
      ra: 67.03853,
      rh: 4.797251999043,
      sangle: 262.416,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 164.5306,
      unc_a: 0.188,
      unc_b: 0.101,
      unc_theta: 18.379,
      vangle: 71.745,
      vmag: 19.48,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Feb05/703_20210205_2B_N24015_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210205_2b_n24015_01_0003.arch?ra=67.03857&dec=24.99993&size=5.00arcmin&format=fits',
      date: '2021-02-05 03:49:11.207',
      ddec: 0.301824,
      dec: 24.99993,
      delta: 4.32605137281638,
      dra: -0.93188,
      drh: 1.0389321,
      elong: 112.9612,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59250.158983877314,
      mjd_stop: 59250.15933109954,
      phase: 10.9047,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210205_2b_n24015_01_0003.arch?ra=67.03857&dec=24.99993&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210205_2b_n24015_01_0003.arch',
      ra: 67.03857,
      rh: 4.797248825385,
      sangle: 262.41700000000003,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 164.5302,
      unc_a: 0.188,
      unc_b: 0.101,
      unc_theta: 18.379,
      vangle: 71.745,
      vmag: 19.48,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Feb05/703_20210205_2B_N24015_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210205_2b_n24015_01_0001.arch?ra=67.03864&dec=24.99991&size=5.00arcmin&format=fits',
      date: '2021-02-05 03:33:56.557',
      ddec: 0.313513,
      dec: 24.99991,
      delta: 4.32588906255729,
      dra: -0.9444,
      drh: 1.0389887,
      elong: 112.9721,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59250.14839765046,
      mjd_stop: 59250.148744872684,
      phase: 10.9038,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210205_2b_n24015_01_0001.arch?ra=67.03864&dec=24.99991&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210205_2b_n24015_01_0001.arch',
      ra: 67.03864,
      rh: 4.797242473684,
      sangle: 262.41700000000003,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 164.5293,
      unc_a: 0.188,
      unc_b: 0.101,
      unc_theta: 18.379,
      vangle: 71.74600000000001,
      vmag: 19.48,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Jan18/703_20210118_2B_N24015_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210118_2b_n24015_01_0003.arch?ra=67.75576&dec=25.02744&size=5.00arcmin&format=fits',
      date: '2021-01-18 06:11:41.133',
      ddec: -0.83462,
      dec: 25.02744,
      delta: 4.07424891208301,
      dra: -10.7936,
      drh: 1.1345421,
      elong: 131.7585,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59232.25794135417,
      mjd_stop: 59232.25828857639,
      phase: 8.8164,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210118_2b_n24015_01_0003.arch?ra=67.75576&dec=25.02744&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210118_2b_n24015_01_0003.arch',
      ra: 67.75576,
      rh: 4.786014049387,
      sangle: 264.123,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 163.0675,
      unc_a: 0.199,
      unc_b: 0.107,
      unc_theta: 17.94,
      vangle: 72.12700000000001,
      vmag: 19.34,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Jan18/703_20210118_2B_N24015_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210118_2b_n24015_01_0004.arch?ra=67.75532&dec=25.02741&size=5.00arcmin&format=fits',
      date: '2021-01-18 06:19:41.765',
      ddec: -0.84032,
      dec: 25.02741,
      delta: 4.07431953711132,
      dra: -10.7814,
      drh: 1.1345124,
      elong: 131.7524,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59232.26350422454,
      mjd_stop: 59232.26385144676,
      phase: 8.8172,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210118_2b_n24015_01_0004.arch?ra=67.75532&dec=25.02741&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210118_2b_n24015_01_0004.arch',
      ra: 67.75532,
      rh: 4.786017694158,
      sangle: 264.122,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 163.068,
      unc_a: 0.199,
      unc_b: 0.107,
      unc_theta: 17.94,
      vangle: 72.126,
      vmag: 19.34,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Jan18/703_20210118_2B_N24015_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210118_2b_n24015_01_0001.arch?ra=67.75665&dec=25.02751&size=5.00arcmin&format=fits',
      date: '2021-01-18 05:55:39.977',
      ddec: -0.82282,
      dec: 25.02751,
      delta: 4.07410779888187,
      dra: -10.8164,
      drh: 1.1346014,
      elong: 131.7708,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59232.24681686343,
      mjd_stop: 59232.247164085646,
      phase: 8.8147,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210118_2b_n24015_01_0001.arch?ra=67.75665&dec=25.02751&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210118_2b_n24015_01_0001.arch',
      ra: 67.75665,
      rh: 4.786006760376,
      sangle: 264.124,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 163.0666,
      unc_a: 0.199,
      unc_b: 0.107,
      unc_theta: 17.939,
      vangle: 72.12700000000001,
      vmag: 19.34,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Jan18/703_20210118_2B_N24015_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210118_2b_n24015_01_0002.arch?ra=67.75621&dec=25.02747&size=5.00arcmin&format=fits',
      date: '2021-01-18 06:03:40.152',
      ddec: -0.82878,
      dec: 25.02747,
      delta: 4.07417827591313,
      dra: -10.8052,
      drh: 1.1345717,
      elong: 131.7646,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59232.25237444445,
      mjd_stop: 59232.252721666664,
      phase: 8.8155,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210118_2b_n24015_01_0002.arch?ra=67.75621&dec=25.02747&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210118_2b_n24015_01_0002.arch',
      ra: 67.75621,
      rh: 4.786010401873,
      sangle: 264.124,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 163.0671,
      unc_a: 0.199,
      unc_b: 0.107,
      unc_theta: 17.94,
      vangle: 72.12700000000001,
      vmag: 19.34,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Feb01/703_20210201_2B_N24015_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210201_2b_n24015_01_0003.arch?ra=67.08364&dec=24.9943&size=5.00arcmin&format=fits',
      date: '2021-02-01 05:55:30.799',
      ddec: -0.04384,
      dec: 24.9943,
      delta: 4.26712439083513,
      dra: -2.99191,
      drh: 1.0598489,
      elong: 116.961,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59246.24671063657,
      mjd_stop: 59246.247057858796,
      phase: 10.5503,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210201_2b_n24015_01_0003.arch?ra=67.08364&dec=24.9943&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210201_2b_n24015_01_0003.arch',
      ra: 67.08364,
      rh: 4.79487790373,
      sangle: 262.7,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 164.2111,
      unc_a: 0.19,
      unc_b: 0.102,
      unc_theta: 18.336,
      vangle: 71.781,
      vmag: 19.448,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Feb01/703_20210201_2B_N24015_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210201_2b_n24015_01_0002.arch?ra=67.08376&dec=24.9943&size=5.00arcmin&format=fits',
      date: '2021-02-01 05:47:34.094',
      ddec: -0.03916,
      dec: 24.9943,
      delta: 4.26704182574362,
      dra: -3.00549,
      drh: 1.0598784,
      elong: 116.9668,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59246.24119321759,
      mjd_stop: 59246.24154043981,
      phase: 10.5497,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210201_2b_n24015_01_0002.arch?ra=67.08376&dec=24.9943&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210201_2b_n24015_01_0002.arch',
      ra: 67.08376,
      rh: 4.794874526686,
      sangle: 262.7,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 164.2106,
      unc_a: 0.19,
      unc_b: 0.102,
      unc_theta: 18.336,
      vangle: 71.781,
      vmag: 19.448,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Feb01/703_20210201_2B_N24015_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210201_2b_n24015_01_0001.arch?ra=67.08389&dec=24.9943&size=5.00arcmin&format=fits',
      date: '2021-02-01 05:39:37.279',
      ddec: -0.03433,
      dec: 24.9943,
      delta: 4.26695927550257,
      dra: -3.01866,
      drh: 1.0599079,
      elong: 116.9726,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59246.235674525466,
      mjd_stop: 59246.23602174768,
      phase: 10.5492,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210201_2b_n24015_01_0001.arch?ra=67.08389&dec=24.9943&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210201_2b_n24015_01_0001.arch',
      ra: 67.08389,
      rh: 4.794871148769,
      sangle: 262.701,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 164.2102,
      unc_a: 0.19,
      unc_b: 0.102,
      unc_theta: 18.336,
      vangle: 71.781,
      vmag: 19.448,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Feb01/703_20210201_2B_N24015_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210201_2b_n24015_01_0004.arch?ra=67.08352&dec=24.99429&size=5.00arcmin&format=fits',
      date: '2021-02-01 06:03:27.280',
      ddec: -0.04836,
      dec: 24.99429,
      delta: 4.26720695008572,
      dra: -2.97793,
      drh: 1.0598194,
      elong: 116.9553,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59246.252225462966,
      mjd_stop: 59246.25257268518,
      phase: 10.5508,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210201_2b_n24015_01_0004.arch?ra=67.08352&dec=24.99429&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210201_2b_n24015_01_0004.arch',
      ra: 67.08352,
      rh: 4.794881279093,
      sangle: 262.699,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 164.2115,
      unc_a: 0.19,
      unc_b: 0.102,
      unc_theta: 18.336,
      vangle: 71.781,
      vmag: 19.448,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Jan09/703_20210109_2B_N24015_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210109_2b_n24015_01_0001.arch?ra=68.59621&dec=25.0832&size=5.00arcmin&format=fits',
      date: '2021-01-09 07:11:24.170',
      ddec: -1.17817,
      dec: 25.0832,
      delta: 3.97002174721364,
      dra: -15.4248,
      drh: 1.1822876,
      elong: 141.622,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59223.29941168981,
      mjd_stop: 59223.299758912035,
      phase: 7.3334,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210109_2b_n24015_01_0001.arch?ra=68.59621&dec=25.0832&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210109_2b_n24015_01_0001.arch',
      ra: 68.59621,
      rh: 4.780020802139,
      sangle: 265.525,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 162.333,
      unc_a: 0.205,
      unc_b: 0.109,
      unc_theta: 17.497,
      vangle: 72.519,
      vmag: 19.279,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Jan09/703_20210109_2B_N24015_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210109_2b_n24015_01_0004.arch?ra=68.59441&dec=25.08307&size=5.00arcmin&format=fits',
      date: '2021-01-09 07:34:14.799',
      ddec: -1.19374,
      dec: 25.08307,
      delta: 3.97019233104798,
      dra: -15.3854,
      drh: 1.1822031,
      elong: 141.6041,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59223.31527545139,
      mjd_stop: 59223.31562267361,
      phase: 7.3363,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210109_2b_n24015_01_0004.arch?ra=68.59441&dec=25.08307&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210109_2b_n24015_01_0004.arch',
      ra: 68.59441,
      rh: 4.780031633304,
      sangle: 265.522,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 162.3343,
      unc_a: 0.205,
      unc_b: 0.109,
      unc_theta: 17.498,
      vangle: 72.518,
      vmag: 19.279,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Jan09/703_20210109_2B_N24015_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210109_2b_n24015_01_0003.arch?ra=68.59501&dec=25.08311&size=5.00arcmin&format=fits',
      date: '2021-01-09 07:26:38.053',
      ddec: -1.1887,
      dec: 25.08311,
      delta: 3.97013545167742,
      dra: -15.399,
      drh: 1.1822313,
      elong: 141.6101,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59223.30998903935,
      mjd_stop: 59223.31033626157,
      phase: 7.3353,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210109_2b_n24015_01_0003.arch?ra=68.59501&dec=25.08311&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210109_2b_n24015_01_0003.arch',
      ra: 68.59501,
      rh: 4.780028024031,
      sangle: 265.523,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 162.3338,
      unc_a: 0.205,
      unc_b: 0.109,
      unc_theta: 17.498,
      vangle: 72.518,
      vmag: 19.279,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Jan09/703_20210109_2B_N24015_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210109_2b_n24015_01_0002.arch?ra=68.59561&dec=25.08315&size=5.00arcmin&format=fits',
      date: '2021-01-09 07:19:01.184',
      ddec: -1.18351,
      dec: 25.08315,
      delta: 3.97007859104598,
      dra: -15.4121,
      drh: 1.1822594,
      elong: 141.616,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59223.304701203706,
      mjd_stop: 59223.30504842593,
      phase: 7.3344,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210109_2b_n24015_01_0002.arch?ra=68.59561&dec=25.08315&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210109_2b_n24015_01_0002.arch',
      ra: 68.59561,
      rh: 4.780024413702,
      sangle: 265.524,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 162.3334,
      unc_a: 0.205,
      unc_b: 0.109,
      unc_theta: 17.497,
      vangle: 72.519,
      vmag: 19.279,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Jan14/703_20210114_2B_N24015_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210114_2b_n24015_01_0002.arch?ra=68.09298&dec=25.04984&size=5.00arcmin&format=fits',
      date: '2021-01-14 06:12:39.582',
      ddec: -0.99442,
      dec: 25.04984,
      delta: 4.02554898841177,
      dra: -12.9499,
      drh: 1.1558656,
      elong: 136.1276,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59228.25861784722,
      mjd_stop: 59228.25896506944,
      phase: 8.1887,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210114_2b_n24015_01_0002.arch?ra=68.09298&dec=25.04984&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210114_2b_n24015_01_0002.arch',
      ra: 68.09298,
      rh: 4.783369037975,
      sangle: 264.68600000000004,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 162.7398,
      unc_a: 0.202,
      unc_b: 0.108,
      unc_theta: 17.759,
      vangle: 72.286,
      vmag: 19.312,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Jan14/703_20210114_2B_N24015_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210114_2b_n24015_01_0004.arch?ra=68.09193&dec=25.04976&size=5.00arcmin&format=fits',
      date: '2021-01-14 06:28:32.967',
      ddec: -1.00638,
      dec: 25.04976,
      delta: 4.02567986041796,
      dra: -12.9272,
      drh: 1.1558068,
      elong: 136.1153,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59228.26965239584,
      mjd_stop: 59228.26999961805,
      phase: 8.1905,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210114_2b_n24015_01_0004.arch?ra=68.09193&dec=25.04976&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210114_2b_n24015_01_0004.arch',
      ra: 68.09193,
      rh: 4.783376403598,
      sangle: 264.685,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 162.7407,
      unc_a: 0.202,
      unc_b: 0.108,
      unc_theta: 17.76,
      vangle: 72.286,
      vmag: 19.312,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Jan14/703_20210114_2B_N24015_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210114_2b_n24015_01_0001.arch?ra=68.0935&dec=25.04987&size=5.00arcmin&format=fits',
      date: '2021-01-14 06:04:43.493',
      ddec: -0.98826,
      dec: 25.04987,
      delta: 4.02548369700883,
      dra: -12.9604,
      drh: 1.155895,
      elong: 136.1337,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59228.25310755787,
      mjd_stop: 59228.25345478009,
      phase: 8.1878,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210114_2b_n24015_01_0001.arch?ra=68.0935&dec=25.04987&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210114_2b_n24015_01_0001.arch',
      ra: 68.0935,
      rh: 4.783365359686,
      sangle: 264.687,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 162.7394,
      unc_a: 0.202,
      unc_b: 0.108,
      unc_theta: 17.759,
      vangle: 72.287,
      vmag: 19.312,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Jan14/703_20210114_2B_N24015_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210114_2b_n24015_01_0003.arch?ra=68.09245&dec=25.0498&size=5.00arcmin&format=fits',
      date: '2021-01-14 06:20:36.282',
      ddec: -1.00046,
      dec: 25.0498,
      delta: 4.02561440504663,
      dra: -12.9388,
      drh: 1.1558362,
      elong: 136.1214,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59228.26413520833,
      mjd_stop: 59228.26448243055,
      phase: 8.1896,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210114_2b_n24015_01_0003.arch?ra=68.09245&dec=25.0498&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210114_2b_n24015_01_0003.arch',
      ra: 68.09245,
      rh: 4.783372720891,
      sangle: 264.685,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 162.7403,
      unc_a: 0.202,
      unc_b: 0.108,
      unc_theta: 17.76,
      vangle: 72.286,
      vmag: 19.312,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Jan06/703_20210106_2B_N24015_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210106_2b_n24015_01_0001.arch?ra=68.94997&dec=25.10592&size=5.00arcmin&format=fits',
      date: '2021-01-06 06:02:22.961',
      ddec: -1.17056,
      dec: 25.10592,
      delta: 3.93879056992706,
      dra: -16.9508,
      drh: 1.1985158,
      elong: 145.0383,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59220.25148103009,
      mjd_stop: 59220.251828252316,
      phase: 6.7677,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210106_2b_n24015_01_0001.arch?ra=68.94997&dec=25.10592&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210106_2b_n24015_01_0001.arch',
      ra: 68.94997,
      rh: 4.777925428457,
      sangle: 266.14,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 162.0826,
      unc_a: 0.207,
      unc_b: 0.11,
      unc_theta: 17.316,
      vangle: 72.68,
      vmag: 19.26,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Jan06/703_20210106_2B_N24015_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210106_2b_n24015_01_0002.arch?ra=68.94932&dec=25.10588&size=5.00arcmin&format=fits',
      date: '2021-01-06 06:09:57.527',
      ddec: -1.17714,
      dec: 25.10588,
      delta: 3.93884280123728,
      dra: -16.9434,
      drh: 1.1984878,
      elong: 145.0323,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59220.25674221065,
      mjd_stop: 59220.25708943287,
      phase: 6.7687,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210106_2b_n24015_01_0002.arch?ra=68.94932&dec=25.10588&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210106_2b_n24015_01_0002.arch',
      ra: 68.94932,
      rh: 4.777929069999,
      sangle: 266.139,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 162.0831,
      unc_a: 0.207,
      unc_b: 0.11,
      unc_theta: 17.317,
      vangle: 72.679,
      vmag: 19.26,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Jan06/703_20210106_2B_N24015_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210106_2b_n24015_01_0004.arch?ra=68.948&dec=25.10579&size=5.00arcmin&format=fits',
      date: '2021-01-06 06:25:06.554',
      ddec: -1.19007,
      dec: 25.10579,
      delta: 3.93894737443556,
      dra: -16.9271,
      drh: 1.1984318,
      elong: 145.0203,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59220.26726335648,
      mjd_stop: 59220.2676105787,
      phase: 6.7707,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210106_2b_n24015_01_0004.arch?ra=68.948&dec=25.10579&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210106_2b_n24015_01_0004.arch',
      ra: 68.948,
      rh: 4.777936351986,
      sangle: 266.13599999999997,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 162.0839,
      unc_a: 0.207,
      unc_b: 0.11,
      unc_theta: 17.317,
      vangle: 72.679,
      vmag: 19.26,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Jan06/703_20210106_2B_N24015_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210106_2b_n24015_01_0003.arch?ra=68.94866&dec=25.10584&size=5.00arcmin&format=fits',
      date: '2021-01-06 06:17:31.648',
      ddec: -1.18364,
      dec: 25.10584,
      delta: 3.9388950223777,
      dra: -16.9355,
      drh: 1.1984598,
      elong: 145.0263,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59220.26199824074,
      mjd_stop: 59220.262345462965,
      phase: 6.7697,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210106_2b_n24015_01_0003.arch?ra=68.94866&dec=25.10584&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210106_2b_n24015_01_0003.arch',
      ra: 68.94866,
      rh: 4.777932707891,
      sangle: 266.137,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 162.0835,
      unc_a: 0.207,
      unc_b: 0.11,
      unc_theta: 17.317,
      vangle: 72.679,
      vmag: 19.26,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Jan02/703_20210102_2B_N24015_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210102_2b_n24015_01_0004.arch?ra=69.45865&dec=25.13691&size=5.00arcmin&format=fits',
      date: '2021-01-02 06:31:29.320',
      ddec: -1.21566,
      dec: 25.13691,
      delta: 3.90157685396945,
      dra: -18.6956,
      drh: 1.2196932,
      elong: 149.5361,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59216.271693518516,
      mjd_stop: 59216.27204074074,
      phase: 5.9873,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210102_2b_n24015_01_0004.arch?ra=69.45865&dec=25.13691&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210102_2b_n24015_01_0004.arch',
      ra: 69.45865,
      rh: 4.775146425549,
      sangle: 267.098,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 161.7554,
      unc_a: 0.209,
      unc_b: 0.111,
      unc_theta: 17.06,
      vangle: 72.90799999999999,
      vmag: 19.237,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Jan02/703_20210102_2B_N24015_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210102_2b_n24015_01_0003.arch?ra=69.45938&dec=25.13695&size=5.00arcmin&format=fits',
      date: '2021-01-02 06:23:52.355',
      ddec: -1.20895,
      dec: 25.13695,
      delta: 3.90152982591851,
      dra: -18.7031,
      drh: 1.2197213,
      elong: 149.5422,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59216.26640457176,
      mjd_stop: 59216.266751793984,
      phase: 5.9862,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210102_2b_n24015_01_0003.arch?ra=69.45938&dec=25.13695&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210102_2b_n24015_01_0003.arch',
      ra: 69.45938,
      rh: 4.775142699996,
      sangle: 267.099,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 161.755,
      unc_a: 0.209,
      unc_b: 0.111,
      unc_theta: 17.06,
      vangle: 72.90799999999999,
      vmag: 19.237,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Jan02/703_20210102_2B_N24015_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210102_2b_n24015_01_0001.arch?ra=69.46084&dec=25.13704&size=5.00arcmin&format=fits',
      date: '2021-01-02 06:08:38.708',
      ddec: -1.19532,
      dec: 25.13704,
      delta: 3.9014359253413,
      dra: -18.7166,
      drh: 1.2197776,
      elong: 149.5544,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59216.255829953705,
      mjd_stop: 59216.25617717593,
      phase: 5.9841,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210102_2b_n24015_01_0001.arch?ra=69.46084&dec=25.13704&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210102_2b_n24015_01_0001.arch',
      ra: 69.46084,
      rh: 4.775135250938,
      sangle: 267.102,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 161.7541,
      unc_a: 0.209,
      unc_b: 0.111,
      unc_theta: 17.059,
      vangle: 72.90899999999999,
      vmag: 19.237,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2021/21Jan02/703_20210102_2B_N24015_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210102_2b_n24015_01_0002.arch?ra=69.46011&dec=25.137&size=5.00arcmin&format=fits',
      date: '2021-01-02 06:16:15.357',
      ddec: -1.20216,
      dec: 25.137,
      delta: 3.90148283651534,
      dra: -18.7101,
      drh: 1.2197494,
      elong: 149.5483,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59216.261115243055,
      mjd_stop: 59216.26146246528,
      phase: 5.9852,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210102_2b_n24015_01_0002.arch?ra=69.46011&dec=25.137&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20210102_2b_n24015_01_0002.arch',
      ra: 69.46011,
      rh: 4.775138974087,
      sangle: 267.101,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 161.7545,
      unc_a: 0.209,
      unc_b: 0.111,
      unc_theta: 17.059,
      vangle: 72.90799999999999,
      vmag: 19.237,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Dec24/703_20201224_2B_N24016_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201224_2b_n24016_01_0002.arch?ra=70.95387&dec=25.21429&size=5.00arcmin&format=fits',
      date: '2020-12-23 04:40:29.484',
      ddec: -0.95031,
      dec: 25.21429,
      delta: 3.82679970209213,
      dra: -22.326,
      drh: 1.2732518,
      elong: 161.075,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59206.19461208333,
      mjd_stop: 59206.194959305554,
      phase: 3.8311,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201224_2b_n24016_01_0002.arch?ra=70.95387&dec=25.21429&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201224_2b_n24016_01_0002.arch',
      ra: 70.95387,
      rh: 4.767892219694,
      sangle: 271.043,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 160.9251,
      unc_a: 0.213,
      unc_b: 0.113,
      unc_theta: 16.327,
      vangle: 73.56200000000001,
      vmag: 19.189,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Dec24/703_20201224_2B_N24016_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201224_2b_n24016_01_0004.arch?ra=70.95203&dec=25.21422&size=5.00arcmin&format=fits',
      date: '2020-12-23 04:56:39.317',
      ddec: -0.96528,
      dec: 25.21422,
      delta: 3.82686591204906,
      dra: -22.3336,
      drh: 1.2731922,
      elong: 161.0619,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59206.205837002315,
      mjd_stop: 59206.20618422454,
      phase: 3.8337,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201224_2b_n24016_01_0004.arch?ra=70.95203&dec=25.21422&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201224_2b_n24016_01_0004.arch',
      ra: 70.95203,
      rh: 4.767900473625,
      sangle: 271.03700000000003,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 160.926,
      unc_a: 0.213,
      unc_b: 0.113,
      unc_theta: 16.328,
      vangle: 73.561,
      vmag: 19.189,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Dec24/703_20201224_2B_N24016_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201224_2b_n24016_01_0003.arch?ra=70.95295&dec=25.21425&size=5.00arcmin&format=fits',
      date: '2020-12-23 04:48:34.899',
      ddec: -0.95776,
      dec: 25.21425,
      delta: 3.82683281693953,
      dra: -22.3301,
      drh: 1.273222,
      elong: 161.0684,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59206.2002303125,
      mjd_stop: 59206.20057753472,
      phase: 3.8324,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201224_2b_n24016_01_0003.arch?ra=70.95295&dec=25.21425&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201224_2b_n24016_01_0003.arch',
      ra: 70.95295,
      rh: 4.76789635095,
      sangle: 271.04,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 160.9255,
      unc_a: 0.213,
      unc_b: 0.113,
      unc_theta: 16.328,
      vangle: 73.561,
      vmag: 19.189,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Dec24/703_20201224_2B_N24016_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201224_2b_n24016_01_0001.arch?ra=70.9548&dec=25.21433&size=5.00arcmin&format=fits',
      date: '2020-12-23 04:32:24.827',
      ddec: -0.94296,
      dec: 25.21433,
      delta: 3.82676668680453,
      dra: -22.3212,
      drh: 1.2732816,
      elong: 161.0815,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59206.18900262732,
      mjd_stop: 59206.189349849534,
      phase: 3.8298,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201224_2b_n24016_01_0001.arch?ra=70.9548&dec=25.21433&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201224_2b_n24016_01_0001.arch',
      ra: 70.9548,
      rh: 4.767888094791,
      sangle: 271.046,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 160.9246,
      unc_a: 0.213,
      unc_b: 0.113,
      unc_theta: 16.327,
      vangle: 73.56200000000001,
      vmag: 19.189,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Dec21/703_20201221_2B_N24016_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201221_2b_n24016_01_0003.arch?ra=71.4317&dec=25.23429&size=5.00arcmin&format=fits',
      date: '2020-12-20 06:41:25.880',
      ddec: -0.96065,
      dec: 25.23429,
      delta: 3.810709313602,
      dra: -23.1437,
      drh: 1.2887326,
      elong: 164.4315,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59203.27859814815,
      mjd_stop: 59203.27894537037,
      phase: 3.1708,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201221_2b_n24016_01_0003.arch?ra=71.4317&dec=25.23429&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201221_2b_n24016_01_0003.arch',
      ra: 71.4317,
      rh: 4.765734918486,
      sangle: 273.077,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 160.6843,
      unc_a: 0.214,
      unc_b: 0.113,
      unc_theta: 16.098,
      vangle: 73.76599999999999,
      vmag: 19.178,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Dec21/703_20201221_2B_N24016_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201221_2b_n24016_01_0001.arch?ra=71.43365&dec=25.23437&size=5.00arcmin&format=fits',
      date: '2020-12-20 06:24:57.438',
      ddec: -0.94454,
      dec: 25.23437,
      delta: 3.81065110069181,
      dra: -23.1498,
      drh: 1.2887934,
      elong: 164.4449,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59203.26715784722,
      mjd_stop: 59203.267505069445,
      phase: 3.1681,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201221_2b_n24016_01_0001.arch?ra=71.43365&dec=25.23437&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201221_2b_n24016_01_0001.arch',
      ra: 71.43365,
      rh: 4.765726403458,
      sangle: 273.086,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 160.6834,
      unc_a: 0.214,
      unc_b: 0.113,
      unc_theta: 16.097,
      vangle: 73.767,
      vmag: 19.178,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Dec21/703_20201221_2B_N24016_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201221_2b_n24016_01_0002.arch?ra=71.43268&dec=25.23433&size=5.00arcmin&format=fits',
      date: '2020-12-20 06:33:11.713',
      ddec: -0.95261,
      dec: 25.23433,
      delta: 3.81068018418837,
      dra: -23.1471,
      drh: 1.288763,
      elong: 164.4382,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59203.27287862269,
      mjd_stop: 59203.27322584491,
      phase: 3.1694,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201221_2b_n24016_01_0002.arch?ra=71.43268&dec=25.23433&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201221_2b_n24016_01_0002.arch',
      ra: 71.43268,
      rh: 4.765730661487,
      sangle: 273.081,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 160.6838,
      unc_a: 0.214,
      unc_b: 0.113,
      unc_theta: 16.098,
      vangle: 73.767,
      vmag: 19.178,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Dec21/703_20201221_2B_N24016_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201221_2b_n24016_01_0004.arch?ra=71.43073&dec=25.23425&size=5.00arcmin&format=fits',
      date: '2020-12-20 06:49:39.751',
      ddec: -0.96865,
      dec: 25.23425,
      delta: 3.81073847761978,
      dra: -23.1396,
      drh: 1.2887023,
      elong: 164.4249,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59203.284314247685,
      mjd_stop: 59203.28466146991,
      phase: 3.1721,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201221_2b_n24016_01_0004.arch?ra=71.43073&dec=25.23425&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201221_2b_n24016_01_0004.arch',
      ra: 71.43073,
      rh: 4.765739172834,
      sangle: 273.072,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 160.6848,
      unc_a: 0.214,
      unc_b: 0.113,
      unc_theta: 16.098,
      vangle: 73.76599999999999,
      vmag: 19.178,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Dec18/703_20201218_2B_N24016_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201218_2b_n24016_01_0004.arch?ra=71.92952&dec=25.25244&size=5.00arcmin&format=fits',
      date: '2020-12-17 08:06:23.310',
      ddec: -0.91064,
      dec: 25.25244,
      delta: 3.79713837103253,
      dra: -23.7596,
      drh: 1.304338,
      elong: 167.8068,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59200.33759618056,
      mjd_stop: 59200.33794340278,
      phase: 2.4954,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201218_2b_n24016_01_0004.arch?ra=71.92952&dec=25.25244&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201218_2b_n24016_01_0004.arch',
      ra: 71.92952,
      rh: 4.763532719099,
      sangle: 276.13,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 160.4412,
      unc_a: 0.215,
      unc_b: 0.114,
      unc_theta: 15.862,
      vangle: 73.977,
      vmag: 19.168,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Dec18/703_20201218_2B_N24016_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201218_2b_n24016_01_0001.arch?ra=71.93212&dec=25.25254&size=5.00arcmin&format=fits',
      date: '2020-12-17 07:45:04.634',
      ddec: -0.89105,
      dec: 25.25254,
      delta: 3.79707574892108,
      dra: -23.7808,
      drh: 1.3044165,
      elong: 167.824,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59200.32279668981,
      mjd_stop: 59200.323143912035,
      phase: 2.4919,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201218_2b_n24016_01_0001.arch?ra=71.93212&dec=25.25254&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201218_2b_n24016_01_0001.arch',
      ra: 71.93212,
      rh: 4.76352157031,
      sangle: 276.149,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 160.44,
      unc_a: 0.215,
      unc_b: 0.114,
      unc_theta: 15.86,
      vangle: 73.97800000000001,
      vmag: 19.168,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Dec18/703_20201218_2B_N24016_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201218_2b_n24016_01_0002.arch?ra=71.93125&dec=25.25251&size=5.00arcmin&format=fits',
      date: '2020-12-17 07:52:10.969',
      ddec: -0.89766,
      dec: 25.25251,
      delta: 3.79709659146764,
      dra: -23.7742,
      drh: 1.3043903,
      elong: 167.8182,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59200.327731122685,
      mjd_stop: 59200.32807834491,
      phase: 2.4931,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201218_2b_n24016_01_0002.arch?ra=71.93125&dec=25.25251&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201218_2b_n24016_01_0002.arch',
      ra: 71.93125,
      rh: 4.763525287604,
      sangle: 276.14300000000003,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 160.4404,
      unc_a: 0.215,
      unc_b: 0.114,
      unc_theta: 15.861,
      vangle: 73.97800000000001,
      vmag: 19.168,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Dec18/703_20201218_2B_N24016_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201218_2b_n24016_01_0003.arch?ra=71.93039&dec=25.25248&size=5.00arcmin&format=fits',
      date: '2020-12-17 07:59:16.629',
      ddec: -0.90419,
      dec: 25.25248,
      delta: 3.79711743792818,
      dra: -23.7671,
      drh: 1.3043642,
      elong: 167.8125,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59200.332657743056,
      mjd_stop: 59200.33300496528,
      phase: 2.4943,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201218_2b_n24016_01_0003.arch?ra=71.93039&dec=25.25248&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201218_2b_n24016_01_0003.arch',
      ra: 71.93039,
      rh: 4.763528998938,
      sangle: 276.13599999999997,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 160.4408,
      unc_a: 0.215,
      unc_b: 0.114,
      unc_theta: 15.861,
      vangle: 73.97800000000001,
      vmag: 19.168,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Dec05/703_20201205_2B_N24016_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201205_2b_n24016_01_0002.arch?ra=74.23864&dec=25.29749&size=5.00arcmin&format=fits',
      date: '2020-12-04 07:48:29.863',
      ddec: -0.0816,
      dec: 25.29749,
      delta: 3.77013075216893,
      dra: -24.8351,
      drh: 1.3732823,
      elong: 175.664,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59187.32517202546,
      mjd_stop: 59187.325519247686,
      phase: 0.9029,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201205_2b_n24016_01_0002.arch?ra=74.23864&dec=25.29749&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201205_2b_n24016_01_0002.arch',
      ra: 74.23864,
      rh: 4.75347116881,
      sangle: 46.31100000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 159.363,
      unc_a: 0.216,
      unc_b: 0.114,
      unc_theta: 14.795,
      vangle: 74.929,
      vmag: 19.144,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Dec05/703_20201205_2B_N24016_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201205_2b_n24016_01_0003.arch?ra=74.23754&dec=25.29749&size=5.00arcmin&format=fits',
      date: '2020-12-04 07:57:08.943',
      ddec: -0.09035,
      dec: 25.29749,
      delta: 3.77013081399641,
      dra: -24.8331,
      drh: 1.3732505,
      elong: 175.6696,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59187.33117989583,
      mjd_stop: 59187.331527118055,
      phase: 0.9018,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201205_2b_n24016_01_0003.arch?ra=74.23754&dec=25.29749&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201205_2b_n24016_01_0003.arch',
      ra: 74.23754,
      rh: 4.753475933818,
      sangle: 46.25200000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 159.3635,
      unc_a: 0.216,
      unc_b: 0.114,
      unc_theta: 14.795,
      vangle: 74.929,
      vmag: 19.144,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Dec05/703_20201205_2B_N24016_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201205_2b_n24016_01_0001.arch?ra=74.23975&dec=25.2975&size=5.00arcmin&format=fits',
      date: '2020-12-04 07:39:46.202',
      ddec: -0.07274,
      dec: 25.2975,
      delta: 3.77013074861074,
      dra: -24.8364,
      drh: 1.3733144,
      elong: 175.6583,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59187.31911113426,
      mjd_stop: 59187.319458356484,
      phase: 0.9041,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201205_2b_n24016_01_0001.arch?ra=74.23975&dec=25.2975&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201205_2b_n24016_01_0001.arch',
      ra: 74.23975,
      rh: 4.753466361638,
      sangle: 46.37100000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 159.3625,
      unc_a: 0.216,
      unc_b: 0.114,
      unc_theta: 14.794,
      vangle: 74.93,
      vmag: 19.144,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Dec05/703_20201205_2B_N24016_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201205_2b_n24016_01_0004.arch?ra=74.23645&dec=25.29748&size=5.00arcmin&format=fits',
      date: '2020-12-04 08:05:44.288',
      ddec: -0.09899,
      dec: 25.29748,
      delta: 3.7701309326003,
      dra: -24.8305,
      drh: 1.3732189,
      elong: 175.6752,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59187.337144537036,
      mjd_stop: 59187.33749175926,
      phase: 0.9006,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201205_2b_n24016_01_0004.arch?ra=74.23645&dec=25.29748&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201205_2b_n24016_01_0004.arch',
      ra: 74.23645,
      rh: 4.75348066443,
      sangle: 46.19300000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 159.364,
      unc_a: 0.216,
      unc_b: 0.114,
      unc_theta: 14.796,
      vangle: 74.928,
      vmag: 19.144,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Nov27/703_20201127_2B_N24017_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201127_2b_n24017_01_0004.arch?ra=75.49342&dec=25.29104&size=5.00arcmin&format=fits',
      date: '2020-11-27 05:55:07.851',
      ddec: 0.556364,
      dec: 25.29104,
      delta: 3.7781873258411,
      dra: -24.0423,
      drh: 1.4107155,
      elong: 167.9931,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59180.246445034725,
      mjd_stop: 59180.24679225694,
      phase: 2.4834,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201127_2b_n24017_01_0004.arch?ra=75.49342&dec=25.29104&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201127_2b_n24017_01_0004.arch',
      ra: 75.49342,
      rh: 4.747780194926,
      sangle: 72.36500000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 158.7744,
      unc_a: 0.215,
      unc_b: 0.114,
      unc_theta: 14.235,
      vangle: 75.42599999999999,
      vmag: 19.144,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Nov27/703_20201127_2B_N24017_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201127_2b_n24017_01_0002.arch?ra=75.49533&dec=25.291&size=5.00arcmin&format=fits',
      date: '2020-11-27 05:39:34.360',
      ddec: 0.569749,
      dec: 25.291,
      delta: 3.77821288287165,
      dra: -24.0214,
      drh: 1.4107726,
      elong: 167.9807,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59180.23564074074,
      mjd_stop: 59180.235987962966,
      phase: 2.486,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201127_2b_n24017_01_0002.arch?ra=75.49533&dec=25.291&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201127_2b_n24017_01_0002.arch',
      ra: 75.49533,
      rh: 4.747771391761,
      sangle: 72.38,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 158.7735,
      unc_a: 0.215,
      unc_b: 0.114,
      unc_theta: 14.234,
      vangle: 75.42699999999999,
      vmag: 19.144,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Nov27/703_20201127_2B_N24017_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201127_2b_n24017_01_0001.arch?ra=75.49629&dec=25.29098&size=5.00arcmin&format=fits',
      date: '2020-11-27 05:31:47.328',
      ddec: 0.576228,
      dec: 25.29098,
      delta: 3.77822572966676,
      dra: -24.0101,
      drh: 1.4108011,
      elong: 167.9745,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59180.23023527778,
      mjd_stop: 59180.2305825,
      phase: 2.4873,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201127_2b_n24017_01_0001.arch?ra=75.49629&dec=25.29098&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201127_2b_n24017_01_0001.arch',
      ra: 75.49629,
      rh: 4.747766987342,
      sangle: 72.388,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 158.773,
      unc_a: 0.215,
      unc_b: 0.114,
      unc_theta: 14.234,
      vangle: 75.42699999999999,
      vmag: 19.144,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Nov27/703_20201127_2B_N24017_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201127_2b_n24017_01_0003.arch?ra=75.49438&dec=25.29102&size=5.00arcmin&format=fits',
      date: '2020-11-27 05:47:21.459',
      ddec: 0.563121,
      dec: 25.29102,
      delta: 3.77820007430965,
      dra: -24.0321,
      drh: 1.410744,
      elong: 167.9869,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59180.241046979165,
      mjd_stop: 59180.24139420139,
      phase: 2.4847,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201127_2b_n24017_01_0003.arch?ra=75.49438&dec=25.29102&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201127_2b_n24017_01_0003.arch',
      ra: 75.49438,
      rh: 4.747775796722,
      sangle: 72.37299999999999,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 158.7739,
      unc_a: 0.215,
      unc_b: 0.114,
      unc_theta: 14.235,
      vangle: 75.42599999999999,
      vmag: 19.144,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Dec09/703_20201209_2B_N24016_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201209_2b_n24016_01_0004.arch?ra=73.51774&dec=25.29083&size=5.00arcmin&format=fits',
      date: '2020-12-08 08:08:59.102',
      ddec: -0.38543,
      dec: 25.29083,
      delta: 3.77265873597684,
      dra: -24.8364,
      drh: 1.3520315,
      elong: 177.0109,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59191.3393993287,
      mjd_stop: 59191.339746550926,
      phase: 0.6167,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201209_2b_n24016_01_0004.arch?ra=73.51774&dec=25.29083&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201209_2b_n24016_01_0004.arch',
      ra: 73.51774,
      rh: 4.75663035817,
      sangle: 327.946,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 159.6961,
      unc_a: 0.216,
      unc_b: 0.114,
      unc_theta: 15.123,
      vangle: 74.637,
      vmag: 19.148,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Dec09/703_20201209_2B_N24016_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201209_2b_n24016_01_0001.arch?ra=73.52083&dec=25.29088&size=5.00arcmin&format=fits',
      date: '2020-12-08 07:44:41.212',
      ddec: -0.36135,
      dec: 25.29088,
      delta: 3.77263654648887,
      dra: -24.8486,
      drh: 1.3521209,
      elong: 177.0197,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59191.32252560185,
      mjd_stop: 59191.32287282407,
      phase: 0.6149,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201209_2b_n24016_01_0001.arch?ra=73.52083&dec=25.29088&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201209_2b_n24016_01_0001.arch',
      ra: 73.52083,
      rh: 4.756617181756,
      sangle: 328.294,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 159.6947,
      unc_a: 0.216,
      unc_b: 0.114,
      unc_theta: 15.121,
      vangle: 74.638,
      vmag: 19.148,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Dec09/703_20201209_2B_N24016_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201209_2b_n24016_01_0002.arch?ra=73.5198&dec=25.29087&size=5.00arcmin&format=fits',
      date: '2020-12-08 07:52:46.955',
      ddec: -0.36943,
      dec: 25.29087,
      delta: 3.77264388938905,
      dra: -24.8451,
      drh: 1.3520911,
      elong: 177.0168,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59191.32814762731,
      mjd_stop: 59191.328494849535,
      phase: 0.6155,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201209_2b_n24016_01_0002.arch?ra=73.5198&dec=25.29087&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201209_2b_n24016_01_0002.arch',
      ra: 73.5198,
      rh: 4.756621572,
      sangle: 328.178,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 159.6952,
      unc_a: 0.216,
      unc_b: 0.114,
      unc_theta: 15.122,
      vangle: 74.638,
      vmag: 19.148,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Dec09/703_20201209_2B_N24016_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201209_2b_n24016_01_0003.arch?ra=73.51877&dec=25.29085&size=5.00arcmin&format=fits',
      date: '2020-12-08 08:00:53.437',
      ddec: -0.37747,
      dec: 25.29085,
      delta: 3.77265129388491,
      dra: -24.8411,
      drh: 1.3520613,
      elong: 177.0138,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59191.33377820602,
      mjd_stop: 59191.33412542824,
      phase: 0.6161,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201209_2b_n24016_01_0003.arch?ra=73.51877&dec=25.29085&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201209_2b_n24016_01_0003.arch',
      ra: 73.51877,
      rh: 4.756625968825,
      sangle: 328.062,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 159.6957,
      unc_a: 0.216,
      unc_b: 0.114,
      unc_theta: 15.122,
      vangle: 74.637,
      vmag: 19.148,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Dec14/703_20201214_2B_N24016_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201214_2b_n24016_01_0001.arch?ra=72.63128&dec=25.27324&size=5.00arcmin&format=fits',
      date: '2020-12-13 07:29:36.024',
      ddec: -0.66424,
      dec: 25.27324,
      delta: 3.78297699988077,
      dra: -24.4541,
      drh: 1.3256846,
      elong: 172.3474,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59196.31204888889,
      mjd_stop: 59196.31239611111,
      phase: 1.5729,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201214_2b_n24016_01_0001.arch?ra=72.63128&dec=25.27324&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201214_2b_n24016_01_0001.arch',
      ra: 72.63128,
      rh: 4.760475445223,
      sangle: 284.301,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 160.1082,
      unc_a: 0.215,
      unc_b: 0.114,
      unc_theta: 15.532,
      vangle: 74.27099999999999,
      vmag: 19.157,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Dec14/703_20201214_2B_N24016_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201214_2b_n24016_01_0003.arch?ra=72.6294&dec=25.27319&size=5.00arcmin&format=fits',
      date: '2020-12-13 07:44:39.532',
      ddec: -0.67897,
      dec: 25.27319,
      delta: 3.78300756579374,
      dra: -24.4451,
      drh: 1.3256292,
      elong: 172.3357,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59196.322506157405,
      mjd_stop: 59196.32285337963,
      phase: 1.5753,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201214_2b_n24016_01_0003.arch?ra=72.6294&dec=25.27319&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201214_2b_n24016_01_0003.arch',
      ra: 72.6294,
      rh: 4.760483451496,
      sangle: 284.267,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 160.109,
      unc_a: 0.215,
      unc_b: 0.114,
      unc_theta: 15.533,
      vangle: 74.27099999999999,
      vmag: 19.157,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Dec14/703_20201214_2B_N24016_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201214_2b_n24016_01_0004.arch?ra=72.62846&dec=25.27316&size=5.00arcmin&format=fits',
      date: '2020-12-13 07:52:11.115',
      ddec: -0.68624,
      dec: 25.27316,
      delta: 3.7830229075175,
      dra: -24.4398,
      drh: 1.3256015,
      elong: 172.3299,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59196.3277328125,
      mjd_stop: 59196.32808003472,
      phase: 1.5765,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201214_2b_n24016_01_0004.arch?ra=72.62846&dec=25.27316&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201214_2b_n24016_01_0004.arch',
      ra: 72.62846,
      rh: 4.760487452991,
      sangle: 284.251,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 160.1095,
      unc_a: 0.215,
      unc_b: 0.114,
      unc_theta: 15.534,
      vangle: 74.27000000000001,
      vmag: 19.157,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Dec14/703_20201214_2B_N24016_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201214_2b_n24016_01_0002.arch?ra=72.63034&dec=25.27321&size=5.00arcmin&format=fits',
      date: '2020-12-13 07:37:07.369',
      ddec: -0.67163,
      dec: 25.27321,
      delta: 3.78299224737899,
      dra: -24.4499,
      drh: 1.3256569,
      elong: 172.3416,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59196.317272789354,
      mjd_stop: 59196.31762001158,
      phase: 1.5741,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201214_2b_n24016_01_0002.arch?ra=72.63034&dec=25.27321&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20201214_2b_n24016_01_0002.arch',
      ra: 72.63034,
      rh: 4.760479444777,
      sangle: 284.284,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 160.1086,
      unc_a: 0.215,
      unc_b: 0.114,
      unc_theta: 15.533,
      vangle: 74.27099999999999,
      vmag: 19.157,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Aug28/703_20200828_2B_N24017_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200828_2b_n24017_01_0002.arch?ra=77.98413&dec=23.74079&size=5.00arcmin&format=fits',
      date: '2020-08-28 11:17:45.321',
      ddec: 3.970804,
      dec: 23.74079,
      delta: 4.7956924159959,
      dra: 18.0345,
      drh: 1.8849872,
      elong: 76.3172,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59089.47048982639,
      mjd_stop: 59089.47083704861,
      phase: 12.1551,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200828_2b_n24017_01_0002.arch?ra=77.98413&dec=23.74079&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200828_2b_n24017_01_0002.arch',
      ra: 77.98413,
      rh: 4.661328915881,
      sangle: 85.43599999999998,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 151.086,
      unc_a: 0.161,
      unc_b: 0.087,
      unc_theta: 13.401,
      vangle: 75.73599999999999,
      vmag: 19.588,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Aug28/703_20200828_2B_N24017_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200828_2b_n24017_01_0004.arch?ra=77.98513&dec=23.74099&size=5.00arcmin&format=fits',
      date: '2020-08-28 11:28:44.658',
      ddec: 3.964619,
      dec: 23.74099,
      delta: 4.79558402139413,
      dra: 18.01869,
      drh: 1.8849479,
      elong: 76.3237,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59089.47812104167,
      mjd_stop: 59089.478468263886,
      phase: 12.1553,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200828_2b_n24017_01_0004.arch?ra=77.98513&dec=23.74099&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200828_2b_n24017_01_0004.arch',
      ra: 77.98513,
      rh: 4.66133722436,
      sangle: 85.43599999999998,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 151.0867,
      unc_a: 0.161,
      unc_b: 0.087,
      unc_theta: 13.4,
      vangle: 75.737,
      vmag: 19.588,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Aug28/703_20200828_2B_N24017_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200828_2b_n24017_01_0003.arch?ra=77.98463&dec=23.74089&size=5.00arcmin&format=fits',
      date: '2020-08-28 11:23:15.036',
      ddec: 3.967742,
      dec: 23.74089,
      delta: 4.79563820428722,
      dra: 18.02651,
      drh: 1.8849676,
      elong: 76.3204,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59089.474305972224,
      mjd_stop: 59089.47465319445,
      phase: 12.1552,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200828_2b_n24017_01_0003.arch?ra=77.98463&dec=23.74089&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200828_2b_n24017_01_0003.arch',
      ra: 77.98463,
      rh: 4.661333070728,
      sangle: 85.43599999999998,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 151.0864,
      unc_a: 0.161,
      unc_b: 0.087,
      unc_theta: 13.4,
      vangle: 75.73599999999999,
      vmag: 19.588,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Aug28/703_20200828_2B_N24017_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200828_2b_n24017_01_0001.arch?ra=77.98362&dec=23.74068&size=5.00arcmin&format=fits',
      date: '2020-08-28 11:12:15.804',
      ddec: 3.973801,
      dec: 23.74068,
      delta: 4.7957466083567,
      dra: 18.04266,
      drh: 1.8850069,
      elong: 76.3139,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59089.466675972224,
      mjd_stop: 59089.46702319445,
      phase: 12.1549,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200828_2b_n24017_01_0001.arch?ra=77.98362&dec=23.74068&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200828_2b_n24017_01_0001.arch',
      ra: 77.98362,
      rh: 4.661324763485,
      sangle: 85.43599999999998,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 151.0857,
      unc_a: 0.161,
      unc_b: 0.087,
      unc_theta: 13.401,
      vangle: 75.73599999999999,
      vmag: 19.588,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Mar16/703_20200316_2B_N15010_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200316_2b_n15010_01_0003.arch?ra=40.84794&dec=14.37555&size=5.00arcmin&format=fits',
      date: '2020-03-17 02:48:37.769',
      ddec: 11.98961,
      dec: 14.37555,
      delta: 5.07381750846277,
      dra: 30.18124,
      drh: 2.6975932,
      elong: 46.1816,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58925.11693019676,
      mjd_stop: 58925.11727741898,
      phase: 9.3003,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200316_2b_n15010_01_0003.arch?ra=40.84794&dec=14.37555&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200316_2b_n15010_01_0003.arch',
      ra: 40.84794,
      rh: 4.443224434678,
      sangle: 253.815,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 136.2579,
      unc_a: 0.136,
      unc_b: 0.075,
      unc_theta: 26.195,
      vangle: 63.887,
      vmag: 19.518,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Mar16/703_20200316_2B_N15010_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200316_2b_n15010_01_0001.arch?ra=40.84559&dec=14.37464&size=5.00arcmin&format=fits',
      date: '2020-03-17 02:32:16.889',
      ddec: 11.99256,
      dec: 14.37464,
      delta: 5.07366741904025,
      dra: 30.15434,
      drh: 2.6976464,
      elong: 46.1905,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58925.10557741898,
      mjd_stop: 58925.105924641204,
      phase: 9.3017,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200316_2b_n15010_01_0001.arch?ra=40.84559&dec=14.37464&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200316_2b_n15010_01_0001.arch',
      ra: 40.84559,
      rh: 4.443206748335,
      sangle: 253.815,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 136.2569,
      unc_a: 0.136,
      unc_b: 0.075,
      unc_theta: 26.196,
      vangle: 63.885999999999996,
      vmag: 19.518,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Mar16/703_20200316_2B_N15010_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200316_2b_n15010_01_0004.arch?ra=40.84912&dec=14.37601&size=5.00arcmin&format=fits',
      date: '2020-03-17 02:56:48.126',
      ddec: 11.98831,
      dec: 14.37601,
      delta: 5.07389256183816,
      dra: 30.195,
      drh: 2.6975667,
      elong: 46.1772,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58925.122605625,
      mjd_stop: 58925.122952847225,
      phase: 9.2996,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200316_2b_n15010_01_0004.arch?ra=40.84912&dec=14.37601&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200316_2b_n15010_01_0004.arch',
      ra: 40.84912,
      rh: 4.443233276222,
      sangle: 253.816,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 136.2585,
      unc_a: 0.136,
      unc_b: 0.075,
      unc_theta: 26.195,
      vangle: 63.887,
      vmag: 19.518,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Mar16/703_20200316_2B_N15010_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200316_2b_n15010_01_0002.arch?ra=40.84676&dec=14.3751&size=5.00arcmin&format=fits',
      date: '2020-03-17 02:40:27.624',
      ddec: 11.99103,
      dec: 14.3751,
      delta: 5.07374250128305,
      dra: 30.16769,
      drh: 2.6976198,
      elong: 46.1861,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58925.11125722222,
      mjd_stop: 58925.11160444444,
      phase: 9.301,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200316_2b_n15010_01_0002.arch?ra=40.84676&dec=14.3751&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200316_2b_n15010_01_0002.arch',
      ra: 40.84676,
      rh: 4.443215596869,
      sangle: 253.815,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 136.2574,
      unc_a: 0.136,
      unc_b: 0.075,
      unc_theta: 26.195,
      vangle: 63.887,
      vmag: 19.518,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Mar05/703_20200305_2B_N15009_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200305_2b_n15009_01_0001.arch?ra=38.64506&dec=13.49334&size=5.00arcmin&format=fits',
      date: '2020-03-06 02:30:45.320',
      ddec: 11.7765,
      dec: 13.49334,
      delta: 4.92189044386189,
      dra: 27.61782,
      drh: 2.7488749,
      elong: 54.8658,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58914.10451759259,
      mjd_stop: 58914.10486481481,
      phase: 10.5634,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200305_2b_n15009_01_0001.arch?ra=38.64506&dec=13.49334&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200305_2b_n15009_01_0001.arch',
      ra: 38.64506,
      rh: 4.42590523324,
      sangle: 253.002,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 135.2076,
      unc_a: 0.139,
      unc_b: 0.077,
      unc_theta: 26.77,
      vangle: 63.399,
      vmag: 19.436,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Mar05/703_20200305_2B_N15009_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200305_2b_n15009_01_0004.arch?ra=38.64834&dec=13.49471&size=5.00arcmin&format=fits',
      date: '2020-03-06 02:55:42.187',
      ddec: 11.77127,
      dec: 13.49471,
      delta: 4.92214531461102,
      dra: 27.65774,
      drh: 2.7487946,
      elong: 54.8518,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58914.12184244213,
      mjd_stop: 58914.122189664355,
      phase: 10.5616,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200305_2b_n15009_01_0004.arch?ra=38.64834&dec=13.49471&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200305_2b_n15009_01_0004.arch',
      ra: 38.64834,
      rh: 4.425932735571,
      sangle: 253.003,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 135.2092,
      unc_a: 0.139,
      unc_b: 0.077,
      unc_theta: 26.77,
      vangle: 63.400000000000006,
      vmag: 19.436,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Mar05/703_20200305_2B_N15009_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200305_2b_n15009_01_0002.arch?ra=38.64615&dec=13.4938&size=5.00arcmin&format=fits',
      date: '2020-03-06 02:39:04.122',
      ddec: 11.77466,
      dec: 13.4938,
      delta: 4.92197535237046,
      dra: 27.63082,
      drh: 2.7488481,
      elong: 54.8611,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58914.11029076389,
      mjd_stop: 58914.11063798611,
      phase: 10.5628,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200305_2b_n15009_01_0002.arch?ra=38.64615&dec=13.4938&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200305_2b_n15009_01_0002.arch',
      ra: 38.64615,
      rh: 4.42591439795,
      sangle: 253.002,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 135.2081,
      unc_a: 0.139,
      unc_b: 0.077,
      unc_theta: 26.77,
      vangle: 63.399,
      vmag: 19.436,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Mar05/703_20200305_2B_N15009_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200305_2b_n15009_01_0003.arch?ra=38.64724&dec=13.49425&size=5.00arcmin&format=fits',
      date: '2020-03-06 02:47:23.233',
      ddec: 11.77291,
      dec: 13.49425,
      delta: 4.92206033619365,
      dra: 27.64414,
      drh: 2.7488214,
      elong: 54.8565,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58914.116067511575,
      mjd_stop: 58914.1164147338,
      phase: 10.5622,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200305_2b_n15009_01_0003.arch?ra=38.64724&dec=13.49425&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200305_2b_n15009_01_0003.arch',
      ra: 38.64724,
      rh: 4.425923568248,
      sangle: 253.002,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 135.2087,
      unc_a: 0.139,
      unc_b: 0.077,
      unc_theta: 26.77,
      vangle: 63.400000000000006,
      vmag: 19.436,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Feb26/703_20200226_2B_N10009_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200226_2b_n10009_01_0001.arch?ra=37.0054&dec=12.79187&size=5.00arcmin&format=fits',
      date: '2020-02-26 02:54:25.863',
      ddec: 11.41111,
      dec: 12.79187,
      delta: 4.78646289015545,
      dra: 25.16553,
      drh: 2.7903262,
      elong: 62.1434,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58905.1209590625,
      mjd_stop: 58905.121306284724,
      phase: 11.443,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200226_2b_n10009_01_0001.arch?ra=37.0054&dec=12.79187&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200226_2b_n10009_01_0001.arch',
      ra: 37.0054,
      rh: 4.411536429004,
      sangle: 252.39,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 134.3444,
      unc_a: 0.141,
      unc_b: 0.079,
      unc_theta: 27.168,
      vangle: 63.06,
      vmag: 19.363,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Feb26/703_20200226_2B_N10009_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200226_2b_n10009_01_0004.arch?ra=37.00809&dec=12.79306&size=5.00arcmin&format=fits',
      date: '2020-02-26 03:16:58.326',
      ddec: 11.40663,
      dec: 12.79306,
      delta: 4.78670968674624,
      dra: 25.20261,
      drh: 2.7902543,
      elong: 62.1304,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58905.13661256945,
      mjd_stop: 58905.136959791664,
      phase: 11.4417,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200226_2b_n10009_01_0004.arch?ra=37.00809&dec=12.79306&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200226_2b_n10009_01_0004.arch',
      ra: 37.00809,
      rh: 4.411561652762,
      sangle: 252.39100000000002,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 134.3459,
      unc_a: 0.141,
      unc_b: 0.079,
      unc_theta: 27.167,
      vangle: 63.06100000000001,
      vmag: 19.363,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Feb26/703_20200226_2B_N10009_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200226_2b_n10009_01_0003.arch?ra=37.00719&dec=12.79266&size=5.00arcmin&format=fits',
      date: '2020-02-26 03:09:27.777',
      ddec: 11.40804,
      dec: 12.79266,
      delta: 4.78662745156864,
      dra: 25.19,
      drh: 2.7902783,
      elong: 62.1347,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58905.131397881945,
      mjd_stop: 58905.13174510417,
      phase: 11.4421,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200226_2b_n10009_01_0003.arch?ra=37.00719&dec=12.79266&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200226_2b_n10009_01_0003.arch',
      ra: 37.00719,
      rh: 4.411553249988,
      sangle: 252.39,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 134.3454,
      unc_a: 0.141,
      unc_b: 0.079,
      unc_theta: 27.167,
      vangle: 63.06100000000001,
      vmag: 19.363,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Feb26/703_20200226_2B_N10009_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200226_2b_n10009_01_0002.arch?ra=37.0063&dec=12.79227&size=5.00arcmin&format=fits',
      date: '2020-02-26 03:01:56.904',
      ddec: 11.40954,
      dec: 12.79227,
      delta: 4.78654517620414,
      dra: 25.17764,
      drh: 2.7903023,
      elong: 62.139,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58905.12617944444,
      mjd_stop: 58905.12652666667,
      phase: 11.4426,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200226_2b_n10009_01_0002.arch?ra=37.0063&dec=12.79227&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200226_2b_n10009_01_0002.arch',
      ra: 37.0063,
      rh: 4.411544841099,
      sangle: 252.39,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 134.3449,
      unc_a: 0.141,
      unc_b: 0.079,
      unc_theta: 27.168,
      vangle: 63.06100000000001,
      vmag: 19.363,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Feb19/703_20200219_2B_N10009_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200219_2b_n10009_01_0002.arch?ra=35.84348&dec=12.2646&size=5.00arcmin&format=fits',
      date: '2020-02-19 03:04:16.098',
      ddec: 10.99788,
      dec: 12.2646,
      delta: 4.67499915995043,
      dra: 22.94336,
      drh: 2.8223468,
      elong: 67.9447,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58898.12779048611,
      mjd_stop: 58898.128137708336,
      phase: 12.0154,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200219_2b_n10009_01_0002.arch?ra=35.84348&dec=12.2646&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200219_2b_n10009_01_0002.arch',
      ra: 35.84348,
      rh: 4.400202906553,
      sangle: 251.939,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 133.6685,
      unc_a: 0.144,
      unc_b: 0.08,
      unc_theta: 27.428,
      vangle: 62.83500000000001,
      vmag: 19.301,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Feb19/703_20200219_2B_N10009_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200219_2b_n10009_01_0001.arch?ra=35.8426&dec=12.26418&size=5.00arcmin&format=fits',
      date: '2020-02-19 02:56:06.153',
      ddec: 10.99968,
      dec: 12.26418,
      delta: 4.67490601119988,
      dra: 22.93061,
      drh: 2.8223727,
      elong: 67.9495,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58898.12211982639,
      mjd_stop: 58898.12246704861,
      phase: 12.0159,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200219_2b_n10009_01_0001.arch?ra=35.8426&dec=12.26418&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200219_2b_n10009_01_0001.arch',
      ra: 35.8426,
      rh: 4.400193663976,
      sangle: 251.939,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 133.668,
      unc_a: 0.144,
      unc_b: 0.08,
      unc_theta: 27.429,
      vangle: 62.83500000000001,
      vmag: 19.301,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Feb19/703_20200219_2B_N10009_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200219_2b_n10009_01_0003.arch?ra=35.84437&dec=12.26501&size=5.00arcmin&format=fits',
      date: '2020-02-19 03:12:25.850',
      ddec: 10.99616,
      dec: 12.26501,
      delta: 4.67509229939929,
      dra: 22.95646,
      drh: 2.822321,
      elong: 67.9399,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58898.13345891204,
      mjd_stop: 58898.13380613426,
      phase: 12.015,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200219_2b_n10009_01_0003.arch?ra=35.84437&dec=12.26501&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200219_2b_n10009_01_0003.arch',
      ra: 35.84437,
      rh: 4.400212145404,
      sangle: 251.94,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 133.6691,
      unc_a: 0.144,
      unc_b: 0.08,
      unc_theta: 27.428,
      vangle: 62.83500000000001,
      vmag: 19.301,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Feb19/703_20200219_2B_N10009_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200219_2b_n10009_01_0004.arch?ra=35.84526&dec=12.26543&size=5.00arcmin&format=fits',
      date: '2020-02-19 03:20:36.126',
      ddec: 10.99453,
      dec: 12.26543,
      delta: 4.67518556463031,
      dra: 22.96992,
      drh: 2.8222951,
      elong: 67.9351,
      exposure: 29.999,
      filter: null,
      maglimit: null,
      mjd_start: 58898.13913340278,
      mjd_stop: 58898.13948061343,
      phase: 12.0146,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200219_2b_n10009_01_0004.arch?ra=35.84526&dec=12.26543&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200219_2b_n10009_01_0004.arch',
      ra: 35.84526,
      rh: 4.400221394046,
      sangle: 251.94,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 133.6696,
      unc_a: 0.144,
      unc_b: 0.08,
      unc_theta: 27.428,
      vangle: 62.83500000000001,
      vmag: 19.301,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Feb16/703_20200216_2B_N10009_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200216_2b_n10009_01_0001.arch?ra=35.37801&dec=12.04443&size=5.00arcmin&format=fits',
      date: '2020-02-16 02:54:26.548',
      ddec: 10.78989,
      dec: 12.04443,
      delta: 4.62571710226033,
      dra: 21.89141,
      drh: 2.8360465,
      elong: 70.4777,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58895.12096699074,
      mjd_stop: 58895.12131421296,
      phase: 12.2277,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200216_2b_n10009_01_0001.arch?ra=35.37801&dec=12.04443&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200216_2b_n10009_01_0001.arch',
      ra: 35.37801,
      rh: 4.395290222811,
      sangle: 251.752,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 133.3769,
      unc_a: 0.145,
      unc_b: 0.081,
      unc_theta: 27.527,
      vangle: 62.74799999999999,
      vmag: 19.274,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Feb16/703_20200216_2B_N10009_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200216_2b_n10009_01_0002.arch?ra=35.37887&dec=12.04484&size=5.00arcmin&format=fits',
      date: '2020-02-16 03:02:43.799',
      ddec: 10.788,
      dec: 12.04484,
      delta: 4.62581299957891,
      dra: 21.90401,
      drh: 2.8360204,
      elong: 70.4728,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58895.12672221065,
      mjd_stop: 58895.12706943287,
      phase: 12.2273,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200216_2b_n10009_01_0002.arch?ra=35.37887&dec=12.04484&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200216_2b_n10009_01_0002.arch',
      ra: 35.37887,
      rh: 4.395299648645,
      sangle: 251.752,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 133.3774,
      unc_a: 0.145,
      unc_b: 0.081,
      unc_theta: 27.527,
      vangle: 62.748999999999995,
      vmag: 19.274,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Feb16/703_20200216_2B_N10009_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200216_2b_n10009_01_0003.arch?ra=35.37973&dec=12.04526&size=5.00arcmin&format=fits',
      date: '2020-02-16 03:11:01.424',
      ddec: 10.78619,
      dec: 12.04526,
      delta: 4.62590899932246,
      dra: 21.91702,
      drh: 2.8359942,
      elong: 70.4679,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58895.13248175926,
      mjd_stop: 58895.132828981485,
      phase: 12.2269,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200216_2b_n10009_01_0003.arch?ra=35.37973&dec=12.04526&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200216_2b_n10009_01_0003.arch',
      ra: 35.37973,
      rh: 4.395309081481,
      sangle: 251.753,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 133.378,
      unc_a: 0.145,
      unc_b: 0.081,
      unc_theta: 27.527,
      vangle: 62.748999999999995,
      vmag: 19.274,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Feb16/703_20200216_2B_N10009_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200216_2b_n10009_01_0004.arch?ra=35.38059&dec=12.04567&size=5.00arcmin&format=fits',
      date: '2020-02-16 03:19:17.617',
      ddec: 10.78447,
      dec: 12.04567,
      delta: 4.62600475177398,
      dra: 21.93036,
      drh: 2.835968,
      elong: 70.463,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58895.1382247338,
      mjd_stop: 58895.13857195602,
      phase: 12.2265,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200216_2b_n10009_01_0004.arch?ra=35.38059&dec=12.04567&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200216_2b_n10009_01_0004.arch',
      ra: 35.38059,
      rh: 4.395318487086,
      sangle: 251.753,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 133.3785,
      unc_a: 0.145,
      unc_b: 0.081,
      unc_theta: 27.526,
      vangle: 62.748999999999995,
      vmag: 19.274,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Feb05/703_20200205_2B_N10008_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200205_2b_n10008_01_0003.arch?ra=33.87244&dec=11.28332&size=5.00arcmin&format=fits',
      date: '2020-02-05 03:57:40.527',
      ddec: 9.834557,
      dec: 11.28332,
      delta: 4.4409108540736,
      dra: 17.80868,
      drh: 2.8856044,
      elong: 79.9166,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58884.16487878472,
      mjd_stop: 58884.165226006946,
      phase: 12.8093,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200205_2b_n10008_01_0003.arch?ra=33.87244&dec=11.28332&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200205_2b_n10008_01_0003.arch',
      ra: 33.87244,
      rh: 4.377189322268,
      sangle: 251.089,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 132.3086,
      unc_a: 0.149,
      unc_b: 0.084,
      unc_theta: 27.813,
      vangle: 62.48599999999999,
      vmag: 19.168,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Feb05/703_20200205_2B_N10008_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200205_2b_n10008_01_0001.arch?ra=33.87116&dec=11.28263&size=5.00arcmin&format=fits',
      date: '2020-02-05 03:42:30.248',
      ddec: 9.83739,
      dec: 11.28263,
      delta: 4.44072824100738,
      dra: 17.78292,
      drh: 2.8856518,
      elong: 79.9259,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58884.15434314815,
      mjd_stop: 58884.15469037037,
      phase: 12.8097,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200205_2b_n10008_01_0001.arch?ra=33.87116&dec=11.28263&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200205_2b_n10008_01_0001.arch',
      ra: 33.87116,
      rh: 4.377171765444,
      sangle: 251.088,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 132.3076,
      unc_a: 0.149,
      unc_b: 0.084,
      unc_theta: 27.813,
      vangle: 62.48599999999999,
      vmag: 19.168,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Feb05/703_20200205_2B_N10008_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200205_2b_n10008_01_0002.arch?ra=33.8718&dec=11.28297&size=5.00arcmin&format=fits',
      date: '2020-02-05 03:50:05.415',
      ddec: 9.835938,
      dec: 11.28297,
      delta: 4.44081954051278,
      dra: 17.79564,
      drh: 2.8856281,
      elong: 79.9212,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58884.159611284726,
      mjd_stop: 58884.15995850694,
      phase: 12.8095,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200205_2b_n10008_01_0002.arch?ra=33.8718&dec=11.28297&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200205_2b_n10008_01_0002.arch',
      ra: 33.8718,
      rh: 4.377180544423,
      sangle: 251.088,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 132.3081,
      unc_a: 0.149,
      unc_b: 0.084,
      unc_theta: 27.813,
      vangle: 62.48599999999999,
      vmag: 19.168,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Feb05/703_20200205_2B_N10008_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200205_2b_n10008_01_0004.arch?ra=33.87308&dec=11.28367&size=5.00arcmin&format=fits',
      date: '2020-02-05 04:05:15.825',
      ddec: 9.833247,
      dec: 11.28367,
      delta: 4.44100222908847,
      dra: 17.82204,
      drh: 2.8855807,
      elong: 79.9119,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58884.1701484375,
      mjd_stop: 58884.170495659724,
      phase: 12.8091,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200205_2b_n10008_01_0004.arch?ra=33.87308&dec=11.28367&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200205_2b_n10008_01_0004.arch',
      ra: 33.87308,
      rh: 4.377198103629,
      sangle: 251.089,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 132.3091,
      unc_a: 0.149,
      unc_b: 0.084,
      unc_theta: 27.813,
      vangle: 62.48599999999999,
      vmag: 19.168,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Jan27/703_20200127_2B_N10008_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200127_2b_n10008_01_0004.arch?ra=32.88441&dec=10.71811&size=5.00arcmin&format=fits',
      date: '2020-01-27 04:09:01.233',
      ddec: 8.826739,
      dec: 10.71811,
      delta: 4.28516881291618,
      dra: 13.87714,
      drh: 2.9258329,
      elong: 87.9394,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58875.17275732639,
      mjd_stop: 58875.17310454861,
      phase: 13.0347,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200127_2b_n10008_01_0004.arch?ra=32.88441&dec=10.71811&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200127_2b_n10008_01_0004.arch',
      ra: 32.88441,
      rh: 4.362100138289,
      sangle: 250.55599999999998,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 131.4254,
      unc_a: 0.154,
      unc_b: 0.086,
      unc_theta: 27.961,
      vangle: 62.33099999999999,
      vmag: 19.077,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Jan27/703_20200127_2B_N10008_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200127_2b_n10008_01_0001.arch?ra=32.8828&dec=10.71711&size=5.00arcmin&format=fits',
      date: '2020-01-27 03:44:25.797',
      ddec: 8.831513,
      dec: 10.71711,
      delta: 4.28486882298114,
      dra: 13.83698,
      drh: 2.9259089,
      elong: 87.955,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58875.15568052083,
      mjd_stop: 58875.156027743054,
      phase: 13.0348,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200127_2b_n10008_01_0001.arch?ra=32.8828&dec=10.71711&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200127_2b_n10008_01_0001.arch',
      ra: 32.8828,
      rh: 4.362071284288,
      sangle: 250.555,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 131.4237,
      unc_a: 0.154,
      unc_b: 0.086,
      unc_theta: 27.961,
      vangle: 62.33099999999999,
      vmag: 19.077,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Jan27/703_20200127_2B_N10008_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200127_2b_n10008_01_0003.arch?ra=32.88387&dec=10.71778&size=5.00arcmin&format=fits',
      date: '2020-01-27 04:00:49.208',
      ddec: 8.828259,
      dec: 10.71778,
      delta: 4.28506873924923,
      dra: 13.86331,
      drh: 2.9258582,
      elong: 87.9446,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58875.16706259259,
      mjd_stop: 58875.167409814814,
      phase: 13.0347,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200127_2b_n10008_01_0003.arch?ra=32.88387&dec=10.71778&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200127_2b_n10008_01_0003.arch',
      ra: 32.88387,
      rh: 4.362090516206,
      sangle: 250.555,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 131.4249,
      unc_a: 0.154,
      unc_b: 0.086,
      unc_theta: 27.961,
      vangle: 62.33099999999999,
      vmag: 19.077,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Jan27/703_20200127_2B_N10008_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200127_2b_n10008_01_0002.arch?ra=32.88334&dec=10.71744&size=5.00arcmin&format=fits',
      date: '2020-01-27 03:52:37.408',
      ddec: 8.829851,
      dec: 10.71744,
      delta: 4.28496874470314,
      dra: 13.84992,
      drh: 2.9258836,
      elong: 87.9498,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58875.16137046296,
      mjd_stop: 58875.16171768519,
      phase: 13.0348,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200127_2b_n10008_01_0002.arch?ra=32.88334&dec=10.71744&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200127_2b_n10008_01_0002.arch',
      ra: 32.88334,
      rh: 4.362080898441,
      sangle: 250.555,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 131.4243,
      unc_a: 0.154,
      unc_b: 0.086,
      unc_theta: 27.961,
      vangle: 62.33099999999999,
      vmag: 19.077,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Jan23/703_20200123_2B_N10008_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200123_2b_n10008_01_0004.arch?ra=32.52324&dec=10.48659&size=5.00arcmin&format=fits',
      date: '2020-01-23 03:41:30.746',
      ddec: 8.315492,
      dec: 10.48659,
      delta: 4.21511127458093,
      dra: 11.92171,
      drh: 2.9436775,
      elong: 91.6131,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58871.15365446759,
      mjd_stop: 58871.154001689814,
      phase: 13.0527,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200123_2b_n10008_01_0004.arch?ra=32.52324&dec=10.48659&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200123_2b_n10008_01_0004.arch',
      ra: 32.52324,
      rh: 4.35528857748,
      sangle: 250.316,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 131.0287,
      unc_a: 0.156,
      unc_b: 0.087,
      unc_theta: 28.001,
      vangle: 62.28,
      vmag: 19.035,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Jan23/703_20200123_2B_N10008_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200123_2b_n10008_01_0002.arch?ra=32.52232&dec=10.48596&size=5.00arcmin&format=fits',
      date: '2020-01-23 03:25:10.922',
      ddec: 8.319119,
      dec: 10.48596,
      delta: 4.2149122936964,
      dra: 11.89879,
      drh: 2.9437277,
      elong: 91.6237,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58871.14231391204,
      mjd_stop: 58871.14266113426,
      phase: 13.0527,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200123_2b_n10008_01_0002.arch?ra=32.52232&dec=10.48596&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200123_2b_n10008_01_0002.arch',
      ra: 32.52232,
      rh: 4.35526929901,
      sangle: 250.316,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 131.0276,
      unc_a: 0.156,
      unc_b: 0.087,
      unc_theta: 28.002,
      vangle: 62.28,
      vmag: 19.035,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Jan23/703_20200123_2B_N10008_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200123_2b_n10008_01_0001.arch?ra=32.52186&dec=10.48564&size=5.00arcmin&format=fits',
      date: '2020-01-23 03:17:00.388',
      ddec: 8.321016,
      dec: 10.48564,
      delta: 4.21481273581353,
      dra: 11.88808,
      drh: 2.9437528,
      elong: 91.629,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58871.136636435185,
      mjd_stop: 58871.13698365741,
      phase: 13.0526,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200123_2b_n10008_01_0001.arch?ra=32.52186&dec=10.48564&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200123_2b_n10008_01_0001.arch',
      ra: 32.52186,
      rh: 4.355259647412,
      sangle: 250.315,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 131.0271,
      unc_a: 0.156,
      unc_b: 0.087,
      unc_theta: 28.002,
      vangle: 62.28,
      vmag: 19.035,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Jan23/703_20200123_2B_N10008_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200123_2b_n10008_01_0003.arch?ra=32.52278&dec=10.48627&size=5.00arcmin&format=fits',
      date: '2020-01-23 03:33:20.794',
      ddec: 8.317277,
      dec: 10.48627,
      delta: 4.21501175670091,
      dra: 11.91,
      drh: 2.9437026,
      elong: 91.6184,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58871.14798372685,
      mjd_stop: 58871.14833094907,
      phase: 13.0527,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200123_2b_n10008_01_0003.arch?ra=32.52278&dec=10.48627&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200123_2b_n10008_01_0003.arch',
      ra: 32.52278,
      rh: 4.355278937499,
      sangle: 250.316,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 131.0282,
      unc_a: 0.156,
      unc_b: 0.087,
      unc_theta: 28.002,
      vangle: 62.28,
      vmag: 19.035,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Jan18/703_20200118_2B_N10008_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200118_2b_n10008_01_0002.arch?ra=32.1498&dec=10.22033&size=5.00arcmin&format=fits',
      date: '2020-01-18 04:16:53.253',
      ddec: 7.60831,
      dec: 10.22033,
      delta: 4.1285701584749,
      dra: 9.469177,
      drh: 2.9656487,
      elong: 96.238,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58866.17822052084,
      mjd_stop: 58866.17856774305,
      phase: 12.9989,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200118_2b_n10008_01_0002.arch?ra=32.1498&dec=10.22033&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200118_2b_n10008_01_0002.arch',
      ra: 32.1498,
      rh: 4.346799016259,
      sangle: 250.015,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 130.536,
      unc_a: 0.159,
      unc_b: 0.089,
      unc_theta: 28.029,
      vangle: 62.230999999999995,
      vmag: 18.982,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Jan18/703_20200118_2B_N10008_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200118_2b_n10008_01_0003.arch?ra=32.15017&dec=10.22062&size=5.00arcmin&format=fits',
      date: '2020-01-18 04:25:07.595',
      ddec: 7.606784,
      dec: 10.22062,
      delta: 4.12867018537194,
      dra: 9.482852,
      drh: 2.9656235,
      elong: 96.2326,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58866.18394207176,
      mjd_stop: 58866.18428929398,
      phase: 12.999,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200118_2b_n10008_01_0003.arch?ra=32.15017&dec=10.22062&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200118_2b_n10008_01_0003.arch',
      ra: 32.15017,
      rh: 4.346808815131,
      sangle: 250.015,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 130.5366,
      unc_a: 0.159,
      unc_b: 0.089,
      unc_theta: 28.029,
      vangle: 62.230999999999995,
      vmag: 18.982,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Jan18/703_20200118_2B_N10008_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200118_2b_n10008_01_0001.arch?ra=32.14943&dec=10.22003&size=5.00arcmin&format=fits',
      date: '2020-01-18 04:08:38.748',
      ddec: 7.609904,
      dec: 10.22003,
      delta: 4.1284701357723,
      dra: 9.455981,
      drh: 2.9656739,
      elong: 96.2434,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58866.17249708333,
      mjd_stop: 58866.172844305554,
      phase: 12.9988,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200118_2b_n10008_01_0001.arch?ra=32.14943&dec=10.22003&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200118_2b_n10008_01_0001.arch',
      ra: 32.14943,
      rh: 4.346789214073,
      sangle: 250.014,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 130.5354,
      unc_a: 0.159,
      unc_b: 0.089,
      unc_theta: 28.029,
      vangle: 62.230999999999995,
      vmag: 18.982,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2020/20Jan18/703_20200118_2B_N10008_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200118_2b_n10008_01_0004.arch?ra=32.15054&dec=10.22091&size=5.00arcmin&format=fits',
      date: '2020-01-18 04:33:22.184',
      ddec: 7.605328,
      dec: 10.22091,
      delta: 4.1287702983885,
      dra: 9.497002,
      drh: 2.9655983,
      elong: 96.2271,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58866.189666481485,
      mjd_stop: 58866.1900137037,
      phase: 12.9992,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200118_2b_n10008_01_0004.arch?ra=32.15054&dec=10.22091&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20200118_2b_n10008_01_0004.arch',
      ra: 32.15054,
      rh: 4.346818618816,
      sangle: 250.015,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 130.5371,
      unc_a: 0.159,
      unc_b: 0.089,
      unc_theta: 28.029,
      vangle: 62.230999999999995,
      vmag: 18.982,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Nov30/703_20221130_2B_N19034_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221130_2b_n19034_01_0003.arch?ra=153.35926&dec=21.03182&size=5.00arcmin&format=fits',
      date: '2022-11-30 10:02:54.989',
      ddec: 2.493178,
      dec: 21.03182,
      delta: 4.23749853997658,
      dra: 9.682771,
      drh: -2.4646328,
      elong: 99.9814,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59913.418518391205,
      mjd_stop: 59913.41886561343,
      phase: 12.4294,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221130_2b_n19034_01_0003.arch?ra=153.35926&dec=21.03182&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221130_2b_n19034_01_0003.arch',
      ra: 153.35926,
      rh: 4.514322145722,
      sangle: 109.45400000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 219.4879,
      unc_a: 0.157,
      unc_b: 0.086,
      unc_theta: -19.632,
      vangle: 110.44900000000001,
      vmag: 19.191,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Nov30/703_20221130_2B_N19034_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221130_2b_n19034_01_0002.arch?ra=153.35889&dec=21.03173&size=5.00arcmin&format=fits',
      date: '2022-11-30 09:55:17.933',
      ddec: 2.495884,
      dec: 21.03173,
      delta: 4.23758785613798,
      dra: 9.696274,
      drh: -2.4646073,
      elong: 99.9764,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59913.413228391204,
      mjd_stop: 59913.41357561343,
      phase: 12.4296,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221130_2b_n19034_01_0002.arch?ra=153.35889&dec=21.03173&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221130_2b_n19034_01_0002.arch',
      ra: 153.35889,
      rh: 4.514329676439,
      sangle: 109.45499999999998,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 219.4874,
      unc_a: 0.157,
      unc_b: 0.086,
      unc_theta: -19.632,
      vangle: 110.44900000000001,
      vmag: 19.191,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Nov30/703_20221130_2B_N19034_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221130_2b_n19034_01_0001.arch?ra=153.35853&dec=21.03164&size=5.00arcmin&format=fits',
      date: '2022-11-30 09:47:42.094',
      ddec: 2.498452,
      dec: 21.03164,
      delta: 4.23767696147814,
      dra: 9.710092,
      drh: -2.4645819,
      elong: 99.9714,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59913.40795247685,
      mjd_stop: 59913.40829969908,
      phase: 12.4298,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221130_2b_n19034_01_0001.arch?ra=153.35853&dec=21.03164&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221130_2b_n19034_01_0001.arch',
      ra: 153.35853,
      rh: 4.514337187027,
      sangle: 109.45600000000002,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 219.4869,
      unc_a: 0.157,
      unc_b: 0.086,
      unc_theta: -19.632,
      vangle: 110.44900000000001,
      vmag: 19.191,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Nov30/703_20221130_2B_N19034_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221130_2b_n19034_01_0004.arch?ra=153.35962&dec=21.03191&size=5.00arcmin&format=fits',
      date: '2022-11-30 10:10:30.209',
      ddec: 2.490356,
      dec: 21.03191,
      delta: 4.23740961043442,
      dra: 9.669684,
      drh: -2.4646581,
      elong: 99.9865,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59913.423787141204,
      mjd_stop: 59913.42413436343,
      phase: 12.4292,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221130_2b_n19034_01_0004.arch?ra=153.35962&dec=21.03191&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221130_2b_n19034_01_0004.arch',
      ra: 153.35962,
      rh: 4.51431464518,
      sangle: 109.45400000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 219.4883,
      unc_a: 0.157,
      unc_b: 0.086,
      unc_theta: -19.633,
      vangle: 110.44900000000001,
      vmag: 19.19,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Nov24/703_20221124_2B_N19034_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221124_2b_n19034_01_0002.arch?ra=152.87007&dec=20.96185&size=5.00arcmin&format=fits',
      date: '2022-11-24 10:19:41.574',
      ddec: 1.221359,
      dec: 20.96185,
      delta: 4.33837526742926,
      dra: 12.52917,
      drh: -2.4357347,
      elong: 94.4065,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59907.430168680556,
      mjd_stop: 59907.43051590278,
      phase: 12.5754,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221124_2b_n19034_01_0002.arch?ra=152.87007&dec=20.96185&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221124_2b_n19034_01_0002.arch',
      ra: 152.87007,
      rh: 4.522797115725,
      sangle: 110.298,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 218.9346,
      unc_a: 0.154,
      unc_b: 0.084,
      unc_theta: -19.305,
      vangle: 110.269,
      vmag: 19.249,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Nov24/703_20221124_2B_N19034_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221124_2b_n19034_01_0004.arch?ra=152.87101&dec=20.96194&size=5.00arcmin&format=fits',
      date: '2022-11-24 10:34:52.544',
      ddec: 1.215894,
      dec: 20.96194,
      delta: 4.33819520993331,
      dra: 12.50367,
      drh: -2.4357857,
      elong: 94.4163,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59907.44071231481,
      mjd_stop: 59907.441059537035,
      phase: 12.5752,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221124_2b_n19034_01_0004.arch?ra=152.87101&dec=20.96194&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221124_2b_n19034_01_0004.arch',
      ra: 152.87101,
      rh: 4.522782281801,
      sangle: 110.29700000000003,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 218.9356,
      unc_a: 0.154,
      unc_b: 0.084,
      unc_theta: -19.306,
      vangle: 110.269,
      vmag: 19.249,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Nov24/703_20221124_2B_N19034_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221124_2b_n19034_01_0003.arch?ra=152.87054&dec=20.9619&size=5.00arcmin&format=fits',
      date: '2022-11-24 10:27:17.020',
      ddec: 1.218688,
      dec: 20.9619,
      delta: 4.33828523270558,
      dra: 12.51624,
      drh: -2.4357602,
      elong: 94.4114,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59907.4354400463,
      mjd_stop: 59907.43578726852,
      phase: 12.5753,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221124_2b_n19034_01_0003.arch?ra=152.87054&dec=20.9619&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221124_2b_n19034_01_0003.arch',
      ra: 152.87054,
      rh: 4.522789699437,
      sangle: 110.298,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 218.9351,
      unc_a: 0.154,
      unc_b: 0.084,
      unc_theta: -19.305,
      vangle: 110.269,
      vmag: 19.249,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Nov24/703_20221124_2B_N19034_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221124_2b_n19034_01_0001.arch?ra=152.8696&dec=20.96181&size=5.00arcmin&format=fits',
      date: '2022-11-24 10:12:06.363',
      ddec: 1.223903,
      dec: 20.96181,
      delta: 4.33846528216703,
      dra: 12.54244,
      drh: -2.4357092,
      elong: 94.4015,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59907.42490003472,
      mjd_stop: 59907.42524725694,
      phase: 12.5755,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221124_2b_n19034_01_0001.arch?ra=152.8696&dec=20.96181&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221124_2b_n19034_01_0001.arch',
      ra: 152.8696,
      rh: 4.522804528108,
      sangle: 110.29899999999998,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 218.9341,
      unc_a: 0.154,
      unc_b: 0.084,
      unc_theta: -19.305,
      vangle: 110.269,
      vmag: 19.249,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Oct30/703_20221030_2B_N19033_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221030_2b_n19033_01_0004.arch?ra=149.6684&dec=21.17289&size=5.00arcmin&format=fits',
      date: '2022-10-30 11:27:12.431',
      ddec: -3.33702,
      dec: 21.17289,
      delta: 4.75650995573303,
      dra: 22.24436,
      drh: -2.3140592,
      elong: 72.4754,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59882.477053599534,
      mjd_stop: 59882.47740082176,
      phase: 11.9956,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221030_2b_n19033_01_0004.arch?ra=149.6684&dec=21.17289&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221030_2b_n19033_01_0004.arch',
      ra: 149.6684,
      rh: 4.557028937726,
      sangle: 112.93299999999999,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 216.6512,
      unc_a: 0.145,
      unc_b: 0.078,
      unc_theta: -17.663,
      vangle: 109.07600000000002,
      vmag: 19.479,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Oct30/703_20221030_2B_N19033_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221030_2b_n19033_01_0003.arch?ra=149.66757&dec=21.173&size=5.00arcmin&format=fits',
      date: '2022-10-30 11:19:39.520',
      ddec: -3.33478,
      dec: 21.173,
      delta: 4.75659585726389,
      dra: 22.2566,
      drh: -2.3140335,
      elong: 72.471,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59882.471811574076,
      mjd_stop: 59882.4721587963,
      phase: 11.9953,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221030_2b_n19033_01_0003.arch?ra=149.66757&dec=21.173&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221030_2b_n19033_01_0003.arch',
      ra: 149.66757,
      rh: 4.557035944217,
      sangle: 112.93400000000003,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 216.6507,
      unc_a: 0.145,
      unc_b: 0.078,
      unc_theta: -17.662,
      vangle: 109.07600000000002,
      vmag: 19.479,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Oct30/703_20221030_2B_N19033_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221030_2b_n19033_01_0002.arch?ra=149.66673&dec=21.17312&size=5.00arcmin&format=fits',
      date: '2022-10-30 11:12:06.366',
      ddec: -3.33266,
      dec: 21.17312,
      delta: 4.75668182552311,
      dra: 22.26911,
      drh: -2.3140077,
      elong: 72.4665,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59882.46656673611,
      mjd_stop: 59882.466913958335,
      phase: 11.995,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221030_2b_n19033_01_0002.arch?ra=149.66673&dec=21.17312&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221030_2b_n19033_01_0002.arch',
      ra: 149.66673,
      rh: 4.557042954389,
      sangle: 112.93400000000003,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 216.6502,
      unc_a: 0.145,
      unc_b: 0.078,
      unc_theta: -17.662,
      vangle: 109.07600000000002,
      vmag: 19.479,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Oct30/703_20221030_2B_N19033_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221030_2b_n19033_01_0001.arch?ra=149.6659&dec=21.17323&size=5.00arcmin&format=fits',
      date: '2022-10-30 11:04:33.035',
      ddec: -3.33067,
      dec: 21.17323,
      delta: 4.7567678470685,
      dra: 22.2819,
      drh: -2.3139819,
      elong: 72.4621,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59882.461319849535,
      mjd_stop: 59882.46166707176,
      phase: 11.9947,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221030_2b_n19033_01_0001.arch?ra=149.6659&dec=21.17323&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221030_2b_n19033_01_0001.arch',
      ra: 149.6659,
      rh: 4.557049967223,
      sangle: 112.935,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 216.6498,
      unc_a: 0.145,
      unc_b: 0.078,
      unc_theta: -17.662,
      vangle: 109.07499999999999,
      vmag: 19.479,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Nov27/703_20221127_2B_N19034_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221127_2b_n19034_01_0002.arch?ra=153.12756&dec=20.99016&size=5.00arcmin&format=fits',
      date: '2022-11-27 09:31:08.684',
      ddec: 1.865383,
      dec: 20.99016,
      delta: 4.28826308108413,
      dra: 11.20107,
      drh: -2.4500644,
      elong: 97.152,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59910.39645467592,
      mjd_stop: 59910.39680189815,
      phase: 12.5184,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221127_2b_n19034_01_0002.arch?ra=153.12756&dec=20.99016&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221127_2b_n19034_01_0002.arch',
      ra: 153.12756,
      rh: 4.518611592519,
      sangle: 109.89499999999998,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 219.2084,
      unc_a: 0.155,
      unc_b: 0.085,
      unc_theta: -19.471,
      vangle: 110.36399999999998,
      vmag: 19.22,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Nov27/703_20221127_2B_N19034_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221127_2b_n19034_01_0004.arch?ra=153.12841&dec=20.99029&size=5.00arcmin&format=fits',
      date: '2022-11-27 09:46:23.058',
      ddec: 1.86117,
      dec: 20.99029,
      delta: 4.28808292894583,
      dra: 11.17176,
      drh: -2.4501154,
      elong: 97.162,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59910.40703770833,
      mjd_stop: 59910.40738493056,
      phase: 12.5182,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221127_2b_n19034_01_0004.arch?ra=153.12841&dec=20.99029&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221127_2b_n19034_01_0004.arch',
      ra: 153.12841,
      rh: 4.518596615577,
      sangle: 109.89299999999997,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 219.2094,
      unc_a: 0.155,
      unc_b: 0.085,
      unc_theta: -19.472,
      vangle: 110.36399999999998,
      vmag: 19.22,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Nov27/703_20221127_2B_N19034_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221127_2b_n19034_01_0001.arch?ra=153.12714&dec=20.99009&size=5.00arcmin&format=fits',
      date: '2022-11-27 09:23:31.048',
      ddec: 1.867271,
      dec: 20.99009,
      delta: 4.2883532796572,
      dra: 11.21618,
      drh: -2.4500388,
      elong: 97.147,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59910.391157962964,
      mjd_stop: 59910.39150518519,
      phase: 12.5186,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221127_2b_n19034_01_0001.arch?ra=153.12714&dec=20.99009&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221127_2b_n19034_01_0001.arch',
      ra: 153.12714,
      rh: 4.518619088228,
      sangle: 109.89600000000002,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 219.2079,
      unc_a: 0.155,
      unc_b: 0.085,
      unc_theta: -19.471,
      vangle: 110.36399999999998,
      vmag: 19.22,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Nov27/703_20221127_2B_N19034_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221127_2b_n19034_01_0003.arch?ra=153.12799&dec=20.99022&size=5.00arcmin&format=fits',
      date: '2022-11-27 09:38:45.904',
      ddec: 1.863349,
      dec: 20.99022,
      delta: 4.2881729868431,
      dra: 11.18626,
      drh: -2.4500899,
      elong: 97.157,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59910.401746574076,
      mjd_stop: 59910.4020937963,
      phase: 12.5183,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221127_2b_n19034_01_0003.arch?ra=153.12799&dec=20.99022&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221127_2b_n19034_01_0003.arch',
      ra: 153.12799,
      rh: 4.518604103546,
      sangle: 109.894,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 219.2089,
      unc_a: 0.155,
      unc_b: 0.085,
      unc_theta: -19.472,
      vangle: 110.36399999999998,
      vmag: 19.22,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Dec19/703_20221219_2B_N19034_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221219_2b_n19034_01_0003.arch?ra=154.05386&dec=21.64048&size=5.00arcmin&format=fits',
      date: '2022-12-20 11:17:33.596',
      ddec: 6.716757,
      dec: 21.64048,
      delta: 3.91710600779935,
      dra: -1.07284,
      drh: -2.5604807,
      elong: 119.5741,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59933.47035412037,
      mjd_stop: 59933.470701342594,
      phase: 11.0032,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221219_2b_n19034_01_0003.arch?ra=154.05386&dec=21.64048&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221219_2b_n19034_01_0003.arch',
      ra: 154.05386,
      rh: 4.485220391038,
      sangle: 105.46100000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 221.3563,
      unc_a: 0.168,
      unc_b: 0.092,
      unc_theta: -20.426,
      vangle: 110.68400000000003,
      vmag: 18.994,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Dec19/703_20221219_2B_N19034_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221219_2b_n19034_01_0002.arch?ra=154.0539&dec=21.64024&size=5.00arcmin&format=fits',
      date: '2022-12-20 11:09:53.828',
      ddec: 6.721728,
      dec: 21.64024,
      delta: 3.91718553914469,
      dra: -1.0676,
      drh: -2.5604554,
      elong: 119.5687,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59933.46503273148,
      mjd_stop: 59933.465379953705,
      phase: 11.0038,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221219_2b_n19034_01_0002.arch?ra=154.0539&dec=21.64024&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221219_2b_n19034_01_0002.arch',
      ra: 154.0539,
      rh: 4.485228260955,
      sangle: 105.46199999999999,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 221.3558,
      unc_a: 0.168,
      unc_b: 0.092,
      unc_theta: -20.426,
      vangle: 110.68400000000003,
      vmag: 18.994,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Dec19/703_20221219_2B_N19034_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221219_2b_n19034_01_0004.arch?ra=154.05382&dec=21.64072&size=5.00arcmin&format=fits',
      date: '2022-12-20 11:25:09.770',
      ddec: 6.711802,
      dec: 21.64072,
      delta: 3.91702713900612,
      dra: -1.0775,
      drh: -2.5605057,
      elong: 119.5795,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59933.475633912036,
      mjd_stop: 59933.47598113426,
      phase: 11.0026,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221219_2b_n19034_01_0004.arch?ra=154.05382&dec=21.64072&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221219_2b_n19034_01_0004.arch',
      ra: 154.05382,
      rh: 4.485212582564,
      sangle: 105.45999999999998,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 221.3568,
      unc_a: 0.168,
      unc_b: 0.092,
      unc_theta: -20.427,
      vangle: 110.68400000000003,
      vmag: 18.994,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Dec19/703_20221219_2B_N19034_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221219_2b_n19034_01_0001.arch?ra=154.05394&dec=21.64&size=5.00arcmin&format=fits',
      date: '2022-12-20 11:02:12.071',
      ddec: 6.726688,
      dec: 21.64,
      delta: 3.91726545600671,
      dra: -1.06177,
      drh: -2.5604301,
      elong: 119.5632,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59933.45968832176,
      mjd_stop: 59933.46003554398,
      phase: 11.0044,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221219_2b_n19034_01_0001.arch?ra=154.05394&dec=21.64&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221219_2b_n19034_01_0001.arch',
      ra: 154.05394,
      rh: 4.485236164841,
      sangle: 105.464,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 221.3553,
      unc_a: 0.168,
      unc_b: 0.092,
      unc_theta: -20.426,
      vangle: 110.68400000000003,
      vmag: 18.994,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Nov21/703_20221121_2B_N19034_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221121_2b_n19034_01_0004.arch?ra=152.58131&dec=20.94567&size=5.00arcmin&format=fits',
      date: '2022-11-21 10:31:58.906',
      ddec: 0.601012,
      dec: 20.94567,
      delta: 4.38908929845926,
      dra: 13.87751,
      drh: -2.4212535,
      elong: 91.6684,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59904.43870261574,
      mjd_stop: 59904.439049837965,
      phase: 12.6032,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221121_2b_n19034_01_0004.arch?ra=152.58131&dec=20.94567&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221121_2b_n19034_01_0004.arch',
      ra: 152.58131,
      rh: 4.526993279355,
      sangle: 110.68,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 218.659,
      unc_a: 0.153,
      unc_b: 0.083,
      unc_theta: -19.13,
      vangle: 110.16199999999998,
      vmag: 19.278,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Nov21/703_20221121_2B_N19034_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221121_2b_n19034_01_0002.arch?ra=152.58026&dec=20.94563&size=5.00arcmin&format=fits',
      date: '2022-11-21 10:16:48.973',
      ddec: 0.605988,
      dec: 20.94563,
      delta: 4.38926964022757,
      dra: 13.90379,
      drh: -2.4212025,
      elong: 91.6587,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59904.428170983796,
      mjd_stop: 59904.42851820602,
      phase: 12.6033,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221121_2b_n19034_01_0002.arch?ra=152.58026&dec=20.94563&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221121_2b_n19034_01_0002.arch',
      ra: 152.58026,
      rh: 4.527008007995,
      sangle: 110.68099999999998,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 218.658,
      unc_a: 0.153,
      unc_b: 0.083,
      unc_theta: -19.129,
      vangle: 110.16199999999998,
      vmag: 19.278,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Nov21/703_20221121_2B_N19034_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221121_2b_n19034_01_0003.arch?ra=152.58079&dec=20.94565&size=5.00arcmin&format=fits',
      date: '2022-11-21 10:24:24.257',
      ddec: 0.603562,
      dec: 20.94565,
      delta: 4.38917939373486,
      dra: 13.89048,
      drh: -2.421228,
      elong: 91.6636,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59904.43344047454,
      mjd_stop: 59904.43378769676,
      phase: 12.6033,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221121_2b_n19034_01_0003.arch?ra=152.58079&dec=20.94565&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221121_2b_n19034_01_0003.arch',
      ra: 152.58079,
      rh: 4.527000638575,
      sangle: 110.68,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 218.6585,
      unc_a: 0.153,
      unc_b: 0.083,
      unc_theta: -19.13,
      vangle: 110.16199999999998,
      vmag: 19.278,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Nov21/703_20221121_2B_N19034_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221121_2b_n19034_01_0001.arch?ra=152.57974&dec=20.9456&size=5.00arcmin&format=fits',
      date: '2022-11-21 10:09:14.113',
      ddec: 0.60828,
      dec: 20.9456,
      delta: 4.38935982712977,
      dra: 13.91741,
      drh: -2.421177,
      elong: 91.6539,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59904.42290640046,
      mjd_stop: 59904.423253622685,
      phase: 12.6033,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221121_2b_n19034_01_0001.arch?ra=152.57974&dec=20.9456&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221121_2b_n19034_01_0001.arch',
      ra: 152.57974,
      rh: 4.527015370474,
      sangle: 110.68200000000002,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 218.6575,
      unc_a: 0.153,
      unc_b: 0.083,
      unc_theta: -19.129,
      vangle: 110.16199999999998,
      vmag: 19.278,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Oct21/703_20221021_2B_N19033_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221021_2b_n19033_01_0004.arch?ra=148.13618&dec=21.41665&size=5.00arcmin&format=fits',
      date: '2022-10-21 12:22:30.184',
      ddec: -4.63285,
      dec: 21.41665,
      delta: 4.89869864290807,
      dra: 24.91255,
      drh: -2.2698928,
      elong: 65.052,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59873.51545351852,
      mjd_stop: 59873.51580074074,
      phase: 11.3951,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221021_2b_n19033_01_0004.arch?ra=148.13618&dec=21.41665&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221021_2b_n19033_01_0004.arch',
      ra: 148.13618,
      rh: 4.568892837941,
      sangle: 113.70499999999998,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 215.8394,
      unc_a: 0.143,
      unc_b: 0.076,
      unc_theta: -16.994,
      vangle: 108.49900000000002,
      vmag: 19.554,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Oct21/703_20221021_2B_N19033_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221021_2b_n19033_01_0003.arch?ra=148.13551&dec=21.41676&size=5.00arcmin&format=fits',
      date: '2022-10-21 12:17:05.789',
      ddec: -4.6309,
      dec: 21.41676,
      delta: 4.89875727449491,
      dra: 24.92017,
      drh: -2.2698742,
      elong: 65.0489,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59873.51169894676,
      mjd_stop: 59873.51204616898,
      phase: 11.3948,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221021_2b_n19033_01_0003.arch?ra=148.13551&dec=21.41676&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221021_2b_n19033_01_0003.arch',
      ra: 148.13551,
      rh: 4.568897760505,
      sangle: 113.70499999999998,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 215.839,
      unc_a: 0.143,
      unc_b: 0.076,
      unc_theta: -16.994,
      vangle: 108.49900000000002,
      vmag: 19.554,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Oct21/703_20221021_2B_N19033_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221021_2b_n19033_01_0002.arch?ra=148.13483&dec=21.41688&size=5.00arcmin&format=fits',
      date: '2022-10-21 12:11:41.303',
      ddec: -4.629,
      dec: 21.41688,
      delta: 4.89881593443216,
      dra: 24.92795,
      drh: -2.2698556,
      elong: 65.0459,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59873.50794332176,
      mjd_stop: 59873.508290543985,
      phase: 11.3946,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221021_2b_n19033_01_0002.arch?ra=148.13483&dec=21.41688&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221021_2b_n19033_01_0002.arch',
      ra: 148.13483,
      rh: 4.568902684411,
      sangle: 113.70499999999998,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 215.8387,
      unc_a: 0.143,
      unc_b: 0.076,
      unc_theta: -16.994,
      vangle: 108.49799999999999,
      vmag: 19.554,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Oct21/703_20221021_2B_N19033_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221021_2b_n19033_01_0001.arch?ra=148.13416&dec=21.41699&size=5.00arcmin&format=fits',
      date: '2022-10-21 12:06:16.875',
      ddec: -4.62716,
      dec: 21.41699,
      delta: 4.89887459547114,
      dra: 24.93589,
      drh: -2.2698371,
      elong: 65.0428,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59873.50418836805,
      mjd_stop: 59873.50453559028,
      phase: 11.3943,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221021_2b_n19033_01_0001.arch?ra=148.13416&dec=21.41699&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221021_2b_n19033_01_0001.arch',
      ra: 148.13416,
      rh: 4.568907607396,
      sangle: 113.70600000000002,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 215.8383,
      unc_a: 0.143,
      unc_b: 0.076,
      unc_theta: -16.993,
      vangle: 108.49799999999999,
      vmag: 19.554,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Oct01/703_20221001_2B_N24031_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221001_2b_n24031_01_0003.arch?ra=144.15771&dec=22.19579&size=5.00arcmin&format=fits',
      date: '2022-10-01 11:38:03.054',
      ddec: -6.71402,
      dec: 22.19579,
      delta: 5.18590435198528,
      dra: 29.69984,
      drh: -2.1703528,
      elong: 49.1955,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59853.484583958336,
      mjd_stop: 59853.48493118055,
      phase: 9.4936,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221001_2b_n24031_01_0003.arch?ra=144.15771&dec=22.19579&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221001_2b_n24031_01_0003.arch',
      ra: 144.15771,
      rh: 4.594580154483,
      sangle: 115.52699999999999,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 214.0393,
      unc_a: 0.14,
      unc_b: 0.073,
      unc_theta: -15.383,
      vangle: 106.97199999999998,
      vmag: 19.7,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Oct01/703_20221001_2B_N24031_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221001_2b_n24031_01_0004.arch?ra=144.15857&dec=22.19561&size=5.00arcmin&format=fits',
      date: '2022-10-01 11:43:53.333',
      ddec: -6.71493,
      dec: 22.19561,
      delta: 5.1858505609641,
      dra: 29.69005,
      drh: -2.1703731,
      elong: 49.1987,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59853.48863811343,
      mjd_stop: 59853.48898533565,
      phase: 9.494,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221001_2b_n24031_01_0004.arch?ra=144.15857&dec=22.19561&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221001_2b_n24031_01_0004.arch',
      ra: 144.15857,
      rh: 4.594575072253,
      sangle: 115.52699999999999,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 214.0397,
      unc_a: 0.14,
      unc_b: 0.073,
      unc_theta: -15.384,
      vangle: 106.97199999999998,
      vmag: 19.7,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Oct01/703_20221001_2B_N24031_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221001_2b_n24031_01_0002.arch?ra=144.15684&dec=22.19597&size=5.00arcmin&format=fits',
      date: '2022-10-01 11:32:12.366',
      ddec: -6.71319,
      dec: 22.19597,
      delta: 5.18595821078637,
      dra: 29.70973,
      drh: -2.1703325,
      elong: 49.1924,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59853.48052506945,
      mjd_stop: 59853.480872291664,
      phase: 9.4931,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221001_2b_n24031_01_0002.arch?ra=144.15684&dec=22.19597&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221001_2b_n24031_01_0002.arch',
      ra: 144.15684,
      rh: 4.5945852426,
      sangle: 115.52800000000002,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 214.0389,
      unc_a: 0.14,
      unc_b: 0.073,
      unc_theta: -15.383,
      vangle: 106.971,
      vmag: 19.7,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Oct01/703_20221001_2B_N24031_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221001_2b_n24031_01_0001.arch?ra=144.15597&dec=22.19615&size=5.00arcmin&format=fits',
      date: '2022-10-01 11:26:22.041',
      ddec: -6.71245,
      dec: 22.19615,
      delta: 5.18601201826614,
      dra: 29.71967,
      drh: -2.1703123,
      elong: 49.1893,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59853.476470381946,
      mjd_stop: 59853.47681760417,
      phase: 9.4927,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221001_2b_n24031_01_0001.arch?ra=144.15597&dec=22.19615&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221001_2b_n24031_01_0001.arch',
      ra: 144.15597,
      rh: 4.594590325402,
      sangle: 115.52800000000002,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 214.0386,
      unc_a: 0.14,
      unc_b: 0.073,
      unc_theta: -15.383,
      vangle: 106.971,
      vmag: 19.7,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Jan14/703_20230114_2B_N24033_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230114_2b_n24033_01_0001.arch?ra=152.69481&dec=23.11525&size=5.00arcmin&format=fits',
      date: '2023-01-14 09:36:05.364',
      ddec: 10.5691,
      dec: 23.11525,
      delta: 3.6009748840327,
      dra: -14.7595,
      drh: -2.6775473,
      elong: 145.5993,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59958.399888472224,
      mjd_stop: 59958.40023569445,
      phase: 7.183,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230114_2b_n24033_01_0001.arch?ra=152.69481&dec=23.11525&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230114_2b_n24033_01_0001.arch',
      ra: 152.69481,
      rh: 4.447506140239,
      sangle: 94.46600000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 223.7153,
      unc_a: 0.183,
      unc_b: 0.098,
      unc_theta: -20.458,
      vangle: 110.05099999999999,
      vmag: 18.777,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Jan14/703_20230114_2B_N24033_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230114_2b_n24033_01_0004.arch?ra=152.69311&dec=23.11636&size=5.00arcmin&format=fits',
      date: '2023-01-14 09:58:53.865',
      ddec: 10.5493,
      dec: 23.11636,
      delta: 3.60081663217306,
      dra: -14.7705,
      drh: -2.6776209,
      elong: 145.6163,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59958.415727604166,
      mjd_stop: 59958.41607482639,
      phase: 7.1799,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230114_2b_n24033_01_0004.arch?ra=152.69311&dec=23.11636&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230114_2b_n24033_01_0004.arch',
      ra: 152.69311,
      rh: 4.447481644651,
      sangle: 94.45400000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 223.7168,
      unc_a: 0.183,
      unc_b: 0.098,
      unc_theta: -20.458,
      vangle: 110.05000000000001,
      vmag: 18.777,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Jan14/703_20230114_2B_N24033_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230114_2b_n24033_01_0003.arch?ra=152.69368&dec=23.11599&size=5.00arcmin&format=fits',
      date: '2023-01-14 09:51:13.995',
      ddec: 10.55597,
      dec: 23.11599,
      delta: 3.60086976713526,
      dra: -14.7674,
      drh: -2.6775961,
      elong: 145.6106,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59958.41040503472,
      mjd_stop: 59958.41075225695,
      phase: 7.181,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230114_2b_n24033_01_0003.arch?ra=152.69368&dec=23.11599&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230114_2b_n24033_01_0003.arch',
      ra: 152.69368,
      rh: 4.447489876204,
      sangle: 94.45800000000003,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 223.7163,
      unc_a: 0.183,
      unc_b: 0.098,
      unc_theta: -20.458,
      vangle: 110.05000000000001,
      vmag: 18.777,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Jan14/703_20230114_2B_N24033_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230114_2b_n24033_01_0002.arch?ra=152.69425&dec=23.11561&size=5.00arcmin&format=fits',
      date: '2023-01-14 09:43:37.000',
      ddec: 10.56258,
      dec: 23.11561,
      delta: 3.60092261392066,
      dra: -14.7637,
      drh: -2.6775716,
      elong: 145.6049,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59958.40511574074,
      mjd_stop: 59958.40546296296,
      phase: 7.182,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230114_2b_n24033_01_0002.arch?ra=152.69425&dec=23.11561&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230114_2b_n24033_01_0002.arch',
      ra: 152.69425,
      rh: 4.447498056219,
      sangle: 94.46199999999999,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 223.7158,
      unc_a: 0.183,
      unc_b: 0.098,
      unc_theta: -20.458,
      vangle: 110.05099999999999,
      vmag: 18.777,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Dec24/703_20221224_2B_N24033_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221224_2b_n24033_01_0003.arch?ra=153.9847&dec=21.87535&size=5.00arcmin&format=fits',
      date: '2022-12-25 08:54:00.273',
      ddec: 7.791926,
      dec: 21.87535,
      delta: 3.84578228745464,
      dra: -3.75829,
      drh: -2.5836807,
      elong: 124.575,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59938.37066288194,
      mjd_stop: 59938.371010104165,
      phase: 10.4247,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221224_2b_n24033_01_0003.arch?ra=153.9847&dec=21.87535&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221224_2b_n24033_01_0003.arch',
      ra: 153.9847,
      rh: 4.477940345155,
      sangle: 104.06099999999998,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 221.8168,
      unc_a: 0.171,
      unc_b: 0.093,
      unc_theta: -20.529,
      vangle: 110.64499999999998,
      vmag: 18.947,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Dec24/703_20221224_2B_N19034_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221224_2b_n19034_01_0002.arch?ra=153.98485&dec=21.87506&size=5.00arcmin&format=fits',
      date: '2022-12-25 08:45:50.822',
      ddec: 7.796068,
      dec: 21.87506,
      delta: 3.84586333358559,
      dra: -3.74373,
      drh: -2.5836539,
      elong: 124.5691,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59938.36499793982,
      mjd_stop: 59938.365345162034,
      phase: 10.4255,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221224_2b_n19034_01_0002.arch?ra=153.98485&dec=21.87506&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221224_2b_n19034_01_0002.arch',
      ra: 153.98485,
      rh: 4.477948799039,
      sangle: 104.06299999999999,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 221.8162,
      unc_a: 0.171,
      unc_b: 0.093,
      unc_theta: -20.529,
      vangle: 110.64600000000002,
      vmag: 18.947,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Dec24/703_20221224_2B_N24033_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221224_2b_n24033_01_0004.arch?ra=153.98456&dec=21.87562&size=5.00arcmin&format=fits',
      date: '2022-12-25 09:01:32.100',
      ddec: 7.787972,
      dec: 21.87562,
      delta: 3.84570750594879,
      dra: -3.77127,
      drh: -2.5837054,
      elong: 124.5805,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59938.37589236111,
      mjd_stop: 59938.37623958333,
      phase: 10.4241,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221224_2b_n24033_01_0004.arch?ra=153.98456&dec=21.87562&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221224_2b_n24033_01_0004.arch',
      ra: 153.98456,
      rh: 4.477932541042,
      sangle: 104.06,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 221.8173,
      unc_a: 0.171,
      unc_b: 0.093,
      unc_theta: -20.529,
      vangle: 110.64499999999998,
      vmag: 18.947,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Dec24/703_20221224_2B_N24033_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221224_2b_n24033_01_0001.arch?ra=153.98498&dec=21.87481&size=5.00arcmin&format=fits',
      date: '2022-12-25 08:38:55.409',
      ddec: 7.799463,
      dec: 21.87481,
      delta: 3.84593214998386,
      dra: -3.73099,
      drh: -2.5836312,
      elong: 124.5641,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59938.36018991898,
      mjd_stop: 59938.360537141205,
      phase: 10.4261,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221224_2b_n24033_01_0001.arch?ra=153.98498&dec=21.87481&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221224_2b_n24033_01_0001.arch',
      ra: 153.98498,
      rh: 4.477955974057,
      sangle: 104.065,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 221.8158,
      unc_a: 0.17,
      unc_b: 0.093,
      unc_theta: -20.529,
      vangle: 110.64600000000002,
      vmag: 18.947,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Dec24/703_20221224_2B_N24033_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221224_2b_n24033_01_0002.arch?ra=153.98484&dec=21.87508&size=5.00arcmin&format=fits',
      date: '2022-12-25 08:46:28.038',
      ddec: 7.795759,
      dec: 21.87508,
      delta: 3.84585716979323,
      dra: -3.74486,
      drh: -2.583656,
      elong: 124.5696,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59938.365428680554,
      mjd_stop: 59938.36577590278,
      phase: 10.4254,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221224_2b_n24033_01_0002.arch?ra=153.98484&dec=21.87508&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221224_2b_n24033_01_0002.arch',
      ra: 153.98484,
      rh: 4.477948156241,
      sangle: 104.06299999999999,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 221.8163,
      unc_a: 0.171,
      unc_b: 0.093,
      unc_theta: -20.529,
      vangle: 110.64600000000002,
      vmag: 18.947,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Dec21/703_20221221_2B_N19034_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221221_2b_n19034_01_0004.arch?ra=154.0375&dec=21.73194&size=5.00arcmin&format=fits',
      date: '2022-12-22 11:01:01.084',
      ddec: 7.130704,
      dec: 21.73194,
      delta: 3.88772419083734,
      dra: -2.19921,
      drh: -2.5699059,
      elong: 121.5945,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59935.458866712965,
      mjd_stop: 59935.45921393519,
      phase: 10.7794,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221221_2b_n19034_01_0004.arch?ra=154.0375&dec=21.73194&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221221_2b_n19034_01_0004.arch',
      ra: 154.0375,
      rh: 4.482274111002,
      sangle: 104.92099999999999,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 221.5429,
      unc_a: 0.169,
      unc_b: 0.092,
      unc_theta: -20.473,
      vangle: 110.673,
      vmag: 18.975,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Dec21/703_20221221_2B_N19034_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221221_2b_n19034_01_0001.arch?ra=154.03777&dec=21.73115&size=5.00arcmin&format=fits',
      date: '2022-12-22 10:37:06.559',
      ddec: 7.146266,
      dec: 21.73115,
      delta: 3.88796785448931,
      dra: -2.17895,
      drh: -2.5698273,
      elong: 121.5774,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59935.44226341435,
      mjd_stop: 59935.44261063657,
      phase: 10.7814,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221221_2b_n19034_01_0001.arch?ra=154.03777&dec=21.73115&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221221_2b_n19034_01_0001.arch',
      ra: 154.03777,
      rh: 4.482298756074,
      sangle: 104.92500000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 221.5414,
      unc_a: 0.169,
      unc_b: 0.092,
      unc_theta: -20.473,
      vangle: 110.67399999999998,
      vmag: 18.975,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Dec21/703_20221221_2B_N19034_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221221_2b_n19034_01_0002.arch?ra=154.03767&dec=21.73144&size=5.00arcmin&format=fits',
      date: '2022-12-22 10:45:52.464',
      ddec: 7.140611,
      dec: 21.73144,
      delta: 3.887878479625,
      dra: -2.187,
      drh: -2.5698561,
      elong: 121.5836,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59935.44835027778,
      mjd_stop: 59935.4486975,
      phase: 10.7806,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221221_2b_n19034_01_0002.arch?ra=154.03767&dec=21.73144&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221221_2b_n19034_01_0002.arch',
      ra: 154.03767,
      rh: 4.482289721138,
      sangle: 104.923,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 221.542,
      unc_a: 0.169,
      unc_b: 0.092,
      unc_theta: -20.473,
      vangle: 110.67399999999998,
      vmag: 18.975,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2022/22Dec21/703_20221221_2B_N19034_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221221_2b_n19034_01_0003.arch?ra=154.03759&dec=21.73169&size=5.00arcmin&format=fits',
      date: '2022-12-22 10:53:27.578',
      ddec: 7.135668,
      dec: 21.73169,
      delta: 3.88780117858192,
      dra: -2.19338,
      drh: -2.5698811,
      elong: 121.5891,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59935.45361780093,
      mjd_stop: 59935.453965023145,
      phase: 10.78,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221221_2b_n19034_01_0003.arch?ra=154.03759&dec=21.73169&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20221221_2b_n19034_01_0003.arch',
      ra: 154.03759,
      rh: 4.482281902295,
      sangle: 104.92200000000003,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 221.5425,
      unc_a: 0.169,
      unc_b: 0.092,
      unc_theta: -20.473,
      vangle: 110.673,
      vmag: 18.975,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Mar01/703_20230301_2B_N24032_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230301_2b_n24032_01_0003.arch?ra=145.65137&dec=25.98965&size=5.00arcmin&format=fits',
      date: '2023-03-01 05:44:47.261',
      ddec: 5.617348,
      dec: 25.98965,
      delta: 3.44838840552442,
      dra: -22.2796,
      drh: -2.8857948,
      elong: 156.3324,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60004.23926228009,
      mjd_stop: 60004.239609502314,
      phase: 5.2122,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230301_2b_n24032_01_0003.arch?ra=145.65137&dec=25.98965&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230301_2b_n24032_01_0003.arch',
      ra: 145.65137,
      rh: 4.373839975799,
      sangle: 317.377,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 228.164,
      unc_a: 0.193,
      unc_b: 0.099,
      unc_theta: -17.595,
      vangle: 106.77100000000002,
      vmag: 18.616,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Mar01/703_20230301_2B_N24032_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230301_2b_n24032_01_0002.arch?ra=145.65224&dec=25.98945&size=5.00arcmin&format=fits',
      date: '2023-03-01 05:37:14.912',
      ddec: 5.626659,
      dec: 25.98945,
      delta: 3.44836992459734,
      dra: -22.2768,
      drh: -2.8857716,
      elong: 156.3377,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60004.23402675926,
      mjd_stop: 60004.23437398148,
      phase: 5.2111,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230301_2b_n24032_01_0002.arch?ra=145.65224&dec=25.98945&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230301_2b_n24032_01_0002.arch',
      ra: 145.65224,
      rh: 4.373848701555,
      sangle: 317.38599999999997,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 228.1635,
      unc_a: 0.193,
      unc_b: 0.099,
      unc_theta: -17.595,
      vangle: 106.77100000000002,
      vmag: 18.616,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Mar01/703_20230301_2B_N24032_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230301_2b_n24032_01_0001.arch?ra=145.6531&dec=25.98926&size=5.00arcmin&format=fits',
      date: '2023-03-01 05:29:41.724',
      ddec: 5.635923,
      dec: 25.98926,
      delta: 3.44835145158688,
      dra: -22.2733,
      drh: -2.8857483,
      elong: 156.343,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60004.22878152778,
      mjd_stop: 60004.22912875,
      phase: 5.2099,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230301_2b_n24032_01_0001.arch?ra=145.6531&dec=25.98926&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230301_2b_n24032_01_0001.arch',
      ra: 145.6531,
      rh: 4.373857443425,
      sangle: 317.394,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 228.1629,
      unc_a: 0.193,
      unc_b: 0.099,
      unc_theta: -17.596,
      vangle: 106.77199999999999,
      vmag: 18.616,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Mar01/703_20230301_2B_N24032_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230301_2b_n24032_01_0004.arch?ra=145.65051&dec=25.98984&size=5.00arcmin&format=fits',
      date: '2023-03-01 05:52:19.478',
      ddec: 5.607985,
      dec: 25.98984,
      delta: 3.44840692337498,
      dra: -22.2818,
      drh: -2.885818,
      elong: 156.3272,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60004.244496273146,
      mjd_stop: 60004.24484349537,
      phase: 5.2133,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230301_2b_n24032_01_0004.arch?ra=145.65051&dec=25.98984&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230301_2b_n24032_01_0004.arch',
      ra: 145.65051,
      rh: 4.37383125252,
      sangle: 317.369,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 228.1645,
      unc_a: 0.193,
      unc_b: 0.099,
      unc_theta: -17.594,
      vangle: 106.77100000000002,
      vmag: 18.616,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Feb11/703_20230211_2B_N24032_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230211_2b_n24032_01_0001.arch?ra=148.71373&dec=25.06945&size=5.00arcmin&format=fits',
      date: '2023-02-11 04:18:11.829',
      ddec: 9.665141,
      dec: 25.06945,
      delta: 3.43242744684552,
      dra: -23.4223,
      drh: -2.8049229,
      elong: 168.3595,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59986.179129965276,
      mjd_stop: 59986.1794771875,
      phase: 2.592,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230211_2b_n24032_01_0001.arch?ra=148.71373&dec=25.06945&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230211_2b_n24032_01_0001.arch',
      ra: 148.71373,
      rh: 4.403520019554,
      sangle: 22.162000000000006,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 226.3933,
      unc_a: 0.194,
      unc_b: 0.101,
      unc_theta: -19.018,
      vangle: 108.20100000000002,
      vmag: 18.633,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Feb11/703_20230211_2B_N24032_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230211_2b_n24032_01_0002.arch?ra=148.71294&dec=25.06974&size=5.00arcmin&format=fits',
      date: '2023-02-11 04:24:47.341',
      ddec: 9.6603,
      dec: 25.06974,
      delta: 3.43241803892836,
      dra: -23.436,
      drh: -2.8049436,
      elong: 168.3597,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59986.18370765046,
      mjd_stop: 59986.18405487268,
      phase: 2.592,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230211_2b_n24032_01_0002.arch?ra=148.71294&dec=25.06974&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230211_2b_n24032_01_0002.arch',
      ra: 148.71294,
      rh: 4.403512603687,
      sangle: 22.133999999999986,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 226.3937,
      unc_a: 0.194,
      unc_b: 0.101,
      unc_theta: -19.018,
      vangle: 108.20100000000002,
      vmag: 18.633,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Feb11/703_20230211_2B_N24032_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230211_2b_n24032_01_0004.arch?ra=148.71136&dec=25.07033&size=5.00arcmin&format=fits',
      date: '2023-02-11 04:37:58.383',
      ddec: 9.650142,
      dec: 25.07033,
      delta: 3.43239929239121,
      dra: -23.4624,
      drh: -2.804985,
      elong: 168.36,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59986.19286322917,
      mjd_stop: 59986.19321045139,
      phase: 2.5919,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230211_2b_n24032_01_0004.arch?ra=148.71136&dec=25.07033&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230211_2b_n24032_01_0004.arch',
      ra: 148.71136,
      rh: 4.403497771452,
      sangle: 22.080000000000013,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 226.3946,
      unc_a: 0.194,
      unc_b: 0.101,
      unc_theta: -19.017,
      vangle: 108.19999999999999,
      vmag: 18.633,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Feb11/703_20230211_2B_N24032_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230211_2b_n24032_01_0003.arch?ra=148.71215&dec=25.07004&size=5.00arcmin&format=fits',
      date: '2023-02-11 04:31:22.894',
      ddec: 9.655298,
      dec: 25.07004,
      delta: 3.43240865307426,
      dra: -23.4493,
      drh: -2.8049643,
      elong: 168.3598,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59986.18828581018,
      mjd_stop: 59986.188633032405,
      phase: 2.5919,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230211_2b_n24032_01_0003.arch?ra=148.71215&dec=25.07004&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230211_2b_n24032_01_0003.arch',
      ra: 148.71215,
      rh: 4.403505186996,
      sangle: 22.107,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 226.3941,
      unc_a: 0.194,
      unc_b: 0.101,
      unc_theta: -19.018,
      vangle: 108.19999999999999,
      vmag: 18.633,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Jan31/703_20230131_2B_N24033_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230131_2b_n24033_01_0004.arch?ra=150.4898&dec=24.33321&size=5.00arcmin&format=fits',
      date: '2023-01-31 06:54:45.792',
      ddec: 10.812,
      dec: 24.33321,
      delta: 3.47130653882417,
      dra: -21.4912,
      drh: -2.7553962,
      elong: 162.5337,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59975.28785638889,
      mjd_stop: 59975.28820361111,
      phase: 3.8394,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230131_2b_n24033_01_0004.arch?ra=150.4898&dec=24.33321&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230131_2b_n24033_01_0004.arch',
      ra: 150.4898,
      rh: 4.42100852878,
      sangle: 70.334,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 225.3369,
      unc_a: 0.191,
      unc_b: 0.1,
      unc_theta: -19.744,
      vangle: 109.03300000000002,
      vmag: 18.674,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Jan31/703_20230131_2B_N24033_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230131_2b_n24033_01_0001.arch?ra=150.4923&dec=24.33207&size=5.00arcmin&format=fits',
      date: '2023-01-31 06:31:49.592',
      ddec: 10.833,
      dec: 24.33207,
      delta: 3.47139162398476,
      dra: -21.458,
      drh: -2.7553234,
      elong: 162.5196,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59975.27192814815,
      mjd_stop: 59975.27227537037,
      phase: 3.8423,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230131_2b_n24033_01_0001.arch?ra=150.4923&dec=24.33207&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230131_2b_n24033_01_0001.arch',
      ra: 150.4923,
      rh: 4.421033877021,
      sangle: 70.37700000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 225.3354,
      unc_a: 0.191,
      unc_b: 0.1,
      unc_theta: -19.745,
      vangle: 109.03399999999999,
      vmag: 18.674,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Jan31/703_20230131_2B_N24033_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230131_2b_n24033_01_0002.arch?ra=150.49147&dec=24.33245&size=5.00arcmin&format=fits',
      date: '2023-01-31 06:39:28.498',
      ddec: 10.82613,
      dec: 24.33245,
      delta: 3.47136321147411,
      dra: -21.4696,
      drh: -2.7553476,
      elong: 162.5243,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59975.277239560186,
      mjd_stop: 59975.27758678241,
      phase: 3.8413,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230131_2b_n24033_01_0002.arch?ra=150.49147&dec=24.33245&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230131_2b_n24033_01_0002.arch',
      ra: 150.49147,
      rh: 4.421025424501,
      sangle: 70.362,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 225.3359,
      unc_a: 0.191,
      unc_b: 0.1,
      unc_theta: -19.745,
      vangle: 109.03300000000002,
      vmag: 18.674,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Jan31/703_20230131_2B_N24033_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230131_2b_n24033_01_0003.arch?ra=150.49063&dec=24.33283&size=5.00arcmin&format=fits',
      date: '2023-01-31 06:47:06.586',
      ddec: 10.81914,
      dec: 24.33283,
      delta: 3.47133488943075,
      dra: -21.4807,
      drh: -2.7553719,
      elong: 162.529,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59975.28254150463,
      mjd_stop: 59975.282888726855,
      phase: 3.8404,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230131_2b_n24033_01_0003.arch?ra=150.49063&dec=24.33283&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230131_2b_n24033_01_0003.arch',
      ra: 150.49063,
      rh: 4.421016986974,
      sangle: 70.34800000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 225.3364,
      unc_a: 0.191,
      unc_b: 0.1,
      unc_theta: -19.745,
      vangle: 109.03300000000002,
      vmag: 18.674,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Jan28/703_20230128_2B_N24033_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230128_2b_n24033_01_0002.arch?ra=150.93885&dec=24.11879&size=5.00arcmin&format=fits',
      date: '2023-01-28 07:12:51.716',
      ddec: 10.9672,
      dec: 24.11879,
      delta: 3.48828156441207,
      dra: -20.5801,
      drh: -2.7417163,
      elong: 159.8177,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59972.300424953704,
      mjd_stop: 59972.30077217593,
      phase: 4.4077,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230128_2b_n24033_01_0002.arch?ra=150.93885&dec=24.11879&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230128_2b_n24033_01_0002.arch',
      ra: 150.93885,
      rh: 4.425751010565,
      sangle: 77.30700000000002,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 225.0486,
      unc_a: 0.189,
      unc_b: 0.1,
      unc_theta: -19.911,
      vangle: 109.24099999999999,
      vmag: 18.688,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Jan28/703_20230128_2B_N24033_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230128_2b_n24033_01_0003.arch?ra=150.93805&dec=24.11917&size=5.00arcmin&format=fits',
      date: '2023-01-28 07:20:27.022',
      ddec: 10.96017,
      dec: 24.11917,
      delta: 3.48824880558596,
      dra: -20.5899,
      drh: -2.7417405,
      elong: 159.8228,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59972.30569469908,
      mjd_stop: 59972.3060419213,
      phase: 4.4066,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230128_2b_n24033_01_0003.arch?ra=150.93805&dec=24.11917&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230128_2b_n24033_01_0003.arch',
      ra: 150.93805,
      rh: 4.425742665726,
      sangle: 77.29700000000003,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 225.0491,
      unc_a: 0.189,
      unc_b: 0.1,
      unc_theta: -19.911,
      vangle: 109.24099999999999,
      vmag: 18.688,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Jan28/703_20230128_2B_N24033_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230128_2b_n24033_01_0004.arch?ra=150.93726&dec=24.11956&size=5.00arcmin&format=fits',
      date: '2023-01-28 07:28:02.310',
      ddec: 10.95304,
      dec: 24.11956,
      delta: 3.48821608931973,
      dra: -20.5991,
      drh: -2.7417647,
      elong: 159.8278,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59972.31096423611,
      mjd_stop: 59972.31131145833,
      phase: 4.4056,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230128_2b_n24033_01_0004.arch?ra=150.93726&dec=24.11956&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230128_2b_n24033_01_0004.arch',
      ra: 150.93726,
      rh: 4.425734321144,
      sangle: 77.286,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 225.0496,
      unc_a: 0.189,
      unc_b: 0.1,
      unc_theta: -19.911,
      vangle: 109.24099999999999,
      vmag: 18.688,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Jan28/703_20230128_2B_N24033_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230128_2b_n24033_01_0001.arch?ra=150.93964&dec=24.1184&size=5.00arcmin&format=fits',
      date: '2023-01-28 07:05:16.568',
      ddec: 10.97412,
      dec: 24.1184,
      delta: 3.48831435259506,
      dra: -20.5698,
      drh: -2.7416922,
      elong: 159.8126,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59972.295157037035,
      mjd_stop: 59972.29550425926,
      phase: 4.4087,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230128_2b_n24033_01_0001.arch?ra=150.93964&dec=24.1184&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230128_2b_n24033_01_0001.arch',
      ra: 150.93964,
      rh: 4.425759352435,
      sangle: 77.31799999999998,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 225.0481,
      unc_a: 0.189,
      unc_b: 0.1,
      unc_theta: -19.912,
      vangle: 109.24200000000002,
      vmag: 18.688,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Jan25/703_20230125_2B_N24033_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230125_2b_n24033_01_0001.arch?ra=151.36213&dec=23.90379&size=5.00arcmin&format=fits',
      date: '2023-01-25 08:07:28.902',
      ddec: 11.0074,
      dec: 23.90379,
      delta: 3.50772274233669,
      dra: -19.5753,
      drh: -2.7281133,
      elong: 156.9383,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59969.33835534722,
      mjd_stop: 59969.33870256945,
      phase: 4.9986,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230125_2b_n24033_01_0001.arch?ra=151.36213&dec=23.90379&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230125_2b_n24033_01_0001.arch',
      ra: 151.36213,
      rh: 4.430429916916,
      sangle: 82.60500000000002,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 224.7634,
      unc_a: 0.188,
      unc_b: 0.1,
      unc_theta: -20.061,
      vangle: 109.43799999999999,
      vmag: 18.705,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Jan25/703_20230125_2B_N24033_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230125_2b_n24033_01_0002.arch?ra=151.36137&dec=23.90418&size=5.00arcmin&format=fits',
      date: '2023-01-25 08:15:06.362',
      ddec: 11.00011,
      dec: 23.90418,
      delta: 3.50768540853715,
      dra: -19.5821,
      drh: -2.7281376,
      elong: 156.9436,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59969.34365002315,
      mjd_stop: 59969.34399724537,
      phase: 4.9975,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230125_2b_n24033_01_0002.arch?ra=151.36137&dec=23.90418&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230125_2b_n24033_01_0002.arch',
      ra: 151.36137,
      rh: 4.430421574157,
      sangle: 82.596,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 224.7639,
      unc_a: 0.188,
      unc_b: 0.1,
      unc_theta: -20.061,
      vangle: 109.43700000000001,
      vmag: 18.705,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Jan25/703_20230125_2B_N24033_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230125_2b_n24033_01_0003.arch?ra=151.36052&dec=23.90462&size=5.00arcmin&format=fits',
      date: '2023-01-25 08:23:42.107',
      ddec: 10.99181,
      dec: 23.90462,
      delta: 3.50764337049739,
      dra: -19.5891,
      drh: -2.7281651,
      elong: 156.9496,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59969.34961929398,
      mjd_stop: 59969.3499665162,
      phase: 4.9963,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230125_2b_n24033_01_0003.arch?ra=151.36052&dec=23.90462&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230125_2b_n24033_01_0003.arch',
      ra: 151.36052,
      rh: 4.430412168357,
      sangle: 82.58699999999999,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 224.7645,
      unc_a: 0.188,
      unc_b: 0.1,
      unc_theta: -20.06,
      vangle: 109.43700000000001,
      vmag: 18.705,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Jan25/703_20230125_2B_N24033_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230125_2b_n24033_01_0004.arch?ra=151.35976&dec=23.90501&size=5.00arcmin&format=fits',
      date: '2023-01-25 08:31:22.219',
      ddec: 10.98435,
      dec: 23.90501,
      delta: 3.50760591431193,
      dra: -19.5948,
      drh: -2.7281896,
      elong: 156.955,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59969.35494466435,
      mjd_stop: 59969.355291886575,
      phase: 4.9952,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230125_2b_n24033_01_0004.arch?ra=151.35976&dec=23.90501&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230125_2b_n24033_01_0004.arch',
      ra: 151.35976,
      rh: 4.430403777074,
      sangle: 82.57900000000001,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 224.765,
      unc_a: 0.188,
      unc_b: 0.1,
      unc_theta: -20.06,
      vangle: 109.43700000000001,
      vmag: 18.705,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Jan21/703_20230121_2B_N24033_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230121_2b_n24033_01_0003.arch?ra=151.8938&dec=23.61291&size=5.00arcmin&format=fits',
      date: '2023-01-21 08:16:02.996',
      ddec: 10.99231,
      dec: 23.61291,
      delta: 3.537960014669,
      dra: -17.9661,
      drh: -2.7097096,
      elong: 152.8798,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59965.34430550926,
      mjd_stop: 59965.34465273148,
      phase: 5.8085,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230121_2b_n24033_01_0003.arch?ra=151.8938&dec=23.61291&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230121_2b_n24033_01_0003.arch',
      ra: 151.8938,
      rh: 4.436702071808,
      sangle: 88.00599999999997,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 224.3798,
      unc_a: 0.186,
      unc_b: 0.099,
      unc_theta: -20.235,
      vangle: 109.68299999999999,
      vmag: 18.729,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Jan21/703_20230121_2B_N24033_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230121_2b_n24033_01_0002.arch?ra=151.89449&dec=23.61252&size=5.00arcmin&format=fits',
      date: '2023-01-21 08:08:25.797',
      ddec: 10.9991,
      dec: 23.61252,
      delta: 3.53800343789284,
      dra: -17.9577,
      drh: -2.7096852,
      elong: 152.8743,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59965.339013854165,
      mjd_stop: 59965.33936107639,
      phase: 5.8096,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230121_2b_n24033_01_0002.arch?ra=151.89449&dec=23.61252&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230121_2b_n24033_01_0002.arch',
      ra: 151.89449,
      rh: 4.436710353541,
      sangle: 88.012,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 224.3793,
      unc_a: 0.186,
      unc_b: 0.099,
      unc_theta: -20.235,
      vangle: 109.68400000000003,
      vmag: 18.729,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Jan21/703_20230121_2B_N24033_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230121_2b_n24033_01_0004.arch?ra=151.8931&dec=23.6133&size=5.00arcmin&format=fits',
      date: '2023-01-21 08:23:43.502',
      ddec: 10.9854,
      dec: 23.6133,
      delta: 3.53791632049612,
      dra: -17.9739,
      drh: -2.7097343,
      elong: 152.8854,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59965.349635439816,
      mjd_stop: 59965.34998266204,
      phase: 5.8074,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230121_2b_n24033_01_0004.arch?ra=151.8931&dec=23.6133&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230121_2b_n24033_01_0004.arch',
      ra: 151.8931,
      rh: 4.436693730096,
      sangle: 88,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 224.3803,
      unc_a: 0.186,
      unc_b: 0.099,
      unc_theta: -20.235,
      vangle: 109.68299999999999,
      vmag: 18.729,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Jan21/703_20230121_2B_N24033_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230121_2b_n24033_01_0001.arch?ra=151.89519&dec=23.61213&size=5.00arcmin&format=fits',
      date: '2023-01-21 08:00:48.288',
      ddec: 11.00581,
      dec: 23.61213,
      delta: 3.53804693286593,
      dra: -17.9487,
      drh: -2.7096608,
      elong: 152.8687,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59965.33371861111,
      mjd_stop: 59965.33406583333,
      phase: 5.8107,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230121_2b_n24033_01_0001.arch?ra=151.89519&dec=23.61213&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230121_2b_n24033_01_0001.arch',
      ra: 151.89519,
      rh: 4.436718640815,
      sangle: 88.019,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 224.3788,
      unc_a: 0.186,
      unc_b: 0.099,
      unc_theta: -20.235,
      vangle: 109.68400000000003,
      vmag: 18.729,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Mar11/703_20230311_2B_N24031_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230311_2b_n24031_01_0003.arch?ra=144.12791&dec=26.27281&size=5.00arcmin&format=fits',
      date: '2023-03-11 08:53:25.787',
      ddec: 2.680267,
      dec: 26.27281,
      delta: 3.49859467980172,
      dra: -18.8811,
      drh: -2.9304337,
      elong: 145.9113,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60014.37026373843,
      mjd_stop: 60014.37061096065,
      phase: 7.3362,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230311_2b_n24031_01_0003.arch?ra=144.12791&dec=26.27281&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230311_2b_n24031_01_0003.arch',
      ra: 144.12791,
      rh: 4.356824407769,
      sangle: 306.468,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 229.168,
      unc_a: 0.19,
      unc_b: 0.097,
      unc_theta: -16.821,
      vangle: 106.082,
      vmag: 18.632,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Mar11/703_20230311_2B_N24031_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230311_2b_n24031_01_0004.arch?ra=144.12717&dec=26.2729&size=5.00arcmin&format=fits',
      date: '2023-03-11 09:00:58.622',
      ddec: 2.673392,
      dec: 26.2729,
      delta: 3.49862837107639,
      dra: -18.8654,
      drh: -2.9304567,
      elong: 145.9058,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60014.37550488426,
      mjd_stop: 60014.37585210648,
      phase: 7.3373,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230311_2b_n24031_01_0004.arch?ra=144.12717&dec=26.2729&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230311_2b_n24031_01_0004.arch',
      ra: 144.12717,
      rh: 4.356815537597,
      sangle: 306.464,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 229.1685,
      unc_a: 0.19,
      unc_b: 0.097,
      unc_theta: -16.82,
      vangle: 106.082,
      vmag: 18.632,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Mar11/703_20230311_2B_N24031_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230311_2b_n24031_01_0002.arch?ra=144.12865&dec=26.27272&size=5.00arcmin&format=fits',
      date: '2023-03-11 08:45:51.851',
      ddec: 2.687355,
      dec: 26.27272,
      delta: 3.49856093766979,
      dra: -18.8964,
      drh: -2.9304107,
      elong: 145.9169,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60014.365009849535,
      mjd_stop: 60014.36535707176,
      phase: 7.3351,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230311_2b_n24031_01_0002.arch?ra=144.12865&dec=26.27272&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230311_2b_n24031_01_0002.arch',
      ra: 144.12865,
      rh: 4.356833299438,
      sangle: 306.472,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 229.1675,
      unc_a: 0.19,
      unc_b: 0.097,
      unc_theta: -16.821,
      vangle: 106.08300000000003,
      vmag: 18.632,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Mar11/703_20230311_2B_N24031_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230311_2b_n24031_01_0001.arch?ra=144.12938&dec=26.27262&size=5.00arcmin&format=fits',
      date: '2023-03-11 08:38:18.580',
      ddec: 2.694623,
      dec: 26.27262,
      delta: 3.49852727684088,
      dra: -18.9113,
      drh: -2.9303877,
      elong: 145.9224,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60014.359763657405,
      mjd_stop: 60014.36011087963,
      phase: 7.334,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230311_2b_n24031_01_0001.arch?ra=144.12938&dec=26.27262&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230311_2b_n24031_01_0001.arch',
      ra: 144.12938,
      rh: 4.356842178012,
      sangle: 306.477,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 229.167,
      unc_a: 0.19,
      unc_b: 0.097,
      unc_theta: -16.822,
      vangle: 106.08300000000003,
      vmag: 18.632,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Mar09/703_20230309_2B_N24031_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230309_2b_n24031_01_0001.arch?ra=144.40823&dec=26.23151&size=5.00arcmin&format=fits',
      date: '2023-03-09 08:57:43.063',
      ddec: 3.225112,
      dec: 26.23151,
      delta: 3.48656169956932,
      dra: -19.6394,
      drh: -2.9216775,
      elong: 147.9961,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60012.373241469904,
      mjd_stop: 60012.37358869213,
      phase: 6.9256,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230309_2b_n24031_01_0001.arch?ra=144.40823&dec=26.23151&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230309_2b_n24031_01_0001.arch',
      ra: 144.40823,
      rh: 4.360199137123,
      sangle: 308.102,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 228.9695,
      unc_a: 0.191,
      unc_b: 0.097,
      unc_theta: -16.967,
      vangle: 106.207,
      vmag: 18.627,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Mar09/703_20230309_2B_N24031_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230309_2b_n24031_01_0003.arch?ra=144.40677&dec=26.23173&size=5.00arcmin&format=fits',
      date: '2023-03-09 09:12:07.397',
      ddec: 3.211913,
      dec: 26.23173,
      delta: 3.48662095721714,
      dra: -19.6098,
      drh: -2.9217214,
      elong: 147.9855,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60012.38324533565,
      mjd_stop: 60012.38359255787,
      phase: 6.9277,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230309_2b_n24031_01_0003.arch?ra=144.40677&dec=26.23173&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230309_2b_n24031_01_0003.arch',
      ra: 144.40677,
      rh: 4.360182256951,
      sangle: 308.09299999999996,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 228.9705,
      unc_a: 0.191,
      unc_b: 0.097,
      unc_theta: -16.966,
      vangle: 106.20600000000002,
      vmag: 18.627,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Mar09/703_20230309_2B_N24031_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230309_2b_n24031_01_0004.arch?ra=144.40604&dec=26.23183&size=5.00arcmin&format=fits',
      date: '2023-03-09 09:19:19.674',
      ddec: 3.205589,
      dec: 26.23183,
      delta: 3.48665063500555,
      dra: -19.5944,
      drh: -2.9217434,
      elong: 147.9803,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60012.38824854167,
      mjd_stop: 60012.38859576389,
      phase: 6.9287,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230309_2b_n24031_01_0004.arch?ra=144.40604&dec=26.23183&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230309_2b_n24031_01_0004.arch',
      ra: 144.40604,
      rh: 4.360173814622,
      sangle: 308.08799999999997,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 228.971,
      unc_a: 0.191,
      unc_b: 0.097,
      unc_theta: -16.965,
      vangle: 106.20600000000002,
      vmag: 18.627,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Mar09/703_20230309_2B_N24031_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230309_2b_n24031_01_0002.arch?ra=144.4075&dec=26.23162&size=5.00arcmin&format=fits',
      date: '2023-03-09 09:04:55.458',
      ddec: 3.218419,
      dec: 26.23162,
      delta: 3.48659132996949,
      dra: -19.6248,
      drh: -2.9216995,
      elong: 147.9908,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60012.37824604167,
      mjd_stop: 60012.378593263886,
      phase: 6.9266,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230309_2b_n24031_01_0002.arch?ra=144.4075&dec=26.23162&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230309_2b_n24031_01_0002.arch',
      ra: 144.4075,
      rh: 4.360190692616,
      sangle: 308.097,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 228.97,
      unc_a: 0.191,
      unc_b: 0.097,
      unc_theta: -16.966,
      vangle: 106.20600000000002,
      vmag: 18.627,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Mar18/703_20230318_2B_N28030_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230318_2b_n28030_01_0004.arch?ra=143.25463&dec=26.36004&size=5.00arcmin&format=fits',
      date: '2023-03-18 05:59:45.965',
      ddec: 0.949072,
      dec: 26.36004,
      delta: 3.547458069213,
      dra: -16.0912,
      drh: -2.9604315,
      elong: 138.7205,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60021.24966394676,
      mjd_stop: 60021.25001116898,
      phase: 8.6857,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230318_2b_n28030_01_0004.arch?ra=143.25463&dec=26.36004&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230318_2b_n28030_01_0004.arch',
      ra: 143.25463,
      rh: 4.34512205082,
      sangle: 301.983,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 229.8543,
      unc_a: 0.187,
      unc_b: 0.095,
      unc_theta: -16.354,
      vangle: 105.707,
      vmag: 18.651,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Mar18/703_20230318_2B_N28030_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230318_2b_n28030_01_0001.arch?ra=143.25653&dec=26.35994&size=5.00arcmin&format=fits',
      date: '2023-03-18 05:36:57.608',
      ddec: 0.97737,
      dec: 26.35994,
      delta: 3.54733248799037,
      dra: -16.1066,
      drh: -2.9603627,
      elong: 138.7372,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60021.23382648148,
      mjd_stop: 60021.234173703706,
      phase: 8.6827,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230318_2b_n28030_01_0001.arch?ra=143.25653&dec=26.35994&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230318_2b_n28030_01_0001.arch',
      ra: 143.25653,
      rh: 4.34514912804,
      sangle: 301.992,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 229.8528,
      unc_a: 0.187,
      unc_b: 0.095,
      unc_theta: -16.355,
      vangle: 105.707,
      vmag: 18.651,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Mar18/703_20230318_2B_N28030_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230318_2b_n28030_01_0003.arch?ra=143.25526&dec=26.36001&size=5.00arcmin&format=fits',
      date: '2023-03-18 05:52:11.027',
      ddec: 0.958439,
      dec: 26.36001,
      delta: 3.54741627567785,
      dra: -16.0969,
      drh: -2.9604086,
      elong: 138.7261,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60021.24439846065,
      mjd_stop: 60021.24474568287,
      phase: 8.6847,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230318_2b_n28030_01_0003.arch?ra=143.25526&dec=26.36001&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230318_2b_n28030_01_0003.arch',
      ra: 143.25526,
      rh: 4.34513105326,
      sangle: 301.986,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 229.8538,
      unc_a: 0.187,
      unc_b: 0.095,
      unc_theta: -16.354,
      vangle: 105.707,
      vmag: 18.651,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Mar18/703_20230318_2B_N28030_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230318_2b_n28030_01_0002.arch?ra=143.25589&dec=26.35997&size=5.00arcmin&format=fits',
      date: '2023-03-18 05:44:35.423',
      ddec: 0.967864,
      dec: 26.35997,
      delta: 3.54737446234575,
      dra: -16.102,
      drh: -2.9603857,
      elong: 138.7317,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60021.239125266206,
      mjd_stop: 60021.23947248843,
      phase: 8.6837,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230318_2b_n28030_01_0002.arch?ra=143.25589&dec=26.35997&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230318_2b_n28030_01_0002.arch',
      ra: 143.25589,
      rh: 4.345140068809,
      sangle: 301.98900000000003,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 229.8533,
      unc_a: 0.187,
      unc_b: 0.095,
      unc_theta: -16.355,
      vangle: 105.707,
      vmag: 18.651,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Mar25/703_20230325_2B_N28030_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230325_2b_n28030_01_0004.arch?ra=142.53505&dec=26.36209&size=5.00arcmin&format=fits',
      date: '2023-03-25 05:42:26.838',
      ddec: -0.91066,
      dec: 26.36209,
      delta: 3.6078445407628,
      dra: -12.5811,
      drh: -2.990634,
      elong: 131.4836,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60028.23763701389,
      mjd_stop: 60028.23798423611,
      phase: 9.9221,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230325_2b_n28030_01_0004.arch?ra=142.53505&dec=26.36209&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230325_2b_n28030_01_0004.arch',
      ra: 142.53505,
      rh: 4.333113629281,
      sangle: 298.642,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 230.5554,
      unc_a: 0.183,
      unc_b: 0.093,
      unc_theta: -15.949,
      vangle: 105.42000000000002,
      vmag: 18.677,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Mar25/703_20230325_2B_N24031_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230325_2b_n24031_01_0004.arch?ra=142.53509&dec=26.36209&size=5.00arcmin&format=fits',
      date: '2023-03-25 05:41:49.237',
      ddec: -0.90991,
      dec: 26.36209,
      delta: 3.60784045343554,
      dra: -12.5817,
      drh: -2.9906321,
      elong: 131.4841,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60028.23720181713,
      mjd_stop: 60028.23754903935,
      phase: 9.922,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230325_2b_n24031_01_0004.arch?ra=142.53509&dec=26.36209&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230325_2b_n24031_01_0004.arch',
      ra: 142.53509,
      rh: 4.333114380927,
      sangle: 298.642,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 230.5553,
      unc_a: 0.183,
      unc_b: 0.093,
      unc_theta: -15.949,
      vangle: 105.42000000000002,
      vmag: 18.677,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Mar25/703_20230325_2B_N28030_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230325_2b_n28030_01_0003.arch?ra=142.53555&dec=26.36212&size=5.00arcmin&format=fits',
      date: '2023-03-25 05:34:43.990',
      ddec: -0.90142,
      dec: 26.36212,
      delta: 3.6077942468484,
      dra: -12.5882,
      drh: -2.990611,
      elong: 131.4892,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60028.23227997685,
      mjd_stop: 60028.232627199075,
      phase: 9.9212,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230325_2b_n28030_01_0003.arch?ra=142.53555&dec=26.36212&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230325_2b_n28030_01_0003.arch',
      ra: 142.53555,
      rh: 4.333122881609,
      sangle: 298.644,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 230.5548,
      unc_a: 0.183,
      unc_b: 0.093,
      unc_theta: -15.949,
      vangle: 105.42000000000002,
      vmag: 18.677,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Mar25/703_20230325_2B_N28030_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230325_2b_n28030_01_0002.arch?ra=142.53606&dec=26.36216&size=5.00arcmin&format=fits',
      date: '2023-03-25 05:27:00.459',
      ddec: -0.89211,
      dec: 26.36216,
      delta: 3.60774392018386,
      dra: -12.5947,
      drh: -2.9905879,
      elong: 131.4948,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60028.22691503472,
      mjd_stop: 60028.22726225694,
      phase: 9.9203,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230325_2b_n28030_01_0002.arch?ra=142.53606&dec=26.36216&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230325_2b_n28030_01_0002.arch',
      ra: 142.53606,
      rh: 4.333132147518,
      sangle: 298.646,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 230.5543,
      unc_a: 0.183,
      unc_b: 0.093,
      unc_theta: -15.949,
      vangle: 105.42000000000002,
      vmag: 18.677,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Mar25/703_20230325_2B_N28030_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230325_2b_n28030_01_0001.arch?ra=142.53656&dec=26.36219&size=5.00arcmin&format=fits',
      date: '2023-03-25 05:19:15.792',
      ddec: -0.88273,
      dec: 26.36219,
      delta: 3.60769351207905,
      dra: -12.6006,
      drh: -2.9905647,
      elong: 131.5003,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60028.221536944446,
      mjd_stop: 60028.22188416667,
      phase: 9.9194,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230325_2b_n28030_01_0001.arch?ra=142.53656&dec=26.36219&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230325_2b_n28030_01_0001.arch',
      ra: 142.53656,
      rh: 4.333141436065,
      sangle: 298.649,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 230.5537,
      unc_a: 0.183,
      unc_b: 0.093,
      unc_theta: -15.95,
      vangle: 105.42000000000002,
      vmag: 18.677,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Mar28/703_20230328_2B_N24031_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230328_2b_n24031_01_0001.arch?ra=142.28657&dec=26.33744&size=5.00arcmin&format=fits',
      date: '2023-03-28 04:53:33.745',
      ddec: -1.6259,
      dec: 26.33744,
      delta: 3.63635863819087,
      dra: -11.0048,
      drh: -3.0033701,
      elong: 128.4521,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60031.20368917824,
      mjd_stop: 60031.204036400464,
      phase: 10.3985,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230328_2b_n24031_01_0001.arch?ra=142.28657&dec=26.33744&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230328_2b_n24031_01_0001.arch',
      ra: 142.28657,
      rh: 4.327979927604,
      sangle: 297.476,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 230.8541,
      unc_a: 0.181,
      unc_b: 0.092,
      unc_theta: -15.801,
      vangle: 105.32999999999998,
      vmag: 18.689,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Mar28/703_20230328_2B_N24031_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230328_2b_n24031_01_0002.arch?ra=142.28614&dec=26.33738&size=5.00arcmin&format=fits',
      date: '2023-03-28 05:01:11.115',
      ddec: -1.63509,
      dec: 26.33738,
      delta: 3.6364110543711,
      dra: -11,
      drh: -3.0033927,
      elong: 128.4467,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60031.2089828125,
      mjd_stop: 60031.20933003472,
      phase: 10.3993,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230328_2b_n24031_01_0002.arch?ra=142.28614&dec=26.33738&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230328_2b_n24031_01_0002.arch',
      ra: 142.28614,
      rh: 4.327970745802,
      sangle: 297.474,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 230.8546,
      unc_a: 0.181,
      unc_b: 0.092,
      unc_theta: -15.801,
      vangle: 105.32999999999998,
      vmag: 18.689,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Mar28/703_20230328_2B_N24031_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230328_2b_n24031_01_0003.arch?ra=142.28571&dec=26.33732&size=5.00arcmin&format=fits',
      date: '2023-03-28 05:08:48.365',
      ddec: -1.64424,
      dec: 26.33732,
      delta: 3.63646349732903,
      dra: -10.9945,
      drh: -3.0034154,
      elong: 128.4412,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60031.21427505787,
      mjd_stop: 60031.214622280095,
      phase: 10.4001,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230328_2b_n24031_01_0003.arch?ra=142.28571&dec=26.33732&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230328_2b_n24031_01_0003.arch',
      ra: 142.28571,
      rh: 4.327961566341,
      sangle: 297.472,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 230.8552,
      unc_a: 0.181,
      unc_b: 0.092,
      unc_theta: -15.801,
      vangle: 105.32999999999998,
      vmag: 18.689,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Mar28/703_20230328_2B_N24031_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230328_2b_n24031_01_0004.arch?ra=142.28526&dec=26.33726&size=5.00arcmin&format=fits',
      date: '2023-03-28 05:16:34.901',
      ddec: -1.65354,
      dec: 26.33726,
      delta: 3.63651704690855,
      dra: -10.9883,
      drh: -3.0034386,
      elong: 128.4357,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60031.21967478009,
      mjd_stop: 60031.220022002315,
      phase: 10.401,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230328_2b_n24031_01_0004.arch?ra=142.28526&dec=26.33726&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230328_2b_n24031_01_0004.arch',
      ra: 142.28526,
      rh: 4.327952200389,
      sangle: 297.469,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 230.8557,
      unc_a: 0.181,
      unc_b: 0.092,
      unc_theta: -15.8,
      vangle: 105.32900000000001,
      vmag: 18.689,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Apr09/703_20230409_2B_N24031_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230409_2b_n24031_01_0004.arch?ra=141.64808&dec=26.09277&size=5.00arcmin&format=fits',
      date: '2023-04-09 05:56:54.567',
      ddec: -4.51969,
      dec: 26.09277,
      delta: 3.76653952623495,
      dra: -4.18454,
      drh: -3.0545593,
      elong: 116.458,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60043.24768017361,
      mjd_stop: 60043.248027395835,
      phase: 12.0098,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230409_2b_n24031_01_0004.arch?ra=141.64808&dec=26.09277&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230409_2b_n24031_01_0004.arch',
      ra: 141.64808,
      rh: 4.306911307157,
      sangle: 293.772,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 232.0744,
      unc_a: 0.173,
      unc_b: 0.088,
      unc_theta: -15.365,
      vangle: 105.16699999999997,
      vmag: 18.746,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Apr09/703_20230409_2B_N24031_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230409_2b_n24031_01_0001.arch?ra=141.64857&dec=26.09325&size=5.00arcmin&format=fits',
      date: '2023-04-09 05:33:50.919',
      ddec: -4.49662,
      dec: 26.09325,
      delta: 3.76635168741885,
      dra: -4.22043,
      drh: -3.0544918,
      elong: 116.4738,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60043.23166572917,
      mjd_stop: 60043.23201295139,
      phase: 12.0079,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230409_2b_n24031_01_0001.arch?ra=141.64857&dec=26.09325&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230409_2b_n24031_01_0001.arch',
      ra: 141.64857,
      rh: 4.306939556902,
      sangle: 293.777,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 232.0728,
      unc_a: 0.173,
      unc_b: 0.088,
      unc_theta: -15.365,
      vangle: 105.16699999999997,
      vmag: 18.746,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Apr09/703_20230409_2B_N24031_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230409_2b_n24031_01_0003.arch?ra=141.64824&dec=26.09293&size=5.00arcmin&format=fits',
      date: '2023-04-09 05:49:13.330',
      ddec: -4.51213,
      dec: 26.09293,
      delta: 3.76647687573183,
      dra: -4.19701,
      drh: -3.0545368,
      elong: 116.4633,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60043.24234178241,
      mjd_stop: 60043.242689004626,
      phase: 12.0092,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230409_2b_n24031_01_0003.arch?ra=141.64824&dec=26.09293&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230409_2b_n24031_01_0003.arch',
      ra: 141.64824,
      rh: 4.306920724237,
      sangle: 293.774,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 232.0739,
      unc_a: 0.173,
      unc_b: 0.088,
      unc_theta: -15.365,
      vangle: 105.16699999999997,
      vmag: 18.746,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Apr09/703_20230409_2B_N24031_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230409_2b_n24031_01_0002.arch?ra=141.64841&dec=26.09309&size=5.00arcmin&format=fits',
      date: '2023-04-09 05:41:32.353',
      ddec: -4.50444,
      dec: 26.09309,
      delta: 3.76641429501863,
      dra: -4.20897,
      drh: -3.0545143,
      elong: 116.4685,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60043.237006400464,
      mjd_stop: 60043.23735362269,
      phase: 12.0085,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230409_2b_n24031_01_0002.arch?ra=141.64841&dec=26.09309&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230409_2b_n24031_01_0002.arch',
      ra: 141.64841,
      rh: 4.306930135939,
      sangle: 293.775,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 232.0734,
      unc_a: 0.173,
      unc_b: 0.088,
      unc_theta: -15.365,
      vangle: 105.16699999999997,
      vmag: 18.746,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Apr12/703_20230412_2B_N24031_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230412_2b_n24031_01_0004.arch?ra=141.58388&dec=25.99914&size=5.00arcmin&format=fits',
      date: '2023-04-12 05:29:35.438',
      ddec: -5.1288,
      dec: 25.99914,
      delta: 3.80169953122962,
      dra: -2.51347,
      drh: -3.0670938,
      elong: 113.5746,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60046.228708773146,
      mjd_stop: 60046.22905599537,
      phase: 12.326,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230412_2b_n24031_01_0004.arch?ra=141.58388&dec=25.99914&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230412_2b_n24031_01_0004.arch',
      ra: 141.58388,
      rh: 4.301641869824,
      sangle: 293.048,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 232.3783,
      unc_a: 0.171,
      unc_b: 0.087,
      unc_theta: -15.298,
      vangle: 105.17700000000002,
      vmag: 18.761,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Apr12/703_20230412_2B_N24031_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230412_2b_n24031_01_0003.arch?ra=141.58398&dec=25.99932&size=5.00arcmin&format=fits',
      date: '2023-04-12 05:22:02.010',
      ddec: -5.12125,
      dec: 25.99932,
      delta: 3.80163625828788,
      dra: -2.52467,
      drh: -3.0670718,
      elong: 113.5797,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60046.22346076389,
      mjd_stop: 60046.22380798611,
      phase: 12.3254,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230412_2b_n24031_01_0003.arch?ra=141.58398&dec=25.99932&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230412_2b_n24031_01_0003.arch',
      ra: 141.58398,
      rh: 4.301651165441,
      sangle: 293.049,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 232.3778,
      unc_a: 0.171,
      unc_b: 0.087,
      unc_theta: -15.298,
      vangle: 105.17599999999999,
      vmag: 18.761,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Apr12/703_20230412_2B_N24031_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230412_2b_n24031_01_0001.arch?ra=141.58418&dec=25.99967&size=5.00arcmin&format=fits',
      date: '2023-04-12 05:06:54.953',
      ddec: -5.10579,
      dec: 25.99967,
      delta: 3.80150978749719,
      dra: -2.54558,
      drh: -3.0670278,
      elong: 113.5899,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60046.212962418984,
      mjd_stop: 60046.21330964121,
      phase: 12.3243,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230412_2b_n24031_01_0001.arch?ra=141.58418&dec=25.99967&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230412_2b_n24031_01_0001.arch',
      ra: 141.58418,
      rh: 4.301669760594,
      sangle: 293.052,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 232.3767,
      unc_a: 0.171,
      unc_b: 0.087,
      unc_theta: -15.298,
      vangle: 105.17599999999999,
      vmag: 18.761,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Apr12/703_20230412_2B_N24031_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230412_2b_n24031_01_0002.arch?ra=141.58408&dec=25.99949&size=5.00arcmin&format=fits',
      date: '2023-04-12 05:14:28.505',
      ddec: -5.11357,
      dec: 25.99949,
      delta: 3.80157300880652,
      dra: -2.53538,
      drh: -3.0670498,
      elong: 113.5848,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60046.21821186342,
      mjd_stop: 60046.218559085646,
      phase: 12.3249,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230412_2b_n24031_01_0002.arch?ra=141.58408&dec=25.99949&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230412_2b_n24031_01_0002.arch',
      ra: 141.58408,
      rh: 4.301660462568,
      sangle: 293.051,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 232.3772,
      unc_a: 0.171,
      unc_b: 0.087,
      unc_theta: -15.298,
      vangle: 105.17599999999999,
      vmag: 18.761,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Apr16/703_20230416_2B_N24031_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230416_2b_n24031_01_0003.arch?ra=141.5563&dec=25.85497&size=5.00arcmin&format=fits',
      date: '2023-04-16 05:15:05.255',
      ddec: -5.92471,
      dec: 25.85497,
      delta: 3.85020048352563,
      dra: -0.25203,
      drh: -3.0837832,
      elong: 109.7694,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60050.21863721065,
      mjd_stop: 60050.21898443287,
      phase: 12.6975,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230416_2b_n24031_01_0003.arch?ra=141.5563&dec=25.85497&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230416_2b_n24031_01_0003.arch',
      ra: 141.5563,
      rh: 4.29455538002,
      sangle: 292.169,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 232.7861,
      unc_a: 0.168,
      unc_b: 0.086,
      unc_theta: -15.234,
      vangle: 105.22000000000003,
      vmag: 18.782,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Apr16/703_20230416_2B_N24031_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230416_2b_n24031_01_0001.arch?ra=141.55632&dec=25.85539&size=5.00arcmin&format=fits',
      date: '2023-04-16 04:59:45.135',
      ddec: -5.90964,
      dec: 25.85539,
      delta: 3.85006802021839,
      dra: -0.2742,
      drh: -3.0837388,
      elong: 109.7796,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60050.20798767361,
      mjd_stop: 60050.20833489583,
      phase: 12.6966,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230416_2b_n24031_01_0001.arch?ra=141.55632&dec=25.85539&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230416_2b_n24031_01_0001.arch',
      ra: 141.55632,
      rh: 4.294574345706,
      sangle: 292.172,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 232.785,
      unc_a: 0.168,
      unc_b: 0.086,
      unc_theta: -15.234,
      vangle: 105.219,
      vmag: 18.782,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Apr16/703_20230416_2B_N24031_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230416_2b_n24031_01_0002.arch?ra=141.55631&dec=25.85518&size=5.00arcmin&format=fits',
      date: '2023-04-16 05:07:25.381',
      ddec: -5.91724,
      dec: 25.85518,
      delta: 3.85013426133281,
      dra: -0.26336,
      drh: -3.083761,
      elong: 109.7745,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60050.213314594905,
      mjd_stop: 60050.21366181713,
      phase: 12.697,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230416_2b_n24031_01_0002.arch?ra=141.55631&dec=25.85518&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230416_2b_n24031_01_0002.arch',
      ra: 141.55631,
      rh: 4.294564859063,
      sangle: 292.171,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 232.7855,
      unc_a: 0.168,
      unc_b: 0.086,
      unc_theta: -15.234,
      vangle: 105.219,
      vmag: 18.782,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Apr16/703_20230416_2B_N24031_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230416_2b_n24031_01_0004.arch?ra=141.5563&dec=25.85475&size=5.00arcmin&format=fits',
      date: '2023-04-16 05:22:46.314',
      ddec: -5.93207,
      dec: 25.85475,
      delta: 3.85026691048956,
      dra: -0.24017,
      drh: -3.0838055,
      elong: 109.7643,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60050.223973541666,
      mjd_stop: 60050.22432076389,
      phase: 12.698,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230416_2b_n24031_01_0004.arch?ra=141.5563&dec=25.85475&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230416_2b_n24031_01_0004.arch',
      ra: 141.5563,
      rh: 4.294545876484,
      sangle: 292.168,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 232.7866,
      unc_a: 0.168,
      unc_b: 0.086,
      unc_theta: -15.234,
      vangle: 105.22000000000003,
      vmag: 18.782,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Apr19/703_20230419_2B_N24031_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230419_2b_n24031_01_0004.arch?ra=141.5793&dec=25.73329&size=5.00arcmin&format=fits',
      date: '2023-04-19 05:04:38.829',
      ddec: -6.49404,
      dec: 25.73329,
      delta: 3.88750626823707,
      dra: 1.436062,
      drh: -3.0962347,
      elong: 106.9566,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60053.21138690972,
      mjd_stop: 60053.211734131946,
      phase: 12.9374,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230419_2b_n24031_01_0004.arch?ra=141.5793&dec=25.73329&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230419_2b_n24031_01_0004.arch',
      ra: 141.5793,
      rh: 4.289214806983,
      sangle: 291.57,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 233.0928,
      unc_a: 0.166,
      unc_b: 0.085,
      unc_theta: -15.205,
      vangle: 105.274,
      vmag: 18.798,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Apr19/703_20230419_2B_N24031_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230419_2b_n24031_01_0001.arch?ra=141.57911&dec=25.73404&size=5.00arcmin&format=fits',
      date: '2023-04-19 04:39:31.576',
      ddec: -6.46963,
      dec: 25.73404,
      delta: 3.88728514594083,
      dra: 1.400855,
      drh: -3.0961623,
      elong: 106.9731,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60053.19394185185,
      mjd_stop: 60053.194289074076,
      phase: 12.936,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230419_2b_n24031_01_0001.arch?ra=141.57911&dec=25.73404&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230419_2b_n24031_01_0001.arch',
      ra: 141.57911,
      rh: 4.289246000025,
      sangle: 291.574,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 233.091,
      unc_a: 0.166,
      unc_b: 0.085,
      unc_theta: -15.205,
      vangle: 105.27300000000002,
      vmag: 18.798,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Apr19/703_20230419_2B_N24031_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230419_2b_n24031_01_0002.arch?ra=141.57917&dec=25.73379&size=5.00arcmin&format=fits',
      date: '2023-04-19 04:47:53.151',
      ddec: -6.47789,
      dec: 25.73379,
      delta: 3.88735868848249,
      dra: 1.411972,
      drh: -3.0961864,
      elong: 106.9676,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60053.19974711806,
      mjd_stop: 60053.200094340275,
      phase: 12.9365,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230419_2b_n24031_01_0002.arch?ra=141.57917&dec=25.73379&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230419_2b_n24031_01_0002.arch',
      ra: 141.57917,
      rh: 4.289235619864,
      sangle: 291.573,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 233.0916,
      unc_a: 0.166,
      unc_b: 0.085,
      unc_theta: -15.205,
      vangle: 105.274,
      vmag: 18.798,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Apr19/703_20230419_2B_N24031_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230419_2b_n24031_01_0003.arch?ra=141.57923&dec=25.73354&size=5.00arcmin&format=fits',
      date: '2023-04-19 04:56:16.102',
      ddec: -6.48604,
      dec: 25.73354,
      delta: 3.88743247435736,
      dra: 1.423724,
      drh: -3.0962106,
      elong: 106.9621,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60053.205568310186,
      mjd_stop: 60053.20591553241,
      phase: 12.9369,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230419_2b_n24031_01_0003.arch?ra=141.57923&dec=25.73354&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230419_2b_n24031_01_0003.arch',
      ra: 141.57923,
      rh: 4.289225211146,
      sangle: 291.571,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 233.0922,
      unc_a: 0.166,
      unc_b: 0.085,
      unc_theta: -15.205,
      vangle: 105.274,
      vmag: 18.798,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Apr23/703_20230423_2B_N24031_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230423_2b_n24031_01_0002.arch?ra=141.66757&dec=25.55377&size=5.00arcmin&format=fits',
      date: '2023-04-23 04:55:02.211',
      ddec: -7.21791,
      dec: 25.55377,
      delta: 3.93828202232797,
      dra: 3.662598,
      drh: -3.1127584,
      elong: 103.2598,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60057.20471309028,
      mjd_stop: 60057.2050603125,
      phase: 13.2061,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230423_2b_n24031_01_0002.arch?ra=141.66757&dec=25.55377&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230423_2b_n24031_01_0002.arch',
      ra: 141.66757,
      rh: 4.28205529191,
      sangle: 290.841,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 233.5032,
      unc_a: 0.163,
      unc_b: 0.083,
      unc_theta: -15.19,
      vangle: 105.375,
      vmag: 18.819,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Apr23/703_20230423_2B_N24031_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230423_2b_n24031_01_0004.arch?ra=141.66788&dec=25.55322&size=5.00arcmin&format=fits',
      date: '2023-04-23 05:11:28.946',
      ddec: -7.23258,
      dec: 25.55322,
      delta: 3.93842992924415,
      dra: 3.688858,
      drh: -3.1128055,
      elong: 103.2492,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60057.21613363426,
      mjd_stop: 60057.216480856485,
      phase: 13.2068,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230423_2b_n24031_01_0004.arch?ra=141.66788&dec=25.55322&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230423_2b_n24031_01_0004.arch',
      ra: 141.66788,
      rh: 4.282034761799,
      sangle: 290.839,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 233.5044,
      unc_a: 0.163,
      unc_b: 0.083,
      unc_theta: -15.19,
      vangle: 105.375,
      vmag: 18.819,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Apr23/703_20230423_2B_N24031_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230423_2b_n24031_01_0001.arch?ra=141.66742&dec=25.55405&size=5.00arcmin&format=fits',
      date: '2023-04-23 04:46:49.846',
      ddec: -7.21036,
      dec: 25.55405,
      delta: 3.93820827479369,
      dra: 3.650301,
      drh: -3.1127349,
      elong: 103.265,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60057.1990144213,
      mjd_stop: 60057.19936164352,
      phase: 13.2058,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230423_2b_n24031_01_0001.arch?ra=141.66742&dec=25.55405&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230423_2b_n24031_01_0001.arch',
      ra: 141.66742,
      rh: 4.282065535993,
      sangle: 290.842,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 233.5027,
      unc_a: 0.163,
      unc_b: 0.083,
      unc_theta: -15.19,
      vangle: 105.375,
      vmag: 18.819,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Apr23/703_20230423_2B_N24031_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230423_2b_n24031_01_0003.arch?ra=141.66773&dec=25.5535&size=5.00arcmin&format=fits',
      date: '2023-04-23 05:03:15.822',
      ddec: -7.22533,
      dec: 25.5535,
      delta: 3.9383559939593,
      dra: 3.675469,
      drh: -3.112782,
      elong: 103.2545,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60057.21042618056,
      mjd_stop: 60057.21077340278,
      phase: 13.2065,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230423_2b_n24031_01_0003.arch?ra=141.66773&dec=25.5535&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230423_2b_n24031_01_0003.arch',
      ra: 141.66773,
      rh: 4.282045021827,
      sangle: 290.84000000000003,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 233.5038,
      unc_a: 0.163,
      unc_b: 0.083,
      unc_theta: -15.19,
      vangle: 105.375,
      vmag: 18.819,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Apr26/703_20230426_2B_N24031_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230426_2b_n24031_01_0004.arch?ra=141.77604&dec=25.40706&size=5.00arcmin&format=fits',
      date: '2023-04-26 04:44:02.096',
      ddec: -7.72717,
      dec: 25.40706,
      delta: 3.9769147778376,
      dra: 5.29063,
      drh: -3.1250712,
      elong: 100.532,
      exposure: 10,
      filter: null,
      maglimit: null,
      mjd_start: 60060.19718861111,
      mjd_stop: 60060.19730435185,
      phase: 13.3697,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230426_2b_n24031_01_0004.arch?ra=141.77604&dec=25.40706&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230426_2b_n24031_01_0004.arch',
      ra: 141.77604,
      rh: 4.276665475857,
      sangle: 290.341,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 233.8117,
      unc_a: 0.161,
      unc_b: 0.082,
      unc_theta: -15.197,
      vangle: 105.471,
      vmag: 18.835,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Apr26/703_20230426_2B_N24031_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230426_2b_n24031_01_0001.arch?ra=141.77592&dec=25.40723&size=5.00arcmin&format=fits',
      date: '2023-04-26 04:39:28.579',
      ddec: -7.72308,
      dec: 25.40723,
      delta: 3.97687335203087,
      dra: 5.283805,
      drh: -3.1250582,
      elong: 100.5349,
      exposure: 10,
      filter: null,
      maglimit: null,
      mjd_start: 60060.194022905096,
      mjd_stop: 60060.194138645835,
      phase: 13.3695,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230426_2b_n24031_01_0001.arch?ra=141.77592&dec=25.40723&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230426_2b_n24031_01_0001.arch',
      ra: 141.77592,
      rh: 4.276671189132,
      sangle: 290.342,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 233.8113,
      unc_a: 0.161,
      unc_b: 0.082,
      unc_theta: -15.197,
      vangle: 105.471,
      vmag: 18.835,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Apr26/703_20230426_2B_N24031_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230426_2b_n24031_01_0003.arch?ra=141.776&dec=25.40712&size=5.00arcmin&format=fits',
      date: '2023-04-26 04:42:30.863',
      ddec: -7.72581,
      dec: 25.40712,
      delta: 3.97690095877138,
      dra: 5.288335,
      drh: -3.1250669,
      elong: 100.5329,
      exposure: 10,
      filter: null,
      maglimit: null,
      mjd_start: 60060.19613267361,
      mjd_stop: 60060.19624841435,
      phase: 13.3696,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230426_2b_n24031_01_0003.arch?ra=141.776&dec=25.40712&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230426_2b_n24031_01_0003.arch',
      ra: 141.776,
      rh: 4.276667381552,
      sangle: 290.341,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 233.8116,
      unc_a: 0.161,
      unc_b: 0.082,
      unc_theta: -15.197,
      vangle: 105.471,
      vmag: 18.835,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23Apr26/703_20230426_2B_N24031_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230426_2b_n24031_01_0002.arch?ra=141.77596&dec=25.40717&size=5.00arcmin&format=fits',
      date: '2023-04-26 04:40:59.676',
      ddec: -7.72444,
      dec: 25.40717,
      delta: 3.97688714794833,
      dra: 5.28606,
      drh: -3.1250625,
      elong: 100.5339,
      exposure: 10,
      filter: null,
      maglimit: null,
      mjd_start: 60060.195077268516,
      mjd_stop: 60060.19519300926,
      phase: 13.3696,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230426_2b_n24031_01_0002.arch?ra=141.77596&dec=25.40717&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230426_2b_n24031_01_0002.arch',
      ra: 141.77596,
      rh: 4.276669286283,
      sangle: 290.341,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 233.8115,
      unc_a: 0.161,
      unc_b: 0.082,
      unc_theta: -15.197,
      vangle: 105.471,
      vmag: 18.835,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May03/703_20230503_2B_N24031_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230503_2b_n24031_01_0002.arch?ra=142.16361&dec=25.0281&size=5.00arcmin&format=fits',
      date: '2023-05-03 03:54:10.651',
      ddec: -8.79951,
      dec: 25.0281,
      delta: 4.06808442716619,
      dra: 8.901805,
      drh: -3.1534979,
      elong: 94.3208,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60067.16244966435,
      mjd_stop: 60067.16279688657,
      phase: 13.6294,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230503_2b_n24031_01_0002.arch?ra=142.16361&dec=25.0281&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230503_2b_n24031_01_0002.arch',
      ra: 142.16361,
      rh: 4.264037475261,
      sangle: 289.30899999999997,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 234.5326,
      unc_a: 0.156,
      unc_b: 0.08,
      unc_theta: -15.268,
      vangle: 105.75599999999997,
      vmag: 18.873,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May03/703_20230503_2B_N24031_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230503_2b_n24031_01_0003.arch?ra=142.16401&dec=25.02775&size=5.00arcmin&format=fits',
      date: '2023-05-03 04:02:55.206',
      ddec: -8.80728,
      dec: 25.02775,
      delta: 4.06816494767211,
      dra: 8.913269,
      drh: -3.1535226,
      elong: 94.3154,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60067.16852090278,
      mjd_stop: 60067.168868125,
      phase: 13.6296,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230503_2b_n24031_01_0003.arch?ra=142.16401&dec=25.02775&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230503_2b_n24031_01_0003.arch',
      ra: 142.16401,
      rh: 4.264026418534,
      sangle: 289.308,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 234.5332,
      unc_a: 0.156,
      unc_b: 0.08,
      unc_theta: -15.268,
      vangle: 105.757,
      vmag: 18.873,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May03/703_20230503_2B_N24031_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230503_2b_n24031_01_0001.arch?ra=142.16322&dec=25.02846&size=5.00arcmin&format=fits',
      date: '2023-05-03 03:45:26.453',
      ddec: -8.79161,
      dec: 25.02846,
      delta: 4.06800400485732,
      dra: 8.890977,
      drh: -3.1534733,
      elong: 94.3261,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60067.15638255787,
      mjd_stop: 60067.156729780094,
      phase: 13.6292,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230503_2b_n24031_01_0001.arch?ra=142.16322&dec=25.02846&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230503_2b_n24031_01_0001.arch',
      ra: 142.16322,
      rh: 4.264048524377,
      sangle: 289.31,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 234.5319,
      unc_a: 0.156,
      unc_b: 0.08,
      unc_theta: -15.268,
      vangle: 105.75599999999997,
      vmag: 18.873,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May03/703_20230503_2B_N24031_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230503_2b_n24031_01_0004.arch?ra=142.16441&dec=25.02739&size=5.00arcmin&format=fits',
      date: '2023-05-03 04:11:39.444',
      ddec: -8.8149,
      dec: 25.02739,
      delta: 4.06824546208653,
      dra: 8.925343,
      drh: -3.1535472,
      elong: 94.31,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60067.17458847222,
      mjd_stop: 60067.17493569444,
      phase: 13.6298,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230503_2b_n24031_01_0004.arch?ra=142.16441&dec=25.02739&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230503_2b_n24031_01_0004.arch',
      ra: 142.16441,
      rh: 4.264015368404,
      sangle: 289.307,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 234.5338,
      unc_a: 0.156,
      unc_b: 0.08,
      unc_theta: -15.268,
      vangle: 105.757,
      vmag: 18.873,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May08/703_20230508_2B_N24031_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230508_2b_n24031_01_0001.arch?ra=142.5502&dec=24.72799&size=5.00arcmin&format=fits',
      date: '2023-05-08 03:14:34.229',
      ddec: -9.49108,
      dec: 24.72799,
      delta: 4.13366850226074,
      dra: 11.33735,
      drh: -3.1735865,
      elong: 90.0001,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60072.13494478009,
      mjd_stop: 60072.13529200231,
      phase: 13.7159,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230508_2b_n24031_01_0001.arch?ra=142.5502&dec=24.72799&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230508_2b_n24031_01_0001.arch',
      ra: 142.5502,
      rh: 4.254952887756,
      sangle: 288.666,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 235.0497,
      unc_a: 0.153,
      unc_b: 0.078,
      unc_theta: -15.362,
      vangle: 106.00900000000001,
      vmag: 18.899,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May08/703_20230508_2B_N24031_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230508_2b_n24031_01_0003.arch?ra=142.55114&dec=24.72728&size=5.00arcmin&format=fits',
      date: '2023-05-08 03:30:46.777',
      ddec: -9.50548,
      dec: 24.72728,
      delta: 4.13381795279743,
      dra: 11.35574,
      drh: -3.1736318,
      elong: 89.9903,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60072.14620112268,
      mjd_stop: 60072.14654834491,
      phase: 13.716,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230508_2b_n24031_01_0003.arch?ra=142.55114&dec=24.72728&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230508_2b_n24031_01_0003.arch',
      ra: 142.55114,
      rh: 4.254932257447,
      sangle: 288.665,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 235.0509,
      unc_a: 0.153,
      unc_b: 0.078,
      unc_theta: -15.363,
      vangle: 106.00900000000001,
      vmag: 18.899,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May08/703_20230508_2B_N24031_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230508_2b_n24031_01_0004.arch?ra=142.55161&dec=24.72693&size=5.00arcmin&format=fits',
      date: '2023-05-08 03:38:54.866',
      ddec: -9.51255,
      dec: 24.72693,
      delta: 4.1338930135128,
      dra: 11.36578,
      drh: -3.1736545,
      elong: 89.9854,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60072.15185030093,
      mjd_stop: 60072.15219752315,
      phase: 13.7161,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230508_2b_n24031_01_0004.arch?ra=142.55161&dec=24.72693&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230508_2b_n24031_01_0004.arch',
      ra: 142.55161,
      rh: 4.25492190368,
      sangle: 288.664,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 235.0515,
      unc_a: 0.153,
      unc_b: 0.078,
      unc_theta: -15.363,
      vangle: 106.00999999999999,
      vmag: 18.899,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May08/703_20230508_2B_N24031_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230508_2b_n24031_01_0002.arch?ra=142.55067&dec=24.72764&size=5.00arcmin&format=fits',
      date: '2023-05-08 03:22:40.381',
      ddec: -9.49832,
      dec: 24.72764,
      delta: 4.13374318983221,
      dra: 11.34627,
      drh: -3.1736092,
      elong: 89.9952,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60072.14057153935,
      mjd_stop: 60072.14091876157,
      phase: 13.716,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230508_2b_n24031_01_0002.arch?ra=142.55067&dec=24.72764&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230508_2b_n24031_01_0002.arch',
      ra: 142.55067,
      rh: 4.254942575226,
      sangle: 288.666,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 235.0503,
      unc_a: 0.153,
      unc_b: 0.078,
      unc_theta: -15.362,
      vangle: 106.00900000000001,
      vmag: 18.899,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May10/703_20230510_2C_N24031_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230510_2c_n24031_01_0002.arch?ra=142.73356&dec=24.5984&size=5.00arcmin&format=fits',
      date: '2023-05-10 04:07:49.190',
      ddec: -9.81284,
      dec: 24.5984,
      delta: 4.16054581817501,
      dra: 12.3685,
      drh: -3.1817655,
      elong: 88.2555,
      exposure: 10,
      filter: null,
      maglimit: null,
      mjd_start: 60074.17203923611,
      mjd_stop: 60074.172154976855,
      phase: 13.7287,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230510_2c_n24031_01_0002.arch?ra=142.73356&dec=24.5984&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230510_2c_n24031_01_0002.arch',
      ra: 142.73356,
      rh: 4.251214781362,
      sangle: 288.421,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 235.2622,
      unc_a: 0.151,
      unc_b: 0.078,
      unc_theta: -15.41,
      vangle: 106.12299999999999,
      vmag: 18.909,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May10/703_20230510_2C_N24031_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230510_2c_n24031_01_0001.arch?ra=142.73345&dec=24.59848&size=5.00arcmin&format=fits',
      date: '2023-05-10 04:06:03.080',
      ddec: -9.81145,
      dec: 24.59848,
      delta: 4.16052947633407,
      dra: 12.3658,
      drh: -3.1817605,
      elong: 88.2566,
      exposure: 10,
      filter: null,
      maglimit: null,
      mjd_start: 60074.170811111115,
      mjd_stop: 60074.17092685185,
      phase: 13.7287,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230510_2c_n24031_01_0001.arch?ra=142.73345&dec=24.59848&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230510_2c_n24031_01_0001.arch',
      ra: 142.73345,
      rh: 4.251217038018,
      sangle: 288.422,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 235.2621,
      unc_a: 0.151,
      unc_b: 0.078,
      unc_theta: -15.41,
      vangle: 106.12200000000001,
      vmag: 18.909,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May10/703_20230510_2C_N24031_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230510_2c_n24031_01_0004.arch?ra=142.73378&dec=24.59824&size=5.00arcmin&format=fits',
      date: '2023-05-10 04:11:21.469',
      ddec: -9.81559,
      dec: 24.59824,
      delta: 4.16057851567232,
      dra: 12.37396,
      drh: -3.1817753,
      elong: 88.2534,
      exposure: 10,
      filter: null,
      maglimit: null,
      mjd_start: 60074.174496168984,
      mjd_stop: 60074.17461190972,
      phase: 13.7288,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230510_2c_n24031_01_0004.arch?ra=142.73378&dec=24.59824&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230510_2c_n24031_01_0004.arch',
      ra: 142.73378,
      rh: 4.251210266785,
      sangle: 288.421,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 235.2625,
      unc_a: 0.151,
      unc_b: 0.078,
      unc_theta: -15.41,
      vangle: 106.12299999999999,
      vmag: 18.91,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May10/703_20230510_2C_N24031_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230510_2c_n24031_01_0003.arch?ra=142.73367&dec=24.59832&size=5.00arcmin&format=fits',
      date: '2023-05-10 04:09:35.383',
      ddec: -9.81422,
      dec: 24.59832,
      delta: 4.16056217437808,
      dra: 12.37122,
      drh: -3.1817704,
      elong: 88.2545,
      exposure: 10,
      filter: null,
      maglimit: null,
      mjd_start: 60074.173268321756,
      mjd_stop: 60074.1733840625,
      phase: 13.7288,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230510_2c_n24031_01_0003.arch?ra=142.73367&dec=24.59832&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230510_2c_n24031_01_0003.arch',
      ra: 142.73367,
      rh: 4.251212522938,
      sangle: 288.421,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 235.2624,
      unc_a: 0.151,
      unc_b: 0.078,
      unc_theta: -15.41,
      vangle: 106.12299999999999,
      vmag: 18.91,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May09/703_20230509_2C_N24031_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230509_2c_n24031_01_0002.arch?ra=142.6416&dec=24.66255&size=5.00arcmin&format=fits',
      date: '2023-05-09 04:06:11.259',
      ddec: -9.67437,
      dec: 24.66255,
      delta: 4.14734061986384,
      dra: 11.88676,
      drh: -3.1777494,
      elong: 89.111,
      exposure: 10,
      filter: null,
      maglimit: null,
      mjd_start: 60073.17090577546,
      mjd_stop: 60073.1710215162,
      phase: 13.7241,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230509_2c_n24031_01_0002.arch?ra=142.6416&dec=24.66255&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230509_2c_n24031_01_0002.arch',
      ra: 142.6416,
      rh: 4.253053186717,
      sangle: 288.54,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 235.1578,
      unc_a: 0.152,
      unc_b: 0.078,
      unc_theta: -15.386,
      vangle: 106.06599999999997,
      vmag: 18.904,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May09/703_20230509_2C_N24031_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230509_2c_n24031_01_0001.arch?ra=142.64149&dec=24.66263&size=5.00arcmin&format=fits',
      date: '2023-05-09 04:04:24.792',
      ddec: -9.67295,
      dec: 24.66263,
      delta: 4.14732421900164,
      dra: 11.88411,
      drh: -3.1777444,
      elong: 89.112,
      exposure: 10,
      filter: null,
      maglimit: null,
      mjd_start: 60073.16967351852,
      mjd_stop: 60073.16978925926,
      phase: 13.7241,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230509_2c_n24031_01_0001.arch?ra=142.64149&dec=24.66263&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230509_2c_n24031_01_0001.arch',
      ra: 142.64149,
      rh: 4.253055448108,
      sangle: 288.541,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 235.1576,
      unc_a: 0.152,
      unc_b: 0.078,
      unc_theta: -15.386,
      vangle: 106.06599999999997,
      vmag: 18.904,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May09/703_20230509_2C_N24031_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230509_2c_n24031_01_0004.arch?ra=142.64182&dec=24.66239&size=5.00arcmin&format=fits',
      date: '2023-05-09 04:09:43.736',
      ddec: -9.67718,
      dec: 24.66239,
      delta: 4.14737335600966,
      dra: 11.89212,
      drh: -3.1777592,
      elong: 89.1088,
      exposure: 10,
      filter: null,
      maglimit: null,
      mjd_start: 60073.173365,
      mjd_stop: 60073.17348074074,
      phase: 13.7241,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230509_2c_n24031_01_0004.arch?ra=142.64182&dec=24.66239&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230509_2c_n24031_01_0004.arch',
      ra: 142.64182,
      rh: 4.253048673634,
      sangle: 288.54,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 235.158,
      unc_a: 0.152,
      unc_b: 0.078,
      unc_theta: -15.386,
      vangle: 106.06599999999997,
      vmag: 18.904,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May09/703_20230509_2C_N24031_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230509_2c_n24031_01_0003.arch?ra=142.64171&dec=24.66247&size=5.00arcmin&format=fits',
      date: '2023-05-09 04:07:57.410',
      ddec: -9.67578,
      dec: 24.66247,
      delta: 4.14735697365338,
      dra: 11.88943,
      drh: -3.1777543,
      elong: 89.1099,
      exposure: 10,
      filter: null,
      maglimit: null,
      mjd_start: 60073.172134375,
      mjd_stop: 60073.17225011574,
      phase: 13.7241,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230509_2c_n24031_01_0003.arch?ra=142.64171&dec=24.66247&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230509_2c_n24031_01_0003.arch',
      ra: 142.64171,
      rh: 4.253050932036,
      sangle: 288.54,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 235.1579,
      unc_a: 0.152,
      unc_b: 0.078,
      unc_theta: -15.386,
      vangle: 106.06599999999997,
      vmag: 18.904,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May11/703_20230511_2C_N24031_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230511_2c_n24031_01_0001.arch?ra=142.82972&dec=24.5328&size=5.00arcmin&format=fits',
      date: '2023-05-11 04:21:27.187',
      ddec: -9.95857,
      dec: 24.5328,
      delta: 4.17385309924857,
      dra: 12.8654,
      drh: -3.1858077,
      elong: 87.3964,
      exposure: 10,
      filter: null,
      maglimit: null,
      mjd_start: 60075.18150679398,
      mjd_stop: 60075.18162253472,
      phase: 13.7303,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230511_2c_n24031_01_0001.arch?ra=142.82972&dec=24.5328&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230511_2c_n24031_01_0001.arch',
      ra: 142.82972,
      rh: 4.249358722914,
      sangle: 288.304,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 235.3677,
      unc_a: 0.15,
      unc_b: 0.077,
      unc_theta: -15.436,
      vangle: 106.18099999999998,
      vmag: 18.915,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May11/703_20230511_2C_N24031_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230511_2c_n24031_01_0002.arch?ra=142.82983&dec=24.53272&size=5.00arcmin&format=fits',
      date: '2023-05-11 04:23:13.509',
      ddec: -9.95988,
      dec: 24.53272,
      delta: 4.17386947745607,
      dra: 12.86832,
      drh: -3.1858127,
      elong: 87.3954,
      exposure: 10,
      filter: null,
      maglimit: null,
      mjd_start: 60075.182737372685,
      mjd_stop: 60075.18285311342,
      phase: 13.7303,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230511_2c_n24031_01_0002.arch?ra=142.82983&dec=24.53272&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230511_2c_n24031_01_0002.arch',
      ra: 142.82983,
      rh: 4.249356458873,
      sangle: 288.304,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 235.3678,
      unc_a: 0.15,
      unc_b: 0.077,
      unc_theta: -15.436,
      vangle: 106.18099999999998,
      vmag: 18.915,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May11/703_20230511_2C_N24031_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230511_2c_n24031_01_0004.arch?ra=142.83007&dec=24.53256&size=5.00arcmin&format=fits',
      date: '2023-05-11 04:26:46.195',
      ddec: -9.96246,
      dec: 24.53256,
      delta: 4.17390224478058,
      dra: 12.87424,
      drh: -3.1858225,
      elong: 87.3932,
      exposure: 10,
      filter: null,
      maglimit: null,
      mjd_start: 60075.1851990162,
      mjd_stop: 60075.18531475694,
      phase: 13.7303,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230511_2c_n24031_01_0004.arch?ra=142.83007&dec=24.53256&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230511_2c_n24031_01_0004.arch',
      ra: 142.83007,
      rh: 4.249351929887,
      sangle: 288.303,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 235.3681,
      unc_a: 0.15,
      unc_b: 0.077,
      unc_theta: -15.436,
      vangle: 106.18099999999998,
      vmag: 18.915,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May11/703_20230511_2C_N24031_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230511_2c_n24031_01_0003.arch?ra=142.82995&dec=24.53264&size=5.00arcmin&format=fits',
      date: '2023-05-11 04:24:59.724',
      ddec: -9.96117,
      dec: 24.53264,
      delta: 4.17388584066643,
      dra: 12.87127,
      drh: -3.1858176,
      elong: 87.3943,
      exposure: 10,
      filter: null,
      maglimit: null,
      mjd_start: 60075.18396671296,
      mjd_stop: 60075.18408245371,
      phase: 13.7303,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230511_2c_n24031_01_0003.arch?ra=142.82995&dec=24.53264&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230511_2c_n24031_01_0003.arch',
      ra: 142.82995,
      rh: 4.249354197107,
      sangle: 288.303,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 235.3679,
      unc_a: 0.15,
      unc_b: 0.077,
      unc_theta: -15.436,
      vangle: 106.18099999999998,
      vmag: 18.915,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May12/703_20230512_2C_N24031_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230512_2c_n24031_01_0002.arch?ra=142.9256&dec=24.46886&size=5.00arcmin&format=fits',
      date: '2023-05-12 03:40:15.825',
      ddec: -10.0605,
      dec: 24.46886,
      delta: 4.18664232995483,
      dra: 13.27414,
      drh: -3.1896906,
      elong: 86.5733,
      exposure: 10,
      filter: null,
      maglimit: null,
      mjd_start: 60076.15290306713,
      mjd_stop: 60076.15301880787,
      phase: 13.7288,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230512_2c_n24031_01_0002.arch?ra=142.9256&dec=24.46886&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230512_2c_n24031_01_0002.arch',
      ra: 142.9256,
      rh: 4.247570441163,
      sangle: 288.193,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 235.4692,
      unc_a: 0.15,
      unc_b: 0.077,
      unc_theta: -15.462,
      vangle: 106.23899999999998,
      vmag: 18.92,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May12/703_20230512_2C_N24031_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230512_2c_n24031_01_0001.arch?ra=142.92548&dec=24.46894&size=5.00arcmin&format=fits',
      date: '2023-05-12 03:38:28.130',
      ddec: -10.059,
      dec: 24.46894,
      delta: 4.18662579287666,
      dra: 13.2717,
      drh: -3.1896857,
      elong: 86.5744,
      exposure: 10,
      filter: null,
      maglimit: null,
      mjd_start: 60076.15165659722,
      mjd_stop: 60076.151772337966,
      phase: 13.7288,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230512_2c_n24031_01_0001.arch?ra=142.92548&dec=24.46894&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230512_2c_n24031_01_0001.arch',
      ra: 142.92548,
      rh: 4.247572737233,
      sangle: 288.193,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 235.4691,
      unc_a: 0.15,
      unc_b: 0.077,
      unc_theta: -15.462,
      vangle: 106.23899999999998,
      vmag: 18.92,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May12/703_20230512_2C_N24031_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230512_2c_n24031_01_0004.arch?ra=142.92585&dec=24.46869&size=5.00arcmin&format=fits',
      date: '2023-05-12 03:43:51.536',
      ddec: -10.0634,
      dec: 24.46869,
      delta: 4.18667545855118,
      dra: 13.2791,
      drh: -3.1897006,
      elong: 86.5712,
      exposure: 10,
      filter: null,
      maglimit: null,
      mjd_start: 60076.15539972222,
      mjd_stop: 60076.15551546296,
      phase: 13.7288,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230512_2c_n24031_01_0004.arch?ra=142.92585&dec=24.46869&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230512_2c_n24031_01_0004.arch',
      ra: 142.92585,
      rh: 4.24756584217,
      sangle: 288.193,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 235.4695,
      unc_a: 0.15,
      unc_b: 0.077,
      unc_theta: -15.462,
      vangle: 106.23899999999998,
      vmag: 18.92,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May12/703_20230512_2C_N24031_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230512_2c_n24031_01_0003.arch?ra=142.92572&dec=24.46877&size=5.00arcmin&format=fits',
      date: '2023-05-12 03:42:03.405',
      ddec: -10.0619,
      dec: 24.46877,
      delta: 4.1866588510832,
      dra: 13.2766,
      drh: -3.1896956,
      elong: 86.5723,
      exposure: 10,
      filter: null,
      maglimit: null,
      mjd_start: 60076.15414820602,
      mjd_stop: 60076.15426394676,
      phase: 13.7288,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230512_2c_n24031_01_0003.arch?ra=142.92572&dec=24.46877&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230512_2c_n24031_01_0003.arch',
      ra: 142.92572,
      rh: 4.247568147542,
      sangle: 288.193,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 235.4694,
      unc_a: 0.15,
      unc_b: 0.077,
      unc_theta: -15.462,
      vangle: 106.23899999999998,
      vmag: 18.92,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May14/703_20230514_2C_N24031_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230514_2c_n24031_01_0002.arch?ra=143.13344&dec=24.33413&size=5.00arcmin&format=fits',
      date: '2023-05-14 03:49:46.071',
      ddec: -10.3314,
      dec: 24.33413,
      delta: 4.2130194126768,
      dra: 14.21632,
      drh: -3.1976899,
      elong: 84.8828,
      exposure: 10,
      filter: null,
      maglimit: null,
      mjd_start: 60078.15950313657,
      mjd_stop: 60078.15961887732,
      phase: 13.7167,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230514_2c_n24031_01_0002.arch?ra=143.13344&dec=24.33413&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230514_2c_n24031_01_0002.arch',
      ra: 143.13344,
      rh: 4.243869524604,
      sangle: 287.97,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 235.6793,
      unc_a: 0.148,
      unc_b: 0.077,
      unc_theta: -15.519,
      vangle: 106.36200000000002,
      vmag: 18.93,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May14/703_20230514_2C_N24031_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230514_2c_n24031_01_0001.arch?ra=143.13331&dec=24.33421&size=5.00arcmin&format=fits',
      date: '2023-05-14 03:47:59.869',
      ddec: -10.33,
      dec: 24.33421,
      delta: 4.21300313532104,
      dra: 14.21372,
      drh: -3.197685,
      elong: 84.8838,
      exposure: 10,
      filter: null,
      maglimit: null,
      mjd_start: 60078.158273946756,
      mjd_stop: 60078.1583896875,
      phase: 13.7167,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230514_2c_n24031_01_0001.arch?ra=143.13331&dec=24.33421&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230514_2c_n24031_01_0001.arch',
      ra: 143.13331,
      rh: 4.243871794521,
      sangle: 287.97,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 235.6791,
      unc_a: 0.148,
      unc_b: 0.077,
      unc_theta: -15.519,
      vangle: 106.36200000000002,
      vmag: 18.93,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May14/703_20230514_2C_N24031_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230514_2c_n24031_01_0003.arch?ra=143.13357&dec=24.33404&size=5.00arcmin&format=fits',
      date: '2023-05-14 03:51:32.590',
      ddec: -10.3328,
      dec: 24.33404,
      delta: 4.21303574020782,
      dra: 14.21895,
      drh: -3.1976948,
      elong: 84.8817,
      exposure: 10,
      filter: null,
      maglimit: null,
      mjd_start: 60078.16073599537,
      mjd_stop: 60078.16085173611,
      phase: 13.7167,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230514_2c_n24031_01_0003.arch?ra=143.13357&dec=24.33404&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230514_2c_n24031_01_0003.arch',
      ra: 143.13357,
      rh: 4.243867247907,
      sangle: 287.97,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 235.6794,
      unc_a: 0.148,
      unc_b: 0.077,
      unc_theta: -15.519,
      vangle: 106.36200000000002,
      vmag: 18.93,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May14/703_20230514_2C_N24031_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230514_2c_n24031_01_0004.arch?ra=143.1337&dec=24.33396&size=5.00arcmin&format=fits',
      date: '2023-05-14 03:53:18.754',
      ddec: -10.3341,
      dec: 24.33396,
      delta: 4.21305201490247,
      dra: 14.2216,
      drh: -3.1976997,
      elong: 84.8807,
      exposure: 10,
      filter: null,
      maglimit: null,
      mjd_start: 60078.16196474537,
      mjd_stop: 60078.16208048611,
      phase: 13.7167,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230514_2c_n24031_01_0004.arch?ra=143.1337&dec=24.33396&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230514_2c_n24031_01_0004.arch',
      ra: 143.1337,
      rh: 4.243864978795,
      sangle: 287.969,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 235.6795,
      unc_a: 0.148,
      unc_b: 0.077,
      unc_theta: -15.519,
      vangle: 106.36200000000002,
      vmag: 18.93,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May13/703_20230513_2C_N24031_01_0003.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230513_2c_n24031_01_0003.arch?ra=143.02821&dec=24.40169&size=5.00arcmin&format=fits',
      date: '2023-05-13 03:50:02.230',
      ddec: -10.2008,
      dec: 24.40169,
      delta: 4.19988649876684,
      dra: 13.75438,
      drh: -3.1937078,
      elong: 85.7234,
      exposure: 10,
      filter: null,
      maglimit: null,
      mjd_start: 60077.159690162036,
      mjd_stop: 60077.159805902775,
      phase: 13.7242,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230513_2c_n24031_01_0003.arch?ra=143.02821&dec=24.40169&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230513_2c_n24031_01_0003.arch',
      ra: 143.02821,
      rh: 4.245714710254,
      sangle: 288.08,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 235.5746,
      unc_a: 0.149,
      unc_b: 0.077,
      unc_theta: -15.49,
      vangle: 106.30000000000001,
      vmag: 18.925,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May13/703_20230513_2C_N24031_01_0004.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230513_2c_n24031_01_0004.arch?ra=143.02833&dec=24.40161&size=5.00arcmin&format=fits',
      date: '2023-05-13 03:51:48.663',
      ddec: -10.2022,
      dec: 24.40161,
      delta: 4.1999028352192,
      dra: 13.75698,
      drh: -3.1937127,
      elong: 85.7223,
      exposure: 10,
      filter: null,
      maglimit: null,
      mjd_start: 60077.16092202546,
      mjd_stop: 60077.16103776621,
      phase: 13.7242,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230513_2c_n24031_01_0004.arch?ra=143.02833&dec=24.40161&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230513_2c_n24031_01_0004.arch',
      ra: 143.02833,
      rh: 4.245712438229,
      sangle: 288.08,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 235.5747,
      unc_a: 0.149,
      unc_b: 0.077,
      unc_theta: -15.49,
      vangle: 106.30000000000001,
      vmag: 18.925,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May13/703_20230513_2C_N24031_01_0002.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230513_2c_n24031_01_0002.arch?ra=143.02809&dec=24.40177&size=5.00arcmin&format=fits',
      date: '2023-05-13 03:48:16.101',
      ddec: -10.1994,
      dec: 24.40177,
      delta: 4.19987021058504,
      dra: 13.75181,
      drh: -3.1937029,
      elong: 85.7244,
      exposure: 10,
      filter: null,
      maglimit: null,
      mjd_start: 60077.15846181713,
      mjd_stop: 60077.15857755787,
      phase: 13.7242,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230513_2c_n24031_01_0002.arch?ra=143.02809&dec=24.40177&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230513_2c_n24031_01_0002.arch',
      ra: 143.02809,
      rh: 4.245716975786,
      sangle: 288.08,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 235.5744,
      unc_a: 0.149,
      unc_b: 0.077,
      unc_theta: -15.49,
      vangle: 106.30000000000001,
      vmag: 18.925,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/703/2023/23May13/703_20230513_2C_N24031_01_0001.arch.fz',
      'css:telescope': 'Catalina Sky Survey, 0.7-m Schmidt (703)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230513_2c_n24031_01_0001.arch?ra=143.02796&dec=24.40186&size=5.00arcmin&format=fits',
      date: '2023-05-13 03:46:29.870',
      ddec: -10.198,
      dec: 24.40186,
      delta: 4.19985390835545,
      dra: 13.74926,
      drh: -3.193698,
      elong: 85.7255,
      exposure: 10,
      filter: null,
      maglimit: null,
      mjd_start: 60077.15723229167,
      mjd_stop: 60077.15734803241,
      phase: 13.7242,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230513_2c_n24031_01_0001.arch?ra=143.02796&dec=24.40186&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:703_20230513_2c_n24031_01_0001.arch',
      ra: 143.02796,
      rh: 4.245719243492,
      sangle: 288.08,
      seeing: null,
      source: 'catalina_bigelow',
      source_name: 'Catalina Sky Survey, Mt. Bigelow',
      true_anomaly: 235.5743,
      unc_a: 0.149,
      unc_b: 0.077,
      unc_theta: -15.49,
      vangle: 106.30000000000001,
      vmag: 18.925,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2022/22Jan30/G96_20220130_2B_N32042_01_0002.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220130_2b_n32042_01_0002.arch?ra=105.80098&dec=30.96831&size=5.00arcmin&format=fits',
      date: '2022-01-30 06:03:15.852',
      ddec: 1.643274,
      dec: 30.96831,
      delta: 3.91689557773185,
      dra: -20.2009,
      drh: -0.8975376,
      elong: 152.4655,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59609.252093194445,
      mjd_stop: 59609.25244041667,
      phase: 5.4255,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220130_2b_n32042_01_0002.arch?ra=105.80098&dec=30.96831&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220130_2b_n32042_01_0002.arch',
      ra: 105.80098,
      rh: 4.811960133334,
      sangle: 292.478,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 193.36,
      unc_a: 0.219,
      unc_b: 0.108,
      unc_theta: 0.866,
      vangle: 89.08300000000003,
      vmag: 19.276,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2022/22Jan30/G96_20220130_2B_N32042_01_0003.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220130_2b_n32042_01_0003.arch?ra=105.80009&dec=30.96837&size=5.00arcmin&format=fits',
      date: '2022-01-30 06:11:24.374',
      ddec: 1.633318,
      dec: 30.96837,
      delta: 3.91693212739399,
      dra: -20.1976,
      drh: -0.8975678,
      elong: 152.4592,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59609.25774738426,
      mjd_stop: 59609.258094606485,
      phase: 5.4266,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220130_2b_n32042_01_0003.arch?ra=105.80009&dec=30.96837&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220130_2b_n32042_01_0003.arch',
      ra: 105.80009,
      rh: 4.811957202424,
      sangle: 292.473,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 193.3604,
      unc_a: 0.219,
      unc_b: 0.108,
      unc_theta: 0.867,
      vangle: 89.082,
      vmag: 19.276,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2022/22Jan30/G96_20220130_2B_N32042_01_0004.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220130_2b_n32042_01_0004.arch?ra=105.7992&dec=30.96843&size=5.00arcmin&format=fits',
      date: '2022-01-30 06:19:34.219',
      ddec: 1.623366,
      dec: 30.96843,
      delta: 3.91696882427222,
      dra: -20.1936,
      drh: -0.8975982,
      elong: 152.453,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59609.26341688658,
      mjd_stop: 59609.26376410879,
      phase: 5.4278,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220130_2b_n32042_01_0004.arch?ra=105.7992&dec=30.96843&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220130_2b_n32042_01_0004.arch',
      ra: 105.7992,
      rh: 4.811954263477,
      sangle: 292.468,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 193.3609,
      unc_a: 0.219,
      unc_b: 0.108,
      unc_theta: 0.867,
      vangle: 89.082,
      vmag: 19.276,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2022/22Jan30/G96_20220130_2B_N32042_01_0001.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220130_2b_n32042_01_0001.arch?ra=105.80187&dec=30.96825&size=5.00arcmin&format=fits',
      date: '2022-01-30 05:55:06.044',
      ddec: 1.653275,
      dec: 30.96825,
      delta: 3.91685898018794,
      dra: -20.2036,
      drh: -0.8975072,
      elong: 152.4717,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59609.24642412037,
      mjd_stop: 59609.246771342594,
      phase: 5.4243,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220130_2b_n32042_01_0001.arch?ra=105.80187&dec=30.96825&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220130_2b_n32042_01_0001.arch',
      ra: 105.80187,
      rh: 4.81196307186,
      sangle: 292.483,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 193.3595,
      unc_a: 0.219,
      unc_b: 0.108,
      unc_theta: 0.866,
      vangle: 89.08300000000003,
      vmag: 19.276,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2022/22Jan27/G96_20220127_2B_N29043_01_0002.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220127_2b_n29043_01_0002.arch?ra=106.27638&dec=30.93122&size=5.00arcmin&format=fits',
      date: '2022-01-27 05:26:27.694',
      ddec: 2.161006,
      dec: 30.93122,
      delta: 3.89868316904652,
      dra: -21.1758,
      drh: -0.8813229,
      elong: 155.7556,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59606.22653581019,
      mjd_stop: 59606.22688303241,
      phase: 4.8137,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220127_2b_n29043_01_0002.arch?ra=106.27638&dec=30.93122&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220127_2b_n29043_01_0002.arch',
      ra: 106.27638,
      rh: 4.813514275633,
      sangle: 295.324,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 193.114,
      unc_a: 0.221,
      unc_b: 0.109,
      unc_theta: 0.606,
      vangle: 89.346,
      vmag: 19.267,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2022/22Jan27/G96_20220127_2B_N29043_01_0001.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220127_2b_n29043_01_0001.arch?ra=106.2781&dec=30.93107&size=5.00arcmin&format=fits',
      date: '2022-01-27 05:11:25.263',
      ddec: 2.179213,
      dec: 30.93107,
      delta: 3.89862523584583,
      dra: -21.1725,
      drh: -0.8812669,
      elong: 155.7671,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59606.21609100694,
      mjd_stop: 59606.21643822917,
      phase: 4.8116,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220127_2b_n29043_01_0001.arch?ra=106.2781&dec=30.93107&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220127_2b_n29043_01_0001.arch',
      ra: 106.2781,
      rh: 4.813519591767,
      sangle: 295.335,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 193.1131,
      unc_a: 0.221,
      unc_b: 0.109,
      unc_theta: 0.605,
      vangle: 89.34699999999998,
      vmag: 19.267,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2022/22Jan27/G96_20220127_2B_N29043_01_0004.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220127_2b_n29043_01_0004.arch?ra=106.27451&dec=30.93138&size=5.00arcmin&format=fits',
      date: '2022-01-27 05:42:51.315',
      ddec: 2.140958,
      dec: 30.93138,
      delta: 3.89874650098353,
      dra: -21.1769,
      drh: -0.8813839,
      elong: 155.7431,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59606.2379203125,
      mjd_stop: 59606.238267534725,
      phase: 4.8161,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220127_2b_n29043_01_0004.arch?ra=106.27451&dec=30.93138&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220127_2b_n29043_01_0004.arch',
      ra: 106.27451,
      rh: 4.813508480832,
      sangle: 295.312,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 193.1149,
      unc_a: 0.221,
      unc_b: 0.109,
      unc_theta: 0.607,
      vangle: 89.34500000000003,
      vmag: 19.267,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2022/22Jan27/G96_20220127_2B_N29043_01_0003.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220127_2b_n29043_01_0003.arch?ra=106.27545&dec=30.9313&size=5.00arcmin&format=fits',
      date: '2022-01-27 05:34:39.145',
      ddec: 2.15101,
      dec: 30.9313,
      delta: 3.89871478743746,
      dra: -21.1767,
      drh: -0.8813534,
      elong: 155.7494,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59606.232223900464,
      mjd_stop: 59606.23257112269,
      phase: 4.8149,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220127_2b_n29043_01_0003.arch?ra=106.27545&dec=30.9313&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220127_2b_n29043_01_0003.arch',
      ra: 106.27545,
      rh: 4.813511380401,
      sangle: 295.318,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 193.1145,
      unc_a: 0.221,
      unc_b: 0.109,
      unc_theta: 0.606,
      vangle: 89.346,
      vmag: 19.267,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2022/22Jan04/G96_20220104_2B_N29044_01_0002.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220104_2b_n29044_01_0002.arch?ra=110.32027&dec=30.35009&size=5.00arcmin&format=fits',
      date: '2022-01-04 06:54:25.854',
      ddec: 5.528434,
      dec: 30.35009,
      delta: 3.85083912525788,
      dra: -23.796,
      drh: -0.758186,
      elong: 170.9074,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59583.287625625,
      mjd_stop: 59583.287972847225,
      phase: 1.8487,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220104_2b_n29044_01_0002.arch?ra=110.32027&dec=30.35009&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220104_2b_n29044_01_0002.arch',
      ra: 110.32027,
      rh: 4.8243748682,
      sangle: 34.50200000000001,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 191.2538,
      unc_a: 0.223,
      unc_b: 0.111,
      unc_theta: -1.533,
      vangle: 91.541,
      vmag: 19.25,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2022/22Jan04/G96_20220104_2B_N29044_01_0001.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220104_2b_n29044_01_0001.arch?ra=110.32123&dec=30.3499&size=5.00arcmin&format=fits',
      date: '2022-01-04 06:46:54.844',
      ddec: 5.537197,
      dec: 30.3499,
      delta: 3.85084742448156,
      dra: -23.7912,
      drh: -0.7581579,
      elong: 170.9047,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59583.28240560185,
      mjd_stop: 59583.282752824074,
      phase: 1.8492,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220104_2b_n29044_01_0001.arch?ra=110.32123&dec=30.3499&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220104_2b_n29044_01_0001.arch',
      ra: 110.32123,
      rh: 4.824377153969,
      sangle: 34.53800000000001,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 191.2534,
      unc_a: 0.223,
      unc_b: 0.111,
      unc_theta: -1.534,
      vangle: 91.54199999999997,
      vmag: 19.25,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2022/22Jan04/G96_20220104_2B_N29044_01_0003.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220104_2b_n29044_01_0003.arch?ra=110.31932&dec=30.35028&size=5.00arcmin&format=fits',
      date: '2022-01-04 07:01:54.845',
      ddec: 5.519643,
      dec: 30.35028,
      delta: 3.85083090433875,
      dra: -23.8002,
      drh: -0.7582139,
      elong: 170.9101,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59583.29282228009,
      mjd_stop: 59583.293169502314,
      phase: 1.8482,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220104_2b_n29044_01_0003.arch?ra=110.31932&dec=30.35028&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220104_2b_n29044_01_0003.arch',
      ra: 110.31932,
      rh: 4.824372592579,
      sangle: 34.46600000000001,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 191.2542,
      unc_a: 0.223,
      unc_b: 0.111,
      unc_theta: -1.533,
      vangle: 91.541,
      vmag: 19.25,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2022/22Jan04/G96_20220104_2B_N29044_01_0004.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220104_2b_n29044_01_0004.arch?ra=110.31836&dec=30.35047&size=5.00arcmin&format=fits',
      date: '2022-01-04 07:09:23.362',
      ddec: 5.510804,
      dec: 30.35047,
      delta: 3.85082273333984,
      dra: -23.8038,
      drh: -0.7582418,
      elong: 170.9128,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59583.29801344907,
      mjd_stop: 59583.298360671295,
      phase: 1.8476,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220104_2b_n29044_01_0004.arch?ra=110.31836&dec=30.35047&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220104_2b_n29044_01_0004.arch',
      ra: 110.31836,
      rh: 4.824370319277,
      sangle: 34.43000000000001,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 191.2546,
      unc_a: 0.223,
      unc_b: 0.111,
      unc_theta: -1.532,
      vangle: 91.54000000000002,
      vmag: 19.25,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2022/22Jan13/G96_20220113_2B_N29044_01_0003.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220113_2b_n29044_01_0003.arch?ra=108.6975&dec=30.63776&size=5.00arcmin&format=fits',
      date: '2022-01-13 05:29:59.868',
      ddec: 4.367626,
      dec: 30.63776,
      delta: 3.84990431483006,
      dra: -23.8086,
      drh: -0.8062207,
      elong: 169.5145,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59592.22899152778,
      mjd_stop: 59592.22933875,
      phase: 2.1248,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220113_2b_n29044_01_0003.arch?ra=108.6975&dec=30.63776&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220113_2b_n29044_01_0003.arch',
      ra: 108.6975,
      rh: 4.820335494933,
      sangle: 328.882,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 191.9779,
      unc_a: 0.223,
      unc_b: 0.111,
      unc_theta: -0.693,
      vangle: 90.67200000000003,
      vmag: 19.246,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2022/22Jan13/G96_20220113_2B_N29044_01_0002.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220113_2b_n29044_01_0002.arch?ra=108.6983&dec=30.63764&size=5.00arcmin&format=fits',
      date: '2022-01-13 05:23:45.851',
      ddec: 4.374618,
      dec: 30.63764,
      delta: 3.84989895983075,
      dra: -23.8029,
      drh: -0.8061974,
      elong: 169.5177,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59592.22466262731,
      mjd_stop: 59592.225009849535,
      phase: 2.1241,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220113_2b_n29044_01_0002.arch?ra=108.6983&dec=30.63764&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220113_2b_n29044_01_0002.arch',
      ra: 108.6983,
      rh: 4.820337510561,
      sangle: 328.905,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 191.9775,
      unc_a: 0.223,
      unc_b: 0.111,
      unc_theta: -0.693,
      vangle: 90.673,
      vmag: 19.246,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2022/22Jan13/G96_20220113_2B_N29044_01_0001.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220113_2b_n29044_01_0001.arch?ra=108.6991&dec=30.63751&size=5.00arcmin&format=fits',
      date: '2022-01-13 05:17:31.396',
      ddec: 4.381532,
      dec: 30.63751,
      delta: 3.84989362525283,
      dra: -23.797,
      drh: -0.8061741,
      elong: 169.5209,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59592.22032865741,
      mjd_stop: 59592.22067587963,
      phase: 2.1235,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220113_2b_n29044_01_0001.arch?ra=108.6991&dec=30.63751&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220113_2b_n29044_01_0001.arch',
      ra: 108.6991,
      rh: 4.820339528492,
      sangle: 328.927,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 191.9772,
      unc_a: 0.223,
      unc_b: 0.111,
      unc_theta: -0.694,
      vangle: 90.673,
      vmag: 19.246,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2022/22Jan13/G96_20220113_2B_N29044_01_0004.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220113_2b_n29044_01_0004.arch?ra=108.6967&dec=30.63789&size=5.00arcmin&format=fits',
      date: '2022-01-13 05:36:14.295',
      ddec: 4.360547,
      dec: 30.63789,
      delta: 3.84990970266177,
      dra: -23.8138,
      drh: -0.8062439,
      elong: 169.5113,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59592.23332517361,
      mjd_stop: 59592.233672395836,
      phase: 2.1254,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220113_2b_n29044_01_0004.arch?ra=108.6967&dec=30.63789&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20220113_2b_n29044_01_0004.arch',
      ra: 108.6967,
      rh: 4.820333477037,
      sangle: 328.86,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 191.9782,
      unc_a: 0.223,
      unc_b: 0.111,
      unc_theta: -0.692,
      vangle: 90.67200000000003,
      vmag: 19.246,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2021/21Dec08/G96_20211208_2B_N29046_01_0001.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211208_2b_n29046_01_0001.arch?ra=114.37289&dec=29.23179&size=5.00arcmin&format=fits',
      date: '2021-12-09 08:52:23.481',
      ddec: 7.066771,
      dec: 29.23179,
      delta: 3.99336940716011,
      dra: -16.2697,
      drh: -0.6187223,
      elong: 145.153,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59557.36954260417,
      mjd_stop: 59557.36988982639,
      phase: 6.6902,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211208_2b_n29046_01_0001.arch?ra=114.37289&dec=29.23179&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211208_2b_n29046_01_0001.arch',
      ra: 114.37289,
      rh: 4.83468102446,
      sangle: 88.50900000000001,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 189.1617,
      unc_a: 0.213,
      unc_b: 0.108,
      unc_theta: -3.469,
      vangle: 93.59899999999999,
      vmag: 19.337,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2021/21Dec08/G96_20211208_2B_N29046_01_0004.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211208_2b_n29046_01_0004.arch?ra=114.37094&dec=29.23252&size=5.00arcmin&format=fits',
      date: '2021-12-09 09:14:50.619',
      ddec: 7.043926,
      dec: 29.23252,
      delta: 3.99322590000072,
      dra: -16.2861,
      drh: -0.6188063,
      elong: 145.1703,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59557.38513447917,
      mjd_stop: 59557.385481701385,
      phase: 6.6872,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211208_2b_n29046_01_0004.arch?ra=114.37094&dec=29.23252&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211208_2b_n29046_01_0004.arch',
      ra: 114.37094,
      rh: 4.834675452153,
      sangle: 88.5,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 189.1629,
      unc_a: 0.213,
      unc_b: 0.108,
      unc_theta: -3.468,
      vangle: 93.59800000000001,
      vmag: 19.337,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2021/21Dec08/G96_20211208_2B_N29046_01_0003.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211208_2b_n29046_01_0003.arch?ra=114.37159&dec=29.23228&size=5.00arcmin&format=fits',
      date: '2021-12-09 09:07:22.142',
      ddec: 7.051581,
      dec: 29.23228,
      delta: 3.99327363510097,
      dra: -16.2811,
      drh: -0.6187783,
      elong: 145.1646,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59557.37994377315,
      mjd_stop: 59557.380290995374,
      phase: 6.6882,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211208_2b_n29046_01_0003.arch?ra=114.37159&dec=29.23228&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211208_2b_n29046_01_0003.arch',
      ra: 114.37159,
      rh: 4.834677307319,
      sangle: 88.50299999999999,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 189.1625,
      unc_a: 0.213,
      unc_b: 0.108,
      unc_theta: -3.468,
      vangle: 93.59800000000001,
      vmag: 19.337,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2021/21Dec08/G96_20211208_2B_N29046_01_0002.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211208_2b_n29046_01_0002.arch?ra=114.37224&dec=29.23204&size=5.00arcmin&format=fits',
      date: '2021-12-09 08:59:52.375',
      ddec: 7.059211,
      dec: 29.23204,
      delta: 3.99332154767968,
      dra: -16.2757,
      drh: -0.6187503,
      elong: 145.1588,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59557.374738136576,
      mjd_stop: 59557.3750853588,
      phase: 6.6892,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211208_2b_n29046_01_0002.arch?ra=114.37224&dec=29.23204&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211208_2b_n29046_01_0002.arch',
      ra: 114.37224,
      rh: 4.834679167737,
      sangle: 88.50599999999997,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 189.1621,
      unc_a: 0.213,
      unc_b: 0.108,
      unc_theta: -3.468,
      vangle: 93.59800000000001,
      vmag: 19.337,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2021/21Nov28/G96_20211128_2B_N27047_01_0001.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211128_2b_n27047_01_0001.arch?ra=115.46603&dec=28.7358&size=5.00arcmin&format=fits',
      date: '2021-11-28 12:06:13.023',
      ddec: 6.565164,
      dec: 28.7358,
      delta: 4.10712269859301,
      dra: -10.8808,
      drh: -0.5601673,
      elong: 133.39,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59546.504143784725,
      mjd_stop: 59546.50449100694,
      phase: 8.5262,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211128_2b_n27047_01_0001.arch?ra=115.46603&dec=28.7358&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211128_2b_n27047_01_0001.arch',
      ra: 115.46603,
      rh: 4.8383802105,
      sangle: 93.01600000000002,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 188.2874,
      unc_a: 0.207,
      unc_b: 0.105,
      unc_theta: -3.911,
      vangle: 94.10000000000002,
      vmag: 19.401,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2021/21Nov28/G96_20211128_2B_N27047_01_0004.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211128_2b_n27047_01_0004.arch?ra=115.46466&dec=28.73653&size=5.00arcmin&format=fits',
      date: '2021-11-28 12:30:07.270',
      ddec: 6.544577,
      dec: 28.73653,
      delta: 4.10693031709519,
      dra: -10.8681,
      drh: -0.5602568,
      elong: 133.4079,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59546.52074386574,
      mjd_stop: 59546.52109108796,
      phase: 8.5237,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211128_2b_n27047_01_0004.arch?ra=115.46466&dec=28.73653&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211128_2b_n27047_01_0004.arch',
      ra: 115.46466,
      rh: 4.838374839192,
      sangle: 93.01100000000002,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 188.2887,
      unc_a: 0.207,
      unc_b: 0.105,
      unc_theta: -3.911,
      vangle: 94.10000000000002,
      vmag: 19.401,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2021/21Nov28/G96_20211128_2B_N27047_01_0003.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211128_2b_n27047_01_0003.arch?ra=115.46509&dec=28.7363&size=5.00arcmin&format=fits',
      date: '2021-11-28 12:22:40.341',
      ddec: 6.550876,
      dec: 28.7363,
      delta: 4.10699022615679,
      dra: -10.8726,
      drh: -0.5602289,
      elong: 133.4023,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59546.51557107639,
      mjd_stop: 59546.51591829861,
      phase: 8.5245,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211128_2b_n27047_01_0003.arch?ra=115.46509&dec=28.7363&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211128_2b_n27047_01_0003.arch',
      ra: 115.46509,
      rh: 4.838376513049,
      sangle: 93.012,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 188.2883,
      unc_a: 0.207,
      unc_b: 0.105,
      unc_theta: -3.911,
      vangle: 94.10000000000002,
      vmag: 19.401,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2021/21Nov28/G96_20211128_2B_N27047_01_0002.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211128_2b_n27047_01_0002.arch?ra=115.4656&dec=28.73603&size=5.00arcmin&format=fits',
      date: '2021-11-28 12:13:40.065',
      ddec: 6.558634,
      dec: 28.73603,
      delta: 4.10706269540164,
      dra: -10.8774,
      drh: -0.5601952,
      elong: 133.3956,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59546.509317881944,
      mjd_stop: 59546.50966510417,
      phase: 8.5254,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211128_2b_n27047_01_0002.arch?ra=115.4656&dec=28.73603&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211128_2b_n27047_01_0002.arch',
      ra: 115.4656,
      rh: 4.838378536403,
      sangle: 93.01400000000001,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 188.2878,
      unc_a: 0.207,
      unc_b: 0.105,
      unc_theta: -3.911,
      vangle: 94.10000000000002,
      vmag: 19.401,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2021/21Oct17/G96_20211017_2B_N27047_01_0002.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211017_2b_n27047_01_0002.arch?ra=115.25299&dec=27.39752&size=5.00arcmin&format=fits',
      date: '2021-10-17 11:28:29.878',
      ddec: 2.690231,
      dec: 27.39752,
      delta: 4.71746266542574,
      dra: 10.91815,
      drh: -0.3333133,
      elong: 91.6238,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59504.47794997685,
      mjd_stop: 59504.47829719907,
      phase: 11.8569,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211017_2b_n27047_01_0002.arch?ra=115.25299&dec=27.39752&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211017_2b_n27047_01_0002.arch',
      ra: 115.25299,
      rh: 4.849225521938,
      sangle: 99.654,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 184.918,
      unc_a: 0.179,
      unc_b: 0.092,
      unc_theta: -3.441,
      vangle: 93.769,
      vmag: 19.711,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2021/21Oct17/G96_20211017_2B_N27047_01_0004.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211017_2b_n27047_01_0004.arch?ra=115.25393&dec=27.39773&size=5.00arcmin&format=fits',
      date: '2021-10-17 11:44:59.905',
      ddec: 2.679075,
      dec: 27.39773,
      delta: 4.71727887388786,
      dra: 10.89989,
      drh: -0.3333752,
      elong: 91.6344,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59504.489408622685,
      mjd_stop: 59504.48975584491,
      phase: 11.8567,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211017_2b_n27047_01_0004.arch?ra=115.25393&dec=27.39773&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211017_2b_n27047_01_0004.arch',
      ra: 115.25393,
      rh: 4.849223315687,
      sangle: 99.65300000000002,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 184.919,
      unc_a: 0.179,
      unc_b: 0.092,
      unc_theta: -3.441,
      vangle: 93.76999999999998,
      vmag: 19.711,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2021/21Oct17/G96_20211017_2B_N27047_01_0003.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211017_2b_n27047_01_0003.arch?ra=115.25346&dec=27.39763&size=5.00arcmin&format=fits',
      date: '2021-10-17 11:36:44.962',
      ddec: 2.684706,
      dec: 27.39763,
      delta: 4.71737073774694,
      dra: 10.90877,
      drh: -0.3333442,
      elong: 91.6291,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59504.48368011574,
      mjd_stop: 59504.48402733796,
      phase: 11.8568,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211017_2b_n27047_01_0003.arch?ra=115.25346&dec=27.39763&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211017_2b_n27047_01_0003.arch',
      ra: 115.25346,
      rh: 4.849224418707,
      sangle: 99.65300000000002,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 184.9185,
      unc_a: 0.179,
      unc_b: 0.092,
      unc_theta: -3.441,
      vangle: 93.76999999999998,
      vmag: 19.711,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2021/21Oct17/G96_20211017_2B_N27047_01_0001.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211017_2b_n27047_01_0001.arch?ra=115.25252&dec=27.39742&size=5.00arcmin&format=fits',
      date: '2021-10-17 11:20:13.199',
      ddec: 2.695655,
      dec: 27.39742,
      delta: 4.71755492642653,
      dra: 10.92802,
      drh: -0.3332822,
      elong: 91.6185,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59504.47220137731,
      mjd_stop: 59504.47254859954,
      phase: 11.8569,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211017_2b_n27047_01_0001.arch?ra=115.25252&dec=27.39742&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211017_2b_n27047_01_0001.arch',
      ra: 115.25252,
      rh: 4.84922662862,
      sangle: 99.654,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 184.9176,
      unc_a: 0.179,
      unc_b: 0.092,
      unc_theta: -3.441,
      vangle: 93.769,
      vmag: 19.711,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2021/21Nov06/G96_20211106_2B_N27048_01_0001.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211106_2b_n27048_01_0001.arch?ra=116.22643&dec=27.88718&size=5.00arcmin&format=fits',
      date: '2021-11-06 11:21:04.695',
      ddec: 4.779411,
      dec: 27.88718,
      delta: 4.40512659361614,
      dra: 0.957355,
      drh: -0.4413095,
      elong: 110.6699,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59524.472797395836,
      mjd_stop: 59524.47314461805,
      phase: 11.0405,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211106_2b_n27048_01_0001.arch?ra=116.22643&dec=27.88718&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211106_2b_n27048_01_0001.arch',
      ra: 116.22643,
      rh: 4.844752352714,
      sangle: 97.71300000000002,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 186.5189,
      unc_a: 0.191,
      unc_b: 0.098,
      unc_theta: -4.059,
      vangle: 94.34800000000001,
      vmag: 19.559,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2021/21Nov06/G96_20211106_2B_N27048_01_0004.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211106_2b_n27048_01_0004.arch?ra=116.22654&dec=27.88769&size=5.00arcmin&format=fits',
      date: '2021-11-06 11:43:46.482',
      ddec: 4.760788,
      dec: 27.88769,
      delta: 4.40488992624648,
      dra: 0.942534,
      drh: -0.4413946,
      elong: 110.6857,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59524.488558819445,
      mjd_stop: 59524.48890604167,
      phase: 11.0392,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211106_2b_n27048_01_0004.arch?ra=116.22654&dec=27.88769&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211106_2b_n27048_01_0004.arch',
      ra: 116.22654,
      rh: 4.844748334745,
      sangle: 97.71100000000001,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 186.5201,
      unc_a: 0.191,
      unc_b: 0.098,
      unc_theta: -4.06,
      vangle: 94.34800000000001,
      vmag: 19.558,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2021/21Nov06/G96_20211106_2B_N27048_01_0003.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211106_2b_n27048_01_0003.arch?ra=116.2265&dec=27.88752&size=5.00arcmin&format=fits',
      date: '2021-11-06 11:36:11.579',
      ddec: 4.767041,
      dec: 27.88752,
      delta: 4.40496894737513,
      dra: 0.947004,
      drh: -0.4413662,
      elong: 110.6804,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59524.48329373843,
      mjd_stop: 59524.48364096065,
      phase: 11.0397,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211106_2b_n27048_01_0003.arch?ra=116.2265&dec=27.88752&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211106_2b_n27048_01_0003.arch',
      ra: 116.2265,
      rh: 4.844749677028,
      sangle: 97.71100000000001,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 186.5197,
      unc_a: 0.191,
      unc_b: 0.098,
      unc_theta: -4.059,
      vangle: 94.34800000000001,
      vmag: 19.558,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2021/21Nov06/G96_20211106_2B_N27048_01_0002.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211106_2b_n27048_01_0002.arch?ra=116.22646&dec=27.88735&size=5.00arcmin&format=fits',
      date: '2021-11-06 11:28:37.799',
      ddec: 4.773249,
      dec: 27.88735,
      delta: 4.40504781073134,
      dra: 0.951945,
      drh: -0.4413379,
      elong: 110.6752,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59524.47804165509,
      mjd_stop: 59524.478388877316,
      phase: 11.0401,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211106_2b_n27048_01_0002.arch?ra=116.22646&dec=27.88735&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20211106_2b_n27048_01_0002.arch',
      ra: 116.22646,
      rh: 4.844751015911,
      sangle: 97.71199999999999,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 186.5193,
      unc_a: 0.191,
      unc_b: 0.098,
      unc_theta: -4.059,
      vangle: 94.34800000000001,
      vmag: 19.559,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2021/21Sep13/G96_20210913_2B_N27045_01_0001.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210913_2b_n27045_01_0001.arch?ra=110.66406&dec=27.12645&size=5.00arcmin&format=fits',
      date: '2021-09-13 11:49:56.728',
      ddec: 0.169388,
      dec: 27.12645,
      delta: 5.2382051310727,
      dra: 23.55569,
      drh: -0.1496152,
      elong: 62.356,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59470.49284407408,
      mjd_stop: 59470.49319129629,
      phase: 10.5807,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210913_2b_n27045_01_0001.arch?ra=110.66406&dec=27.12645&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210913_2b_n27045_01_0001.arch',
      ra: 110.66406,
      rh: 4.853965635648,
      sangle: 100.72500000000002,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 182.2047,
      unc_a: 0.162,
      unc_b: 0.083,
      unc_theta: -1.228,
      vangle: 91.493,
      vmag: 19.942,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2021/21Sep13/G96_20210913_2B_N27045_01_0002.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210913_2b_n27045_01_0002.arch?ra=110.66456&dec=27.12645&size=5.00arcmin&format=fits',
      date: '2021-09-13 11:53:59.549',
      ddec: 0.167634,
      dec: 27.12645,
      delta: 5.23816518637866,
      dra: 23.54976,
      drh: -0.1496304,
      elong: 62.3583,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59470.495654502316,
      mjd_stop: 59470.49600172454,
      phase: 10.5809,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210913_2b_n27045_01_0002.arch?ra=110.66456&dec=27.12645&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210913_2b_n27045_01_0002.arch',
      ra: 110.66456,
      rh: 4.853965392766,
      sangle: 100.72500000000002,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 182.2049,
      unc_a: 0.162,
      unc_b: 0.083,
      unc_theta: -1.228,
      vangle: 91.493,
      vmag: 19.942,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2021/21Sep13/G96_20210913_2B_N27045_01_0003.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210913_2b_n27045_01_0003.arch?ra=110.66505&dec=27.12646&size=5.00arcmin&format=fits',
      date: '2021-09-13 11:58:02.298',
      ddec: 0.165841,
      dec: 27.12646,
      delta: 5.23812525906722,
      dra: 23.54389,
      drh: -0.1496456,
      elong: 62.3606,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59470.498464097225,
      mjd_stop: 59470.49881131944,
      phase: 10.5811,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210913_2b_n27045_01_0003.arch?ra=110.66505&dec=27.12646&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210913_2b_n27045_01_0003.arch',
      ra: 110.66505,
      rh: 4.853965149932,
      sangle: 100.72500000000002,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 182.2051,
      unc_a: 0.162,
      unc_b: 0.083,
      unc_theta: -1.228,
      vangle: 91.493,
      vmag: 19.942,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2021/21Sep13/G96_20210913_2B_N27045_01_0004.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210913_2b_n27045_01_0004.arch?ra=110.66555&dec=27.12646&size=5.00arcmin&format=fits',
      date: '2021-09-13 12:02:05.852',
      ddec: 0.164,
      dec: 27.12646,
      delta: 5.23808520505232,
      dra: 23.53809,
      drh: -0.1496608,
      elong: 62.3629,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59470.50128300926,
      mjd_stop: 59470.50163023148,
      phase: 10.5813,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210913_2b_n27045_01_0004.arch?ra=110.66555&dec=27.12646&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210913_2b_n27045_01_0004.arch',
      ra: 110.66555,
      rh: 4.853964906268,
      sangle: 100.72500000000002,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 182.2054,
      unc_a: 0.162,
      unc_b: 0.083,
      unc_theta: -1.228,
      vangle: 91.49400000000003,
      vmag: 19.942,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2021/21Jan08/G96_20210108_2B_N25029_01_0001.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210108_2b_n25029_01_0001.arch?ra=68.72266&dec=25.09142&size=5.00arcmin&format=fits',
      date: '2021-01-08 04:20:04.099',
      ddec: -1.05147,
      dec: 25.09142,
      delta: 3.95828505986017,
      dra: -16.0625,
      drh: 1.1882464,
      elong: 142.8746,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59222.18042938657,
      mjd_stop: 59222.180776608795,
      phase: 7.1288,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210108_2b_n25029_01_0001.arch?ra=68.72266&dec=25.09142&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210108_2b_n25029_01_0001.arch',
      ra: 68.72266,
      rh: 4.779254850329,
      sangle: 265.741,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 162.2411,
      unc_a: 0.206,
      unc_b: 0.11,
      unc_theta: 17.432,
      vangle: 72.577,
      vmag: 19.272,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2021/21Jan08/G96_20210108_2B_N25029_01_0003.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210108_2b_n25029_01_0003.arch?ra=68.72141&dec=25.09135&size=5.00arcmin&format=fits',
      date: '2021-01-08 04:35:16.594',
      ddec: -1.06514,
      dec: 25.09135,
      delta: 3.95839415682356,
      dra: -16.0603,
      drh: 1.1881902,
      elong: 142.8626,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59222.1909906713,
      mjd_stop: 59222.19133789352,
      phase: 7.1307,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210108_2b_n25029_01_0003.arch?ra=68.72141&dec=25.09135&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210108_2b_n25029_01_0003.arch',
      ra: 68.72141,
      rh: 4.779262097615,
      sangle: 265.738,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 162.2419,
      unc_a: 0.206,
      unc_b: 0.11,
      unc_theta: 17.433,
      vangle: 72.576,
      vmag: 19.272,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2021/21Jan08/G96_20210108_2B_N25029_01_0004.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210108_2b_n25029_01_0004.arch?ra=68.72079&dec=25.09131&size=5.00arcmin&format=fits',
      date: '2021-01-08 04:42:50.145',
      ddec: -1.07196,
      dec: 25.09131,
      delta: 3.95844844675271,
      dra: -16.0584,
      drh: 1.1881622,
      elong: 142.8567,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59222.19624010417,
      mjd_stop: 59222.19658732639,
      phase: 7.1317,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210108_2b_n25029_01_0004.arch?ra=68.72079&dec=25.09131&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210108_2b_n25029_01_0004.arch',
      ra: 68.72079,
      rh: 4.779265699713,
      sangle: 265.73699999999997,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 162.2424,
      unc_a: 0.206,
      unc_b: 0.11,
      unc_theta: 17.433,
      vangle: 72.576,
      vmag: 19.272,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2021/21Jan08/G96_20210108_2B_N25029_01_0002.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210108_2b_n25029_01_0002.arch?ra=68.72203&dec=25.09138&size=5.00arcmin&format=fits',
      date: '2021-01-08 04:27:41.783',
      ddec: -1.05832,
      dec: 25.09138,
      delta: 3.95833975866753,
      dra: -16.0617,
      drh: 1.1882182,
      elong: 142.8686,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59222.185726655094,
      mjd_stop: 59222.18607387732,
      phase: 7.1297,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210108_2b_n25029_01_0002.arch?ra=68.72203&dec=25.09138&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210108_2b_n25029_01_0002.arch',
      ra: 68.72203,
      rh: 4.779258485424,
      sangle: 265.74,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 162.2415,
      unc_a: 0.206,
      unc_b: 0.11,
      unc_theta: 17.433,
      vangle: 72.576,
      vmag: 19.272,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2021/21Jan04/G96_20210104_2B_N25029_01_0004.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210104_2b_n25029_01_0004.arch?ra=69.21339&dec=25.1222&size=5.00arcmin&format=fits',
      date: '2021-01-04 03:27:25.847',
      ddec: -1.04651,
      dec: 25.1222,
      delta: 3.91856955438828,
      dra: -17.8749,
      drh: 1.2097326,
      elong: 147.4167,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59218.14387554398,
      mjd_stop: 59218.144222766205,
      phase: 6.3597,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210104_2b_n25029_01_0004.arch?ra=69.21339&dec=25.1222&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210104_2b_n25029_01_0004.arch',
      ra: 69.21339,
      rh: 4.776459796332,
      sangle: 266.622,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 161.9094,
      unc_a: 0.208,
      unc_b: 0.111,
      unc_theta: 17.183,
      vangle: 72.798,
      vmag: 19.247,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2021/21Jan04/G96_20210104_2B_N25029_01_0001.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210104_2b_n25029_01_0001.arch?ra=69.21541&dec=25.12231&size=5.00arcmin&format=fits',
      date: '2021-01-04 03:05:19.385',
      ddec: -1.02842,
      dec: 25.12231,
      delta: 3.91842790653251,
      dra: -17.8596,
      drh: 1.2098143,
      elong: 147.4342,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59218.12852297453,
      mjd_stop: 59218.12887019676,
      phase: 6.3567,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210104_2b_n25029_01_0001.arch?ra=69.21541&dec=25.12231&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210104_2b_n25029_01_0001.arch',
      ra: 69.21541,
      rh: 4.776449070023,
      sangle: 266.625,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 161.9081,
      unc_a: 0.208,
      unc_b: 0.111,
      unc_theta: 17.182,
      vangle: 72.799,
      vmag: 19.247,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2021/21Jan04/G96_20210104_2B_N25029_01_0003.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210104_2b_n25029_01_0003.arch?ra=69.21406&dec=25.12223&size=5.00arcmin&format=fits',
      date: '2021-01-04 03:20:02.482',
      ddec: -1.04036,
      dec: 25.12223,
      delta: 3.91852217199242,
      dra: -17.8702,
      drh: 1.2097599,
      elong: 147.4226,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59218.13874400463,
      mjd_stop: 59218.139091226854,
      phase: 6.3587,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210104_2b_n25029_01_0003.arch?ra=69.21406&dec=25.12223&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210104_2b_n25029_01_0003.arch',
      ra: 69.21406,
      rh: 4.776456211184,
      sangle: 266.623,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 161.909,
      unc_a: 0.208,
      unc_b: 0.111,
      unc_theta: 17.183,
      vangle: 72.798,
      vmag: 19.247,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2021/21Jan04/G96_20210104_2B_N25029_01_0002.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210104_2b_n25029_01_0002.arch?ra=69.21474&dec=25.12227&size=5.00arcmin&format=fits',
      date: '2021-01-04 03:12:40.845',
      ddec: -1.03434,
      dec: 25.12227,
      delta: 3.91847501149638,
      dra: -17.8651,
      drh: 1.2097871,
      elong: 147.4284,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59218.13363246528,
      mjd_stop: 59218.1339796875,
      phase: 6.3577,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210104_2b_n25029_01_0002.arch?ra=69.21474&dec=25.12227&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20210104_2b_n25029_01_0002.arch',
      ra: 69.21474,
      rh: 4.776452639928,
      sangle: 266.624,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 161.9085,
      unc_a: 0.208,
      unc_b: 0.111,
      unc_theta: 17.182,
      vangle: 72.799,
      vmag: 19.247,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Dec16/G96_20201216_2B_N25030_01_0004.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201216_2b_n25030_01_0004.arch?ra=72.29263&dec=25.26393&size=5.00arcmin&format=fits',
      date: '2020-12-15 05:49:26.889',
      ddec: -0.67628,
      dec: 25.26393,
      delta: 3.78912332695599,
      dra: -24.1757,
      drh: 1.3154498,
      elong: 170.1916,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59198.24249871528,
      mjd_stop: 59198.2428459375,
      phase: 2.0126,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201216_2b_n25030_01_0004.arch?ra=72.29263&dec=25.26393&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201216_2b_n25030_01_0004.arch',
      ra: 72.29263,
      rh: 4.761947755883,
      sangle: 279.48199999999997,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 160.268,
      unc_a: 0.215,
      unc_b: 0.114,
      unc_theta: 15.691,
      vangle: 74.13,
      vmag: 19.162,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Dec16/G96_20201216_2B_N25030_01_0001.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201216_2b_n25030_01_0001.arch?ra=72.29537&dec=25.264&size=5.00arcmin&format=fits',
      date: '2020-12-15 05:27:18.936',
      ddec: -0.65488,
      dec: 25.264,
      delta: 3.78907047992997,
      dra: -24.1657,
      drh: 1.3155313,
      elong: 170.2092,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59198.22712888889,
      mjd_stop: 59198.22747611111,
      phase: 2.009,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201216_2b_n25030_01_0001.arch?ra=72.29537&dec=25.264&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201216_2b_n25030_01_0001.arch',
      ra: 72.29537,
      rh: 4.761936078745,
      sangle: 279.512,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 160.2667,
      unc_a: 0.215,
      unc_b: 0.114,
      unc_theta: 15.689,
      vangle: 74.131,
      vmag: 19.162,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Dec16/G96_20201216_2B_N25030_01_0003.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201216_2b_n25030_01_0003.arch?ra=72.29354&dec=25.26395&size=5.00arcmin&format=fits',
      date: '2020-12-15 05:42:02.875',
      ddec: -0.66907,
      dec: 25.26395,
      delta: 3.78910561568094,
      dra: -24.1729,
      drh: 1.3154771,
      elong: 170.1975,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59198.23735966435,
      mjd_stop: 59198.237706886575,
      phase: 2.0114,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201216_2b_n25030_01_0003.arch?ra=72.29354&dec=25.26395&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201216_2b_n25030_01_0003.arch',
      ra: 72.29354,
      rh: 4.761943851599,
      sangle: 279.492,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 160.2675,
      unc_a: 0.215,
      unc_b: 0.114,
      unc_theta: 15.69,
      vangle: 74.13,
      vmag: 19.162,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Dec16/G96_20201216_2B_N25030_01_0002.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201216_2b_n25030_01_0002.arch?ra=72.29445&dec=25.26398&size=5.00arcmin&format=fits',
      date: '2020-12-15 05:34:40.794',
      ddec: -0.66194,
      dec: 25.26398,
      delta: 3.7890880228806,
      dra: -24.1696,
      drh: 1.3155042,
      elong: 170.2033,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59198.23224298611,
      mjd_stop: 59198.23259020833,
      phase: 2.0102,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201216_2b_n25030_01_0002.arch?ra=72.29445&dec=25.26398&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201216_2b_n25030_01_0002.arch',
      ra: 72.29445,
      rh: 4.761939964232,
      sangle: 279.502,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 160.2671,
      unc_a: 0.215,
      unc_b: 0.114,
      unc_theta: 15.69,
      vangle: 74.131,
      vmag: 19.162,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Dec04/G96_20201204_2B_N25031_01_0004.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201204_2b_n25031_01_0004.arch?ra=74.39005&dec=25.29785&size=5.00arcmin&format=fits',
      date: '2020-12-03 11:28:00.576',
      ddec: -0.1925,
      dec: 25.29785,
      delta: 3.77027308799439,
      dra: -24.5439,
      drh: 1.377767,
      elong: 174.8468,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59186.47761083333,
      mjd_stop: 59186.477958055555,
      phase: 1.0724,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201204_2b_n25031_01_0004.arch?ra=74.39005&dec=25.29785&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201204_2b_n25031_01_0004.arch',
      ra: 74.39005,
      rh: 4.752797838447,
      sangle: 53.27600000000001,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 159.2926,
      unc_a: 0.216,
      unc_b: 0.114,
      unc_theta: 14.726,
      vangle: 74.99000000000001,
      vmag: 19.144,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Dec04/G96_20201204_2B_N25031_01_0001.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201204_2b_n25031_01_0001.arch?ra=74.39301&dec=25.29788&size=5.00arcmin&format=fits',
      date: '2020-12-03 11:04:29.966',
      ddec: -0.17873,
      dec: 25.29788,
      delta: 3.77027498962565,
      dra: -24.5873,
      drh: 1.3778534,
      elong: 174.8303,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59186.4612843287,
      mjd_stop: 59186.46163155093,
      phase: 1.0758,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201204_2b_n25031_01_0001.arch?ra=74.39301&dec=25.29788&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201204_2b_n25031_01_0001.arch',
      ra: 74.39301,
      rh: 4.75278484659,
      sangle: 53.38900000000001,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 159.2912,
      unc_a: 0.216,
      unc_b: 0.114,
      unc_theta: 14.725,
      vangle: 74.99100000000001,
      vmag: 19.144,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Dec04/G96_20201204_2B_N25031_01_0002.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201204_2b_n25031_01_0002.arch?ra=74.39203&dec=25.29787&size=5.00arcmin&format=fits',
      date: '2020-12-03 11:12:18.377',
      ddec: -0.18352,
      dec: 25.29787,
      delta: 3.77027432733859,
      dra: -24.5732,
      drh: 1.3778247,
      elong: 174.8358,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59186.46670575232,
      mjd_stop: 59186.467052974534,
      phase: 1.0747,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201204_2b_n25031_01_0002.arch?ra=74.39203&dec=25.29787&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201204_2b_n25031_01_0002.arch',
      ra: 74.39203,
      rh: 4.752789160792,
      sangle: 53.351,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 159.2917,
      unc_a: 0.216,
      unc_b: 0.114,
      unc_theta: 14.726,
      vangle: 74.99100000000001,
      vmag: 19.144,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Dec04/G96_20201204_2B_N25031_01_0003.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201204_2b_n25031_01_0003.arch?ra=74.39104&dec=25.29786&size=5.00arcmin&format=fits',
      date: '2020-12-03 11:20:08.378',
      ddec: -0.18811,
      dec: 25.29786,
      delta: 3.77027369397512,
      dra: -24.5588,
      drh: 1.377796,
      elong: 174.8413,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59186.4721455787,
      mjd_stop: 59186.472492800924,
      phase: 1.0735,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201204_2b_n25031_01_0003.arch?ra=74.39104&dec=25.29786&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201204_2b_n25031_01_0003.arch',
      ra: 74.39104,
      rh: 4.752793489548,
      sangle: 53.31399999999999,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 159.2921,
      unc_a: 0.216,
      unc_b: 0.114,
      unc_theta: 14.726,
      vangle: 74.99000000000001,
      vmag: 19.144,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Nov23/G96_20201123_2B_N25032_01_0003.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201123_2b_n25032_01_0003.arch?ra=76.15898&dec=25.27729&size=5.00arcmin&format=fits',
      date: '2020-11-23 08:39:50.597',
      ddec: 0.702607,
      dec: 25.27729,
      delta: 3.78926880760249,
      dra: -23.3567,
      drh: 1.4312406,
      elong: 163.5519,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59176.360828668985,
      mjd_stop: 59176.3611758912,
      phase: 3.3839,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201123_2b_n25032_01_0003.arch?ra=76.15898&dec=25.27729&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201123_2b_n25032_01_0003.arch',
      ra: 76.15898,
      rh: 4.744591278299,
      sangle: 76.23399999999998,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 158.4507,
      unc_a: 0.214,
      unc_b: 0.113,
      unc_theta: 13.945,
      vangle: 75.68299999999999,
      vmag: 19.148,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Nov23/G96_20201123_2B_N25032_01_0001.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201123_2b_n25032_01_0001.arch?ra=76.16074&dec=25.27724&size=5.00arcmin&format=fits',
      date: '2020-11-23 08:25:07.452',
      ddec: 0.717502,
      dec: 25.27724,
      delta: 3.78930395645704,
      dra: -23.3564,
      drh: 1.4312946,
      elong: 163.54,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59176.35060708333,
      mjd_stop: 59176.350954305555,
      phase: 3.3863,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201123_2b_n25032_01_0001.arch?ra=76.16074&dec=25.27724&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201123_2b_n25032_01_0001.arch',
      ra: 76.16074,
      rh: 4.744582828701,
      sangle: 76.24200000000002,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 158.4498,
      unc_a: 0.214,
      unc_b: 0.113,
      unc_theta: 13.944,
      vangle: 75.68299999999999,
      vmag: 19.148,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Nov23/G96_20201123_2B_N25032_01_0004.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201123_2b_n25032_01_0004.arch?ra=76.1581&dec=25.27731&size=5.00arcmin&format=fits',
      date: '2020-11-23 08:47:11.994',
      ddec: 0.695192,
      dec: 25.27731,
      delta: 3.78925130258745,
      dra: -23.356,
      drh: 1.4312136,
      elong: 163.5578,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59176.36593743056,
      mjd_stop: 59176.36628465278,
      phase: 3.3827,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201123_2b_n25032_01_0004.arch?ra=76.1581&dec=25.27731&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201123_2b_n25032_01_0004.arch',
      ra: 76.1581,
      rh: 4.744595501299,
      sangle: 76.23000000000002,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 158.4511,
      unc_a: 0.214,
      unc_b: 0.113,
      unc_theta: 13.945,
      vangle: 75.68199999999999,
      vmag: 19.148,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Nov23/G96_20201123_2B_N25032_01_0002.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201123_2b_n25032_01_0002.arch?ra=76.15986&dec=25.27727&size=5.00arcmin&format=fits',
      date: '2020-11-23 08:32:28.925',
      ddec: 0.710049,
      dec: 25.27727,
      delta: 3.78928636514077,
      dra: -23.3568,
      drh: 1.4312676,
      elong: 163.5459,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59176.35571672454,
      mjd_stop: 59176.35606394676,
      phase: 3.3851,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201123_2b_n25032_01_0002.arch?ra=76.15986&dec=25.27727&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201123_2b_n25032_01_0002.arch',
      ra: 76.15986,
      rh: 4.744587052588,
      sangle: 76.238,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 158.4503,
      unc_a: 0.214,
      unc_b: 0.113,
      unc_theta: 13.944,
      vangle: 75.68299999999999,
      vmag: 19.148,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Nov12/G96_20201112_2B_N25033_01_0003.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201112_2b_n25033_01_0003.arch?ra=77.88354&dec=25.19916&size=5.00arcmin&format=fits',
      date: '2020-11-12 08:47:48.573',
      ddec: 1.507835,
      dec: 25.19916,
      delta: 3.84508423614246,
      dra: -19.9089,
      drh: 1.489228,
      elong: 151.0131,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59165.36636079861,
      mjd_stop: 59165.36670802083,
      phase: 5.8191,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201112_2b_n25033_01_0003.arch?ra=77.88354&dec=25.19916&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201112_2b_n25033_01_0003.arch',
      ra: 77.88354,
      rh: 4.735318686947,
      sangle: 81.19,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 157.5322,
      unc_a: 0.21,
      unc_b: 0.111,
      unc_theta: 13.215,
      vangle: 76.32,
      vmag: 19.172,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Nov12/G96_20201112_2B_N25033_01_0004.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201112_2b_n25033_01_0004.arch?ra=77.88279&dec=25.19921&size=5.00arcmin&format=fits',
      date: '2020-11-12 08:55:10.979',
      ddec: 1.500539,
      dec: 25.19921,
      delta: 3.84505015017036,
      dra: -19.9121,
      drh: 1.4892011,
      elong: 151.019,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59165.37148123843,
      mjd_stop: 59165.37182846065,
      phase: 5.8181,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201112_2b_n25033_01_0004.arch?ra=77.88279&dec=25.19921&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201112_2b_n25033_01_0004.arch',
      ra: 77.88279,
      rh: 4.735323091173,
      sangle: 81.18799999999999,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 157.5327,
      unc_a: 0.21,
      unc_b: 0.111,
      unc_theta: 13.215,
      vangle: 76.32,
      vmag: 19.171,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Nov12/G96_20201112_2B_N25033_01_0002.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201112_2b_n25033_01_0002.arch?ra=77.88429&dec=25.19911&size=5.00arcmin&format=fits',
      date: '2020-11-12 08:40:25.857',
      ddec: 1.515115,
      dec: 25.19911,
      delta: 3.84511838697461,
      dra: -19.9051,
      drh: 1.489255,
      elong: 151.0073,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59165.361236770834,
      mjd_stop: 59165.36158399306,
      phase: 5.8202,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201112_2b_n25033_01_0002.arch?ra=77.88429&dec=25.19911&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201112_2b_n25033_01_0002.arch',
      ra: 77.88429,
      rh: 4.735314279555,
      sangle: 81.19099999999997,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 157.5318,
      unc_a: 0.21,
      unc_b: 0.111,
      unc_theta: 13.215,
      vangle: 76.32,
      vmag: 19.172,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Nov12/G96_20201112_2B_N25033_01_0001.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201112_2b_n25033_01_0001.arch?ra=77.88505&dec=25.19906&size=5.00arcmin&format=fits',
      date: '2020-11-12 08:33:02.874',
      ddec: 1.522372,
      dec: 25.19906,
      delta: 3.8451525993135,
      dra: -19.9009,
      drh: 1.489282,
      elong: 151.0014,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59165.35610965278,
      mjd_stop: 59165.356456875,
      phase: 5.8213,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201112_2b_n25033_01_0001.arch?ra=77.88505&dec=25.19906&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201112_2b_n25033_01_0001.arch',
      ra: 77.88505,
      rh: 4.735309869425,
      sangle: 81.19299999999998,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 157.5314,
      unc_a: 0.21,
      unc_b: 0.111,
      unc_theta: 13.215,
      vangle: 76.32100000000003,
      vmag: 19.172,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Oct13/G96_20201013_2B_N25034_01_0004.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201013_2b_n25034_01_0004.arch?ra=80.58203&dec=24.75622&size=5.00arcmin&format=fits',
      date: '2020-10-13 10:42:36.490',
      ddec: 2.83398,
      dec: 24.75622,
      delta: 4.14555450330817,
      dra: -4.72391,
      drh: 1.6462931,
      elong: 118.8032,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59135.44608206018,
      mjd_stop: 59135.446429282405,
      phase: 10.7052,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201013_2b_n25034_01_0004.arch?ra=80.58203&dec=24.75622&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201013_2b_n25034_01_0004.arch',
      ra: 80.58203,
      rh: 4.708223853725,
      sangle: 85.387,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 155.0139,
      unc_a: 0.191,
      unc_b: 0.102,
      unc_theta: 12.2,
      vangle: 77.12200000000001,
      vmag: 19.312,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Oct13/G96_20201013_2B_N25034_01_0002.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201013_2b_n25034_01_0002.arch?ra=80.58236&dec=24.75604&size=5.00arcmin&format=fits',
      date: '2020-10-13 10:28:51.858',
      ddec: 2.845894,
      dec: 24.75604,
      delta: 4.14567657003893,
      dra: -4.71344,
      drh: 1.646343,
      elong: 118.7934,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59135.43653770833,
      mjd_stop: 59135.436884930554,
      phase: 10.7062,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201013_2b_n25034_01_0002.arch?ra=80.58236&dec=24.75604&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201013_2b_n25034_01_0002.arch',
      ra: 80.58236,
      rh: 4.708214778015,
      sangle: 85.38799999999998,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 155.0131,
      unc_a: 0.191,
      unc_b: 0.102,
      unc_theta: 12.2,
      vangle: 77.12200000000001,
      vmag: 19.312,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Oct13/G96_20201013_2B_N25034_01_0003.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201013_2b_n25034_01_0003.arch?ra=80.58219&dec=24.75613&size=5.00arcmin&format=fits',
      date: '2020-10-13 10:35:44.242',
      ddec: 2.839952,
      dec: 24.75613,
      delta: 4.14561551056649,
      dra: -4.71888,
      drh: 1.646318,
      elong: 118.7983,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59135.4413106713,
      mjd_stop: 59135.44165789352,
      phase: 10.7057,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201013_2b_n25034_01_0003.arch?ra=80.58219&dec=24.75613&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201013_2b_n25034_01_0003.arch',
      ra: 80.58219,
      rh: 4.708219316653,
      sangle: 85.38799999999998,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 155.0135,
      unc_a: 0.191,
      unc_b: 0.102,
      unc_theta: 12.2,
      vangle: 77.12200000000001,
      vmag: 19.312,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Oct13/G96_20201013_2B_N25034_01_0001.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201013_2b_n25034_01_0001.arch?ra=80.58252&dec=24.75595&size=5.00arcmin&format=fits',
      date: '2020-10-13 10:22:01.059',
      ddec: 2.851775,
      dec: 24.75595,
      delta: 4.14573742656399,
      dra: -4.7076,
      drh: 1.6463678,
      elong: 118.7885,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59135.43178309028,
      mjd_stop: 59135.4321303125,
      phase: 10.7068,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201013_2b_n25034_01_0001.arch?ra=80.58252&dec=24.75595&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20201013_2b_n25034_01_0001.arch',
      ra: 80.58252,
      rh: 4.708210256754,
      sangle: 85.38799999999998,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 155.0127,
      unc_a: 0.191,
      unc_b: 0.102,
      unc_theta: 12.2,
      vangle: 77.12299999999999,
      vmag: 19.312,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Sep27/G96_20200927_2B_N25034_01_0001.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200927_2b_n25034_01_0001.arch?ra=80.56135&dec=24.43741&size=5.00arcmin&format=fits',
      date: '2020-09-27 10:00:19.875',
      ddec: 3.245768,
      dec: 24.43741,
      delta: 4.36338251692209,
      dra: 4.158621,
      drh: 1.7299105,
      elong: 103.0564,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59119.416723090275,
      mjd_stop: 59119.4170703125,
      phase: 12.0117,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200927_2b_n25034_01_0001.arch?ra=80.56135&dec=24.43741&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200927_2b_n25034_01_0001.arch',
      ra: 80.56135,
      rh: 4.69259433344,
      sangle: 85.96199999999999,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 153.6529,
      unc_a: 0.18,
      unc_b: 0.097,
      unc_theta: 12.284,
      vangle: 76.952,
      vmag: 19.41,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Sep27/G96_20200927_2B_N25034_01_0003.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200927_2b_n25034_01_0003.arch?ra=80.56167&dec=24.43764&size=5.00arcmin&format=fits',
      date: '2020-09-27 10:15:19.404',
      ddec: 3.23541,
      dec: 24.43764,
      delta: 4.36323322654518,
      dra: 4.137132,
      drh: 1.7298563,
      elong: 103.0664,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59119.42713430556,
      mjd_stop: 59119.427481527775,
      phase: 12.0111,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200927_2b_n25034_01_0003.arch?ra=80.56167&dec=24.43764&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200927_2b_n25034_01_0003.arch',
      ra: 80.56167,
      rh: 4.692604736055,
      sangle: 85.96199999999999,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 153.6538,
      unc_a: 0.18,
      unc_b: 0.097,
      unc_theta: 12.284,
      vangle: 76.95299999999997,
      vmag: 19.41,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Sep27/G96_20200927_2B_N25034_01_0004.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200927_2b_n25034_01_0004.arch?ra=80.56183&dec=24.43775&size=5.00arcmin&format=fits',
      date: '2020-09-27 10:22:48.613',
      ddec: 3.23008,
      dec: 24.43775,
      delta: 4.36315872072289,
      dra: 4.127012,
      drh: 1.7298292,
      elong: 103.0714,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59119.4323334838,
      mjd_stop: 59119.43268070602,
      phase: 12.0108,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200927_2b_n25034_01_0004.arch?ra=80.56183&dec=24.43775&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200927_2b_n25034_01_0004.arch',
      ra: 80.56183,
      rh: 4.692609930816,
      sangle: 85.96199999999999,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 153.6543,
      unc_a: 0.18,
      unc_b: 0.097,
      unc_theta: 12.284,
      vangle: 76.95299999999997,
      vmag: 19.41,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Sep27/G96_20200927_2B_N25034_01_0002.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200927_2b_n25034_01_0002.arch?ra=80.56151&dec=24.43752&size=5.00arcmin&format=fits',
      date: '2020-09-27 10:07:49.372',
      ddec: 3.240646,
      dec: 24.43752,
      delta: 4.36330790058359,
      dra: 4.147682,
      drh: 1.7298834,
      elong: 103.0614,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59119.421925601855,
      mjd_stop: 59119.42227282407,
      phase: 12.0114,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200927_2b_n25034_01_0002.arch?ra=80.56151&dec=24.43752&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200927_2b_n25034_01_0002.arch',
      ra: 80.56151,
      rh: 4.692599531695,
      sangle: 85.96199999999999,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 153.6534,
      unc_a: 0.18,
      unc_b: 0.097,
      unc_theta: 12.284,
      vangle: 76.952,
      vmag: 19.41,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Mar05/G96_20200305_2B_N14018_01_0004.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200305_2b_n14018_01_0004.arch?ra=38.6473&dec=13.49428&size=5.00arcmin&format=fits',
      date: '2020-03-06 02:47:48.833',
      ddec: 11.7729,
      dec: 13.49428,
      delta: 4.92206466774944,
      dra: 27.64456,
      drh: 2.74882,
      elong: 54.8562,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58914.11636380787,
      mjd_stop: 58914.116711030096,
      phase: 10.5622,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200305_2b_n14018_01_0004.arch?ra=38.6473&dec=13.49428&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200305_2b_n14018_01_0004.arch',
      ra: 38.6473,
      rh: 4.425924038601,
      sangle: 253.002,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 135.2087,
      unc_a: 0.139,
      unc_b: 0.077,
      unc_theta: 26.77,
      vangle: 63.400000000000006,
      vmag: 19.436,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Mar05/G96_20200305_2B_N14018_01_0003.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200305_2b_n14018_01_0003.arch?ra=38.64638&dec=13.4939&size=5.00arcmin&format=fits',
      date: '2020-03-06 02:40:50.798',
      ddec: 11.77435,
      dec: 13.4939,
      delta: 4.92199348711399,
      dra: 27.63338,
      drh: 2.7488424,
      elong: 54.8601,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58914.11152543982,
      mjd_stop: 58914.11187266203,
      phase: 10.5627,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200305_2b_n14018_01_0003.arch?ra=38.64638&dec=13.4939&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200305_2b_n14018_01_0003.arch',
      ra: 38.64638,
      rh: 4.425916357944,
      sangle: 253.002,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 135.2082,
      unc_a: 0.139,
      unc_b: 0.077,
      unc_theta: 26.77,
      vangle: 63.399,
      vmag: 19.436,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Mar05/G96_20200305_2B_N14018_01_0001.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200305_2b_n14018_01_0001.arch?ra=38.64328&dec=13.49261&size=5.00arcmin&format=fits',
      date: '2020-03-06 02:17:14.274',
      ddec: 11.77978,
      dec: 13.49261,
      delta: 4.92175241129941,
      dra: 27.59714,
      drh: 2.7489184,
      elong: 54.8733,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58914.09513048611,
      mjd_stop: 58914.09547770833,
      phase: 10.5644,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200305_2b_n14018_01_0001.arch?ra=38.64328&dec=13.49261&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200305_2b_n14018_01_0001.arch',
      ra: 38.64328,
      rh: 4.425890331342,
      sangle: 253.001,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 135.2067,
      unc_a: 0.139,
      unc_b: 0.077,
      unc_theta: 26.771,
      vangle: 63.399,
      vmag: 19.436,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Mar05/G96_20200305_2B_N14018_01_0002.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200305_2b_n14018_01_0002.arch?ra=38.64513&dec=13.49338&size=5.00arcmin&format=fits',
      date: '2020-03-06 02:31:19.677',
      ddec: 11.77644,
      dec: 13.49338,
      delta: 4.9218962654975,
      dra: 27.61846,
      drh: 2.748873,
      elong: 54.8655,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58914.10491524306,
      mjd_stop: 58914.10526246528,
      phase: 10.5634,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200305_2b_n14018_01_0002.arch?ra=38.64513&dec=13.49338&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200305_2b_n14018_01_0002.arch',
      ra: 38.64513,
      rh: 4.425905864499,
      sangle: 253.002,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 135.2076,
      unc_a: 0.139,
      unc_b: 0.077,
      unc_theta: 26.77,
      vangle: 63.399,
      vmag: 19.436,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Jan26/G96_20200126_2B_N09015_01_0003.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200126_2b_n09015_01_0003.arch?ra=32.78285&dec=10.65479&size=5.00arcmin&format=fits',
      date: '2020-01-26 02:19:54.307',
      ddec: 8.726825,
      dec: 10.65479,
      delta: 4.26641466841226,
      dra: 13.26481,
      drh: 2.9306176,
      elong: 88.9179,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58874.09698271991,
      mjd_stop: 58874.09732994213,
      phase: 13.0445,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200126_2b_n09015_01_0003.arch?ra=32.78285&dec=10.65479&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200126_2b_n09015_01_0003.arch',
      ra: 32.78285,
      rh: 4.36028098026,
      sangle: 250.49200000000002,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 131.3194,
      unc_a: 0.154,
      unc_b: 0.087,
      unc_theta: 27.974,
      vangle: 62.31700000000001,
      vmag: 19.066,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Jan26/G96_20200126_2B_N09015_01_0002.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200126_2b_n09015_01_0002.arch?ra=32.78233&dec=10.65445&size=5.00arcmin&format=fits',
      date: '2020-01-26 02:11:36.920',
      ddec: 8.729076,
      dec: 10.65445,
      delta: 4.26631400094058,
      dra: 13.25782,
      drh: 2.9306432,
      elong: 88.9231,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58874.09122592593,
      mjd_stop: 58874.09157314815,
      phase: 13.0446,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200126_2b_n09015_01_0002.arch?ra=32.78233&dec=10.65445&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200126_2b_n09015_01_0002.arch',
      ra: 32.78233,
      rh: 4.360271237407,
      sangle: 250.49099999999999,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 131.3188,
      unc_a: 0.154,
      unc_b: 0.087,
      unc_theta: 27.974,
      vangle: 62.316,
      vmag: 19.066,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Jan26/G96_20200126_2B_N09015_01_0004.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200126_2b_n09015_01_0004.arch?ra=32.78337&dec=10.65512&size=5.00arcmin&format=fits',
      date: '2020-01-26 02:28:11.594',
      ddec: 8.724606,
      dec: 10.65512,
      delta: 4.2665153601708,
      dra: 13.27237,
      drh: 2.930592,
      elong: 88.9126,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58874.10273835648,
      mjd_stop: 58874.1030855787,
      phase: 13.0445,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200126_2b_n09015_01_0004.arch?ra=32.78337&dec=10.65512&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200126_2b_n09015_01_0004.arch',
      ra: 32.78337,
      rh: 4.36029072107,
      sangle: 250.49200000000002,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 131.3199,
      unc_a: 0.154,
      unc_b: 0.087,
      unc_theta: 27.973,
      vangle: 62.31700000000001,
      vmag: 19.066,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2020/20Jan26/G96_20200126_2B_N09015_01_0001.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200126_2b_n09015_01_0001.arch?ra=32.78181&dec=10.65412&size=5.00arcmin&format=fits',
      date: '2020-01-26 02:03:19.021',
      ddec: 8.731356,
      dec: 10.65412,
      delta: 4.26621327488756,
      dra: 13.25141,
      drh: 2.9306688,
      elong: 88.9284,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 58874.08546320602,
      mjd_stop: 58874.08581042824,
      phase: 13.0446,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200126_2b_n09015_01_0001.arch?ra=32.78181&dec=10.65412&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20200126_2b_n09015_01_0001.arch',
      ra: 32.78181,
      rh: 4.360261484438,
      sangle: 250.49099999999999,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 131.3182,
      unc_a: 0.154,
      unc_b: 0.087,
      unc_theta: 27.974,
      vangle: 62.316,
      vmag: 19.066,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2022/22Oct09/G96_20221009_2B_N20063_01_0002.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221009_2b_n20063_01_0002.arch?ra=145.83264&dec=21.85058&size=5.00arcmin&format=fits',
      date: '2022-10-09 12:12:31.029',
      ddec: -5.99773,
      dec: 21.85058,
      delta: 5.07669338144982,
      dra: 27.95445,
      drh: -2.2103556,
      elong: 55.4357,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59861.50851885416,
      mjd_stop: 59861.50886607639,
      phase: 10.3362,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221009_2b_n20063_01_0002.arch?ra=145.83264&dec=21.85058&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221009_2b_n20063_01_0002.arch',
      ra: 145.83264,
      rh: 4.584428731567,
      sangle: 114.74700000000001,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 214.758,
      unc_a: 0.141,
      unc_b: 0.074,
      unc_theta: -16.047,
      vangle: 107.62,
      vmag: 19.645,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2022/22Oct09/G96_20221009_2B_N20063_01_0004.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221009_2b_n20063_01_0004.arch?ra=145.83377&dec=21.85035&size=5.00arcmin&format=fits',
      date: '2022-10-09 12:20:38.143',
      ddec: -6.00009,
      dec: 21.85035,
      delta: 5.07661276257318,
      dra: 27.94208,
      drh: -2.2103836,
      elong: 55.4401,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59861.51415674768,
      mjd_stop: 59861.514503969905,
      phase: 10.3367,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221009_2b_n20063_01_0004.arch?ra=145.83377&dec=21.85035&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221009_2b_n20063_01_0004.arch',
      ra: 145.83377,
      rh: 4.584421533665,
      sangle: 114.74599999999998,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 214.7585,
      unc_a: 0.141,
      unc_b: 0.074,
      unc_theta: -16.047,
      vangle: 107.62099999999998,
      vmag: 19.645,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2022/22Oct09/G96_20221009_2B_N20063_01_0003.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221009_2b_n20063_01_0003.arch?ra=145.83321&dec=21.85047&size=5.00arcmin&format=fits',
      date: '2022-10-09 12:16:34.640',
      ddec: -5.99889,
      dec: 21.85047,
      delta: 5.07665306052127,
      dra: 27.94823,
      drh: -2.2103696,
      elong: 55.4379,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59861.511338425924,
      mjd_stop: 59861.51168564815,
      phase: 10.3365,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221009_2b_n20063_01_0003.arch?ra=145.83321&dec=21.85047&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221009_2b_n20063_01_0003.arch',
      ra: 145.83321,
      rh: 4.584425131829,
      sangle: 114.74700000000001,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 214.7583,
      unc_a: 0.141,
      unc_b: 0.074,
      unc_theta: -16.047,
      vangle: 107.62,
      vmag: 19.645,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2022/22Oct09/G96_20221009_2B_N20063_01_0001.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221009_2b_n20063_01_0001.arch?ra=145.83208&dec=21.85069&size=5.00arcmin&format=fits',
      date: '2022-10-09 12:08:27.931',
      ddec: -5.99661,
      dec: 21.85069,
      delta: 5.07673362241581,
      dra: 27.96073,
      drh: -2.2103416,
      elong: 55.4334,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59861.505705219904,
      mjd_stop: 59861.50605244213,
      phase: 10.3359,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221009_2b_n20063_01_0001.arch?ra=145.83208&dec=21.85069&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221009_2b_n20063_01_0001.arch',
      ra: 145.83208,
      rh: 4.584432323702,
      sangle: 114.74700000000001,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 214.7578,
      unc_a: 0.141,
      unc_b: 0.074,
      unc_theta: -16.047,
      vangle: 107.62,
      vmag: 19.645,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2022/22Dec25/G96_20221225_2B_N20066_01_0004.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221225_2b_n20066_01_0004.arch?ra=153.95569&dec=21.93178&size=5.00arcmin&format=fits',
      date: '2022-12-26 11:00:06.612',
      ddec: 7.900763,
      dec: 21.93178,
      delta: 3.83045818932345,
      dra: -4.48122,
      drh: -2.5888175,
      elong: 125.6966,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59939.45823625,
      mjd_stop: 59939.458583472224,
      phase: 10.2837,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221225_2b_n20066_01_0004.arch?ra=153.95569&dec=21.93178&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221225_2b_n20066_01_0004.arch',
      ra: 153.95569,
      rh: 4.47631572202,
      sangle: 103.71800000000002,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 221.9192,
      unc_a: 0.171,
      unc_b: 0.093,
      unc_theta: -20.546,
      vangle: 110.63099999999997,
      vmag: 18.937,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2022/22Dec25/G96_20221225_2B_N20066_01_0002.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221225_2b_n20066_01_0002.arch?ra=153.95602&dec=21.93124&size=5.00arcmin&format=fits',
      date: '2022-12-26 10:45:14.720',
      ddec: 7.911036,
      dec: 21.93124,
      delta: 3.83060290425454,
      dra: -4.47131,
      drh: -2.5887688,
      elong: 125.6858,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59939.44791342592,
      mjd_stop: 59939.44826064815,
      phase: 10.2851,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221225_2b_n20066_01_0002.arch?ra=153.95602&dec=21.93124&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221225_2b_n20066_01_0002.arch',
      ra: 153.95602,
      rh: 4.476331157472,
      sangle: 103.721,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 221.9182,
      unc_a: 0.171,
      unc_b: 0.093,
      unc_theta: -20.545,
      vangle: 110.63099999999997,
      vmag: 18.937,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2022/22Dec25/G96_20221225_2B_N20066_01_0001.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221225_2b_n20066_01_0001.arch?ra=153.95618&dec=21.93097&size=5.00arcmin&format=fits',
      date: '2022-12-26 10:37:49.088',
      ddec: 7.916125,
      dec: 21.93097,
      delta: 3.83067527002007,
      dra: -4.46556,
      drh: -2.5887444,
      elong: 125.6804,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59939.442755648146,
      mjd_stop: 59939.44310287037,
      phase: 10.2858,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221225_2b_n20066_01_0001.arch?ra=153.95618&dec=21.93097&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221225_2b_n20066_01_0001.arch',
      ra: 153.95618,
      rh: 4.476338869656,
      sangle: 103.72300000000001,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 221.9177,
      unc_a: 0.171,
      unc_b: 0.093,
      unc_theta: -20.545,
      vangle: 110.63099999999997,
      vmag: 18.937,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2022/22Dec25/G96_20221225_2B_N20066_01_0003.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221225_2b_n20066_01_0003.arch?ra=153.95585&dec=21.93151&size=5.00arcmin&format=fits',
      date: '2022-12-26 10:52:40.610',
      ddec: 7.905913,
      dec: 21.93151,
      delta: 3.83053053605633,
      dra: -4.47653,
      drh: -2.5887931,
      elong: 125.6912,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59939.45307418981,
      mjd_stop: 59939.453421412036,
      phase: 10.2844,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221225_2b_n20066_01_0003.arch?ra=153.95585&dec=21.93151&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221225_2b_n20066_01_0003.arch',
      ra: 153.95585,
      rh: 4.476323440752,
      sangle: 103.719,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 221.9187,
      unc_a: 0.171,
      unc_b: 0.093,
      unc_theta: -20.545,
      vangle: 110.63099999999997,
      vmag: 18.937,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2022/22Dec24/G96_20221224_2B_N20066_01_0004.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221224_2b_n20066_01_0004.arch?ra=153.98217&dec=21.88007&size=5.00arcmin&format=fits',
      date: '2022-12-25 11:05:22.127',
      ddec: 7.710263,
      dec: 21.88007,
      delta: 3.84448305984119,
      dra: -3.91435,
      drh: -2.5841117,
      elong: 124.6701,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59938.46188804398,
      mjd_stop: 59938.462235266205,
      phase: 10.4128,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221224_2b_n20066_01_0004.arch?ra=153.98217&dec=21.88007&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221224_2b_n20066_01_0004.arch',
      ra: 153.98217,
      rh: 4.477804196377,
      sangle: 104.03300000000002,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 221.8253,
      unc_a: 0.171,
      unc_b: 0.093,
      unc_theta: -20.53,
      vangle: 110.644,
      vmag: 18.947,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2022/22Dec24/G96_20221224_2B_N20066_01_0003.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221224_2b_n20066_01_0003.arch?ra=153.98232&dec=21.8798&size=5.00arcmin&format=fits',
      date: '2022-12-25 10:57:57.306',
      ddec: 7.715346,
      dec: 21.8798,
      delta: 3.84455606782259,
      dra: -3.90976,
      drh: -2.5840874,
      elong: 124.6648,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59938.45673965278,
      mjd_stop: 59938.457086875,
      phase: 10.4135,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221224_2b_n20066_01_0003.arch?ra=153.98232&dec=21.8798&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221224_2b_n20066_01_0003.arch',
      ra: 153.98232,
      rh: 4.477811880684,
      sangle: 104.03500000000003,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 221.8249,
      unc_a: 0.171,
      unc_b: 0.093,
      unc_theta: -20.53,
      vangle: 110.644,
      vmag: 18.947,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2022/22Dec24/G96_20221224_2B_N20066_01_0001.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221224_2b_n20066_01_0001.arch?ra=153.98261&dec=21.87927&size=5.00arcmin&format=fits',
      date: '2022-12-25 10:43:07.170',
      ddec: 7.725442,
      dec: 21.87927,
      delta: 3.84470228285452,
      dra: -3.89899,
      drh: -2.5840387,
      elong: 124.654,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59938.44643715278,
      mjd_stop: 59938.446784375,
      phase: 10.4149,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221224_2b_n20066_01_0001.arch?ra=153.98261&dec=21.87927&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221224_2b_n20066_01_0001.arch',
      ra: 153.98261,
      rh: 4.477827257614,
      sangle: 104.03800000000001,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 221.8239,
      unc_a: 0.171,
      unc_b: 0.093,
      unc_theta: -20.53,
      vangle: 110.644,
      vmag: 18.947,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2022/22Dec24/G96_20221224_2B_N20066_01_0002.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221224_2b_n20066_01_0002.arch?ra=153.98246&dec=21.87954&size=5.00arcmin&format=fits',
      date: '2022-12-25 10:50:32.905',
      ddec: 7.720402,
      dec: 21.87954,
      delta: 3.84462904615793,
      dra: -3.90465,
      drh: -2.5840631,
      elong: 124.6594,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59938.451596122686,
      mjd_stop: 59938.45194334491,
      phase: 10.4142,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221224_2b_n20066_01_0002.arch?ra=153.98246&dec=21.87954&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20221224_2b_n20066_01_0002.arch',
      ra: 153.98246,
      rh: 4.477819557663,
      sangle: 104.036,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 221.8244,
      unc_a: 0.171,
      unc_b: 0.093,
      unc_theta: -20.53,
      vangle: 110.644,
      vmag: 18.947,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2023/23Feb11/G96_20230211_2B_N25062_01_0003.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230211_2b_n25062_01_0003.arch?ra=148.67358&dec=25.08402&size=5.00arcmin&format=fits',
      date: '2023-02-11 09:50:41.590',
      ddec: 9.317105,
      dec: 25.08402,
      delta: 3.43198974860741,
      dra: -23.6093,
      drh: -2.8059668,
      elong: 168.365,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59986.410029976854,
      mjd_stop: 59986.41037719908,
      phase: 2.591,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230211_2b_n25062_01_0003.arch?ra=148.67358&dec=25.08402&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230211_2b_n25062_01_0003.arch',
      ra: 148.67358,
      rh: 4.403145892802,
      sangle: 20.783999999999992,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 226.4157,
      unc_a: 0.194,
      unc_b: 0.101,
      unc_theta: -19.001,
      vangle: 108.18200000000002,
      vmag: 18.633,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2023/23Feb11/G96_20230211_2B_N25062_01_0002.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230211_2b_n25062_01_0002.arch?ra=148.67448&dec=25.0837&size=5.00arcmin&format=fits',
      date: '2023-02-11 09:43:15.242',
      ddec: 9.324825,
      dec: 25.0837,
      delta: 3.43199864686625,
      dra: -23.6175,
      drh: -2.8059435,
      elong: 168.3649,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59986.40486391204,
      mjd_stop: 59986.40521113426,
      phase: 2.591,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230211_2b_n25062_01_0002.arch?ra=148.67448&dec=25.0837&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230211_2b_n25062_01_0002.arch',
      ra: 148.67448,
      rh: 4.403154264879,
      sangle: 20.813999999999993,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 226.4152,
      unc_a: 0.194,
      unc_b: 0.101,
      unc_theta: -19.001,
      vangle: 108.18299999999999,
      vmag: 18.633,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2023/23Feb11/G96_20230211_2B_N25062_01_0001.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230211_2b_n25062_01_0001.arch?ra=148.67538&dec=25.08338&size=5.00arcmin&format=fits',
      date: '2023-02-11 09:35:48.809',
      ddec: 9.332662,
      dec: 25.08338,
      delta: 3.43200758591237,
      dra: -23.6252,
      drh: -2.8059201,
      elong: 168.3649,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59986.399696863424,
      mjd_stop: 59986.40004408565,
      phase: 2.5911,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230211_2b_n25062_01_0001.arch?ra=148.67538&dec=25.08338&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230211_2b_n25062_01_0001.arch',
      ra: 148.67538,
      rh: 4.403162638481,
      sangle: 20.845,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 226.4147,
      unc_a: 0.194,
      unc_b: 0.101,
      unc_theta: -19.002,
      vangle: 108.18299999999999,
      vmag: 18.633,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2023/23Feb11/G96_20230211_2B_N25062_01_0004.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230211_2b_n25062_01_0004.arch?ra=148.67269&dec=25.08434&size=5.00arcmin&format=fits',
      date: '2023-02-11 09:58:07.526',
      ddec: 9.309516,
      dec: 25.08434,
      delta: 3.43198089705838,
      dra: -23.6005,
      drh: -2.8059902,
      elong: 168.3651,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59986.41519127315,
      mjd_stop: 59986.41553849537,
      phase: 2.591,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230211_2b_n25062_01_0004.arch?ra=148.67269&dec=25.08434&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230211_2b_n25062_01_0004.arch',
      ra: 148.67269,
      rh: 4.403137528384,
      sangle: 20.752999999999986,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 226.4162,
      unc_a: 0.194,
      unc_b: 0.101,
      unc_theta: -19,
      vangle: 108.18200000000002,
      vmag: 18.633,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2023/23Feb02/G96_20230202_2B_N25063_01_0001.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230202_2b_n25063_01_0001.arch?ra=150.18776&dec=24.47041&size=5.00arcmin&format=fits',
      date: '2023-02-02 05:29:23.327',
      ddec: 10.72774,
      dec: 24.47041,
      delta: 3.46172412844053,
      dra: -21.8975,
      drh: -2.7642613,
      elong: 164.1444,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59977.22856859954,
      mjd_stop: 59977.22891582176,
      phase: 3.4978,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230202_2b_n25063_01_0001.arch?ra=150.18776&dec=24.47041&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230202_2b_n25063_01_0001.arch',
      ra: 150.18776,
      rh: 4.417915070964,
      sangle: 64.624,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 225.5245,
      unc_a: 0.191,
      unc_b: 0.1,
      unc_theta: -19.628,
      vangle: 108.892,
      vmag: 18.665,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2023/23Feb02/G96_20230202_2B_N25063_01_0003.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230202_2b_n25063_01_0003.arch?ra=150.18611&dec=24.47114&size=5.00arcmin&format=fits',
      date: '2023-02-02 05:44:11.124',
      ddec: 10.71624,
      dec: 24.47114,
      delta: 3.46167489499863,
      dra: -21.9259,
      drh: -2.7643082,
      elong: 164.1526,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59977.23884402778,
      mjd_stop: 59977.23919125,
      phase: 3.496,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230202_2b_n25063_01_0003.arch?ra=150.18611&dec=24.47114&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230202_2b_n25063_01_0003.arch',
      ra: 150.18611,
      rh: 4.417898665707,
      sangle: 64.59,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 225.5255,
      unc_a: 0.191,
      unc_b: 0.1,
      unc_theta: -19.627,
      vangle: 108.89100000000002,
      vmag: 18.665,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2023/23Feb02/G96_20230202_2B_N25063_01_0002.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230202_2b_n25063_01_0002.arch?ra=150.18694&dec=24.47077&size=5.00arcmin&format=fits',
      date: '2023-02-02 05:36:47.387',
      ddec: 10.72207,
      dec: 24.47077,
      delta: 3.46169948656807,
      dra: -21.912,
      drh: -2.7642848,
      elong: 164.1485,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59977.233708182874,
      mjd_stop: 59977.23405540509,
      phase: 3.4969,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230202_2b_n25063_01_0002.arch?ra=150.18694&dec=24.47077&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230202_2b_n25063_01_0002.arch',
      ra: 150.18694,
      rh: 4.417906865386,
      sangle: 64.607,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 225.525,
      unc_a: 0.191,
      unc_b: 0.1,
      unc_theta: -19.627,
      vangle: 108.89100000000002,
      vmag: 18.665,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2023/23Feb02/G96_20230202_2B_N25063_01_0004.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230202_2b_n25063_01_0004.arch?ra=150.18528&dec=24.4715&size=5.00arcmin&format=fits',
      date: '2023-02-02 05:51:35.648',
      ddec: 10.71022,
      dec: 24.4715,
      delta: 3.46165029304438,
      dra: -21.9395,
      drh: -2.7643317,
      elong: 164.1568,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 59977.243988981485,
      mjd_stop: 59977.2443362037,
      phase: 3.4951,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230202_2b_n25063_01_0004.arch?ra=150.18528&dec=24.4715&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230202_2b_n25063_01_0004.arch',
      ra: 150.18528,
      rh: 4.417890451416,
      sangle: 64.57300000000001,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 225.526,
      unc_a: 0.191,
      unc_b: 0.1,
      unc_theta: -19.627,
      vangle: 108.89100000000002,
      vmag: 18.665,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2023/23Mar20/G96_20230320_2B_N25060_01_0003.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230320_2b_n25060_01_0003.arch?ra=143.03372&dec=26.36934&size=5.00arcmin&format=fits',
      date: '2023-03-20 05:15:11.416',
      ddec: 0.459114,
      dec: 26.36934,
      delta: 3.56344205434251,
      dra: -15.158,
      drh: -2.9689695,
      elong: 136.6708,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60023.21870851852,
      mjd_stop: 60023.21905574074,
      phase: 9.0492,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230320_2b_n25060_01_0003.arch?ra=143.03372&dec=26.36934&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230320_2b_n25060_01_0003.arch',
      ra: 143.03372,
      rh: 4.341750695017,
      sangle: 300.943,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 230.0515,
      unc_a: 0.186,
      unc_b: 0.094,
      unc_theta: -16.232,
      vangle: 105.61599999999999,
      vmag: 18.658,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2023/23Mar20/G96_20230320_2B_N25060_01_0002.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230320_2b_n25060_01_0002.arch?ra=143.03587&dec=26.36928&size=5.00arcmin&format=fits',
      date: '2023-03-20 04:47:46.563',
      ddec: 0.493258,
      dec: 26.36928,
      delta: 3.56328357482417,
      dra: -15.1658,
      drh: -2.9688871,
      elong: 136.6908,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60023.19967086805,
      mjd_stop: 60023.200018090276,
      phase: 9.0457,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230320_2b_n25060_01_0002.arch?ra=143.03587&dec=26.36928&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230320_2b_n25060_01_0002.arch',
      ra: 143.03587,
      rh: 4.341783337297,
      sangle: 300.953,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 230.0496,
      unc_a: 0.186,
      unc_b: 0.094,
      unc_theta: -16.233,
      vangle: 105.61599999999999,
      vmag: 18.658,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2023/23Mar20/G96_20230320_2B_N25060_01_0001.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230320_2b_n25060_01_0001.arch?ra=143.03646&dec=26.36927&size=5.00arcmin&format=fits',
      date: '2023-03-20 04:40:19.087',
      ddec: 0.502514,
      dec: 26.36927,
      delta: 3.56324055479685,
      dra: -15.1666,
      drh: -2.9688646,
      elong: 136.6962,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60023.19449174769,
      mjd_stop: 60023.19483896991,
      phase: 9.0447,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230320_2b_n25060_01_0001.arch?ra=143.03646&dec=26.36927&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230320_2b_n25060_01_0001.arch',
      ra: 143.03646,
      rh: 4.341792217349,
      sangle: 300.955,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 230.0491,
      unc_a: 0.186,
      unc_b: 0.094,
      unc_theta: -16.234,
      vangle: 105.61700000000002,
      vmag: 18.658,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2023/23Mar20/G96_20230320_2B_N25060_01_0004.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230320_2b_n25060_01_0004.arch?ra=143.03314&dec=26.36935&size=5.00arcmin&format=fits',
      date: '2023-03-20 05:22:40.731',
      ddec: 0.449793,
      dec: 26.36935,
      delta: 3.56348543957876,
      dra: -15.1545,
      drh: -2.968992,
      elong: 136.6653,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60023.22390892361,
      mjd_stop: 60023.22425614583,
      phase: 9.0502,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230320_2b_n25060_01_0004.arch?ra=143.03314&dec=26.36935&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230320_2b_n25060_01_0004.arch',
      ra: 143.03314,
      rh: 4.341741778156,
      sangle: 300.94,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 230.052,
      unc_a: 0.186,
      unc_b: 0.094,
      unc_theta: -16.232,
      vangle: 105.61500000000001,
      vmag: 18.658,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2023/23Apr12/G96_20230412_2B_N25059_01_0003.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230412_2b_n25059_01_0003.arch?ra=141.58349&dec=25.9984&size=5.00arcmin&format=fits',
      date: '2023-04-12 06:00:38.210',
      ddec: -5.15829,
      dec: 25.9984,
      delta: 3.80195979437209,
      dra: -2.46266,
      drh: -3.0671843,
      elong: 113.5536,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60046.250268634256,
      mjd_stop: 60046.25061585648,
      phase: 12.3282,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230412_2b_n25059_01_0003.arch?ra=141.58349&dec=25.9984&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230412_2b_n25059_01_0003.arch',
      ra: 141.58349,
      rh: 4.301603680897,
      sangle: 293.043,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 232.3805,
      unc_a: 0.171,
      unc_b: 0.087,
      unc_theta: -15.297,
      vangle: 105.17700000000002,
      vmag: 18.761,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2023/23Apr12/G96_20230412_2B_N25059_01_0001.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230412_2b_n25059_01_0001.arch?ra=141.5837&dec=25.99879&size=5.00arcmin&format=fits',
      date: '2023-04-12 05:43:58.458',
      ddec: -5.14263,
      dec: 25.99879,
      delta: 3.80182003404515,
      dra: -2.49098,
      drh: -3.0671357,
      elong: 113.5648,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60046.238697430555,
      mjd_stop: 60046.23904465278,
      phase: 12.327,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230412_2b_n25059_01_0001.arch?ra=141.5837&dec=25.99879&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230412_2b_n25059_01_0001.arch',
      ra: 141.5837,
      rh: 4.301624177081,
      sangle: 293.046,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 232.3793,
      unc_a: 0.171,
      unc_b: 0.087,
      unc_theta: -15.298,
      vangle: 105.17700000000002,
      vmag: 18.761,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2023/23Apr12/G96_20230412_2B_N25059_01_0004.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230412_2b_n25059_01_0004.arch?ra=141.58338&dec=25.9982&size=5.00arcmin&format=fits',
      date: '2023-04-12 06:08:57.859',
      ddec: -5.16584,
      dec: 25.9982,
      delta: 3.80202969966351,
      dra: -2.44769,
      drh: -3.0672085,
      elong: 113.5479,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60046.2560516088,
      mjd_stop: 60046.25639883102,
      phase: 12.3288,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230412_2b_n25059_01_0004.arch?ra=141.58338&dec=25.9982&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230412_2b_n25059_01_0004.arch',
      ra: 141.58338,
      rh: 4.301593437339,
      sangle: 293.04200000000003,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 232.3811,
      unc_a: 0.171,
      unc_b: 0.087,
      unc_theta: -15.297,
      vangle: 105.17700000000002,
      vmag: 18.761,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2023/23Apr12/G96_20230412_2B_N25059_01_0002.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230412_2b_n25059_01_0002.arch?ra=141.58359&dec=25.99859&size=5.00arcmin&format=fits',
      date: '2023-04-12 05:52:18.397',
      ddec: -5.15055,
      dec: 25.99859,
      delta: 3.80188990373552,
      dra: -2.4771,
      drh: -3.06716,
      elong: 113.5592,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60046.24448376158,
      mjd_stop: 60046.24483098379,
      phase: 12.3276,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230412_2b_n25059_01_0002.arch?ra=141.58359&dec=25.99859&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230412_2b_n25059_01_0002.arch',
      ra: 141.58359,
      rh: 4.301613927738,
      sangle: 293.044,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 232.3799,
      unc_a: 0.171,
      unc_b: 0.087,
      unc_theta: -15.298,
      vangle: 105.17700000000002,
      vmag: 18.761,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2023/23Apr26/G96_20230426_2B_N25059_01_0004.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230426_2b_n25059_01_0004.arch?ra=141.77403&dec=25.40972&size=5.00arcmin&format=fits',
      date: '2023-04-26 03:29:22.764',
      ddec: -7.65546,
      dec: 25.40972,
      delta: 3.97623786986148,
      dra: 5.200972,
      drh: -3.1248584,
      elong: 100.5794,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60060.14522875,
      mjd_stop: 60060.14557597222,
      phase: 13.367,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230426_2b_n25059_01_0004.arch?ra=141.77403&dec=25.40972&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230426_2b_n25059_01_0004.arch',
      ra: 141.77403,
      rh: 4.27675903801,
      sangle: 290.35,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 233.8063,
      unc_a: 0.161,
      unc_b: 0.082,
      unc_theta: -15.197,
      vangle: 105.469,
      vmag: 18.835,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2023/23Apr26/G96_20230426_2B_N25059_01_0002.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230426_2b_n25059_01_0002.arch?ra=141.7736&dec=25.41029&size=5.00arcmin&format=fits',
      date: '2023-04-26 03:13:05.858',
      ddec: -7.63893,
      dec: 25.41029,
      delta: 3.97609069711663,
      dra: 5.188026,
      drh: -3.124812,
      elong: 100.5897,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60060.133921967594,
      mjd_stop: 60060.13426918982,
      phase: 13.3664,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230426_2b_n25059_01_0002.arch?ra=141.7736&dec=25.41029&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230426_2b_n25059_01_0002.arch',
      ra: 141.7736,
      rh: 4.276779442315,
      sangle: 290.352,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 233.8052,
      unc_a: 0.161,
      unc_b: 0.082,
      unc_theta: -15.197,
      vangle: 105.469,
      vmag: 18.835,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2023/23Apr26/G96_20230426_2B_N25059_01_0001.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230426_2b_n25059_01_0001.arch?ra=141.77338&dec=25.41058&size=5.00arcmin&format=fits',
      date: '2023-04-26 03:04:58.351',
      ddec: -7.63061,
      dec: 25.41058,
      delta: 3.97601731611228,
      dra: 5.182483,
      drh: -3.1247888,
      elong: 100.5948,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60060.12827952547,
      mjd_stop: 60060.12862674768,
      phase: 13.3661,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230426_2b_n25059_01_0001.arch?ra=141.77338&dec=25.41058&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230426_2b_n25059_01_0001.arch',
      ra: 141.77338,
      rh: 4.276789624597,
      sangle: 290.353,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 233.8046,
      unc_a: 0.161,
      unc_b: 0.082,
      unc_theta: -15.197,
      vangle: 105.46800000000002,
      vmag: 18.835,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.catalina.survey/data_calibrated/G96/2023/23Apr26/G96_20230426_2B_N25059_01_0003.arch.fz',
      'css:telescope': 'Mount Lemmon Survey, 60-inch telescope (G96)',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230426_2b_n25059_01_0003.arch?ra=141.77382&dec=25.41001&size=5.00arcmin&format=fits',
      date: '2023-04-26 03:21:13.967',
      ddec: -7.64721,
      dec: 25.41001,
      delta: 3.97616421074669,
      dra: 5.19419,
      drh: -3.1248352,
      elong: 100.5845,
      exposure: 30,
      filter: null,
      maglimit: null,
      mjd_start: 60060.139571377316,
      mjd_stop: 60060.13991859954,
      phase: 13.3667,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230426_2b_n25059_01_0003.arch?ra=141.77382&dec=25.41001&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.catalina.survey:data_calibrated:g96_20230426_2b_n25059_01_0003.arch',
      ra: 141.77382,
      rh: 4.276769247385,
      sangle: 290.351,
      seeing: null,
      source: 'catalina_lemmon',
      source_name: 'Catalina Sky Survey, Mt. Lemmon',
      true_anomaly: 233.8057,
      unc_a: 0.161,
      unc_b: 0.082,
      unc_theta: -15.197,
      vangle: 105.469,
      vmag: 18.835,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2005/10/24/sw_1025_29.04_2005_10_24_10_32_23.003.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_29.04_2005_10_24_10_32_23.003.fits?ra=61.97018&dec=18.23815&size=5.00arcmin&format=fits',
      date: '2005-10-24 10:33:23.000',
      ddec: -1.7072,
      dec: 18.23815,
      delta: 3.61445561178914,
      dra: -20.0179,
      drh: 2.5133142,
      elong: 147.4734,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 22,
      mjd_start: 53667.439155092594,
      mjd_stop: 53667.44054398148,
      phase: 6.8529,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_29.04_2005_10_24_10_32_23.003.fits?ra=61.97018&dec=18.23815&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_29.04_2005_10_24_10_32_23.003.fits',
      ra: 61.97018,
      rh: 4.485084400492,
      sangle: 83.387,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 151.6663,
      unc_a: 3.875,
      unc_b: 0.256,
      unc_theta: 21.175,
      vangle: 68.285,
      vmag: 18.819,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2005/10/24/sw_1025_29.04_2005_10_24_10_57_38.003.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_29.04_2005_10_24_10_57_38.003.fits?ra=61.96772&dec=18.23795&size=5.00arcmin&format=fits',
      date: '2005-10-24 10:58:38.000',
      ddec: -1.72591,
      dec: 18.23795,
      delta: 3.61433774312328,
      dra: -20.0045,
      drh: 2.5131888,
      elong: 147.4933,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 22,
      mjd_start: 53667.45668981481,
      mjd_stop: 53667.458078703705,
      phase: 6.8491,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_29.04_2005_10_24_10_57_38.003.fits?ra=61.96772&dec=18.23795&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_29.04_2005_10_24_10_57_38.003.fits',
      ra: 61.96772,
      rh: 4.485109853554,
      sangle: 83.38900000000001,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 151.6678,
      unc_a: 3.875,
      unc_b: 0.256,
      unc_theta: 21.175,
      vangle: 68.285,
      vmag: 18.819,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2005/10/24/sw_1025_29.04_2005_10_24_11_22_44.003.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_29.04_2005_10_24_11_22_44.003.fits?ra=61.96527&dec=18.23775&size=5.00arcmin&format=fits',
      date: '2005-10-24 11:23:44.000',
      ddec: -1.74367,
      dec: 18.23775,
      delta: 3.61422103270017,
      dra: -19.9853,
      drh: 2.5130641,
      elong: 147.513,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 22,
      mjd_start: 53667.47412037037,
      mjd_stop: 53667.47550925926,
      phase: 6.8452,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_29.04_2005_10_24_11_22_44.003.fits?ra=61.96527&dec=18.23775&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_29.04_2005_10_24_11_22_44.003.fits',
      ra: 61.96527,
      rh: 4.485135154148,
      sangle: 83.39100000000002,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 151.6694,
      unc_a: 3.875,
      unc_b: 0.256,
      unc_theta: 21.176,
      vangle: 68.28399999999999,
      vmag: 18.819,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2005/10/28/sw_1025_29.04_2005_10_28_08_42_54.003.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_29.04_2005_10_28_08_42_54.003.fits?ra=61.40987&dec=18.19131&size=5.00arcmin&format=fits',
      date: '2005-10-28 08:43:54.000',
      ddec: -1.88492,
      dec: 18.19131,
      delta: 3.58977687906757,
      dra: -21.7212,
      drh: 2.4852415,
      elong: 151.9043,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 22.4,
      mjd_start: 53671.363125,
      mjd_stop: 53671.36451388889,
      phase: 5.9858,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_29.04_2005_10_28_08_42_54.003.fits?ra=61.40987&dec=18.19131&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_29.04_2005_10_28_08_42_54.003.fits',
      ra: 61.40987,
      rh: 4.490748679398,
      sangle: 83.89999999999998,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 152.011,
      unc_a: 3.911,
      unc_b: 0.258,
      unc_theta: 21.331,
      vangle: 68.17500000000001,
      vmag: 18.809,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2005/10/28/sw_1025_29.04_2005_10_28_09_08_00.003.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_29.04_2005_10_28_09_08_00.003.fits?ra=61.40721&dec=18.19109&size=5.00arcmin&format=fits',
      date: '2005-10-28 09:09:00.000',
      ddec: -1.9047,
      dec: 18.19109,
      delta: 3.58967627801318,
      dra: -21.7305,
      drh: 2.4851168,
      elong: 151.9244,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 22.4,
      mjd_start: 53671.38055555556,
      mjd_stop: 53671.381944444445,
      phase: 5.9818,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_29.04_2005_10_28_09_08_00.003.fits?ra=61.40721&dec=18.19109&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_29.04_2005_10_28_09_08_00.003.fits',
      ra: 61.40721,
      rh: 4.490773698501,
      sangle: 83.90199999999999,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 152.0125,
      unc_a: 3.911,
      unc_b: 0.258,
      unc_theta: 21.332,
      vangle: 68.174,
      vmag: 18.809,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2005/10/28/sw_1025_29.04_2005_10_28_09_33_14.003.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_29.04_2005_10_28_09_33_14.003.fits?ra=61.40454&dec=18.19087&size=5.00arcmin&format=fits',
      date: '2005-10-28 09:34:14.000',
      ddec: -1.92454,
      dec: 18.19087,
      delta: 3.5895756439947,
      dra: -21.7333,
      drh: 2.4849913,
      elong: 151.9445,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 22.4,
      mjd_start: 53671.39807870371,
      mjd_stop: 53671.39946759259,
      phase: 5.9778,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_29.04_2005_10_28_09_33_14.003.fits?ra=61.40454&dec=18.19087&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_29.04_2005_10_28_09_33_14.003.fits',
      ra: 61.40454,
      rh: 4.490798849238,
      sangle: 83.90499999999997,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 152.0141,
      unc_a: 3.911,
      unc_b: 0.258,
      unc_theta: 21.332,
      vangle: 68.174,
      vmag: 18.809,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2005/11/01/sw_1025_29.04_2005_11_01_09_51_59.003.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_29.04_2005_11_01_09_51_59.003.fits?ra=60.78614&dec=18.13658&size=5.00arcmin&format=fits',
      date: '2005-11-01 09:52:59.000',
      ddec: -2.17523,
      dec: 18.13658,
      delta: 3.56871167533886,
      dra: -23.2159,
      drh: 2.4562601,
      elong: 156.5254,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 22.2,
      mjd_start: 53675.411099537036,
      mjd_stop: 53675.41248842593,
      phase: 5.0494,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_29.04_2005_11_01_09_51_59.003.fits?ra=60.78614&dec=18.13658&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_29.04_2005_11_01_09_51_59.003.fits',
      ra: 60.78614,
      rh: 4.496525227995,
      sangle: 84.66199999999998,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 152.3657,
      unc_a: 3.943,
      unc_b: 0.26,
      unc_theta: 21.51,
      vangle: 68.04599999999999,
      vmag: 18.802,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2005/11/01/sw_1025_29.04_2005_11_01_10_17_09.003.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_29.04_2005_11_01_10_17_09.003.fits?ra=60.78329&dec=18.13633&size=5.00arcmin&format=fits',
      date: '2005-11-01 10:18:09.000',
      ddec: -2.19389,
      dec: 18.13633,
      delta: 3.56863203919392,
      dra: -23.2015,
      drh: 2.456135,
      elong: 156.5457,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 22.2,
      mjd_start: 53675.42857638889,
      mjd_stop: 53675.42996527778,
      phase: 5.0452,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_29.04_2005_11_01_10_17_09.003.fits?ra=60.78329&dec=18.13633&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_29.04_2005_11_01_10_17_09.003.fits',
      ra: 60.78329,
      rh: 4.496550020834,
      sangle: 84.666,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 152.3672,
      unc_a: 3.943,
      unc_b: 0.26,
      unc_theta: 21.511,
      vangle: 68.04499999999999,
      vmag: 18.802,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2005/11/01/sw_1025_29.04_2005_11_01_10_42_12.003.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_29.04_2005_11_01_10_42_12.003.fits?ra=60.78046&dec=18.13607&size=5.00arcmin&format=fits',
      date: '2005-11-01 10:43:12.000',
      ddec: -2.21166,
      dec: 18.13607,
      delta: 3.56855324119895,
      dra: -23.1811,
      drh: 2.4560104,
      elong: 156.5659,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 22.2,
      mjd_start: 53675.445972222224,
      mjd_stop: 53675.44736111111,
      phase: 5.041,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_29.04_2005_11_01_10_42_12.003.fits?ra=60.78046&dec=18.13607&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_29.04_2005_11_01_10_42_12.003.fits',
      ra: 60.78046,
      rh: 4.496574697481,
      sangle: 84.67000000000002,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 152.3687,
      unc_a: 3.943,
      unc_b: 0.26,
      unc_theta: 21.512,
      vangle: 68.04499999999999,
      vmag: 18.802,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2005/11/04/sw_1025_25.03_2005_11_04_08_53_06.008.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_25.03_2005_11_04_08_53_06.008.fits?ra=60.30529&dec=18.09296&size=5.00arcmin&format=fits',
      date: '2005-11-04 08:54:06.000',
      ddec: -2.28499,
      dec: 18.09296,
      delta: 3.55627593279335,
      dra: -24.1812,
      drh: 2.435061,
      elong: 159.9271,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 22.4,
      mjd_start: 53678.370208333334,
      mjd_stop: 53678.37159722222,
      phase: 4.3426,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_25.03_2005_11_04_08_53_06.008.fits?ra=60.30529&dec=18.09296&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_25.03_2005_11_04_08_53_06.008.fits',
      ra: 60.30529,
      rh: 4.500705034348,
      sangle: 85.476,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 152.6244,
      unc_a: 3.962,
      unc_b: 0.261,
      unc_theta: 21.652,
      vangle: 67.94300000000001,
      vmag: 18.798,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2005/11/04/sw_1025_25.03_2005_11_04_09_19_49.008.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_25.03_2005_11_04_09_19_49.008.fits?ra=60.30215&dec=18.09268&size=5.00arcmin&format=fits',
      date: '2005-11-04 09:20:49.000',
      ddec: -2.30564,
      dec: 18.09268,
      delta: 3.55620669040931,
      dra: -24.177,
      drh: 2.434928,
      elong: 159.9488,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 22.4,
      mjd_start: 53678.388761574075,
      mjd_stop: 53678.39015046296,
      phase: 4.338,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_25.03_2005_11_04_09_19_49.008.fits?ra=60.30215&dec=18.09268&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_25.03_2005_11_04_09_19_49.008.fits',
      ra: 60.30215,
      rh: 4.500731126834,
      sangle: 85.48200000000003,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 152.626,
      unc_a: 3.962,
      unc_b: 0.261,
      unc_theta: 21.653,
      vangle: 67.94200000000001,
      vmag: 18.798,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2005/11/04/sw_1025_25.03_2005_11_04_09_44_51.008.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_25.03_2005_11_04_09_44_51.008.fits?ra=60.2992&dec=18.09241&size=5.00arcmin&format=fits',
      date: '2005-11-04 09:45:51.000',
      ddec: -2.32456,
      dec: 18.09241,
      delta: 3.55614232127838,
      dra: -24.1664,
      drh: 2.4348035,
      elong: 159.9691,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 22.4,
      mjd_start: 53678.40614583333,
      mjd_stop: 53678.407534722224,
      phase: 4.3337,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_25.03_2005_11_04_09_44_51.008.fits?ra=60.2992&dec=18.09241&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1025_25.03_2005_11_04_09_44_51.008.fits',
      ra: 60.2992,
      rh: 4.500755574019,
      sangle: 85.48700000000002,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 152.6276,
      unc_a: 3.962,
      unc_b: 0.261,
      unc_theta: 21.654,
      vangle: 67.94200000000001,
      vmag: 18.798,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2005/11/22/sw_1026_13.05_2005_11_22_02_29_01.006.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1026_13.05_2005_11_22_02_29_01.006.fits?ra=57.16314&dec=17.79224&size=5.00arcmin&format=fits',
      date: '2005-11-22 02:30:01.000',
      ddec: -2.48325,
      dec: 17.79224,
      delta: 3.53796941188611,
      dra: -26.1838,
      drh: 2.3077939,
      elong: 177.6471,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 21.8,
      mjd_start: 53696.103483796294,
      mjd_stop: 53696.10487268519,
      phase: 0.511,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1026_13.05_2005_11_22_02_29_01.006.fits?ra=57.16314&dec=17.79224&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1026_13.05_2005_11_22_02_29_01.006.fits',
      ra: 57.16314,
      rh: 4.524993245365,
      sangle: 190.441,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 154.1654,
      unc_a: 3.997,
      unc_b: 0.265,
      unc_theta: 22.645,
      vangle: 67.21000000000001,
      vmag: 18.808,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2005/11/22/sw_1026_13.05_2005_11_22_02_53_58.006.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1026_13.05_2005_11_22_02_53_58.006.fits?ra=57.15996&dec=17.79195&size=5.00arcmin&format=fits',
      date: '2005-11-22 02:54:58.000',
      ddec: -2.49038,
      dec: 17.79195,
      delta: 3.53799663408203,
      dra: -26.239,
      drh: 2.3076693,
      elong: 177.6396,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 22,
      mjd_start: 53696.12081018519,
      mjd_stop: 53696.122199074074,
      phase: 0.5126,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1026_13.05_2005_11_22_02_53_58.006.fits?ra=57.15996&dec=17.79195&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1026_13.05_2005_11_22_02_53_58.006.fits',
      ra: 57.15996,
      rh: 4.525016338227,
      sangle: 190.904,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 154.1669,
      unc_a: 3.997,
      unc_b: 0.265,
      unc_theta: 22.646,
      vangle: 67.21000000000001,
      vmag: 18.808,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2005/11/22/sw_1026_13.05_2005_11_22_03_18_57.006.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1026_13.05_2005_11_22_03_18_57.006.fits?ra=57.15677&dec=17.79166&size=5.00arcmin&format=fits',
      date: '2005-11-22 03:19:57.000',
      ddec: -2.49934,
      dec: 17.79166,
      delta: 3.53802417028079,
      dra: -26.2913,
      drh: 2.3075446,
      elong: 177.6318,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 22.2,
      mjd_start: 53696.13815972222,
      mjd_stop: 53696.139548611114,
      phase: 0.5142,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1026_13.05_2005_11_22_03_18_57.006.fits?ra=57.15677&dec=17.79166&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1026_13.05_2005_11_22_03_18_57.006.fits',
      ra: 57.15677,
      rh: 4.52503946069,
      sangle: 191.365,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 154.1684,
      unc_a: 3.997,
      unc_b: 0.265,
      unc_theta: 22.647,
      vangle: 67.209,
      vmag: 18.808,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2006/11/19/sw_1038_39.07_2006_11_19_11_11_22.001.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1038_39.07_2006_11_19_11_11_22.001.fits?ra=96.18453&dec=27.569&size=5.00arcmin&format=fits',
      date: '2006-11-19 11:12:22.000',
      ddec: 4.658338,
      dec: 27.569,
      delta: 3.92223685482178,
      dra: -16.3306,
      drh: -0.3238961,
      elong: 141.1865,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 20.4,
      mjd_start: 54058.46622685185,
      mjd_stop: 54058.46761574074,
      phase: 7.5254,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1038_39.07_2006_11_19_11_11_22.001.fits?ra=96.18453&dec=27.569&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1038_39.07_2006_11_19_11_11_22.001.fits',
      ra: 96.18453,
      rh: 4.73313362115,
      sangle: 87.16300000000001,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 183.5026,
      unc_a: 4.941,
      unc_b: 0.292,
      unc_theta: 6.61,
      vangle: 83.173,
      vmag: 19.213,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2006/11/19/sw_1038_39.07_2006_11_19_11_36_24.001.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1038_39.07_2006_11_19_11_36_24.001.fits?ra=96.18239&dec=27.56954&size=5.00arcmin&format=fits',
      date: '2006-11-19 11:37:24.000',
      ddec: 4.634581,
      dec: 27.56954,
      delta: 3.92206421125974,
      dra: -16.3191,
      drh: -0.3240227,
      elong: 141.2059,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 20.4,
      mjd_start: 54058.483611111114,
      mjd_stop: 54058.485,
      phase: 7.5222,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1038_39.07_2006_11_19_11_36_24.001.fits?ra=96.18239&dec=27.56954&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1038_39.07_2006_11_19_11_36_24.001.fits',
      ra: 96.18239,
      rh: 4.73313036833,
      sangle: 87.15800000000002,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 183.5039,
      unc_a: 4.941,
      unc_b: 0.292,
      unc_theta: 6.611,
      vangle: 83.17200000000003,
      vmag: 19.213,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2006/11/19/sw_1038_39.07_2006_11_19_12_01_25.001.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1038_39.07_2006_11_19_12_01_25.001.fits?ra=96.18026&dec=27.57008&size=5.00arcmin&format=fits',
      date: '2006-11-19 12:02:25.000',
      ddec: 4.611998,
      dec: 27.57008,
      delta: 3.9218921037268,
      dra: -16.3021,
      drh: -0.3241493,
      elong: 141.2253,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 20.6,
      mjd_start: 54058.50098379629,
      mjd_stop: 54058.50237268519,
      phase: 7.519,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1038_39.07_2006_11_19_12_01_25.001.fits?ra=96.18026&dec=27.57008&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1038_39.07_2006_11_19_12_01_25.001.fits',
      ra: 96.18026,
      rh: 4.733127116405,
      sangle: 87.15300000000002,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 183.5053,
      unc_a: 4.941,
      unc_b: 0.292,
      unc_theta: 6.613,
      vangle: 83.17099999999999,
      vmag: 19.213,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2006/11/23/sw_1038_39.07_2006_11_23_10_29_27.001.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1038_39.07_2006_11_23_10_29_27.001.fits?ra=95.68168&dec=27.69312&size=5.00arcmin&format=fits',
      date: '2006-11-23 10:30:27.000',
      ddec: 4.611487,
      dec: 27.69312,
      delta: 3.88442498555622,
      dra: -18.264,
      drh: -0.3528289,
      elong: 145.6025,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 20.6,
      mjd_start: 54062.43711805555,
      mjd_stop: 54062.43850694445,
      phase: 6.7753,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1038_39.07_2006_11_23_10_29_27.001.fits?ra=95.68168&dec=27.69312&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1038_39.07_2006_11_23_10_29_27.001.fits',
      ra: 95.68168,
      rh: 4.732357583862,
      sangle: 85.87700000000001,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 183.8159,
      unc_a: 4.992,
      unc_b: 0.295,
      unc_theta: 6.884,
      vangle: 82.95999999999998,
      vmag: 19.191,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2006/11/23/sw_1038_39.07_2006_11_23_10_54_27.001.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1038_39.07_2006_11_23_10_54_27.001.fits?ra=95.67929&dec=27.69365&size=5.00arcmin&format=fits',
      date: '2006-11-23 10:55:27.000',
      ddec: 4.586336,
      dec: 27.69365,
      delta: 3.88426929918881,
      dra: -18.2572,
      drh: -0.3529554,
      elong: 145.6221,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 20.2,
      mjd_start: 54062.45447916666,
      mjd_stop: 54062.45586805556,
      phase: 6.7719,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1038_39.07_2006_11_23_10_54_27.001.fits?ra=95.67929&dec=27.69365&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1038_39.07_2006_11_23_10_54_27.001.fits',
      ra: 95.67929,
      rh: 4.732354045271,
      sangle: 85.87,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 183.8172,
      unc_a: 4.993,
      unc_b: 0.295,
      unc_theta: 6.886,
      vangle: 82.959,
      vmag: 19.191,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2006/11/23/sw_1038_39.07_2006_11_23_11_19_25.001.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1038_39.07_2006_11_23_11_19_25.001.fits?ra=95.67691&dec=27.69418&size=5.00arcmin&format=fits',
      date: '2006-11-23 11:20:25.000',
      ddec: 4.562114,
      dec: 27.69418,
      delta: 3.88411426013047,
      dra: -18.2447,
      drh: -0.3530817,
      elong: 145.6417,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 20.4,
      mjd_start: 54062.471817129626,
      mjd_stop: 54062.47320601852,
      phase: 6.7685,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1038_39.07_2006_11_23_11_19_25.001.fits?ra=95.67691&dec=27.69418&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1038_39.07_2006_11_23_11_19_25.001.fits',
      ra: 95.67691,
      rh: 4.732350510133,
      sangle: 85.86399999999998,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 183.8186,
      unc_a: 4.993,
      unc_b: 0.295,
      unc_theta: 6.887,
      vangle: 82.95800000000003,
      vmag: 19.191,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2006/12/11/sw_1039_19.06_2006_12_11_05_44_56.007.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1039_19.06_2006_12_11_05_44_56.007.fits?ra=92.82683&dec=28.1975&size=5.00arcmin&format=fits',
      date: '2006-12-11 05:45:56.000',
      ddec: 3.855597,
      dec: 28.1975,
      delta: 3.76780326365745,
      dra: -24.4146,
      drh: -0.4825234,
      elong: 165.6264,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 19.8,
      mjd_start: 54080.239537037036,
      mjd_stop: 54080.24092592593,
      phase: 2.9689,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1039_19.06_2006_12_11_05_44_56.007.fits?ra=92.82683&dec=28.1975&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1039_19.06_2006_12_11_05_44_56.007.fits',
      ra: 92.82683,
      rh: 4.728062957228,
      sangle: 72.054,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 185.2217,
      unc_a: 5.133,
      unc_b: 0.307,
      unc_theta: 8.476,
      vangle: 81.65899999999999,
      vmag: 19.121,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2006/12/11/sw_1039_19.06_2006_12_11_06_09_55.007.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1039_19.06_2006_12_11_06_09_55.007.fits?ra=92.82363&dec=28.19794&size=5.00arcmin&format=fits',
      date: '2006-12-11 06:10:55.000',
      ddec: 3.832207,
      dec: 28.19794,
      delta: 3.76773332792463,
      dra: -24.4512,
      drh: -0.4826498,
      elong: 165.6458,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 20,
      mjd_start: 54080.256886574076,
      mjd_stop: 54080.25827546296,
      phase: 2.965,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1039_19.06_2006_12_11_06_09_55.007.fits?ra=92.82363&dec=28.19794&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1039_19.06_2006_12_11_06_09_55.007.fits',
      ra: 92.82363,
      rh: 4.728058121503,
      sangle: 72.023,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 185.223,
      unc_a: 5.133,
      unc_b: 0.307,
      unc_theta: 8.478,
      vangle: 81.65800000000002,
      vmag: 19.121,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2006/12/11/sw_1039_19.06_2006_12_11_06_34_54.007.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1039_19.06_2006_12_11_06_34_54.007.fits?ra=92.82041&dec=28.19838&size=5.00arcmin&format=fits',
      date: '2006-12-11 06:35:54.000',
      ddec: 3.807255,
      dec: 28.19838,
      delta: 3.76766380957451,
      dra: -24.4825,
      drh: -0.4827762,
      elong: 165.6651,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 20,
      mjd_start: 54080.27423611111,
      mjd_stop: 54080.275625,
      phase: 2.9611,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1039_19.06_2006_12_11_06_34_54.007.fits?ra=92.82041&dec=28.19838&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1039_19.06_2006_12_11_06_34_54.007.fits',
      ra: 92.82041,
      rh: 4.728053284512,
      sangle: 71.99199999999999,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 185.2244,
      unc_a: 5.133,
      unc_b: 0.307,
      unc_theta: 8.48,
      vangle: 81.656,
      vmag: 19.121,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2006/12/15/sw_1039_19.06_2006_12_15_05_22_47.007.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1039_19.06_2006_12_15_05_22_47.007.fits?ra=92.09093&dec=28.29089&size=5.00arcmin&format=fits',
      date: '2006-12-15 05:23:47.000',
      ddec: 3.518954,
      dec: 28.29089,
      delta: 3.75468966658042,
      dra: -25.1553,
      drh: -0.5115491,
      elong: 169.8956,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 20.4,
      mjd_start: 54084.22415509259,
      mjd_stop: 54084.22554398148,
      phase: 2.0987,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1039_19.06_2006_12_15_05_22_47.007.fits?ra=92.09093&dec=28.29089&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1039_19.06_2006_12_15_05_22_47.007.fits',
      ra: 92.09093,
      rh: 4.726919101072,
      sangle: 62.27099999999999,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 185.5367,
      unc_a: 5.141,
      unc_b: 0.309,
      unc_theta: 8.896,
      vangle: 81.30599999999998,
      vmag: 19.113,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2006/12/15/sw_1039_19.06_2006_12_15_05_47_44.007.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1039_19.06_2006_12_15_05_47_44.007.fits?ra=92.08763&dec=28.2913&size=5.00arcmin&format=fits',
      date: '2006-12-15 05:48:44.000',
      ddec: 3.49553,
      dec: 28.2913,
      delta: 3.75464159781911,
      dra: -25.1914,
      drh: -0.5116753,
      elong: 169.9135,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 20.4,
      mjd_start: 54084.241481481484,
      mjd_stop: 54084.24287037037,
      phase: 2.095,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1039_19.06_2006_12_15_05_47_44.007.fits?ra=92.08763&dec=28.2913&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1039_19.06_2006_12_15_05_47_44.007.fits',
      ra: 92.08763,
      rh: 4.726913981375,
      sangle: 62.21000000000001,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 185.538,
      unc_a: 5.141,
      unc_b: 0.309,
      unc_theta: 8.898,
      vangle: 81.30399999999997,
      vmag: 19.113,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2006/12/15/sw_1039_19.06_2006_12_15_06_13_20.007.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1039_19.06_2006_12_15_06_13_20.007.fits?ra=92.08423&dec=28.29171&size=5.00arcmin&format=fits',
      date: '2006-12-15 06:14:20.000',
      ddec: 3.469831,
      dec: 28.29171,
      delta: 3.75459270855158,
      dra: -25.2231,
      drh: -0.5118048,
      elong: 169.932,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 20.2,
      mjd_start: 54084.25925925926,
      mjd_stop: 54084.26064814815,
      phase: 2.0912,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1039_19.06_2006_12_15_06_13_20.007.fits?ra=92.08423&dec=28.29171&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1039_19.06_2006_12_15_06_13_20.007.fits',
      ra: 92.08423,
      rh: 4.726908726987,
      sangle: 62.14699999999999,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 185.5394,
      unc_a: 5.141,
      unc_b: 0.309,
      unc_theta: 8.9,
      vangle: 81.303,
      vmag: 19.113,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2007/01/09/sw_1040_08.02_2007_01_09_02_07_49.003.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1040_08.02_2007_01_09_02_07_49.003.fits?ra=87.44055&dec=28.64196&size=5.00arcmin&format=fits',
      date: '2007-01-09 02:08:49.000',
      ddec: 1.129953,
      dec: 28.64196,
      delta: 3.78804723531733,
      dra: -22.9317,
      drh: -0.6926547,
      elong: 158.8098,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 20.4,
      mjd_start: 54109.08876157407,
      mjd_stop: 54109.090150462966,
      phase: 4.3153,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1040_08.02_2007_01_09_02_07_49.003.fits?ra=87.44055&dec=28.64196&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1040_08.02_2007_01_09_02_07_49.003.fits',
      ra: 87.44055,
      rh: 4.718272647012,
      sangle: 282.608,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 187.5063,
      unc_a: 4.992,
      unc_b: 0.31,
      unc_theta: 11.635,
      vangle: 78.95400000000001,
      vmag: 19.125,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2007/01/09/sw_1040_08.02_2007_01_09_02_38_15.003.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1040_08.02_2007_01_09_02_38_15.003.fits?ra=87.43686&dec=28.64211&size=5.00arcmin&format=fits',
      date: '2007-01-09 02:39:15.000',
      ddec: 1.10904,
      dec: 28.64211,
      delta: 3.78815457826117,
      dra: -22.9818,
      drh: -0.6928086,
      elong: 158.7856,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 20.4,
      mjd_start: 54109.10989583333,
      mjd_stop: 54109.111284722225,
      phase: 4.3201,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1040_08.02_2007_01_09_02_38_15.003.fits?ra=87.43686&dec=28.64211&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1040_08.02_2007_01_09_02_38_15.003.fits',
      ra: 87.43686,
      rh: 4.718264191739,
      sangle: 282.59000000000003,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 187.508,
      unc_a: 4.992,
      unc_b: 0.31,
      unc_theta: 11.637,
      vangle: 78.952,
      vmag: 19.125,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2007/01/09/sw_1040_08.02_2007_01_09_03_08_42.003.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1040_08.02_2007_01_09_03_08_42.003.fits?ra=87.43317&dec=28.64227&size=5.00arcmin&format=fits',
      date: '2007-01-09 03:09:42.000',
      ddec: 1.084693,
      dec: 28.64227,
      delta: 3.78826246751243,
      dra: -23.026,
      drh: -0.6929626,
      elong: 158.7615,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 20.6,
      mjd_start: 54109.13104166667,
      mjd_stop: 54109.13243055555,
      phase: 4.3248,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1040_08.02_2007_01_09_03_08_42.003.fits?ra=87.43317&dec=28.64227&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1040_08.02_2007_01_09_03_08_42.003.fits',
      ra: 87.43317,
      rh: 4.718255729956,
      sangle: 282.572,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 187.5097,
      unc_a: 4.992,
      unc_b: 0.31,
      unc_theta: 11.64,
      vangle: 78.94999999999999,
      vmag: 19.125,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2012/11/05/sw_1112_24.07_2012_11_05_06_42_22.005.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1112_24.07_2012_11_05_06_42_22.005.fits?ra=67.92996&dec=21.01983&size=5.00arcmin&format=fits',
      date: '2012-11-05 06:43:22.000',
      ddec: -0.41785,
      dec: 21.01983,
      delta: 3.72115268559504,
      dra: -21.699,
      drh: 2.2940009,
      elong: 153.5901,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 22,
      mjd_start: 56236.2794212963,
      mjd_stop: 56236.280810185184,
      phase: 5.4704,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1112_24.07_2012_11_05_06_42_22.005.fits?ra=67.92996&dec=21.01983&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1112_24.07_2012_11_05_06_42_22.005.fits',
      ra: 67.92996,
      rh: 4.630229374975,
      sangle: 83.13499999999999,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 153.3795,
      unc_a: 10.607,
      unc_b: 0.258,
      unc_theta: 20.136,
      vangle: 70.513,
      vmag: 19.01,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2012/11/05/sw_1112_24.07_2012_11_05_07_12_49.005.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1112_24.07_2012_11_05_07_12_49.005.fits?ra=67.92668&dec=21.01977&size=5.00arcmin&format=fits',
      date: '2012-11-05 07:13:49.000',
      ddec: -0.44087,
      dec: 21.01977,
      delta: 3.7210333082912,
      dra: -21.7421,
      drh: 2.2938807,
      elong: 153.6146,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 22,
      mjd_start: 56236.30056712963,
      mjd_stop: 56236.30195601852,
      phase: 5.4656,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1112_24.07_2012_11_05_07_12_49.005.fits?ra=67.92668&dec=21.01977&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1112_24.07_2012_11_05_07_12_49.005.fits',
      ra: 67.92668,
      rh: 4.630257391193,
      sangle: 83.13499999999999,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 153.3808,
      unc_a: 10.608,
      unc_b: 0.258,
      unc_theta: 20.137,
      vangle: 70.512,
      vmag: 19.01,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2012/11/05/sw_1112_24.07_2012_11_05_07_38_18.005.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1112_24.07_2012_11_05_07_38_18.005.fits?ra=67.92393&dec=21.01971&size=5.00arcmin&format=fits',
      date: '2012-11-05 07:39:18.000',
      ddec: -0.4613,
      dec: 21.01971,
      delta: 3.72093390975838,
      dra: -21.7717,
      drh: 2.2937802,
      elong: 153.6351,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 22,
      mjd_start: 56236.31826388889,
      mjd_stop: 56236.319652777776,
      phase: 5.4616,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1112_24.07_2012_11_05_07_38_18.005.fits?ra=67.92393&dec=21.01971&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1112_24.07_2012_11_05_07_38_18.005.fits',
      ra: 67.92393,
      rh: 4.630280836584,
      sangle: 83.13499999999999,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 153.3819,
      unc_a: 10.608,
      unc_b: 0.258,
      unc_theta: 20.138,
      vangle: 70.512,
      vmag: 19.01,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2012/11/17/sw_1112_24.07_2012_11_17_05_20_21.008.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1112_24.07_2012_11_17_05_20_21.008.fits?ra=65.95934&dec=20.94968&size=5.00arcmin&format=fits',
      date: '2012-11-17 05:21:21.000',
      ddec: -1.02714,
      dec: 20.94968,
      delta: 3.67581537238203,
      dra: -24.9713,
      drh: 2.2262294,
      elong: 167.4263,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 22.2,
      mjd_start: 56248.22246527778,
      mjd_stop: 56248.22385416667,
      phase: 2.6608,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1112_24.07_2012_11_17_05_20_21.008.fits?ra=65.95934&dec=20.94968&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1112_24.07_2012_11_17_05_20_21.008.fits',
      ra: 65.95934,
      rh: 4.645819196466,
      sangle: 83.555,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 154.1058,
      unc_a: 10.817,
      unc_b: 0.262,
      unc_theta: 20.781,
      vangle: 69.989,
      vmag: 18.997,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2012/11/17/sw_1112_24.07_2012_11_17_05_46_16.008.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1112_24.07_2012_11_17_05_46_16.008.fits?ra=65.95613&dec=20.94955&size=5.00arcmin&format=fits',
      date: '2012-11-17 05:47:16.000',
      ddec: -1.04513,
      dec: 20.94955,
      delta: 3.67577748652822,
      dra: -25.0112,
      drh: 2.2261274,
      elong: 167.4476,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 22.4,
      mjd_start: 56248.24046296296,
      mjd_stop: 56248.24185185185,
      phase: 2.6564,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1112_24.07_2012_11_17_05_46_16.008.fits?ra=65.95613&dec=20.94955&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1112_24.07_2012_11_17_05_46_16.008.fits',
      ra: 65.95613,
      rh: 4.645842336832,
      sangle: 83.55700000000002,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 154.1069,
      unc_a: 10.818,
      unc_b: 0.262,
      unc_theta: 20.782,
      vangle: 69.988,
      vmag: 18.997,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2012/11/17/sw_1112_24.07_2012_11_17_06_12_09.008.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1112_24.07_2012_11_17_06_12_09.008.fits?ra=65.95292&dec=20.94942&size=5.00arcmin&format=fits',
      date: '2012-11-17 06:13:09.000',
      ddec: -1.06448,
      dec: 20.94942,
      delta: 3.67574010965063,
      dra: -25.0455,
      drh: 2.2260255,
      elong: 167.4689,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 22.4,
      mjd_start: 56248.2584375,
      mjd_stop: 56248.25982638889,
      phase: 2.652,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1112_24.07_2012_11_17_06_12_09.008.fits?ra=65.95292&dec=20.94942&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1112_24.07_2012_11_17_06_12_09.008.fits',
      ra: 65.95292,
      rh: 4.645865446373,
      sangle: 83.55900000000003,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 154.108,
      unc_a: 10.818,
      unc_b: 0.262,
      unc_theta: 20.783,
      vangle: 69.988,
      vmag: 18.997,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2012/11/22/sw_1112_24.07_2012_11_22_05_18_45.008.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1112_24.07_2012_11_22_05_18_45.008.fits?ra=65.07014&dec=20.90675&size=5.00arcmin&format=fits',
      date: '2012-11-22 05:19:45.000',
      ddec: -1.24195,
      dec: 20.90675,
      delta: 3.66987574581735,
      dra: -25.6361,
      drh: 2.1979017,
      elong: 173.2923,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 22,
      mjd_start: 56253.221354166664,
      mjd_stop: 56253.22274305556,
      phase: 1.4264,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1112_24.07_2012_11_22_05_18_45.008.fits?ra=65.07014&dec=20.90675&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1112_24.07_2012_11_22_05_18_45.008.fits',
      ra: 65.07014,
      rh: 4.652205689858,
      sangle: 85.012,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 154.4081,
      unc_a: 10.86,
      unc_b: 0.262,
      unc_theta: 21.084,
      vangle: 69.738,
      vmag: 18.999,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2012/11/22/sw_1112_24.07_2012_11_22_05_44_12.008.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1112_24.07_2012_11_22_05_44_12.008.fits?ra=65.0669&dec=20.9066&size=5.00arcmin&format=fits',
      date: '2012-11-22 05:45:12.000',
      ddec: -1.26059,
      dec: 20.9066,
      delta: 3.6698668494971,
      dra: -25.6692,
      drh: 2.1978016,
      elong: 173.3133,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 22,
      mjd_start: 56253.23902777778,
      mjd_stop: 56253.24041666667,
      phase: 1.422,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1112_24.07_2012_11_22_05_44_12.008.fits?ra=65.0669&dec=20.9066&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1112_24.07_2012_11_22_05_44_12.008.fits',
      ra: 65.0669,
      rh: 4.652228124196,
      sangle: 85.02199999999999,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 154.4091,
      unc_a: 10.861,
      unc_b: 0.262,
      unc_theta: 21.085,
      vangle: 69.737,
      vmag: 18.999,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2012/11/22/sw_1112_24.07_2012_11_22_06_09_42.008.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1112_24.07_2012_11_22_06_09_42.008.fits?ra=65.06366&dec=20.90644&size=5.00arcmin&format=fits',
      date: '2012-11-22 06:10:42.000',
      ddec: -1.28041,
      dec: 20.90644,
      delta: 3.66985840392331,
      dra: -25.6965,
      drh: 2.1977013,
      elong: 173.3344,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 22,
      mjd_start: 56253.256736111114,
      mjd_stop: 56253.258125,
      phase: 1.4175,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1112_24.07_2012_11_22_06_09_42.008.fits?ra=65.06366&dec=20.90644&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1112_24.07_2012_11_22_06_09_42.008.fits',
      ra: 65.06366,
      rh: 4.652250601581,
      sangle: 85.03199999999998,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 154.4102,
      unc_a: 10.861,
      unc_b: 0.262,
      unc_theta: 21.086,
      vangle: 69.73599999999999,
      vmag: 18.999,
    },
    {
      airmass: null,
      archive_url:
        'https://sbnarchive.psi.edu/pds4/surveys/gbo.ast.spacewatch.survey/data/2014/12/22/sw_1138_33.02_2014_12_22_12_22_20.001.fits',
      cutout_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1138_33.02_2014_12_22_12_22_20.001.fits?ra=139.69033&dec=25.84805&size=5.00arcmin&format=fits',
      date: '2014-12-22 12:23:20.000',
      ddec: 8.741771,
      dec: 25.84805,
      delta: 3.9129616676499,
      dra: -10.445,
      drh: -2.0727533,
      elong: 135.3814,
      exposure: 120,
      filter: 'Schott OG-515',
      maglimit: 22,
      mjd_start: 57013.51550925926,
      mjd_stop: 57013.51689814815,
      phase: 8.5233,
      preview_url:
        'https://5ub5yo2kmj.execute-api.us-east-1.amazonaws.com/api/images/urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1138_33.02_2014_12_22_12_22_20.001.fits?ra=139.69033&dec=25.84805&size=5.00arcmin&format=jpeg',
      product_id:
        'urn:nasa:pds:gbo.ast.spacewatch.survey:data:sw_1138_33.02_2014_12_22_12_22_20.001.fits',
      ra: 139.69033,
      rh: 4.664753616757,
      sangle: 97.94600000000003,
      seeing: null,
      source: 'spacewatch',
      source_name: 'Spacewatch',
      true_anomaly: 210.6596,
      unc_a: 10.508,
      unc_b: 0.473,
      unc_theta: -25.326,
      vangle: 104.767,
      vmag: 19.149,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2004-10-08 06:58:42.000',
      ddec: -7.70496,
      dec: -3.71074,
      delta: 2.66429118236259,
      dra: -28.3411,
      drh: 4.9278757,
      elong: 168.5964,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53286.29050925926,
      mjd_stop: 53286.29101851852,
      phase: 3.1037,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:041008_2a_213_fits',
      ra: 20.02446,
      rh: 3.648985727831,
      sangle: 148.536,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 111.3286,
      unc_a: 5.357,
      unc_b: 0.328,
      unc_theta: 29.8,
      vangle: 60.07599999999999,
      vmag: 17.328,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2004-10-08 07:05:41.000',
      ddec: -7.70273,
      dec: -3.71099,
      delta: 2.66430369049278,
      dra: -28.3447,
      drh: 4.9278541,
      elong: 168.5974,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53286.2953587963,
      mjd_stop: 53286.29586805555,
      phase: 3.1034,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:041008_2a_219_fits',
      ra: 20.02355,
      rh: 3.648999529797,
      sangle: 148.565,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 111.3293,
      unc_a: 5.357,
      unc_b: 0.328,
      unc_theta: 29.799,
      vangle: 60.07599999999999,
      vmag: 17.328,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2004-10-08 07:12:42.500',
      ddec: -7.70047,
      dec: -3.71124,
      delta: 2.66431631289468,
      dra: -28.3476,
      drh: 4.9278324,
      elong: 168.5985,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53286.30023148148,
      mjd_stop: 53286.30075231481,
      phase: 3.1031,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:041008_2a_225_fits',
      ra: 20.02262,
      rh: 3.649013414052,
      sangle: 148.594,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 111.3299,
      unc_a: 5.357,
      unc_b: 0.328,
      unc_theta: 29.799,
      vangle: 60.07599999999999,
      vmag: 17.328,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2004-10-08 07:19:43.500',
      ddec: -7.69822,
      dec: -3.71149,
      delta: 2.66432896013273,
      dra: -28.3499,
      drh: 4.9278106,
      elong: 168.5995,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53286.30510416667,
      mjd_stop: 53286.305625,
      phase: 3.1028,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:041008_2a_231_fits',
      ra: 20.0217,
      rh: 3.649027281774,
      sangle: 148.623,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 111.3306,
      unc_a: 5.357,
      unc_b: 0.328,
      unc_theta: 29.799,
      vangle: 60.07599999999999,
      vmag: 17.328,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2005-11-21 05:58:02.000',
      ddec: -2.5929,
      dec: 17.80748,
      delta: 3.53655356106684,
      dra: -26.5205,
      drh: 2.3139423,
      elong: 177.8094,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53695.24837962963,
      mjd_stop: 53695.24888888889,
      phase: 0.4781,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:051121_1a_230_fits',
      ra: 57.31854,
      rh: 4.523851414709,
      sangle: 165.144,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 154.0914,
      unc_a: 3.999,
      unc_b: 0.265,
      unc_theta: 22.593,
      vangle: 67.249,
      vmag: 18.806,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2005-11-21 06:24:23.500',
      ddec: -2.61103,
      dec: 17.80716,
      delta: 3.53657991772086,
      dra: -26.537,
      drh: 2.3138108,
      elong: 177.8108,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53695.26667824074,
      mjd_stop: 53695.26719907407,
      phase: 0.4778,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:051121_1a_250_fits',
      ra: 57.31514,
      rh: 4.523875876056,
      sangle: 165.716,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 154.093,
      unc_a: 3.999,
      unc_b: 0.265,
      unc_theta: 22.594,
      vangle: 67.24799999999999,
      vmag: 18.806,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2005-11-21 06:50:44.000',
      ddec: -2.62962,
      dec: 17.80684,
      delta: 3.53660679642338,
      dra: -26.5466,
      drh: 2.3136793,
      elong: 177.812,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53695.28497685185,
      mjd_stop: 53695.28548611111,
      phase: 0.4775,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:051121_1a_270_fits',
      ra: 57.31174,
      rh: 4.523900320542,
      sangle: 166.289,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 154.0946,
      unc_a: 3.999,
      unc_b: 0.265,
      unc_theta: 22.596,
      vangle: 67.24700000000001,
      vmag: 18.806,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2005-11-21 07:17:05.000',
      ddec: -2.64844,
      dec: 17.80651,
      delta: 3.53663423056197,
      dra: -26.5491,
      drh: 2.3135478,
      elong: 177.813,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53695.30327546296,
      mjd_stop: 53695.30378472222,
      phase: 0.4772,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:051121_1a_290_fits',
      ra: 57.30834,
      rh: 4.523924771368,
      sangle: 166.86200000000002,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 154.0961,
      unc_a: 3.999,
      unc_b: 0.265,
      unc_theta: 22.597,
      vangle: 67.24600000000001,
      vmag: 18.806,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2005-11-29 04:11:44.000',
      ddec: -2.41276,
      dec: 17.66952,
      delta: 3.55847588979615,
      dra: -25.7254,
      drh: 2.256951,
      elong: 170.5127,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53703.17456018519,
      mjd_stop: 53703.17506944444,
      phase: 2.0496,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:051129_1a_151_fits',
      ra: 55.88982,
      rh: 4.534313481156,
      sangle: 244.90300000000002,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 154.7754,
      unc_a: 3.971,
      unc_b: 0.264,
      unc_theta: 23.079,
      vangle: 66.888,
      vmag: 18.829,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2005-11-29 04:44:35.000',
      ddec: -2.43164,
      dec: 17.66915,
      delta: 3.55856543029979,
      dra: -25.7624,
      drh: 2.2567869,
      elong: 170.4863,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53703.19737268519,
      mjd_stop: 53703.19788194444,
      phase: 2.0552,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:051129_1a_176_fits',
      ra: 55.88572,
      rh: 4.534343215456,
      sangle: 244.941,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 154.7774,
      unc_a: 3.971,
      unc_b: 0.264,
      unc_theta: 23.08,
      vangle: 66.887,
      vmag: 18.829,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2005-11-29 05:17:25.500',
      ddec: -2.45209,
      dec: 17.66878,
      delta: 3.55865571893476,
      dra: -25.7897,
      drh: 2.2566228,
      elong: 170.4598,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53703.22017361111,
      mjd_stop: 53703.22069444445,
      phase: 2.0609,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:051129_1a_201_fits',
      ra: 55.8816,
      rh: 4.534372940046,
      sangle: 244.978,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 154.7793,
      unc_a: 3.971,
      unc_b: 0.264,
      unc_theta: 23.081,
      vangle: 66.886,
      vmag: 18.829,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2005-11-29 05:50:16.500',
      ddec: -2.47368,
      dec: 17.6684,
      delta: 3.55874684176348,
      dra: -25.8067,
      drh: 2.2564587,
      elong: 170.4333,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53703.24298611111,
      mjd_stop: 53703.24350694445,
      phase: 2.0665,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:051129_1a_226_fits',
      ra: 55.87749,
      rh: 4.53440267001,
      sangle: 245.015,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 154.7813,
      unc_a: 3.97,
      unc_b: 0.264,
      unc_theta: 23.083,
      vangle: 66.88499999999999,
      vmag: 18.829,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2005-12-05 06:59:27.000',
      ddec: -2.26895,
      dec: 17.57185,
      delta: 3.58903630384972,
      dra: -24.4408,
      drh: 2.2129315,
      elong: 163.4163,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53709.291030092594,
      mjd_stop: 53709.29153935185,
      phase: 3.5449,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:051205_1a_134_fits',
      ra: 54.83398,
      rh: 4.542208320595,
      sangle: 250.385,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 155.3012,
      unc_a: 3.931,
      unc_b: 0.263,
      unc_theta: 23.454,
      vangle: 66.61099999999999,
      vmag: 18.855,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2005-12-05 07:31:04.500',
      ddec: -2.28883,
      dec: 17.57151,
      delta: 3.58916834663363,
      dra: -24.4139,
      drh: 2.2127734,
      elong: 163.3904,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53709.31298611111,
      mjd_stop: 53709.31350694445,
      phase: 3.5502,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:051205_1a_158_fits',
      ra: 54.83023,
      rh: 4.54223638745,
      sangle: 250.39600000000002,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 155.3031,
      unc_a: 3.93,
      unc_b: 0.263,
      unc_theta: 23.455,
      vangle: 66.61000000000001,
      vmag: 18.855,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2005-12-05 08:02:40.000',
      ddec: -2.30762,
      dec: 17.57118,
      delta: 3.58930099361479,
      dra: -24.3775,
      drh: 2.2126155,
      elong: 163.3645,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53709.33493055555,
      mjd_stop: 53709.335439814815,
      phase: 3.5556,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:051205_1a_182_fits',
      ra: 54.82649,
      rh: 4.542264422715,
      sangle: 250.40699999999998,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 155.3049,
      unc_a: 3.93,
      unc_b: 0.263,
      unc_theta: 23.456,
      vangle: 66.60900000000001,
      vmag: 18.855,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2005-12-05 08:34:18.500',
      ddec: -2.32499,
      dec: 17.57083,
      delta: 3.58943455873257,
      dra: -24.3322,
      drh: 2.2124573,
      elong: 163.3386,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53709.35689814815,
      mjd_stop: 53709.35741898148,
      phase: 3.5609,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:051205_1a_206_fits',
      ra: 54.82275,
      rh: 4.542292500341,
      sangle: 250.418,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 155.3068,
      unc_a: 3.93,
      unc_b: 0.263,
      unc_theta: 23.458,
      vangle: 66.608,
      vmag: 18.855,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2005-12-10 02:45:38.000',
      ddec: -1.80813,
      dec: 17.50443,
      delta: 3.62125491122359,
      dra: -22.7611,
      drh: 2.1781933,
      elong: 157.8149,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53714.11476851852,
      mjd_stop: 53714.115277777775,
      phase: 4.6843,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:051210_1a_020_fits',
      ra: 54.05249,
      rh: 4.548324799044,
      sangle: 252.107,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 155.7145,
      unc_a: 3.889,
      unc_b: 0.261,
      unc_theta: 23.742,
      vangle: 66.398,
      vmag: 18.879,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2005-12-10 03:18:32.500',
      ddec: -1.82351,
      dec: 17.50415,
      delta: 3.62142079164321,
      dra: -22.8025,
      drh: 2.1780287,
      elong: 157.7882,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53714.13761574074,
      mjd_stop: 53714.138136574074,
      phase: 4.6896,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:051210_1a_045_fits',
      ra: 54.04885,
      rh: 4.54835354611,
      sangle: 252.114,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 155.7165,
      unc_a: 3.889,
      unc_b: 0.261,
      unc_theta: 23.743,
      vangle: 66.39699999999999,
      vmag: 18.88,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2005-12-10 03:51:26.000',
      ddec: -1.84084,
      dec: 17.50387,
      delta: 3.62158729037279,
      dra: -22.8353,
      drh: 2.1778642,
      elong: 157.7616,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53714.160462962966,
      mjd_stop: 53714.16097222222,
      phase: 4.695,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:051210_1a_070_fits',
      ra: 54.04521,
      rh: 4.548382276442,
      sangle: 252.12,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 155.7184,
      unc_a: 3.888,
      unc_b: 0.261,
      unc_theta: 23.745,
      vangle: 66.39599999999999,
      vmag: 18.88,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2005-12-10 04:24:20.000',
      ddec: -1.85974,
      dec: 17.50359,
      delta: 3.62175458762791,
      dra: -22.8586,
      drh: 2.1776996,
      elong: 157.7349,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53714.18331018519,
      mjd_stop: 53714.18381944444,
      phase: 4.7003,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:051210_1a_095_fits',
      ra: 54.04156,
      rh: 4.548411011876,
      sangle: 252.126,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 155.7204,
      unc_a: 3.888,
      unc_b: 0.261,
      unc_theta: 23.746,
      vangle: 66.39500000000001,
      vmag: 18.88,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2005-12-21 03:21:13.500',
      ddec: -0.87547,
      dec: 17.39664,
      delta: 3.71973823210992,
      dra: -18.2186,
      drh: 2.0987281,
      elong: 145.1732,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53725.13947916667,
      mjd_stop: 53725.14,
      phase: 7.0691,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:051221_1a_097_fits',
      ra: 52.49698,
      rh: 4.561940383637,
      sangle: 253.77100000000002,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 156.6552,
      unc_a: 3.767,
      unc_b: 0.256,
      unc_theta: 24.352,
      vangle: 65.95500000000001,
      vmag: 18.95,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2005-12-21 03:44:54.000',
      ddec: -0.88849,
      dec: 17.39655,
      delta: 3.71990805585065,
      dra: -18.2295,
      drh: 2.0986095,
      elong: 145.1545,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53725.15592592592,
      mjd_stop: 53725.156435185185,
      phase: 7.0725,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:051221_1a_115_fits',
      ra: 52.49489,
      rh: 4.561960310266,
      sangle: 253.773,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 156.6566,
      unc_a: 3.767,
      unc_b: 0.256,
      unc_theta: 24.353,
      vangle: 65.95400000000001,
      vmag: 18.95,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2005-12-21 04:08:35.000',
      ddec: -0.90201,
      dec: 17.39645,
      delta: 3.72007834786793,
      dra: -18.2353,
      drh: 2.0984909,
      elong: 145.1357,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53725.172372685185,
      mjd_stop: 53725.17288194445,
      phase: 7.0758,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:051221_1a_133_fits',
      ra: 52.4928,
      rh: 4.561980242781,
      sangle: 253.774,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 156.658,
      unc_a: 3.767,
      unc_b: 0.256,
      unc_theta: 24.354,
      vangle: 65.953,
      vmag: 18.95,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2005-12-21 04:32:17.000',
      ddec: -0.91588,
      dec: 17.39635,
      delta: 3.72024917837595,
      dra: -18.2358,
      drh: 2.0983722,
      elong: 145.1168,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53725.18883101852,
      mjd_stop: 53725.18934027778,
      phase: 7.0791,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:051221_1a_151_fits',
      ra: 52.4907,
      rh: 4.56200018819,
      sangle: 253.776,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 156.6594,
      unc_a: 3.767,
      unc_b: 0.256,
      unc_theta: 24.355,
      vangle: 65.953,
      vmag: 18.95,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2005-12-27 02:12:23.500',
      ddec: -0.18988,
      dec: 17.372,
      delta: 3.78599723965717,
      dra: -15.1513,
      drh: 2.0557863,
      elong: 138.4877,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53731.091678240744,
      mjd_stop: 53731.092199074075,
      phase: 8.197,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:051227_1a_025_fits',
      ra: 51.82055,
      rh: 4.569080883328,
      sangle: 254.176,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 157.1607,
      unc_a: 3.691,
      unc_b: 0.252,
      unc_theta: 24.641,
      vangle: 65.75,
      vmag: 18.994,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2005-12-27 02:46:36.500',
      ddec: -0.20637,
      dec: 17.37197,
      delta: 3.78627600275431,
      dra: -15.1764,
      drh: 2.0556148,
      elong: 138.4611,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53731.115439814814,
      mjd_stop: 53731.115960648145,
      phase: 8.2013,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:051227_1a_051_fits',
      ra: 51.81803,
      rh: 4.569109092737,
      sangle: 254.178,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 157.1627,
      unc_a: 3.691,
      unc_b: 0.252,
      unc_theta: 24.642,
      vangle: 65.749,
      vmag: 18.995,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2005-12-27 03:20:47.500',
      ddec: -0.22431,
      dec: 17.37193,
      delta: 3.78655529177179,
      dra: -15.1915,
      drh: 2.0554435,
      elong: 138.4345,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53731.13917824074,
      mjd_stop: 53731.139699074076,
      phase: 8.2056,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:051227_1a_077_fits',
      ra: 51.81551,
      rh: 4.569137272309,
      sangle: 254.179,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 157.1647,
      unc_a: 3.69,
      unc_b: 0.252,
      unc_theta: 24.643,
      vangle: 65.74799999999999,
      vmag: 18.995,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2005-12-27 03:55:01.000',
      ddec: -0.24327,
      dec: 17.37189,
      delta: 3.78683576117215,
      dra: -15.196,
      drh: 2.055272,
      elong: 138.4078,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53731.16295138889,
      mjd_stop: 53731.163460648146,
      phase: 8.2099,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:051227_1a_103_fits',
      ra: 51.81299,
      rh: 4.569165483871,
      sangle: 254.18099999999998,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 157.1667,
      unc_a: 3.69,
      unc_b: 0.252,
      unc_theta: 24.644,
      vangle: 65.74700000000001,
      vmag: 18.995,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2005-12-30 05:05:18.000',
      ddec: 0.072086,
      dec: 17.37006,
      delta: 3.82400365267422,
      dra: -13.5366,
      drh: 2.0332665,
      elong: 135.029,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53734.21175925926,
      mjd_stop: 53734.21226851852,
      phase: 8.7377,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:051230_1a_144_fits',
      ra: 51.51716,
      rh: 4.572764850012,
      sangle: 254.32999999999998,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 157.425,
      unc_a: 3.649,
      unc_b: 0.25,
      unc_theta: 24.778,
      vangle: 65.654,
      vmag: 19.019,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2005-12-30 05:44:42.500',
      ddec: 0.051101,
      dec: 17.37007,
      delta: 3.82434811147749,
      dra: -13.4967,
      drh: 2.0330689,
      elong: 134.9984,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53734.239120370374,
      mjd_stop: 53734.239641203705,
      phase: 8.7423,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:051230_1a_174_fits',
      ra: 51.51458,
      rh: 4.572796983328,
      sangle: 254.33100000000002,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 157.4274,
      unc_a: 3.649,
      unc_b: 0.25,
      unc_theta: 24.78,
      vangle: 65.65299999999999,
      vmag: 19.019,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2005-12-30 06:24:07.000',
      ddec: 0.031753,
      dec: 17.37008,
      delta: 3.82469364789668,
      dra: -13.4432,
      drh: 2.0328713,
      elong: 134.9678,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53734.266493055555,
      mjd_stop: 53734.26700231482,
      phase: 8.747,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:051230_1a_204_fits',
      ra: 51.512,
      rh: 4.572829113514,
      sangle: 254.333,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 157.4297,
      unc_a: 3.648,
      unc_b: 0.25,
      unc_theta: 24.781,
      vangle: 65.65199999999999,
      vmag: 19.02,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2005-12-30 07:03:34.000',
      ddec: 0.0147,
      dec: 17.37008,
      delta: 3.82504055202721,
      dra: -13.3771,
      drh: 2.0326736,
      elong: 134.9372,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53734.29388888889,
      mjd_stop: 53734.29439814815,
      phase: 8.7516,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:051230_1a_234_fits',
      ra: 51.50944,
      rh: 4.572861274537,
      sangle: 254.334,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 157.432,
      unc_a: 3.648,
      unc_b: 0.25,
      unc_theta: 24.782,
      vangle: 65.65199999999999,
      vmag: 19.02,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2006-01-04 03:12:23.500',
      ddec: 0.756334,
      dec: 17.38344,
      delta: 3.88810925185878,
      dra: -10.7598,
      drh: 1.99773,
      elong: 129.6485,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53739.13334490741,
      mjd_stop: 53739.13386574074,
      phase: 9.5142,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:060104_1a_021_fits',
      ra: 51.11599,
      rh: 4.578493403433,
      sangle: 254.52100000000002,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 157.8412,
      unc_a: 3.581,
      unc_b: 0.247,
      unc_theta: 24.972,
      vangle: 65.52199999999999,
      vmag: 19.06,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2006-01-04 03:46:35.500',
      ddec: 0.737794,
      dec: 17.38356,
      delta: 3.88842948863057,
      dra: -10.7547,
      drh: 1.9975585,
      elong: 129.6226,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53739.15709490741,
      mjd_stop: 53739.15761574074,
      phase: 9.5178,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:060104_1a_047_fits',
      ra: 51.1142,
      rh: 4.578520802532,
      sangle: 254.522,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 157.8432,
      unc_a: 3.581,
      unc_b: 0.247,
      unc_theta: 24.973,
      vangle: 65.52199999999999,
      vmag: 19.061,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2006-01-04 04:20:47.500',
      ddec: 0.719118,
      dec: 17.38367,
      delta: 3.88875057507931,
      dra: -10.7387,
      drh: 1.997387,
      elong: 129.5966,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53739.18084490741,
      mjd_stop: 53739.18136574074,
      phase: 9.5214,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:060104_1a_073_fits',
      ra: 51.11242,
      rh: 4.578548199273,
      sangle: 254.523,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 157.8452,
      unc_a: 3.581,
      unc_b: 0.247,
      unc_theta: 24.974,
      vangle: 65.52099999999999,
      vmag: 19.061,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2006-01-04 04:55:00.500',
      ddec: 0.700785,
      dec: 17.38378,
      delta: 3.88907266467531,
      dra: -10.7119,
      drh: 1.9972154,
      elong: 129.5705,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53739.20460648148,
      mjd_stop: 53739.20512731482,
      phase: 9.525,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:060104_1a_099_fits',
      ra: 51.11064,
      rh: 4.578575607005,
      sangle: 254.524,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 157.8472,
      unc_a: 3.58,
      unc_b: 0.246,
      unc_theta: 24.975,
      vangle: 65.52099999999999,
      vmag: 19.061,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2006-01-20 03:55:06.000',
      ddec: 2.780616,
      dec: 17.57293,
      delta: 4.1249793646281,
      dra: -1.50439,
      drh: 1.8818819,
      elong: 112.7904,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53755.16300925926,
      mjd_stop: 53755.163518518515,
      phase: 11.3794,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:060120_1a_030_fits',
      ra: 50.48129,
      rh: 4.596450525989,
      sangle: 254.963,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 159.1894,
      unc_a: 3.361,
      unc_b: 0.234,
      unc_theta: 25.388,
      vangle: 65.269,
      vmag: 19.205,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2006-01-20 04:27:55.500',
      ddec: 2.764872,
      dec: 17.57335,
      delta: 4.12534199050037,
      dra: -1.46868,
      drh: 1.8817171,
      elong: 112.7669,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53755.18579861111,
      mjd_stop: 53755.186319444445,
      phase: 11.3814,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:060120_1a_055_fits',
      ra: 50.48106,
      rh: 4.596475298158,
      sangle: 254.963,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 159.1913,
      unc_a: 3.361,
      unc_b: 0.234,
      unc_theta: 25.388,
      vangle: 65.269,
      vmag: 19.205,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2006-01-20 05:00:50.500',
      ddec: 2.750268,
      dec: 17.57377,
      delta: 4.12570631187013,
      dra: -1.42424,
      drh: 1.8815518,
      elong: 112.7433,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53755.208657407406,
      mjd_stop: 53755.209178240744,
      phase: 11.3834,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:060120_1a_080_fits',
      ra: 50.48083,
      rh: 4.596500137321,
      sangle: 254.964,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 159.1932,
      unc_a: 3.36,
      unc_b: 0.234,
      unc_theta: 25.388,
      vangle: 65.269,
      vmag: 19.205,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2006-01-20 05:33:45.500',
      ddec: 2.73721,
      dec: 17.57419,
      delta: 4.12607126977176,
      dra: -1.37181,
      drh: 1.8813864,
      elong: 112.7197,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53755.231516203705,
      mjd_stop: 53755.232037037036,
      phase: 11.3854,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:060120_1a_105_fits',
      ra: 50.4806,
      rh: 4.596524974299,
      sangle: 254.964,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 159.1951,
      unc_a: 3.36,
      unc_b: 0.234,
      unc_theta: 25.389,
      vangle: 65.269,
      vmag: 19.205,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2006-02-01 02:32:05.000',
      ddec: 4.276191,
      dec: 17.85701,
      delta: 4.32025723989158,
      dra: 5.096418,
      drh: 1.7954796,
      elong: 100.8965,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53767.105358796296,
      mjd_stop: 53767.10586805556,
      phase: 12.1155,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:060201_1a_034_fits',
      ra: 50.67085,
      rh: 4.609131275058,
      sangle: 255.26999999999998,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 160.187,
      unc_a: 3.21,
      unc_b: 0.225,
      unc_theta: 25.464,
      vangle: 65.27000000000001,
      vmag: 19.316,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2006-02-01 03:06:17.000',
      ddec: 4.259239,
      dec: 17.85769,
      delta: 4.32065601444977,
      dra: 5.121808,
      drh: 1.7953077,
      elong: 100.8732,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53767.129108796296,
      mjd_stop: 53767.12961805556,
      phase: 12.1165,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:060201_1a_060_fits',
      ra: 50.6717,
      rh: 4.609155899677,
      sangle: 255.27100000000002,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 160.189,
      unc_a: 3.21,
      unc_b: 0.225,
      unc_theta: 25.464,
      vangle: 65.27000000000001,
      vmag: 19.316,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2006-02-01 03:40:30.500',
      ddec: 4.243079,
      dec: 17.85836,
      delta: 4.3210558231693,
      dra: 5.156648,
      drh: 1.7951357,
      elong: 100.8499,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53767.15287037037,
      mjd_stop: 53767.153391203705,
      phase: 12.1175,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:060201_1a_086_fits',
      ra: 50.67255,
      rh: 4.609180539932,
      sangle: 255.27100000000002,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 160.191,
      unc_a: 3.209,
      unc_b: 0.225,
      unc_theta: 25.464,
      vangle: 65.27000000000001,
      vmag: 19.316,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2006-02-01 04:14:45.000',
      ddec: 4.22814,
      dec: 17.85903,
      delta: 4.32145653234635,
      dra: 5.200447,
      drh: 1.7949636,
      elong: 100.8265,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53767.17665509259,
      mjd_stop: 53767.17716435185,
      phase: 12.1185,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:060201_1a_112_fits',
      ra: 50.67342,
      rh: 4.60920518982,
      sangle: 255.272,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 160.193,
      unc_a: 3.209,
      unc_b: 0.225,
      unc_theta: 25.464,
      vangle: 65.27000000000001,
      vmag: 19.317,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2006-02-21 02:35:41.000',
      ddec: 6.189105,
      dec: 18.56843,
      delta: 4.658524984381,
      dra: 14.72931,
      drh: 1.6506042,
      elong: 82.1961,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53787.1078587963,
      mjd_stop: 53787.10836805555,
      phase: 12.2184,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:060221_1a_023_fits',
      ra: 52.14316,
      rh: 4.629034802279,
      sangle: 255.914,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 161.8459,
      unc_a: 3.002,
      unc_b: 0.211,
      unc_theta: 25.136,
      vangle: 65.638,
      vmag: 19.497,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2006-02-21 03:02:07.500',
      ddec: 6.177526,
      dec: 18.56919,
      delta: 4.65883539785327,
      dra: 14.76019,
      drh: 1.6504712,
      elong: 82.1795,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53787.12621527778,
      mjd_stop: 53787.12673611111,
      phase: 12.2179,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:060221_1a_043_fits',
      ra: 52.14506,
      rh: 4.629052304684,
      sangle: 255.914,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 161.8474,
      unc_a: 3.002,
      unc_b: 0.211,
      unc_theta: 25.135,
      vangle: 65.63900000000001,
      vmag: 19.497,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2006-02-21 03:28:30.500',
      ddec: 6.166902,
      dec: 18.56995,
      delta: 4.65914548555627,
      dra: 14.79554,
      drh: 1.6503384,
      elong: 82.1629,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53787.144537037035,
      mjd_stop: 53787.14505787037,
      phase: 12.2175,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:060221_1a_063_fits',
      ra: 52.14696,
      rh: 4.629069767067,
      sangle: 255.91500000000002,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 161.849,
      unc_a: 3.002,
      unc_b: 0.211,
      unc_theta: 25.135,
      vangle: 65.63900000000001,
      vmag: 19.497,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2006-02-21 03:54:54.500',
      ddec: 6.157363,
      dec: 18.5707,
      delta: 4.65945609822091,
      dra: 14.83509,
      drh: 1.6502055,
      elong: 82.1462,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53787.16287037037,
      mjd_stop: 53787.16339120371,
      phase: 12.217,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:060221_1a_083_fits',
      ra: 52.14887,
      rh: 4.629087239074,
      sangle: 255.916,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 161.8505,
      unc_a: 3.002,
      unc_b: 0.211,
      unc_theta: 25.134,
      vangle: 65.63999999999999,
      vmag: 19.498,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2006-02-21 02:34:22.500',
      ddec: 6.189699,
      dec: 18.5684,
      delta: 4.65850963494691,
      dra: 14.72791,
      drh: 1.6506108,
      elong: 82.197,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53787.106944444444,
      mjd_stop: 53787.107465277775,
      phase: 12.2184,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:060221_2a_022_fits',
      ra: 52.14306,
      rh: 4.629033936223,
      sangle: 255.914,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 161.8459,
      unc_a: 3.002,
      unc_b: 0.211,
      unc_theta: 25.136,
      vangle: 65.638,
      vmag: 19.497,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2006-02-21 03:00:48.000',
      ddec: 6.178085,
      dec: 18.56915,
      delta: 4.65881983413046,
      dra: 14.75853,
      drh: 1.6504778,
      elong: 82.1803,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53787.12530092592,
      mjd_stop: 53787.125810185185,
      phase: 12.2179,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:060221_2a_042_fits',
      ra: 52.14496,
      rh: 4.629051427666,
      sangle: 255.914,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 161.8474,
      unc_a: 3.002,
      unc_b: 0.211,
      unc_theta: 25.135,
      vangle: 65.63900000000001,
      vmag: 19.497,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2006-02-21 03:27:11.000',
      ddec: 6.167411,
      dec: 18.56991,
      delta: 4.65912990450317,
      dra: 14.79366,
      drh: 1.650345,
      elong: 82.1637,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53787.14362268519,
      mjd_stop: 53787.14413194444,
      phase: 12.2175,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:060221_2a_062_fits',
      ra: 52.14687,
      rh: 4.629068890121,
      sangle: 255.91500000000002,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 161.8489,
      unc_a: 3.002,
      unc_b: 0.211,
      unc_theta: 25.135,
      vangle: 65.63900000000001,
      vmag: 19.497,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2006-02-21 03:53:35.000',
      ddec: 6.157814,
      dec: 18.57066,
      delta: 4.659440501424,
      dra: 14.83301,
      drh: 1.6502122,
      elong: 82.147,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53787.16195601852,
      mjd_stop: 53787.162465277775,
      phase: 12.2171,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:060221_2a_082_fits',
      ra: 52.14878,
      rh: 4.629086362198,
      sangle: 255.916,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 161.8504,
      unc_a: 3.002,
      unc_b: 0.211,
      unc_theta: 25.134,
      vangle: 65.63999999999999,
      vmag: 19.498,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2006-03-02 02:31:50.000',
      ddec: 6.81305,
      dec: 18.9634,
      delta: 4.80778167757547,
      dra: 18.39549,
      drh: 1.585383,
      elong: 74.227,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53796.10518518519,
      mjd_stop: 53796.10569444444,
      phase: 11.8674,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:060302_2a_014_fits',
      ra: 53.21631,
      rh: 4.637441735151,
      sangle: 256.271,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 162.5875,
      unc_a: 2.929,
      unc_b: 0.206,
      unc_theta: 24.81,
      vangle: 65.94800000000001,
      vmag: 19.573,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2006-03-02 02:47:40.000',
      ddec: 6.806455,
      dec: 18.9639,
      delta: 4.80796244656469,
      dra: 18.41524,
      drh: 1.5853032,
      elong: 74.2174,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53796.11618055555,
      mjd_stop: 53796.116689814815,
      phase: 11.8669,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:060302_2a_026_fits',
      ra: 53.21774,
      rh: 4.637451801691,
      sangle: 256.271,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 162.5884,
      unc_a: 2.929,
      unc_b: 0.206,
      unc_theta: 24.81,
      vangle: 65.94800000000001,
      vmag: 19.573,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2006-03-02 03:03:30.000',
      ddec: 6.800229,
      dec: 18.9644,
      delta: 4.80814333371944,
      dra: 18.4365,
      drh: 1.5852235,
      elong: 74.2077,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53796.127175925925,
      mjd_stop: 53796.12768518519,
      phase: 11.8664,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:060302_2a_038_fits',
      ra: 53.21916,
      rh: 4.637461867725,
      sangle: 256.272,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 162.5893,
      unc_a: 2.929,
      unc_b: 0.206,
      unc_theta: 24.809,
      vangle: 65.94900000000001,
      vmag: 19.573,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2006-03-02 03:19:22.500',
      ddec: 6.794388,
      dec: 18.9649,
      delta: 4.80832480861414,
      dra: 18.45925,
      drh: 1.5851436,
      elong: 74.198,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 53796.138194444444,
      mjd_stop: 53796.138715277775,
      phase: 11.8658,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:060302_2a_050_fits',
      ra: 53.2206,
      rh: 4.637471959739,
      sangle: 256.272,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 162.5902,
      unc_a: 2.929,
      unc_b: 0.206,
      unc_theta: 24.809,
      vangle: 65.94900000000001,
      vmag: 19.573,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2006-03-14 02:49:10.000',
      ddec: 7.371495,
      dec: 19.54057,
      delta: 4.99782361825993,
      dra: 22.59949,
      drh: 1.4982719,
      elong: 63.9694,
      exposure: 25,
      filter: null,
      maglimit: null,
      mjd_start: 53808.11733796296,
      mjd_stop: 53808.11761574074,
      phase: 11.0799,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:060314_2a_023_fits',
      ra: 54.98518,
      rh: 4.64813725812,
      sangle: 256.81,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 163.5732,
      unc_a: 2.852,
      unc_b: 0.199,
      unc_theta: 24.218,
      vangle: 66.49000000000001,
      vmag: 19.666,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2006-03-14 03:06:51.000',
      ddec: 7.365583,
      dec: 19.54117,
      delta: 4.99801282510392,
      dra: 22.62557,
      drh: 1.4981829,
      elong: 63.959,
      exposure: 25,
      filter: null,
      maglimit: null,
      mjd_start: 53808.12961805556,
      mjd_stop: 53808.129895833335,
      phase: 11.0789,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:060314_2a_041_fits',
      ra: 54.98715,
      rh: 4.648147883123,
      sangle: 256.81100000000004,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 163.5742,
      unc_a: 2.852,
      unc_b: 0.199,
      unc_theta: 24.218,
      vangle: 66.49100000000001,
      vmag: 19.666,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2006-03-14 03:24:28.000',
      ddec: 7.360284,
      dec: 19.54177,
      delta: 4.99820142409987,
      dra: 22.65294,
      drh: 1.4980941,
      elong: 63.9487,
      exposure: 25,
      filter: null,
      maglimit: null,
      mjd_start: 53808.141851851855,
      mjd_stop: 53808.14212962963,
      phase: 11.078,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:060314_2a_059_fits',
      ra: 54.98911,
      rh: 4.648158467442,
      sangle: 256.81100000000004,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 163.5752,
      unc_a: 2.852,
      unc_b: 0.199,
      unc_theta: 24.217,
      vangle: 66.49100000000001,
      vmag: 19.666,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2006-03-14 03:42:05.500',
      ddec: 7.355608,
      dec: 19.54237,
      delta: 4.99839020545817,
      dra: 22.68158,
      drh: 1.4980053,
      elong: 63.9384,
      exposure: 25,
      filter: null,
      maglimit: null,
      mjd_start: 53808.15408564815,
      mjd_stop: 53808.154375,
      phase: 11.0771,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:060314_2a_077_fits',
      ra: 54.99107,
      rh: 4.648169056138,
      sangle: 256.812,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 163.5762,
      unc_a: 2.852,
      unc_b: 0.199,
      unc_theta: 24.216,
      vangle: 66.49199999999999,
      vmag: 19.667,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2007-12-16 11:44:09.000',
      ddec: 9.679536,
      dec: 27.19322,
      delta: 3.60844932480188,
      dra: -9.59175,
      drh: -3.1519181,
      elong: 132.8028,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 54450.48873842593,
      mjd_stop: 54450.48924768518,
      phase: 9.587,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:071216_2a_418_fits',
      ra: 135.8586,
      rh: 4.337790835256,
      sangle: 97.48899999999998,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 216.454,
      unc_a: 8.352,
      unc_b: 0.344,
      unc_theta: -11.782,
      vangle: 101.92899999999997,
      vmag: 18.681,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2007-12-16 12:10:41.000',
      ddec: 9.655354,
      dec: 27.19441,
      delta: 3.60821072497297,
      dra: -9.58536,
      drh: -3.152047,
      elong: 132.8224,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 54450.50716435185,
      mjd_stop: 54450.50767361111,
      phase: 9.5839,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:071216_2a_438_fits',
      ra: 135.85727,
      rh: 4.337757289782,
      sangle: 97.481,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 216.4557,
      unc_a: 8.353,
      unc_b: 0.344,
      unc_theta: -11.781,
      vangle: 101.92899999999997,
      vmag: 18.681,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2007-12-16 12:37:14.000',
      ddec: 9.632246,
      dec: 27.1956,
      delta: 3.6079724378901,
      dra: -9.57231,
      drh: -3.1521759,
      elong: 132.8421,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 54450.52560185185,
      mjd_stop: 54450.52611111111,
      phase: 9.5809,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:071216_2a_458_fits',
      ra: 135.85595,
      rh: 4.337723721868,
      sangle: 97.47399999999999,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 216.4574,
      unc_a: 8.353,
      unc_b: 0.344,
      unc_theta: -11.78,
      vangle: 101.928,
      vmag: 18.681,
    },
    {
      airmass: null,
      archive_url: null,
      cutout_url: null,
      date: '2007-12-16 13:03:47.000',
      ddec: 9.610571,
      dec: 27.19678,
      delta: 3.60773459439127,
      dra: -9.55294,
      drh: -3.1523049,
      elong: 132.8617,
      exposure: 45,
      filter: null,
      maglimit: null,
      mjd_start: 54450.54403935185,
      mjd_stop: 54450.54454861111,
      phase: 9.5778,
      preview_url: null,
      product_id:
        'urn:nasa:pds:gbo.ast.loneos.survey:data_augmented:071216_2a_478_fits',
      ra: 135.85463,
      rh: 4.337690152584,
      sangle: 97.46600000000001,
      seeing: null,
      source: 'loneos',
      source_name: 'LONEOS',
      true_anomaly: 216.4592,
      unc_a: 8.354,
      unc_b: 0.344,
      unc_theta: -11.779,
      vangle: 101.92700000000002,
      vmag: 18.681,
    },
  ],
  job_id: '158359f15ee04935bbefb222cc98b235',
  version: '3.0.0.dev11+g9c7ecce',
};

<div class="streaming-messages-overlay">
  <div class="api-streaming-shade"></div>

  <div class="api-streaming-message">
    <div class="label-row">
      {{ "Target: " + target }}
    </div>

    <div class="label-row">
      {{ "Cached: " + isCached }}
    </div>

    <div class="label-row">
      {{ "Uncertainty Ellipse: " + isUncertaintyEllipse }}
    </div>

    <div class="label-row">
      {{ "Padding: " + padding }}
    </div>

    <div class="label-row">
      {{ "Sources: " }}
    </div>
    <div
      class="label-row"
      style="color: rgba(255, 255, 255, 0.51)"
      *ngFor="let source of sources"
    >
      {{ "> " + source }}
    </div>

    <div class="label-row" style="color: #7e8bff">
      {{ streamingMessage }}
    </div>

    <div class="progress-bar-wrapper">
      <mat-progress-bar mode="query"></mat-progress-bar>
    </div>
  </div>
</div>

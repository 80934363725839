<div class="apis-container">
  <h2>CATCH APIs</h2>

  <h3>Access Methods</h3>

  <p>
    This is the frontend interface of the CATCH web tools that wrap around the
    <a href="https://github.com/Small-Bodies-Node/catch">CATCH</a> command-line
    tool, created by
    <a href="https://www.astro.umd.edu/people/msk.html">Dr Mike Kelley</a> at
    <a href="https://umdphysics.umd.edu/">UMD</a>. The main part of the CATCH
    web tools is the public API that you can access directly through
    <a [href]="DATA_API_ROOT_URL + '/ui'">this swagger interface</a>, or via
    scripts as demonstrated at
    <a [href]="GITHUB_DEMO_SCRIPTS">this github repo</a>.
  </p>

  <h3>Overview of an API Call</h3>

  <p>
    Querying an astronomical object for CATCH data involves a few steps. First,
    you need to ensure that the name of the target comet/asteroid will be
    recognized. SBN provides a separate name-resolution service to which you can
    submit arbitrary text via the 'name' query parameter, e.g. 'Gunn':
  </p>

  <p>
    <a [href]="ROUTE1">
      <code style="margin-bottom: 10px">{{ ROUTE1 }}</code>
    </a>
  </p>

  <p>This will return an array of possible matches of the following form:</p>

  <pre>
    {{ demoJson }}
  </pre>

  <p>
    Once you've identified the object you want to query from those shown to be
    available in this array, use the 'target' property (e.g. here it is the
    exact text of '65P') to start a search for data for that object using the
    '/catch' route as follows:
  </p>

  <p>
    <code>{{ ROUTE2 }}</code>
  </p>

  <p>
    This will return a JSON object with a job_id parameter and information on
    the availability of the data for that target. If the target has been
    searched for previously then it will be cached and the property 'queued'
    will be false. If 'queued=false' then the job_id can be passed straight onto
    the 'caught' route, which retrieves the cached data for that job_id:
  </p>
  <p>
    <code>{{ ROUTE3 }}</code>
  </p>

  <p>
    If data has not been cached (or if you request a recomputation of the data
    via '/catch?target=65P&cached=false' query params), then a lengthy query
    will get launched and the property 'queued=true' will be returned. In this
    case, you'll need to wait until the data is ready before retrieving it via
    the '/caught/&#123;job_id&#125;' route.
  </p>

  <p>
    The time required to prepare the data varies from object to object and
    typically takes between 5-60 seconds. To know exactly when the route is
    ready, you can subscribe to
    <i>Server Sent Events</i>
    generated by the route
  </p>

  <p>
    <code>
      {{ ROUTE4 }}
    </code>
  </p>

  <p>
    ... which will emit messages about the state of the job. If/when the SSE
    emits a message with '"status": "success"', then the data is ready and can
    be retrieved from the '/caught' route described above.
  </p>
</div>

<mat-nav-list (click)="_closeSidenav()">
  <mat-list-item>
    <mat-icon mat-list-icon class="xxx"> home </mat-icon>
    <span (click)="_delayedRouting('home')">Home</span>
  </mat-list-item>

  <mat-list-item>
    <mat-icon mat-list-icon> info </mat-icon>
    <span (click)="_delayedRouting('about')">About</span>
  </mat-list-item>

  <mat-list-item>
    <mat-icon mat-list-icon> sync_alt </mat-icon>
    <span (click)="_delayedRouting('apis')">APIs</span>
  </mat-list-item>

  <mat-list-item>
    <mat-icon mat-list-icon> mail </mat-icon>
    <span (click)="_delayedRouting('contact')">Contact</span>
  </mat-list-item>

  <mat-list-item>
    <mat-icon mat-list-icon style="transform: translate(-2px, -3px)">
      <fa-icon icon="cog" [fixedWidth]="true"></fa-icon>
    </mat-icon>
    <span (click)="_delayedRouting('settings')">Settings</span>
  </mat-list-item>
</mat-nav-list>

<div [class]="'theme-wrapper ' + siteTheme.toLowerCase()">
  <mat-sidenav-container>
    <mat-sidenav mode="push" #sidenav>
      <app-sidenav (closeSidenav)="closeSidenav(sidenav)"></app-sidenav>
    </mat-sidenav>

    <mat-sidenav-content>
      <div
        class="header-wrapper mat-elevation-z4"
        [ngStyle]="getHeaderWrapperStyle()"
      >
        <app-header (openSidenav)="openSidenav(sidenav)"></app-header>
      </div>

      <div class="background-graphic-wrapper">
        <app-background></app-background>
      </div>

      <div class="content-wrapper" [ngStyle]="getContentWrapperStyle()">
        <router-outlet></router-outlet>
      </div>

      <div class="stream-message-wrapper" *ngIf="isStreamingMessage">
        <app-streaming-messages></app-streaming-messages>
      </div>

      <div class="footer-wrapper" [ngStyle]="getFooterStyle()">
        <div class="footer-animator" [ngStyle]="getFooterAnimatorStyle()">
          <app-footer></app-footer>
        </div>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
